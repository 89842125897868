import "./CreateFormation.css";
import { useState } from "react";
import { useAppState } from "../../../utils/appState";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { Pitch, Loader } from "../../common";
import { useMutation } from "@apollo/client";
import { UPDATE_SPORT } from "../../../api/sports";

export default function CreateFormation({ sport }) {
  const PITCH_WIDTH = 324;
  const MARKER_RADIUS = 16;
  const [, setAppState] = useAppState();
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [markers, setMarkers] = useState([]);

  const [mutate, mutation] = useMutation(
    UPDATE_SPORT({
      selectionSet: `
    formations{
      id
      name
      positions{
        id
        positionType{
          id
          name
          shortName
        }
        x
        y
      }
    }
    `,
    })
  );

  const updatePosition = (value, id) => {
    const newMarkers = [...markers];
    newMarkers[id] = {
      ...newMarkers[id],
      ...value,
    };

    // console.log(value);
    setMarkers(newMarkers);
  };

  const deletePosition = (id) => {
    const newMarkers = [
      ...markers.slice(0, id),
      ...markers.slice(id + 1, markers.length),
    ];
    setMarkers(newMarkers);
  };

  return (
    <div
      className="App-modal-form"
      style={{ width: "648px", height: "auto", maxHeight: "95%" }}
    >
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">New Formation</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <div className="App-modal-form__body">
        <div className="position-editor" style={{ width: "100%" }}>
          <div className="pitch-container">
            <Pitch
              width={PITCH_WIDTH}
              markers={markers}
              updatePosition={updatePosition}
              deletePosition={deletePosition}
              deleteZone={true}
            />
          </div>

          <div className="create-formation__form">
            <div className="App-modal-form__body__group__label create-formation__form__label">
              Click a position to add it to the pitch:
            </div>

            <div className="positions">
              {sport.positionTypes.map((p) => (
                <div
                  key={p.id}
                  className="position-item hover-pointer"
                  onClick={() => {
                    const newMarkers = [
                      ...markers,
                      { ...p, x: 32, y: 32, label: p.shortName },
                    ];
                    // newCoordinates[newMarkers.length - 1] = { x: 0, y: 0 };
                    setMarkers(newMarkers);
                    // setCoordinates(newCoordinates);
                  }}
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#37a1fa",
                    color: "white",
                    marginLeft: 16,
                    marginBottom: 16,
                    fontWeight: 600,
                    fontSize: "8px",
                    cursor: "pointer",
                  }}
                >
                  {p.shortName}
                </div>
              ))}
            </div>

            <div
              className="App-modal-form__body__group create-formation__form__group"
              style={{ paddingLeft: "16px", paddingRight: "16px" }}
            >
              <div className="App-modal-form__body__group__label">
                Enter a Name<span className="required">*</span>
              </div>

              <div className="App-modal-form__body__group__input">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  className="App-modal-form__body__group__input__text"
                  // placeholder="Enter Formation Name"
                />
              </div>
            </div>

            {error && (
              <div className="App-modal-form__body__error">{error}</div>
            )}

            <div className="create-formation__form__submit">
              <button
                type="submit"
                className={
                  "App-button App-modal-form__body__submit-btn create-formation__form__submit__btn " +
                  (mutation.loading ? "loading" : "")
                }
                onClick={() => {
                  if (mutation.loading) {
                    return;
                  }

                  mutate({
                    variables: {
                      where: { id: sport.id },
                      data: {
                        formations: {
                          create: {
                            name,
                            positions: {
                              create: markers.map((m) => ({
                                x: (m.x + MARKER_RADIUS) / PITCH_WIDTH,
                                y: (m.y + MARKER_RADIUS) / PITCH_WIDTH,
                                positionType: { connect: { id: m.id } },
                              })),
                            },
                          },
                        },
                      },
                    },
                    update: () => {
                      setAppState({ modal: false });
                    },
                  });
                }}
              >
                {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import LOGIN from "../api/mutations/login";
import queryString from "query-string";
// import { useAppState } from "../../../sp-frontend-pwa/src/utils/appState";

function Login() {
  const location = useLocation();
  const history = useHistory();
  // const [, setAppState] = useAppState();
  // console.log(location);

  const searchParams = queryString.parse(location.search);
  // console.log(searchParams);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [login, { loading }] = useMutation(LOGIN, {
    onError: (e) => {
      console.log(e);
      setError(true);
    },
    update: (cache, { data: { login } }) => {
      if (!login.token || !login.expiresAt) {
        setError(true);
        return;
      }

      localStorage.setItem("sp_auth_token", login.token);
      localStorage.setItem("sp_expires_at", login.expiresAt);
      localStorage.setItem("sp_name", login.name);
      // setAppState({ isLoggedIn: true });

      if (searchParams.referrer) {
        history.push(searchParams.referrer);
      } else {
        history.push("/v2/events");
      }
    },
  });

  return (
    <form
      className="Login"
      onSubmit={(e) => {
        e.preventDefault();
        setError(false);

        if (!email || !password) {
          setError(true);
        }

        login({
          variables: {
            email,
            password,
          },
        });
      }}
    >
      <div className="title">LOGIN</div>
      <div className="form-group">
        <input
          className="input"
          type="email"
          value={email}
          placeholder="Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>

      <div className="form-group">
        <input
          className="input"
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
      </div>

      {error && <div className="error">Invalid username or password.</div>}

      <button type="submit" className="submit">
        Login
      </button>
    </form>
  );
}

export default Login;

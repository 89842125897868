import { gql } from "@apollo/client";

export function EVENT({ selectionSet }) {
  return gql`
    query event($id: ID!) {
      event(id: $id) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function EVENTS() {
  return gql`
    query events($where: EventWhereInput, $cursor: ID) {
      events(where: $where, cursor: $cursor) {
        id
        name
        organizedBy {
          id
          name
        }
        profilePhoto {
          id
          filename
        }
      }
    }
  `;
}

export function UPDATE_EVENT({ selectionSet }) {
  return gql`
    mutation updateEvent($where: UniqueInput!, $data:UpdateEventInput!) {
      updateEvent(where:$where, data:$data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function EVENT_REGISTRATION({ selectionSet }) {
  return gql`
    query eventRegistration($id: ID!) {
      eventRegistration(id: $id) {
        id
        paid
        amountPaid
        jerseyNum
        profile {
          id
          name
          handle
          email
          phone
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  `;
}

export function EVENT_REGISTRATIONS({ selectionSet }) {
  return gql`
    query eventRegistrations($eventId: ID!) {
      eventRegistrations(eventId: $eventId) {
        id
        paid
        amountPaid
        jerseyNum
        profile {
          id
          name
          handle
          email
          phone
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  `;
}

export function UPDATE_REGISTRATION({ selectionSet }) {
  return gql`
    mutation updateRegistration(
      $where: UniqueInput!
      $data: UpdateEventRegistrationInput!
    ) {
      updateRegistration(where: $where, data: $data) {
        id
        paid
        amountPaid
        jerseyNum
        profile {
          id
          name
          handle
          email
          phone
          profilePhoto {
            id
            filename
          }
        }
        organization {
          id
          name
          handle
          profilePhoto {
            id
            filename
          }
        }
      }
    }
  `;
}

export const DELETE_REGISTRATION = gql`
  mutation deleteRegistration($id: ID!) {
    deleteRegistration(id: $id) {
      id
    }
  }
`;

export const CREATE_REGISTRATION = gql`
  mutation createRegistration($data: CreateEventRegistrationInput!) {
    createRegistration(data: $data) {
      id
      paid
      amountPaid
      jerseyNum
      profile {
        id
        name
        handle
        email
        phone
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export function REORDER_EVENT_PARTS({ selectionSet }) {
  return gql`
    mutation reorderEventParts($data: [EventPartReorderInput!]) {
      reorderEventParts(data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export const CREATE_DATA_UPLOAD = gql`
  mutation createDataUpload(
    $uploadType: String!
    $eventId: ID!
    $filename: String!
  ) {
    createDataUpload(
      uploadType: $uploadType
      eventId: $eventId
      filename: $filename
    ) {
      dataUpload {
        id
        rawFilename
        filename
      }
      url
    }
  }
`;

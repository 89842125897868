import "./SportPositionTypeGroups.css";
import { Card, CardTitle, Spinner } from "../../common";
import { useQuery } from "@apollo/client";
import { SPORT } from "../../../api/sports";
import { Fragment } from "react";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { useAppState } from "../../../utils/appState";
import CreatePositionTypeGroup from "../CreatePositionTypeGroup";
import EditPositionTypeGroup from "../EditPositionTypeGroup";
import DeletePositionTypeGroup from "../DeletePositionTypeGroup";

function PositionTypeGroup({ sport, group }) {
  const [, setAppState] = useAppState();
  return (
    <div className="sport-position-groups__items__item">
      <div className="sport-position-groups__items__item__text">
        <div className="sport-position-groups__items__item__text__primary">
          {group.name}
        </div>
      </div>

      <div className="sport-position-groups__items__item__options">
        <div
          className="sport-position-groups__items__item__options__option edit"
          onClick={() => {
            setAppState({
              modal: <EditPositionTypeGroup sport={sport} group={group} />,
            });
          }}
        >
          <PencilIcon className="sport-position-groups__items__item__options__option__icon" />
        </div>

        <div
          className={
            "sport-position-groups__items__item__options__option delete " +
            (group.canDelete ? "" : "disabled")
          }
          onClick={() => {
            setAppState({
              modal: <DeletePositionTypeGroup sport={sport} group={group} />,
            });
          }}
        >
          <TrashIcon className="sport-position-groups__items__item__options__option__icon" />
        </div>
      </div>
    </div>
  );
}

export default function SportPositionTypeGroups({ sport }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet: `
    positionTypeGroups{
      id
      name
      canDelete
    }
  `,
    }),
    {
      variables: {
        id: sport.id,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const groups = data?.sport?.positionTypeGroups || [];

  return (
    <Card className="sport-position-groups">
      <CardTitle
        title="GROUPS"
        options={
          <div
            className="sport-position-groups__add-btn"
            onClick={() => {
              setAppState({
                modal: <CreatePositionTypeGroup sport={data?.sport} />,
              });
            }}
          >
            + ADD
          </div>
        }
      />

      <div className="sport-position-groups__items">
        {loading || !data ? (
          <div className="App-loading-wrapper">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {groups.map((group) => (
              <PositionTypeGroup sport={data?.sport} group={group} />
            ))}
          </Fragment>
        )}
      </div>
    </Card>
  );
}

import ModalComponent from "../ModalComponent";
import FileSelector from "../FileSelector";
import exportTemplate from "../../utils/exportTemplate";
import UPLOAD_DATA from "../../api/mutations/uploadData";
import "../../styles/UploadData.css";
import { useState } from "react";
import Papa from "papaparse";
import { useMutation } from "@apollo/client";
import { useAppState } from "../../utils/appState";

function EditCell({ row, column, value, setCell, setEditing }) {
  const [val, setVal] = useState(value || "");

  return (
    <td>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setCell(row, column, val);
          setEditing(false);
        }}
      >
        <input
          type="text"
          value={val || ""}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          autoFocus
        />
      </form>
    </td>
  );
}

function Cell({ row, column, value, setCell }) {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditCell
        row={row}
        column={column}
        value={value}
        setCell={setCell}
        setEditing={setEditing}
      />
    );
  }

  return (
    <td className={!value ? "empty" : ""} onClick={() => setEditing(true)}>
      {value}
    </td>
  );
}

function UploadData({
  headers,
  type,
  transform,
  validate,
  refetch,
  instructions,
}) {
  const [, setAppState] = useAppState();
  const [rows, setRows] = useState([]);
  const [head, setHead] = useState([]);
  const [filename, setFilename] = useState("");
  const [validationError, setValidationError] = useState("");
  const [uploadData, { loading }] = useMutation(UPLOAD_DATA);

  const setCell = (row, column, value) => {
    setRows((prev) => {
      const newRows = [...prev];
      newRows[row][column] = value;
      return newRows;
    });
  };
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="upload-data">
          <div className="title with-secondary">Upload Data</div>
          <div className="secondary-text">
            Download{" "}
            <span
              className="template-link hover-pointer"
              onClick={() => {
                exportTemplate({
                  headers,
                  type,
                });
              }}
            >
              this template
            </span>
            , fill in the rows with data, and upload it by clicking the box
            below.
          </div>
          {instructions && !!rows.length && (
            <div className="secondary-text">{instructions}</div>
          )}

          {!rows.length ? (
            <FileSelector
              onChange={(e, inputRef) => {
                if (inputRef.current?.files[0]) {
                  console.log("Uploaded:", inputRef?.current?.files[0].name);
                  setFilename(inputRef?.current?.files[0]?.name);
                  Papa.parse(inputRef.current.files[0], {
                    complete: function (results) {
                      console.log("PArsed Data:", results.data);
                      setHead(results.data[0]);
                      setRows(results.data.slice(1, results.data.length));
                    },
                  });
                }
              }}
            />
          ) : (
            <div className="table-wrapper">
              <table>
                <tr>
                  {head.map((r) => (
                    <th>{r}</th>
                  ))}
                </tr>
                <tbody>
                  {rows.map((row, i) => (
                    <tr>
                      {row.map((r, j) => (
                        <Cell row={i} column={j} value={r} setCell={setCell} />
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {!!rows.length && !loading && (
            <div className="footer">
              {validationError && <div>{validationError}</div>}
              <div
                className="App-btn success"
                onClick={() => {
                  if (loading) return;
                  // console.log(transform(rows));
                  console.log("Uploading data clicked");
                  // return;
                  setValidationError(false);
                  console.log("Rows:", rows);
                  const d = transform ? transform(rows) : rows;
                  const isValid = validate(d);

                  if (!isValid.valid) {
                    console.log("Error detected");
                    setValidationError(isValid.error);
                    return;
                  }

                  console.log("Uploading:", d);

                  uploadData({
                    variables: {
                      type,
                      data: JSON.stringify(d),
                      filename,
                    },
                    update: () => {
                      refetch && refetch();
                      setAppState({ modal: false });
                    },
                    onError: (err) => {
                      console.log(err);
                    },
                  });
                }}
              >
                Upload {filename}
              </div>
              <div
                className="App-btn secondary outline"
                onClick={() => {
                  setHead([]);
                  setRows([]);
                }}
              >
                Clear
              </div>
            </div>
          )}

          {loading && (
            <div className="footer">
              Uploading data.... You will be sent an email when the upload is
              complete.
            </div>
          )}
        </div>
      )}
    </ModalComponent>
  );
}

export default UploadData;

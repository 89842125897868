import ModalComponent from "./ModalComponent";
import Form from "./Form";
import Select from "./Select";
import { useQuery, useMutation } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../api/events";
import CREATE_EVENT from "../api/mutations/createEvent";
import GENERATE_FIXTURES from "../api/mutations/generateFixtures";
import EVENTS from "../api/queries/events";
import SPORTS from "../api/queries/sports";
import ORGANIZATIONS from "../api/queries/organizations";
import { useAppState } from "../utils/appState";
import moment from "moment";
import { useState, Fragment } from "react";
import fixtureGenerator from "../utils/fixtureGenerator";
import shuffle from "../utils/shuffle";
import "../styles/GenerateFixtures.css";

function EventParts({ parts, rounds, fixtures, roles, placements }) {
  const [selectedPartIndex, setSelectedPartIndex] = useState(0);

  return (
    <div className="event-parts-container">
      <div
        className="event-parts-list"
        style={{ transform: `translateX(${-100 * selectedPartIndex + "%"})` }}
      >
        {parts.map((part, i) => (
          <div className="event-part">
            <div className="title">
              <div
                className="prev btn hover-pointer"
                onClick={() => {
                  const newPartIndex = Math.max(0, i - 1);
                  setSelectedPartIndex(newPartIndex);
                }}
              >
                {"<"}
              </div>
              <div className="label">{part.label}</div>
              <div
                className="next btn hover-pointer"
                onClick={() => {
                  const newPartIndex = Math.min(parts.length - 1, i + 1);
                  setSelectedPartIndex(newPartIndex);
                }}
              >
                {">"}
              </div>
            </div>

            {!!rounds.filter((r) => r.parent === part.index).length && (
              <EventParts
                parts={rounds.filter((r) => r.parent === part.index)}
                rounds={rounds}
                fixtures={fixtures}
                roles={roles}
                placements={placements}
              />
            )}

            {fixtures
              .filter((f) => f.round === part.index)
              .map((f) => (
                <FixtureItem
                  fixture={f}
                  rounds={rounds}
                  fixtures={fixtures}
                  roles={roles}
                  placements={placements}
                />
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}

function Placeholder({ role, placements, fixtures, rounds }) {
  const placement = placements.find((p) => p.fixtureRole === role.index);
  console.log("Placeholder:", placement);

  if (typeof placement.fixture === "number") {
    return (
      fixtures[placement.fixture].label +
      " " +
      (placement.order ? "Loser" : "Winner")
    );
  }

  if (typeof placement.group === "number") {
    return "#" + (placement.order + 1) + " " + rounds[placement.group].label;
  }

  return "No team added";
}

function FixtureItem({ fixture, rounds, fixtures, roles, placements }) {
  return (
    <div className="fixture-item">
      {fixture.label && <div className="label">{fixture.label}</div>}
      <div className="teams">
        {roles
          .filter((r) => r.fixture === fixture.index)
          .map((r, homeIndex) => (
            <div className={homeIndex ? "away-team" : "home-team"}>
              {r.organization?.name || (
                <Placeholder
                  placements={placements}
                  role={r}
                  fixtures={fixtures}
                  rounds={rounds}
                />
              )}
            </div>
          ))}
      </div>

      <div className="options"></div>
    </div>
  );
}

function GenerateFixtures({ type = "league", eventId, refetch }) {
  const [, setAppState] = useAppState();
  const [rounds, setRounds] = useState(false);
  const [fixtures, setFixtures] = useState(false);
  const [roles, setRoles] = useState(false);
  const [placements, setPlacements] = useState(false);
  // const [selectedPart, setSelectedPart] = useState(0);
  const [formState, setFormState] = useState({
    type,
    meetings: 1,
  });

  const [generate, { loading: generatingFixtures }] = useMutation(
    GENERATE_FIXTURES
  );

  const selectionSet = `
    participantType
    roles(where:{type:{name:"PARTICIPANT"}}){
      id
      type{
        id
        name
      }
      organization{
        id
        name
      }
    }
  `;

  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id: eventId } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const event = data.event;
  const participants = event.roles;
  // console.log(rounds.map((r) => typeof r.parent));
  const rootRounds =
    rounds &&
    rounds.filter((r) => {
      return typeof r.parent !== "number";
    });
  // console.log("Parts:", parts);

  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <div className="App-form">
            <div className="title">Generate Fixtures</div>
            {rootRounds ? (
              <Fragment>
                <div className="fixture-generator-preview">
                  <EventParts
                    parts={rootRounds}
                    rounds={rounds}
                    fixtures={fixtures}
                    roles={roles}
                    placements={placements}
                  />
                </div>

                <div className="options">
                  <button
                    className={"option  submit"}
                    onClick={() => {
                      console.log("Saving fixtures");
                      generate({
                        variables: {
                          eventId,
                          rounds: rounds.map((r) => ({
                            order: r.order,
                            type: r.type,
                            label: r.label,
                            parent: r.parent,
                          })),
                          fixtures: fixtures.map((f) => ({
                            round: f.round,
                            label: f.label,
                          })),
                          roles: roles.map((r) => ({
                            order: r.order,
                            fixture: r.fixture,
                            type: r.type,
                            organizationId: r.organization?.id || null,
                          })),
                          placements: placements
                            ? placements.map((p) => ({
                                order: p.order,
                                fixture: p.fixture,
                                type: "RESULT",
                                group: p.group,
                                fixtureRole: p.fixtureRole,
                              }))
                            : null,
                        },
                        update: (cache, { data: { generateFixtures } }) => {
                          // console.log("RESULT:", result);
                          if (generateFixtures.success) {
                            // console.log(generateFixtures);
                            refetch();
                            setAppState({ modal: false });
                          }
                        },
                        onError: (e) => {
                          console.log("Error occured", e);
                        },
                      });
                    }}
                  >
                    {"Save"}
                  </button>

                  <button
                    className={"option App-btn outline "}
                    onClick={() => {
                      console.log("Shuffling fixtures");
                      console.log("Form state:", formState);
                      let vars = {
                        ...formState,
                        participants: shuffle([...participants]),
                      };
                      if (formState.type === "league-cup") {
                        vars.participants = shuffle([...formState.groups]);
                      }

                      const generated = fixtureGenerator(vars);

                      // console.log(parts);
                      setRounds(generated.rounds);
                      setFixtures(generated.fixtures);
                      setRoles(generated.roles);
                      setPlacements(generated.placements || false);
                    }}
                  >
                    {"Shuffle"}
                  </button>
                </div>
              </Fragment>
            ) : (
              <form
                className={"App-form "}
                onSubmit={(e) => {
                  e.preventDefault();
                  console.log("Form state:", formState);
                  let vars = { ...formState, participants };
                  if (formState.type === "league-cup") {
                    vars.participants = formState.groups;
                  }

                  const generated = fixtureGenerator(vars);
                  // console.log(parts);
                  setRounds(generated.rounds);
                  setFixtures(generated.fixtures);
                  setRoles(generated.roles);
                  setPlacements(generated.placements || false);
                }}
              >
                <div className="form-group">
                  <div className="label">Type</div>
                  <Select
                    items={[
                      { label: "League", value: "league" },
                      { label: "Cup", value: "single-elimination" },
                      { label: "League Cup", value: "league-cup" },
                    ]}
                    value={formState.type}
                    onChange={(e) => {
                      const newState = {};
                      newState["type"] = e.target.value;
                      setFormState({
                        ...formState,
                        ...newState,
                      });
                    }}
                    optionLabel={() => {}}
                  />
                </div>
                {formState.type === "league" && (
                  <div className="form-group">
                    <div className="label">Meetings</div>
                    <input
                      value={formState.meetings}
                      className="input"
                      type={"number"}
                      onChange={(e) => {
                        const newState = {};
                        newState["meetings"] = e.target.value;
                        setFormState({
                          ...formState,
                          ...newState,
                        });
                      }}
                    />
                  </div>
                )}

                {formState.type === "league-cup" && (
                  <Fragment>
                    <div className="form-group">
                      <div className="label">Total Groups in Group Stage</div>
                      <input
                        value={formState.totalGroups}
                        className="input"
                        type={"number"}
                        min={"0"}
                        max={participants.length + ""}
                        onChange={(e) => {
                          const newState = {};
                          newState["totalGroups"] = e.target.value;

                          if (Number(e.target.value)) {
                            let groups = [];
                            let shuffledParticipants = shuffle([
                              ...participants,
                            ]);
                            let participantsPerGroup = Math.floor(
                              shuffledParticipants.length /
                                Number(e.target.value)
                            );

                            for (
                              let i = 0;
                              i < shuffledParticipants.length;
                              i += participantsPerGroup
                            ) {
                              groups.push({
                                teams: shuffledParticipants.slice(
                                  i,
                                  i + participantsPerGroup
                                ),
                              });
                            }

                            newState.groups = groups;
                          }

                          setFormState({
                            ...formState,
                            ...newState,
                          });
                        }}
                      />
                    </div>

                    {formState.groups && (
                      <div className="form-group">
                        {formState.groups.map((g, x) => (
                          <div className="preview-group">
                            <div className="pg-title">{"Group " + (x + 1)}</div>
                            <div className="pg-teams">
                              {g.teams.map((t) => (
                                <div>{t.organization.name}</div>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className="form-group">
                      <div className="label">Meetings per group</div>
                      <input
                        value={formState.meetings}
                        className="input"
                        type={"number"}
                        onChange={(e) => {
                          const newState = {};
                          newState["meetings"] = e.target.value;
                          setFormState({
                            ...formState,
                            ...newState,
                          });
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <div className="label">Qualifiers per group</div>
                      <input
                        value={formState.qualifiers}
                        className="input"
                        type={"number"}
                        onChange={(e) => {
                          const newState = {};
                          newState["qualifiers"] = e.target.value;
                          setFormState({
                            ...formState,
                            ...newState,
                          });
                        }}
                      />
                    </div>
                  </Fragment>
                )}

                <div className="options">
                  <button type="submit" className={"option  submit"}>
                    {"Generate"}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
    </ModalComponent>
  );
}

export default GenerateFixtures;

import { gql } from "@apollo/client";

const UPLOAD_DATA = gql`
  mutation uploadData(
    $type: String!
    $data: Json!
    $filename: String
    $uploadId: ID
  ) {
    uploadData(
      type: $type
      data: $data
      filename: $filename
      uploadId: $uploadId
    ) {
      success
      errors
    }
  }
`;

export default UPLOAD_DATA;

import { useRef, useState } from "react";
import { useAppState } from "../../../utils/appState";
import Card from "../Card";
import ClickOutside from "../ClickOutside";
import "./UploadData.css";
import { ReactComponent as FolderIcon } from "../../../icons/folder.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Button from "../Button";
import exportTemplate from "../../../utils/exportTemplate";
import Papa from "papaparse";
import { useMutation } from "@apollo/client";
import UPLOAD_DATA from "../../../api/mutations/uploadData";
import { CREATE_DATA_UPLOAD } from "../../../api/events";
import uploadFile from "../../../utils/uploadFile";
import Loader from "../Loader";

function EditDataTableCell({
  defaultValue,
  width,
  dismiss,
  row,
  column,
  setCell,
}) {
  const [value, setValue] = useState(defaultValue);
  return (
    <ClickOutside onClickOutside={dismiss}>
      {(wrapperRef) => (
        <div
          ref={wrapperRef}
          className="App-data-uploader__content__datasheet__table__td"
          style={{ width }}
        >
          <form
            className="App-data-uploader__content__datasheet__table__td__form"
            onSubmit={(e) => {
              e.preventDefault();
              setCell(row, column, value);
              dismiss();
            }}
          >
            <input
              type="text"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              className="App-data-uploader__content__datasheet__table__td__form__input"
              autoFocus
            />
          </form>
        </div>
      )}
    </ClickOutside>
  );
}

function DataTableCell({ value, width, setCell, row, column }) {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditDataTableCell
        defaultValue={value}
        dismiss={() => {
          setEditing(false);
        }}
        setCell={setCell}
        row={row}
        column={column}
      />
    );
  }
  return (
    <div
      className="App-data-uploader__content__datasheet__table__td"
      style={{ width, cursor: "pointer", paddingLeft: "16px" }}
      onClick={() => {
        setEditing(true);
      }}
    >
      {value}
    </div>
  );
}

function UploadDataTable({ headers = [], rows = [], setCell }) {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const fixedColumns = headers.filter((h) => h.fixed);
  const flexibleColumns = headers.filter((h) => !h.fixed);
  const fixedColumnWidth = fixedColumns.reduce((accum, curr) => {
    return accum + (curr.width || 100);
  }, 0);
  const scrollHandler = (e) => {
    setScrollTop(e.target.scrollTop);
    setScrollLeft(e.target.scrollLeft);
  };
  return (
    <div className="App-data-uploader__content__datasheet__table">
      <div
        className="App-data-uploader__content__datasheet__table__head"
        style={{ paddingLeft: fixedColumnWidth + "px" }}
      >
        <div className="App-data-uploader__content__datasheet__table__body__columns fixed">
          {fixedColumns.map((c) => (
            <div
              className="App-data-uploader__content__datasheet__table__th"
              style={{ width: (c.width || 100) + "px" }}
            >
              {c.label}
            </div>
          ))}
        </div>

        <div
          className="App-data-uploader__content__datasheet__table__body__columns "
          style={{ transform: `translateX(-${scrollLeft}px)` }}
        >
          {flexibleColumns.map((c) => (
            <div
              className="App-data-uploader__content__datasheet__table__th"
              style={{
                width: (c.width || 100) + "px",
              }}
            >
              {c.label}
            </div>
          ))}
        </div>
      </div>

      <div className="App-data-uploader__content__datasheet__table__body">
        <div
          className="App-data-uploader__content__datasheet__table__body__columns fixed"
          style={{
            width: fixedColumnWidth + "px",
            transform: `translateY(-${scrollTop}px)`,
          }}
        >
          {fixedColumns.map((c, columnIndex) => (
            <div className="App-data-uploader__content__datasheet__table__body__columns__column">
              {rows.map((row, rowIndex) => (
                <DataTableCell
                  value={row[columnIndex]}
                  width={(c.width || 100) + "px"}
                  setCell={setCell}
                  row={rowIndex}
                  column={columnIndex}
                />
              ))}
            </div>
          ))}
        </div>

        <div
          className="App-data-uploader__content__datasheet__table__body__columns flexible"
          style={{ paddingLeft: fixedColumnWidth + "px" }}
          onScroll={scrollHandler}
        >
          {flexibleColumns.map((c, columnIndex) => (
            <div className="App-data-uploader__content__datasheet__table__body__columns__column">
              {rows.map((row, rowIndex) => (
                <DataTableCell
                  value={row[fixedColumns.length + columnIndex]}
                  width={(c.width || 100) + "px"}
                  setCell={setCell}
                  row={rowIndex}
                  column={fixedColumns.length + columnIndex}
                />
              ))}
            </div>
          ))}
        </div>
      </div>

      {/* <div className="App-data-uploader__content__datasheet__table__columns fixed">
        {fixedColumns.map((column) => (
          <div className="App-data-uploader__content__datasheet__table__columns__column">
            {column.label}
          </div>
        ))}
      </div>

      <div className="App-data-uploader__content__datasheet__table__columns flexible">
        {flexibleColumns.map((column) => (
          <div className="App-data-uploader__content__datasheet__table__columns__column">
            {column.label}
          </div>
        ))}
      </div> */}
    </div>
  );
}

export default function UploadData({
  title,
  subtitle,
  text,
  headers,
  type,
  ev,
  transform,
  validate,
  refetch,
}) {
  const [, setAppState] = useAppState();
  const fileInputField = useRef(null);
  const [file, setFile] = useState(false);
  const [head, setHead] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [uploadData, { loading: mutating }] = useMutation(UPLOAD_DATA);
  const [createDataUpload, { loading: creatingUpload }] =
    useMutation(CREATE_DATA_UPLOAD);

  const handleNewFileUpload = (e) => {
    // const { files: newFiles } = e.target;
    if (e.target.files.length) {
      console.log(e.target.files[0]);
      //   let updatedFiles = addNewFiles(newFiles);
      Papa.parse(fileInputField.current?.files[0], {
        complete: function (results) {
          setHead(results.data[0]);
          setRows(results.data.slice(1, results.data.length));
        },
      });

      setFile(e.target.files[0]);
    }
  };

  const setCell = (row, column, value) => {
    setRows((prev) => {
      const newRows = [...prev];
      newRows[row][column] = value;
      return newRows;
    });
  };

  const onSubmit = async () => {
    if (loading) return;

    setLoading(true);
    setError(false);

    const d = transform ? transform(rows) : rows;
    const isValid = validate(d);

    // console.log(isValid);
    // return;

    if (!isValid.valid) {
      console.log("Error detected");
      setError(isValid.error);
      setLoading(false);
      return;
    }

    const csv = Papa.unparse({
      fields: headers.map((h) => h.label),
      data: rows,
    });

    const csvFile = new File([csv], file.name, {
      type: file.type,
    });

    createDataUpload({
      variables: {
        uploadType: type,
        eventId: ev.id,
        filename: file.name,
      },
      update: async (cache, { data: { createDataUpload } }) => {
        await fetch(createDataUpload.url, {
          method: "PUT",
          mode: "cors",
          headers: {
            "x-amz-acl": "public-read",
            "Content-Type": file.type,
          },
          body: csvFile,
        });

        // 3. Call the uploadData resolver, passing in the datauploadId, when the resolver completes it should update the data upload object.
        uploadData({
          variables: {
            type,
            data: JSON.stringify(d),
            filename: file.name,
            uploadId: createDataUpload.dataUpload.id,
          },
          update: () => {
            refetch && refetch();
            setLoading(false);
            setAppState({ modal: false });
          },
        });
      },
    });
  };

  // console.log("Rows:", rows);

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="App-card App-data-uploader" ref={wrapperRef}>
          <div className="App-data-uploader__meta">
            <div className="App-data-uploader__meta__subtitle">{subtitle}</div>
            <div className="App-data-uploader__meta__title">{title}</div>

            <div className="App-data-uploader__meta__blurb">
              Use the format in the below template to upload your{" "}
              {text.template}.
            </div>

            <Button
              className="App-data-uploader__meta__download-template-btn"
              onClick={() => {
                exportTemplate({
                  headers,
                  type,
                });
              }}
            >
              DOWNLOAD TEMPLATE
            </Button>
          </div>

          <div
            className="App-data-uploader__content"
            style={file ? {} : { padding: "48px" }}
          >
            {file ? (
              <div className="App-data-uploader__content__datasheet">
                <div className="App-data-uploader__content__datasheet__header">
                  <div className="App-data-uploader__content__datasheet__header__text">
                    <div className="App-data-uploader__content__datasheet__header__text__secondary">
                      Uploading File:
                    </div>

                    <div className="App-data-uploader__content__datasheet__header__text__primary">
                      {file.name}
                    </div>
                  </div>

                  <div className="App-data-uploader__content__datasheet__header__dismiss">
                    Choose another file
                  </div>
                </div>

                <UploadDataTable
                  headers={headers}
                  rows={rows}
                  setCell={setCell}
                />

                <div className="App-data-uploader__content__datasheet__footer">
                  {error && (
                    <div className="App-data-uploader__content__datasheet__footer__info">
                      {error}
                    </div>
                  )}
                  {loading && !error && (
                    <div className="App-data-uploader__content__datasheet__footer__info">
                      This may take a few minutes. You will be notified by email
                      when the upload is completed.
                    </div>
                  )}

                  <Button
                    className={
                      "App-data-uploader__content__datasheet__footer__submit-btn " +
                      (loading ? "disabled" : "")
                    }
                    onClick={onSubmit}
                  >
                    {loading ? "UPLOADING..." : "UPLOAD FILE"}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="App-data-uploader__content__input-wrapper">
                <FolderIcon className="App-data-uploader__content__input-wrapper__icon" />
                <div className="App-data-uploader__content__input-wrapper__placeholder">
                  Drag and drop your file here
                </div>

                <div className="App-data-uploader__content__input-wrapper__seperator">
                  OR
                </div>
                <Button
                  className="App-data-uploader__content__input-wrapper__btn"
                  label="BROWSE FILES"
                />
                <input
                  type="file"
                  ref={fileInputField}
                  title=""
                  value=""
                  onChange={handleNewFileUpload}
                  multiple={false}
                  accept=".csv"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </ClickOutside>
  );
}

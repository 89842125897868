import { useQuery } from "@apollo/client";
import { Avatar, Button, Search, Spinner, Loader } from "../../common";
import "./ProfilesIndex.css";
import { PROFILES } from "../../../api/profiles";
import { Fragment } from "react";
import { useAppState } from "../../../utils/appState";
// import CreatePage from "../CreatePage";
import MergeProfiles from "../MergeProfiles";
import { useHistory } from "react-router-dom";

export default function ProfilesIndex() {
  const history = useHistory();
  const [, setAppState] = useAppState();
  const { loading, error, data, refetch, networkStatus, variables, fetchMore } =
    useQuery(PROFILES(), {
      notifyOnNetworkStatusChange: true,
    });

  if (error) {
    return <div>Error</div>;
  }

  const items = data?.profiles || [];

  return (
    <div className="App-index-page">
      <div className="App-index-page__header">
        <div className="App-index-page__header__title">Profiles</div>

        <div className="App-index-page__header__options">
          <Search
            className="App-index-page__header__options__search"
            placeholder="Search events..."
            networkStatus={networkStatus}
            onSubmit={(val) => {
              refetch({
                where: {
                  OR: [
                    { name: { contains: val, mode: "insensitive" } },
                    { handle: { contains: val, mode: "insensitive" } },
                    { email: { contains: val, mode: "insensitive" } },
                  ],
                },
              });
            }}
            onReset={() => {
              refetch({ where: undefined });
            }}
          />

          <Button
            label={"MERGE PROFILES"}
            className="App-index-page__header__options__add"
            onClick={() => {
              setAppState({ modal: <MergeProfiles /> });
            }}
          />
        </div>
      </div>

      <div className="App-index-page__list">
        {networkStatus === 1 || networkStatus === 2 ? (
          <div className="App-index-page__list__loading">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {items.map((item) => (
              <div
                className="App-index-page__list__item"
                onClick={() => {
                  history.push("/v2/profiles/view/" + item.id);
                }}
              >
                <div
                  className={
                    "App-index-page__list__item__img " +
                    (item.profilePhoto ? "no-bg" : "")
                  }
                >
                  {item.profilePhoto && <Avatar media={item.profilePhoto} />}
                </div>

                <div className="App-index-page__list__item__text">
                  <div className="App-index-page__list__item__text__primary">
                    {item.name}
                  </div>

                  <div className="App-index-page__list__item__text__secondary">
                    @{item.handle}
                  </div>

                  <div className="App-index-page__list__item__text__secondary">
                    {item.email}
                  </div>
                </div>
              </div>
            ))}

            {!!items.length && (
              <div
                className="App-index-page__list__item"
                onClick={() => {
                  fetchMore({
                    variables: {
                      ...variables,
                      cursor: items[items.length - 1].id,
                    },
                  });
                }}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="App-index-page__list__item__text">
                  <div className="App-index-page__list__item__text__secondary">
                    {networkStatus === 3 ? (
                      <Loader theme={"small grey"} />
                    ) : (
                      "Load More"
                    )}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}

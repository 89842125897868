import "./Fixtures.css";
import { Switch, Route } from "react-router-dom";
import { ViewFixture } from "../../componentsV2/fixtures";

export default function Fixtures() {
  return (
    <Switch>
      <Route path="/v2/fixtures/view/:id" component={ViewFixture} />
    </Switch>
  );
}

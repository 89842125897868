import "./EventRegistrations.css";
import { Avatar, Button, Error, Table, UploadData } from "../../common";
import { EVENT_REGISTRATIONS } from "../../../api/events";
import { useQuery } from "@apollo/client";
import { Fragment } from "react";
import { ReactComponent as UploadIcon } from "../../../icons/upload.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import exportToCSV from "../../../utils/exportToCSV";
import { useAppState } from "../../../utils/appState";
import CreateEventRegistration from "../CreateEventRegistration";
import EditRegistration from "../EditRegistration";

export default function EventRegistrations({ ev }) {
  const [, setAppState] = useAppState();
  const { loading, error, data, refetch } = useQuery(
    EVENT_REGISTRATIONS({ selectionSet: `` }),
    {
      variables: {
        eventId: ev.id,
      },
    }
  );

  if (error) {
    console.log(error);
    return <Error />;
  }

  const tableFields = [
    {
      label: "Profile",
      getValue: (item) => {
        return (
          <Fragment>
            <div className="Table__main__body__rows__row__td__image">
              {item.profile?.profilePhoto && (
                <Avatar media={item.profile.profilePhoto} />
              )}
            </div>

            <div className="Table__main__body__rows__row__td__text">
              {item.profile?.name}
            </div>
          </Fragment>
        );
      },
      style: {
        width: "20%",
        // paddingLeft: "24px",
      },
    },
    {
      label: "Email",
      getValue: (item) => {
        return item.profile?.email;
      },
      style: {
        width: "20%",
        overflow: "hidden",
        // paddingLeft: "24px",
      },
    },
    {
      label: "Team",
      getValue: (item) => {
        return (
          <Fragment>
            <div className="Table__main__body__rows__row__td__image">
              {item.organization?.profilePhoto && (
                <Avatar media={item.organization.profilePhoto} />
              )}
            </div>

            <div className="Table__main__body__rows__row__td__text">
              {item.organization?.name}
            </div>
          </Fragment>
        );
      },
      style: {
        width: "20%",
        // paddingLeft: "24px",
      },
    },
    {
      label: "Paid?",
      getValue: (item) => {
        return item.paid ? "Yes" : "No";
      },
      style: {
        width: "13%",
        // paddingLeft: "24px",
      },
    },
    {
      label: "Amount Paid",
      getValue: (item) => {
        return item.amountPaid || 0;
      },
      style: {
        width: "13%",
        justifyContent: "flex-end",
        // paddingLeft: "24px",
      },
    },
    {
      label: "Jersey Num",
      getValue: (item) => {
        return item.jerseyNum;
      },
      style: {
        width: "14%",
        // paddingLeft: "24px",
        justifyContent: "flex-end",
      },
    },
    // {
    //   label: "",
    //   getValue: (item) => {
    //     return (
    //       <Button className="Table__main__body__rows__row__td__btn">
    //         <PencilIcon className="Table__main__body__rows__row__td__btn__icon" />
    //       </Button>
    //     );
    //   },
    //   style: {
    //     width: "10%",
    //     // paddingLeft: "24px",
    //     justifyContent: "flex-end",
    //   },
    // },
  ];

  const regs = data?.eventRegistrations || [];

  return (
    <div className="App-grid event-regs-page">
      <Table
        title={"Registrations List"}
        fields={tableFields}
        rows={regs}
        onRowClick={(row) => {
          setAppState({
            modal: <EditRegistration ev={ev} reg={row} />,
          });
        }}
        loading={loading}
        options={() => (
          <Fragment>
            <Button
              label="ADD"
              className="Table__main__header__options__option add"
              onClick={() => {
                setAppState({
                  modal: <CreateEventRegistration ev={ev} />,
                });
              }}
            />

            <Button
              className="Table__main__header__options__option"
              onClick={() => {
                setAppState({
                  modal: (
                    <UploadData
                      title={
                        <div>
                          Upload <br /> Registrations
                        </div>
                      }
                      subtitle={ev.name}
                      headers={[
                        {
                          label: "Name",
                          key: "name",
                          fixed: true,
                          width: 240,
                        },
                        {
                          label: "Email",
                          key: "email",
                          width: 160,
                        },
                        { label: "Phone", key: "phone", width: 160 },
                        { label: "Team", key: "organization", width: 160 },
                        { label: "Paid(YES/NO)", key: "paid", width: 160 },
                        { label: "Amount Paid", key: "amountPaid", width: 160 },
                        { label: "Jersey Num", key: "jerseyNum", width: 160 },
                      ]}
                      type={"eventRegistration"}
                      text={{
                        template: "event registration data",
                      }}
                      ev={ev}
                      transform={(rows) => {
                        return rows
                          .map((r) => ({
                            name: r[0]?.trim(),
                            email:
                              r[1]?.trim() ||
                              r[0]?.trim().toLowerCase().split(" ").join("_") +
                                "@sportspaddock.com",
                            phone: r[2]?.trim(),
                            organization: r[3]?.trim(),
                            paid: r[4] === "YES" ? true : false,
                            amountPaid: r[5]?.trim() || 0,
                            eventId: ev.id,
                            jerseyNum: r[6],
                          }))
                          .filter((r) => r.name && r.email);
                      }}
                      validate={() => {
                        return { valid: true };
                      }}
                      refetch={refetch}
                    />
                  ),
                });
              }}
            >
              <UploadIcon style={{ height: "14px", stroke: "var(--dark-3)" }} />
            </Button>

            <Button
              label="EXPORT"
              className="Table__main__header__options__option"
              onClick={() => {
                exportToCSV({
                  fields: [
                    { label: "Name", value: (x) => x.profile?.name },
                    { label: "Email", value: (x) => x.profile?.email },
                    { label: "Phone", value: (x) => x.profile?.phone },
                    { label: "Team", value: (x) => x.organization?.name || "" },
                    {
                      label: "Paid(YES/NO)",
                      value: (x) => (x.paid ? "YES" : "NO"),
                    },
                    { label: "Amount Paid", value: (x) => x.amountPaid || 0 },
                    { label: "Jersey Num", value: (x) => x.jerseyNum || "" },
                  ],
                  data: regs,
                });
              }}
            />
          </Fragment>
        )}
      />
    </div>
  );
}

import "./AppLayoutV2.css";
import { Link, NavLink, Switch, Route } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { ACCOUNT } from "../../api/user";
import { Avatar, NewDropdown } from "../../componentsV2";
import { ReactComponent as EllipsisIcon } from "../../icons/ellipsis.svg";
import {
  AccessControl,
  Dashboard,
  Directory,
  Events,
  Profiles,
  Sports,
  Users,
  Fixtures,
  Uploads,
  Merges,
} from "../../screensV2";
import { useAppState } from "../../utils/appState";
import { Card } from "../../componentsV2/common";

function MenuItem({ to, label, exact = false }) {
  return (
    <div className="App-layout__menu__items__item-wrapper">
      <NavLink
        to={to}
        className={"App-layout__menu__items__item-wrapper__item"}
        activeClassName="selected"
        exact={exact}
      >
        {label}
      </NavLink>
    </div>
  );
}

function MenuFooter() {
  const { loading, error, data } = useQuery(ACCOUNT);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div />;
  }

  const profile = data.userAccount?.profile;

  if (!profile) {
    return <div />;
  }

  return (
    <div className="App-layout__menu__footer">
      <div className="App-layout__menu__footer__icon">
        {profile.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="App-layout__menu__footer__text">
        <div className="App-layout__menu__footer__text__primary">
          {profile.name}
        </div>
        <div className="App-layout__menu__footer__text__secondary">
          @{profile.handle}
        </div>
      </div>
      <div className="App-layout__menu__footer__options">
        <NewDropdown
          target={
            <div className="App-layout__menu__footer__options__option">
              <EllipsisIcon className="App-layout__menu__footer__options__icon" />
            </div>
          }
          menu={
            <Card className="App-layout__menu__footer__options__account__menu">
              <div className="App-layout__menu__footer__options__account__menu__item">
                <Link to={"/logout"}>Logout</Link>
              </div>
            </Card>
          }
        />
      </div>
    </div>
  );
}

export default function AppLayoutV2() {
  const [{ modal }, setAppState] = useAppState();
  return (
    <div className="App-layout">
      <div className="App-layout__menu">
        <div className="App-layout__menu__brand">
          <Link to="/" className="App-layout__menu__brand__logo">
            <img src="/logo.png" />{" "}
            <span className="App-layout__menu__brand__logo__suffix">ADMIN</span>
          </Link>
        </div>

        <div className="App-layout__menu__items">
          <MenuItem to="/v2" label={"Dashboard"} exact={true} />
          <MenuItem to="/v2/events" label={"Events"} />
          <MenuItem to="/v2/directory" label={"Directory"} />
          <MenuItem to="/v2/profiles" label={"Profiles"} />
          <MenuItem to="/v2/sports" label={"Sports"} />
          <MenuItem to="/v2/users" label={"Users"} />
          <MenuItem to="/v2/access" label={"Access Control"} />
        </div>

        <MenuFooter />
      </div>
      <div className="App-layout__content">
        <Switch>
          <Route path="/v2/merges" component={Merges} />
          <Route path="/v2/uploads" component={Uploads} />
          <Route path="/v2/fixtures" component={Fixtures} />
          <Route path="/v2/events" component={Events} />
          <Route path="/v2/directory" component={Directory} />
          <Route path="/v2/profiles" component={Profiles} />
          <Route path="/v2/sports" component={Sports} />
          <Route path="/v2/users" component={Users} />
          <Route path="/v2/access" component={AccessControl} />
          <Route path="/v2/dashboard" component={Dashboard} />
          <Route path="/v2" component={Dashboard} />
        </Switch>
      </div>

      {modal && <div className="App-layout__modal">{modal}</div>}
    </div>
  );
}

import "./UploadEventStats.css";
import { ReactComponent as FolderIcon } from "../../../icons/folder.svg";
import { Card, Button, UploadData } from "../../common";
import { useAppState } from "../../../utils/appState";

const sportConfig = (event) => ({
  Football: {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Team Name", key: "organization", width: 140, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "Position", key: "position" },
      { label: "Minutes Played", key: "minutesPlayed" },
      { label: "Shot On Target", key: "shotsOnTarget" },
      { label: "Shot Off Target", key: "shotOffTarget" },
      { label: "Goals", key: "goals" },
      { label: "Assists", key: "assists" },
      { label: "Key Pass", key: "keyPass" },
      { label: "Tackles", key: "tackles" },
      { label: "Blocks", key: "blocks" },
      { label: "Saves", key: "saves" },
      { label: "Own Goals", key: "ownGoals" },
      { label: "Offsides", key: "offsides" },
      { label: "Fouls", key: "fouls" },
      { label: "Yellow Card", key: "yellowCard" },
      { label: "Red Card", key: "redCard" },
      { label: "Clean Sheet", key: "cleanSheets" },
      { label: "Full Time Score", key: "finalScore" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          teamName: r[1]?.trim(),
          playerName: r[2]?.trim(),
          //   email: r[3],
          email:
            r[3]?.trim()?.toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle:
            r[4]?.trim() || r[2]?.trim().toLowerCase().split(" ").join(""),
          position: r[5]?.trim(),
          minutesPlayed: Number(r[6]) || 0,
          shotOnTarget: Number(r[7]) || 0,
          shotOffTarget: Number(r[8]) || 0,
          goal: Number(r[9]) || 0,
          assist: Number(r[10]) || 0,
          keyPass: Number(r[11]) || 0,
          tackle: Number(r[12]) || 0,
          block: Number(r[13]) || 0,
          save: Number(r[14]) || 0,
          ownGoal: Number(r[15]) || 0,
          offside: Number(r[16]) || 0,
          foul: Number(r[17]) || 0,
          yellowCard: Number(r[18]) || 0,
          redCard: Number(r[19]) || 0,
          cleanSheet: Number(r[20]) || 0,
          finalScore: Number(r[21]) || 0,
        }))
        .filter((r) => r.matchNumber && r.teamName && r.playerName);
    },
    validate: () => {
      return { valid: true, error: "" };
    },
  },

  Cricket: {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Team Name", key: "organization", width: 140, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "Batting Order", key: "battingOrder" },
      { label: "Runs Scored", key: "runs" },
      { label: "Balls", key: "shotsOnTarget" },
      { label: "Method Of Dismissal", key: "methodOfDismissal" },
      { label: "Bowling Team", key: "bowlingTeam" },
      { label: "Bowler Name", key: "bowlerName" },
      { label: "Fielder Name", key: "fielderName" },
      { label: "Bowler Email", key: "bowlerEmail" },
      { label: "Bowler Handle(@)", key: "bowlerHandle" },
      { label: "Fielder Email", key: "fielderEmail" },
      { label: "Fielder Handle(@)", key: "fielderHandle" },
      { label: "Balls Bowled", key: "ballsBowled" },
      { label: "Maidens", key: "maidens" },
      { label: "Runs Conceded", key: "runsConceded" },
      { label: "Wickets", key: "wickets" },
      { label: "No Balls", key: "noBall" },
      { label: "Wides", key: "wide" },
      { label: "Final Score (Runs/Wickets)", key: "finalScore" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          teamName: r[1]?.trim(),
          playerName: r[2]?.trim(),
          //   email: r[3],
          email:
            r[3]?.trim()?.toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle:
            r[4]?.trim() || r[2]?.trim().toLowerCase().split(" ").join(""),
          battingOrder: Number(r[5]) || 0,
          runsScored: Number(r[6]) || 0,
          ballsFaced: Number(r[7]) || 0,
          methodOfDismissal: r[8]?.trim(),
          bowlingTeam: r[9]?.trim(), //
          bowlerName: r[10]?.trim(), //
          fielderName: r[11]?.trim(), //
          bowlerEmail: r[12]?.trim(), //
          bowlerHandle:
            r[13]?.trim() || r[10]?.trim().toLowerCase().split(" ").join(""), //
          fielderEmail: r[14]?.trim(), //
          fielderHandle:
            r[15]?.trim() || r[11]?.trim().toLowerCase().split(" ").join(""),
          ballsBowled: Number(r[16]) || 0,
          maidens: Number(r[17]) || 0,
          runsConceded: Number(r[18]) || 0,
          wicketsTaken: Number(r[19]) || 0,
          noBalls: Number(r[20]) || 0,
          wides: Number(r[21]) || 0,
          finalScore: r[22]?.trim(),
        }))
        .filter((r) => r.matchNumber && r.teamName && r.playerName);
    },
    validate: (rows) => {
      const acceptedStats = [
        "Wide",
        "No Ball",
        "Bye",
        "Leg Bye",
        "Runs Scored",
        "Balls Faced",
        "Runs Conceded",
        "Balls Bowled",
        "Wicket Taken",
        "Maiden Overs",
        "Catch",
        "Run Out",
        "Stumping",
        "Bowled",
        "LBW",
        "Caught",
        "Caught & Bowled",
        "Stumped",
        "Retired",
        "Not Out",
      ];
      let output = { valid: true, error: "" };
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (
          row.methodOfDismissal &&
          !acceptedStats.includes(row.methodOfDismissal)
        ) {
          output.valid = false;
          output.error =
            "Invalid method of dismissal " +
            row.methodOfDismissal +
            " at row " +
            (i + 1);

          console.log(output.error);
          break;
        }

        if (
          ["Run Out", "Caught", "Stumped", "Caught & Bowled"].includes(
            row.methodOfDismissal
          ) &&
          !row.fielderEmail
        ) {
          output.valid = false;
          output.error =
            "Missing fielder details: " +
            row.methodOfDismissal +
            " at row " +
            (i + 1);

          console.log(output.error);
          break;
        }

        if (
          [
            "Caught",
            "Out by Dots",
            "Stumped",
            "Caught & Bowled",
            "Hit Wicket",
            "Double Hit",
            "LBW",
            "Bowled",
          ].includes(row.methodOfDismissal) &&
          !row.bowlerEmail
        ) {
          output.valid = false;
          output.error =
            "Missing bowler details: " +
            row.methodOfDismissal +
            " at row " +
            (i + 1);

          console.log(output.error);
          break;
        }
      }
      return output;
    },
  },

  "Box Cricket": {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Team Name", key: "organization", width: 140, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "Batting Order", key: "battingOrder" },
      { label: "Runs Scored", key: "runs" },
      { label: "Balls", key: "shotsOnTarget" },
      { label: "Method Of Dismissal", key: "methodOfDismissal" },
      { label: "Bowling Team", key: "bowlingTeam" },
      { label: "Bowler Name", key: "bowlerName" },
      { label: "Fielder Name", key: "fielderName" },
      { label: "Bowler Email", key: "bowlerEmail" },
      { label: "Bowler Handle(@)", key: "bowlerHandle" },
      { label: "Fielder Email", key: "fielderEmail" },
      { label: "Fielder Handle(@)", key: "fielderHandle" },
      { label: "Balls Bowled", key: "ballsBowled" },
      { label: "Maidens", key: "maidens" },
      { label: "Runs Conceded", key: "runsConceded" },
      { label: "Wickets", key: "wickets" },
      { label: "No Balls", key: "noBall" },
      { label: "Wides", key: "wide" },
      { label: "Final Score (Runs/Wickets)", key: "finalScore" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          teamName: r[1]?.trim(),
          playerName: r[2]?.trim(),
          //   email: r[3],
          email:
            r[3]?.trim()?.toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle:
            r[4]?.trim() || r[2]?.trim().toLowerCase().split(" ").join(""),
          battingOrder: Number(r[5]) || 0,
          runsScored: Number(r[6]) || 0,
          ballsFaced: Number(r[7]) || 0,
          methodOfDismissal: r[8]?.trim(),
          bowlingTeam: r[9]?.trim(), //
          bowlerName: r[10]?.trim(), //
          fielderName: r[11]?.trim(), //
          bowlerEmail: r[12]?.trim(), //
          bowlerHandle:
            r[13]?.trim() || r[10]?.trim().toLowerCase().split(" ").join(""), //
          fielderEmail: r[14]?.trim(), //
          fielderHandle:
            r[15]?.trim() || r[11]?.trim().toLowerCase().split(" ").join(""),
          ballsBowled: Number(r[16]) || 0,
          maidens: Number(r[17]) || 0,
          runsConceded: Number(r[18]) || 0,
          wicketsTaken: Number(r[19]) || 0,
          noBalls: Number(r[20]) || 0,
          wides: Number(r[21]) || 0,
          finalScore: r[22]?.trim(),
        }))
        .filter((r) => r.matchNumber && r.teamName && r.playerName);
    },
    validate: (rows) => {
      const acceptedStats = [
        "Run Out",
        "Balls Bowled",
        "Caught",
        "Out by Dots",
        "Fours",
        "Stumping",
        "Timed Out",
        "Ball Handling",
        "Out of Bounds",
        "Stumped",
        "Maiden Overs",
        "Runs Conceded",
        "Double Hit",
        "Leg Bye",
        "Hit Wicket",
        "LBW",
        "Sixes",
        "Bowled",
        "No Ball",
        "Obstruction",
        "Retired",
        "Bye",
        "Wide",
        "Wicket Taken",
        "Caught & Bowled",
        "Runs Scored",
        "Catch",
        "Balls Faced",
        "Not Out",
      ];
      let output = { valid: true, error: "" };
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i];
        if (
          row.methodOfDismissal &&
          !acceptedStats.includes(row.methodOfDismissal)
        ) {
          output.valid = false;
          output.error =
            "Invalid method of dismissal " +
            row.methodOfDismissal +
            " at row " +
            (i + 1);
          break;
        }

        if (
          ["Run Out", "Caught", "Stumped", "Caught & Bowled"].includes(
            row.methodOfDismissal
          ) &&
          !row.fielderEmail
        ) {
          output.valid = false;
          output.error =
            "Missing fielder details: " +
            row.methodOfDismissal +
            " at row " +
            (i + 1);

          console.log(output.error);
          break;
        }

        if (
          [
            "Caught",
            "Out by Dots",
            "Stumped",
            "Caught & Bowled",
            "Hit Wicket",
            "Double Hit",
            "LBW",
            "Bowled",
          ].includes(row.methodOfDismissal) &&
          !row.bowlerEmail
        ) {
          output.valid = false;
          output.error =
            "Missing bowler details: " +
            row.methodOfDismissal +
            " at row " +
            (i + 1);

          console.log(output.error);
          break;
        }
      }
      return output;
    },
  },

  Basketball: {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Team Name", key: "organization", width: 140, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "1FGA", key: "1fga" },
      { label: "1FGM", key: "1fgm" },
      { label: "1FG%", key: "1fg%" },
      { label: "2FGA", key: "2fga" },
      { label: "2FGM", key: "2fgm" },
      { label: "2FG%", key: "2fg%" },
      { label: "BLK", key: "blk" },
      { label: "PTS", key: "pts" },
      { label: "FTA", key: "fta" },
      { label: "FTM", key: "ftm" },
      { label: "FT%", key: "ft%" },
      { label: "OFF REB", key: "offreb" },
      { label: "DEF REB", key: "defreb" },
      { label: "AST", key: "ast" },
      { label: "STL", key: "stl" },
      { label: "T/O", key: "t/o" },
      { label: "FOULS", key: "fouls" },
      { label: "3FGA", key: "3fga" },
      { label: "3FGM", key: "3fgm" },
      { label: "3FG%", key: "3fg%" },
      { label: "Full Time Score", key: "finalScore" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          teamName: r[1]?.trim(),
          playerName: r[2]?.trim(),
          //   email: r[3],
          email:
            r[3]?.trim().toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle: r[4] || r[2]?.trim().toLowerCase().split(" ").join(""),
          "1fga": Number(r[5]) || 0,
          "1fgm": Number(r[6]) || 0,
          "1fg%": r[7],
          "2fga": Number(r[8]) || 0,
          "2fgm": Number(r[9]) || 0,
          "2fg%": r[10],
          blocks: Number(r[11]) || 0,
          points: Number(r[12]) || 0,
          fta: Number(r[13]) || 0,
          ftm: Number(r[14]) || 0,
          "ft%": r[15],
          offensiveRebounds: Number(r[16]) || 0,
          defensiveRebounds: Number(r[17]) || 0,
          assists: Number(r[18]) || 0,
          steals: Number(r[19]) || 0,
          turnovers: Number(r[20]) || 0,
          fouls: Number(r[21]) || 0,
          "3fga": Number(r[22]) || 0,
          "3fgm": Number(r[23]) || 0,
          "3fg%": r[24],
          finalScore: Number(r[25]) || 0,
        }))
        .filter((r) => r.matchNumber && r.teamName && r.playerName);
    },
    validate: () => {
      return { valid: true, error: "" };
    },
  },

  "Basketball 3x3": {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Team Name", key: "organization", width: 140, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "1FGA", key: "1fga" },
      { label: "1FGM", key: "1fgm" },
      { label: "1FG%", key: "1fg%" },
      { label: "2FGA", key: "2fga" },
      { label: "2FGM", key: "2fgm" },
      { label: "2FG%", key: "2fg%" },
      { label: "BLK", key: "blk" },
      { label: "PTS", key: "pts" },
      { label: "FTA", key: "fta" },
      { label: "FTM", key: "ftm" },
      { label: "FT%", key: "ft%" },
      { label: "OFF REB", key: "offreb" },
      { label: "DEF REB", key: "defreb" },
      { label: "AST", key: "ast" },
      { label: "STL", key: "stl" },
      { label: "T/O", key: "t/o" },
      { label: "FOULS", key: "fouls" },
      { label: "3FGA", key: "3fga" },
      { label: "3FGM", key: "3fgm" },
      { label: "3FG%", key: "3fg%" },
      { label: "Full Time Score", key: "finalScore" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          teamName: r[1]?.trim(),
          playerName: r[2]?.trim(),
          //   email: r[3],
          email:
            r[3]?.trim().toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle: r[4] || r[2]?.trim().toLowerCase().split(" ").join(""),
          "1fga": Number(r[5]) || 0,
          "1fgm": Number(r[6]) || 0,
          "1fg%": r[7],
          "2fga": Number(r[8]) || 0,
          "2fgm": Number(r[9]) || 0,
          "2fg%": r[10],
          blocks: Number(r[11]) || 0,
          points: Number(r[12]) || 0,
          fta: Number(r[13]) || 0,
          ftm: Number(r[14]) || 0,
          "ft%": r[15],
          offensiveRebounds: Number(r[16]) || 0,
          defensiveRebounds: Number(r[17]) || 0,
          assists: Number(r[18]) || 0,
          steals: Number(r[19]) || 0,
          turnovers: Number(r[20]) || 0,
          fouls: Number(r[21]) || 0,
          "3fga": Number(r[22]) || 0,
          "3fgm": Number(r[23]) || 0,
          "3fg%": r[24],
          finalScore: Number(r[25]) || 0,
        }))
        .filter((r) => r.matchNumber && r.teamName && r.playerName);
    },
    validate: () => {
      return { valid: true, error: "" };
    },
  },

  "Basketball 5x5": {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Team Name", key: "organization", width: 140, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "1FGA", key: "1fga" },
      { label: "1FGM", key: "1fgm" },
      { label: "1FG%", key: "1fg%" },
      { label: "2FGA", key: "2fga" },
      { label: "2FGM", key: "2fgm" },
      { label: "2FG%", key: "2fg%" },
      { label: "BLK", key: "blk" },
      { label: "PTS", key: "pts" },
      { label: "FTA", key: "fta" },
      { label: "FTM", key: "ftm" },
      { label: "FT%", key: "ft%" },
      { label: "OFF REB", key: "offreb" },
      { label: "DEF REB", key: "defreb" },
      { label: "AST", key: "ast" },
      { label: "STL", key: "stl" },
      { label: "T/O", key: "t/o" },
      { label: "FOULS", key: "fouls" },
      { label: "3FGA", key: "3fga" },
      { label: "3FGM", key: "3fgm" },
      { label: "3FG%", key: "3fg%" },
      { label: "Full Time Score", key: "finalScore" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          teamName: r[1]?.trim(),
          playerName: r[2]?.trim(),
          //   email: r[3],
          email:
            r[3]?.trim().toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle: r[4] || r[2]?.trim().toLowerCase().split(" ").join(""),
          "1fga": Number(r[5]) || 0,
          "1fgm": Number(r[6]) || 0,
          "1fg%": r[7],
          "2fga": Number(r[8]) || 0,
          "2fgm": Number(r[9]) || 0,
          "2fg%": r[10],
          blocks: Number(r[11]) || 0,
          points: Number(r[12]) || 0,
          fta: Number(r[13]) || 0,
          ftm: Number(r[14]) || 0,
          "ft%": r[15],
          offensiveRebounds: Number(r[16]) || 0,
          defensiveRebounds: Number(r[17]) || 0,
          assists: Number(r[18]) || 0,
          steals: Number(r[19]) || 0,
          turnovers: Number(r[20]) || 0,
          fouls: Number(r[21]) || 0,
          "3fga": Number(r[22]) || 0,
          "3fgm": Number(r[23]) || 0,
          "3fg%": r[24],
          finalScore: Number(r[25]) || 0,
        }))
        .filter((r) => r.matchNumber && r.teamName && r.playerName);
    },
    validate: () => {
      return { valid: true, error: "" };
    },
  },

  Badminton: {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Set Number", key: "setNumber", width: 100, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "Points", key: "points" },
      { label: "Total Serves", key: "totalServes" },
      { label: "Serves Won", key: "servesWon" },
      { label: "Unforced Errors", key: "unforcedErrors" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          setNumber: Number(r[1]) || 0,
          playerName: r[2]?.trim(),
          email:
            r[3]?.trim()?.toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle:
            r[4]?.trim() || r[2]?.trim().toLowerCase().split(" ").join(""),
          points: Number(r[5]) || 0,
          totalServes: Number(r[6]) || 0,
          servesWon: Number(r[7]) || 0,
          unforcedErrors: Number(r[8]) || 0,
        }))
        .filter((r) => r.matchNumber && r.playerName);
    },
    validate: () => {
      return { valid: true, error: "" };
    },
  },

  Tennis: {
    headers: [
      { label: "Match Number", key: "matchNumber", width: 100, fixed: true },
      { label: "Set Number", key: "setNumber", width: 100, fixed: true },
      { label: "Player Name", key: "name", width: 180, fixed: true },
      { label: "Email", key: "email", width: 200 },
      { label: "Player Handle(@)", key: "playerHandle" },
      { label: "Games Won", key: "points" },
      { label: "Total Serves", key: "totalServes" },
      { label: "Serves Won", key: "servesWon" },
      { label: "Aces", key: "aces" },
      { label: "Unforced Errors", key: "unforcedErrors" },
      { label: "Double Faults", key: "doubleFaults" },
    ],
    transform: (rows) => {
      return rows
        .map((r, i) => ({
          eventId: event.id,
          matchNumber: Number(r[0]) || 0,
          setNumber: Number(r[1]) || 0,
          playerName: r[2]?.trim(),
          email:
            r[3]?.trim()?.toLowerCase() ||
            r[2]?.trim().toLowerCase().split(" ").join("_") +
              "@sportspaddock.com",
          handle:
            r[4]?.trim() || r[2]?.trim().toLowerCase().split(" ").join(""),
          gamesWon: Number(r[5]) || 0,
          totalServes: Number(r[6]) || 0,
          servesWon: Number(r[7]) || 0,
          aces: Number(r[8]) || 0,
          unforcedErrors: Number(r[9]) || 0,
          doubleFaults: Number(r[10]) || 0,
        }))
        .filter((r) => r.matchNumber && r.playerName);
    },
    validate: () => {
      return { valid: true, error: "" };
    },
  },
});

export default function UploadEventStats({ ev }) {
  const [, setAppState] = useAppState();

  const config = ev.sport?.name ? sportConfig(ev)[ev.sport.name] : false;

  return (
    <div className="upload-event-stats-page">
      <Card className="upload-event-stats-page__container">
        <FolderIcon className="upload-event-stats-page__container__icon" />
        <div className="upload-event-stats-page__container__title">
          Upload Stats
        </div>
        <Button
          className="upload-event-stats-page__container__btn"
          onClick={() => {
            if (!config) return;
            setAppState({
              modal: (
                <UploadData
                  title={
                    <div>
                      Upload <br /> {ev.sport?.name} Stats
                    </div>
                  }
                  subtitle={ev.name}
                  headers={config.headers}
                  type={"stat"}
                  text={{
                    template: "stats data",
                  }}
                  ev={ev}
                  transform={config.transform}
                  validate={config.validate}
                />
              ),
            });
          }}
        >
          CLICK TO UPLOAD
        </Button>
      </Card>
    </div>
  );
}

import "./SearchWithDropdown.css";
import React, { useState, useCallback } from "react";
import { usePopper } from "react-popper";
import ClickOutside from "../ClickOutside";
import { SEARCH } from "../../../api/user";
import { useLazyQuery } from "@apollo/client";
import debounce from "lodash.debounce";
import Spinner from "../Spinner";

export default function SearchWithDropdown({
  className,
  inputClassName,
  getItems,
  onItemSelect,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [search, { loading, error, data }] = useLazyQuery(SEARCH);

  if (error) {
    console.log(error);
  }

  const handleChange = (e) => {
    if (e.target.value) {
      //   console.log("Firing query");
      search({ variables: { term: e.target.value.trim() } });
    }
  };

  const debouncedSearch = useCallback(
    debounce(handleChange, 500, {
      leading: false,
      trailing: true,
    }),
    []
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  const items = getItems(data);

  return (
    <div style={{ position: "relative" }}>
      <div className={className} ref={setReferenceElement}>
        <input
          type="text"
          className={inputClassName}
          onFocus={() => {
            setShowMenu(true);
          }}
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            debouncedSearch(e);
          }}
        />
      </div>

      {showMenu && (
        <ClickOutside
          onClickOutside={() => {
            setShowMenu(false);
            setSearchTerm("");
          }}
        >
          {(wrapperRef) => (
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 5, width: "100%" }}
              {...attributes.popper}
            >
              <div className={"search-with-dropdown__menu"} ref={wrapperRef}>
                {loading && (
                  <div className="search-with-dropdown__menu__loading">
                    <Spinner size={12} />
                  </div>
                )}
                {items.map((item) => (
                  <div
                    className={"search-with-dropdown__menu__item"}
                    key={item.id}
                    onClick={() => {
                      onItemSelect(item);
                      setSearchTerm("");
                    }}
                  >
                    <div className="search-with-dropdown__menu__item__img"></div>
                    <div className="search-with-dropdown__menu__item__text">
                      <div className="search-with-dropdown__menu__item__text__primary">
                        {item.name}
                      </div>

                      {item.handle && (
                        <div className="search-with-dropdown__menu__item__text__secondary">
                          @{item.handle}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div ref={setArrowElement} style={styles.arrow} />
              </div>
            </div>
          )}
        </ClickOutside>
      )}
    </div>
  );
}

import ModalComponent from "./ModalComponent";
import Form from "./Form";
import CREATE_EVENT from "../api/mutations/createEvent";
import EVENTS from "../api/queries/events";
import { EVENT } from "../api/events";
import SPORTS from "../api/queries/sports";
import ORGANIZATIONS from "../api/queries/organizations";
import { useAppState } from "../utils/appState";
import moment from "moment";

function CreateFixture({ eventId }) {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={CREATE_EVENT}
            initialState={{
              scheduledStart: "",
              scheduledEnd: "",
              homeTeam: "",
              awayTeam: "",
              partId: "",
            }}
            fields={[
              {
                label: "Start Date",
                type: "datetime-local",
                key: "scheduledStart",
              },
              {
                label: "End Date",
                type: "datetime-local",
                key: "scheduledEnd",
              },
              {
                label: "Home Team",
                type: "select",
                key: "homeTeam",
                query: ORGANIZATIONS,
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
              {
                label: "Away Team",
                type: "select",
                key: "awayTeam",
                query: ORGANIZATIONS,
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
              {
                label: "Event Part",
                type: "select",
                key: "partId",
                query: EVENT({ selectionSet: `parts{id label}` }),
                variables: { id: eventId },
                items: (d) => d.event.parts || [],
                optionLabel: (i) => i.label,
              },
            ]}
            update={(cache, { data: { createEvent } }) => {
              // console.log(createOrganization);
              const q = cache.readQuery({ query: EVENTS });
              cache.writeQuery({
                query: EVENTS,
                data: {
                  events: [createEvent, ...q.events],
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => ({
              data: {
                name: s.name,
                scheduledStart: s.scheduledStart
                  ? moment(s.scheduledStart).toISOString()
                  : null,
                scheduledEnd: s.scheduledEnd
                  ? moment(s.scheduledEnd).toISOString()
                  : null,
                registrationEnd: s.registrationEnd
                  ? moment(s.registrationEnd).toISOString()
                  : null,
                sportId: s.sportId,
                organizedById: s.organizedById,
                venueId: s.venueId || null,
              },
            })}
            title={"New Fixture"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateFixture;

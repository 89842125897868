import "./Search.css";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import Spinner from "../Spinner";

export default function Search({
  className = "",
  placeholder = "",
  onSubmit = () => {},
  onReset = () => {},
  networkStatus,
}) {
  const [value, setValue] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  //   console.log(networkStatus);
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setFormSubmitted(true);
        onSubmit(value);
      }}
      className={"App-search " + className}
    >
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />

      {formSubmitted && (
        <div
          className="App-search__dismiss"
          onClick={() => {
            setFormSubmitted(false);
            setValue("");
            onReset();
          }}
        >
          {networkStatus === 7 ? (
            <TimesIcon className="App-search__dismiss__icon" />
          ) : (
            <Spinner size={"14px"} />
          )}
        </div>
      )}
    </form>
  );
}

import ORGANIZATIONS from "../api/queries/organizations";
import EVENTS from "../api/queries/events";
import DELETE_ORGANIZATION from "../api/mutations/deleteOrganization";
import DELETE_PROFILE from "../api/mutations/deleteProfile";
import DELETE_EVENT from "../api/mutations/deleteEvent";
import USER from "../api/queries/user";
import ORGANIZATION from "../api/queries/organization";
import PROFILE from "../api/queries/profile";
import PROFILES from "../api/queries/profiles";
import PROFILE_CLAIMS from "../api/queries/profileClaims";
import SPORT from "../api/queries/sport";
import ENQUIRY from "../api/queries/enquiry";
import EVENT from "../api/queries/event";
import FIXTURE from "../api/queries/fixture";
import DeleteForm from "../components/DeleteForm";
import EventInfo from "../components/events/info/EventInfo";
import EventRegistrations from "../components/events/registrations/EventRegistrations";
import EventStats from "../components/events/stats/EventStats";
import EventMedia from "../components/events/media/EventMedia";
import EventFixtures from "../components/events/fixtures/EventFixtures";
import EventEnquiries from "../components/events/enquiries/EventEnquiries";
// import moment from "moment";

import SportInfo from "../components/sports/info/SportInfo";
import SportStatTypes from "../components/sports/statTypes/SportStatTypes";
import SportPositionTypes from "../components/sports/positionTypes/SportPositionTypes";
import SportFormations from "../components/sports/formations/SportFormations";

import FixtureInfo from "../components/fixtures/info/FixtureInfo";
import FixtureLineups from "../components/fixtures/lineups/FixtureLineups";
import FixtureMedia from "../components/fixtures/media/FixtureMedia";
import FixturePosts from "../components/fixtures/posts/FixturePosts";
import FixtureStats from "../components/fixtures/stats/FixtureStats";

import EnquiryInfo from "../components/enquiries/info/EnquiryInfo";

import OrganizationInfo from "../components/organizations/info/OrganizationInfo";
import OrganizationEvents from "../components/organizations/events/OrganizationEvents";
import OrganizationMedia from "../components/organizations/media/OrganizationMedia";
import OrganizationPosts from "../components/organizations/posts/OrganizationPosts";

import ProfileInfo from "../components/profiles/info/ProfileInfo";
import ProfileRoles from "../components/profiles/roles/ProfileRoles";
import ProfileMedia from "../components/profiles/media/ProfileMedia";
import ProfilePosts from "../components/profiles/posts/ProfilePosts";
import ProfileParticipations from "../components/profiles/participations/ProfileParticipations";
import ProfileClaimsList from "../components/claims/ProfileClaimsList";

const viewRouteConfig = {
  users: {
    query: USER,
    headerOptions: [],
    title: (d) => d.user.name,
  },
  organizations: {
    query: ORGANIZATION,
    headerOptions: [
      (setAppState, d, history) => (
        <div
          className="option delete"
          onClick={() => {
            setAppState({
              modal: (
                <DeleteForm
                  type="organization"
                  mutation={DELETE_ORGANIZATION}
                  update={(cache, { data: { deleteOrganization } }) => {
                    const q = cache.readQuery({ query: ORGANIZATIONS });
                    const newOrgs = [
                      ...q.organizations.filter(
                        (u) => u.id !== deleteOrganization.id
                      ),
                    ];
                    cache.writeQuery({
                      query: ORGANIZATIONS,
                      data: {
                        organizations: newOrgs,
                      },
                    });
                    setAppState({ modal: false });
                    history.push("/index/organizations");
                  }}
                  id={d.organization.id}
                  label={d.organization.name}
                />
              ),
            });
          }}
        >
          Delete
        </div>
      ),
    ],
    title: (d) => d.organization.name,
    tabs: (d) => {
      return [
        {
          label: "Info",
          component: <OrganizationInfo organization={d.organization} />,
        },
        {
          label: "Events",
          component: <OrganizationEvents organization={d.organization} />,
        },
        {
          label: "Media",
          component: <OrganizationMedia organization={d.organization} />,
        },
        {
          label: "Posts",
          component: <OrganizationPosts organization={d.organization} />,
        },
      ];
    },
  },
  profiles: {
    query: PROFILE,
    headerOptions: [
      (setAppState, d, history) => (
        <div
          className="option delete"
          onClick={() => {
            setAppState({
              modal: (
                <DeleteForm
                  type="organization"
                  mutation={DELETE_PROFILE}
                  update={(cache, { data: { deleteProfile } }) => {
                    const q = cache.readQuery({ query: PROFILES });
                    const newProfiles = [
                      ...q.profiles.filter((u) => u.id !== deleteProfile.id),
                    ];
                    cache.writeQuery({
                      query: PROFILES,
                      data: {
                        profiles: newProfiles,
                      },
                    });
                    setAppState({ modal: false });
                    history.push("/index/profiles");
                  }}
                  id={d.profile.id}
                  label={d.profile.name}
                />
              ),
            });
          }}
        >
          Delete
        </div>
      ),
    ],
    title: (d) => d.profile.name,
    tabs: (d) => {
      return [
        {
          label: "Info",
          component: <ProfileInfo profile={d.profile} />,
        },
        {
          label: "Participations",
          component: <ProfileParticipations profile={d.profile} />,
        },
        {
          label: "Roles",
          component: <ProfileRoles profile={d.profile} />,
        },
        {
          label: "Media",
          component: <ProfileMedia profile={d.profile} />,
        },
        {
          label: "Posts",
          component: <OrganizationPosts profile={d.profile} />,
        },
      ];
    },
  },
  enquiries: {
    query: ENQUIRY,
    headerOptions: [],
    title: (d) => d.enquiry.id,
    tabs: (d) => {
      return [
        { label: "Info", component: <EnquiryInfo enquiry={d.enquiry} /> },
      ];
    },
  },
  events: {
    query: EVENT,
    headerOptions: [
      (setAppState, d, history) => (
        <div
          className="option delete"
          onClick={() => {
            setAppState({
              modal: (
                <DeleteForm
                  type="event"
                  mutation={DELETE_EVENT}
                  update={(cache, { data: { deleteEvent } }) => {
                    const q = cache.readQuery({ query: EVENTS });
                    const newEvents = [
                      ...q.events.filter((u) => u.id !== deleteEvent.id),
                    ];
                    cache.writeQuery({
                      query: EVENTS,
                      data: {
                        events: newEvents,
                      },
                    });
                    setAppState({ modal: false });
                    history.push("/index/events");
                  }}
                  id={d.event.id}
                  label={d.event.name}
                />
              ),
            });
          }}
        >
          Delete
        </div>
      ),
    ],
    title: (d) => d.event.name,
    tabs: (d) => {
      return [
        { label: "Info", component: <EventInfo event={d.event} /> },
        {
          label: "Registrations",
          component: <EventRegistrations event={d.event} />,
        },
        { label: "Fixtures", component: <EventFixtures event={d.event} /> },
        { label: "Upload Stats", component: <EventStats event={d.event} /> },
        { label: "Enquiries", component: <EventEnquiries event={d.event} /> },
        { label: "Media", component: <EventMedia event={d.event} /> },
        { label: "Posts", component: <div>Coming Soon</div> },
      ];
    },
  },
  sports: {
    query: SPORT,
    headerOptions: [],
    title: (d) => d.sport.name,
    tabs: (d) => {
      return [
        { label: "Info", component: <SportInfo sport={d.sport} /> },
        { label: "Stat Types", component: <SportStatTypes sport={d.sport} /> },
        {
          label: "Position Types",
          component: <SportPositionTypes sport={d.sport} />,
        },
        { label: "Formations", component: <SportFormations sport={d.sport} /> },
      ];
    },
  },
  fixtures: {
    query: FIXTURE,
    headerOptions: [],
    title: (d) => d.fixture.label,
    goBackRoute: (d) => `/view/events/${d.fixture.event.id}?t=2`,
    tabs: (d) => {
      return [
        { label: "Info", component: <FixtureInfo fixture={d.fixture} /> },
        { label: "Lineups", component: <FixtureLineups fixture={d.fixture} /> },
        { label: "Stats", component: <FixtureStats fixture={d.fixture} /> },
        { label: "Media", component: <FixtureMedia fixture={d.fixture} /> },
        { label: "Posts", component: <FixturePosts fixture={d.fixture} /> },
      ];
    },
  },
  claims: {
    query: PROFILE_CLAIMS,
    headerOptions: [],
    title: (d) => d.profile.name + " Claims",
    tabs: (d) => {
      return [
        {
          label: "Overview",
          component: <ProfileClaimsList profile={d.profile} />,
        },
      ];
    },
  },
};

export default viewRouteConfig;

import "./DeletePositionType.css";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { Loader } from "../../common";
import { useMutation } from "@apollo/client";
import { UPDATE_SPORT } from "../../../api/sports";

export default function DeletePositionType({ sport, pos }) {
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");

  const [mutate, mutation] = useMutation(
    UPDATE_SPORT({
      selectionSet: `
      positionTypes{
        id
        name
        shortName
        group{
          id
          name
        }
        canDelete
      }
  `,
    }),
    {
      update: () => {
        setAppState({ modal: false });
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">
          Confirm Delete Position
        </div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!pos?.id) {
            setError("Invalid Position Id.");
            return;
          }

          const vars = {
            where: { id: sport.id },
            data: {
              positionTypes: {
                deleteMany: [{ id: pos.id }],
              },
            },
          };

          mutate({
            variables: vars,
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Are you sure you want to delete this position?
          </div>
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__delete-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

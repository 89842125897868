import "./Sports.css";
import { Switch, Route } from "react-router-dom";
import { SportsIndex, ViewSport } from "../../componentsV2/sports";

export default function Sports() {
  return (
    <Switch>
      <Route path="/v2/sports/index" component={SportsIndex} />
      <Route path="/v2/sports/view/:id" component={ViewSport} />
      <Route path="/v2/sports" component={SportsIndex} />
    </Switch>
  );
}

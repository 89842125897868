import { Avatar, Card, CardTitle, Loader } from "../../common";
import "./EventParticipantsList.css";
import { EVENT, UPDATE_EVENT } from "../../../api/events";
import { useQuery, useMutation } from "@apollo/client";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { useAppState } from "../../../utils/appState";
import AddEventParticipant from "../AddEventParticipant";

function EventParticipantsListItem({ ev, role, selectionSet }) {
  const [mutate, { loading }] = useMutation(UPDATE_EVENT({ selectionSet }), {
    update: (cache, { data: { updateEvent } }) => {
      const q = cache.readQuery({
        query: EVENT({ selectionSet }),
        variables: { id: ev.id },
      });

      cache.writeQuery({
        query: EVENT({ selectionSet }),
        variables: { id: ev.id },
        data: {
          event: {
            ...q.event,
            roles: updateEvent.roles,
          },
        },
      });
    },
  });

  return (
    <div className="event-participants-list__items__item">
      <div className="event-participants-list__items__item__img">
        {role?.organization?.profilePhoto && (
          <Avatar media={role.organization.profilePhoto} />
        )}
      </div>

      <div className="event-participants-list__items__item__text">
        <div className="event-participants-list__items__item__text__primary">
          {role?.organization?.name}
        </div>

        <div className="event-participants-list__items__item__text__secondary">
          @{role?.organization?.handle}
        </div>
      </div>

      <div className="event-participants-list__items__item__options">
        <div
          className="event-participants-list__items__item__options__option delete"
          onClick={() => {
            mutate({
              variables: {
                where: { id: ev.id },
                data: {
                  roles: {
                    deleteMany: [{ id: role.id }],
                  },
                },
              },
            });
          }}
        >
          {loading ? (
            <Loader theme={"red small"} />
          ) : (
            <TrashIcon className="event-participants-list__items__item__options__option__icon" />
          )}
        </div>
      </div>
    </div>
  );
}

export default function EventParticipantsList({ ev }) {
  const [, setAppState] = useAppState();
  const selectionSet = `
  participantType
  participants{
    id
    type{
      id
      name
    }
    organization{
      id
      name
      handle
      profilePhoto{
        id
        filename
      }
    }
  }  
  `;

  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id: ev.id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const participants = data?.event?.participants || [];

  return (
    <Card className="event-participants-list">
      <CardTitle
        title="PARTICIPANTS"
        options={
          <div
            className="event-participants-list__add-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <AddEventParticipant ev={ev} selectionSet={selectionSet} />
                ),
              });
            }}
          >
            + ADD
          </div>
        }
      />
      <div className="event-participants-list__items">
        {participants.map((p) => (
          <EventParticipantsListItem
            ev={ev}
            role={p}
            selectionSet={selectionSet}
          />
        ))}
      </div>
    </Card>
  );
}

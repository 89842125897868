import { ADMINS, UPDATE_USER } from "../../../api/users";
import "./AddAdmin.css";
import { useState } from "react";
import { useAppState } from "../../../utils/appState";
import { useMutation } from "@apollo/client";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { SearchWithDropdown, Loader } from "../../common";

export default function AddAdmin() {
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");
  const [profile, setProfile] = useState("");
  //   const [fromDate, setFromDate] = useState("");
  //   const [toDate, setToDate] = useState("");

  const [mutate, mutation] = useMutation(
    UPDATE_USER({
      selectionSet: `
      profile{
        id
        name
        handle
        profilePhoto{
          id
          filename
        }
      }
  `,
    }),
    {
      update: (cache, { data: { updateUser } }) => {
        const q = cache.readQuery({
          query: ADMINS({
            selectionSet: `
          profile{
            id
            name
            handle
            profilePhoto{
              id
              filename
            }
          }
        `,
          }),
          variables: { where: {} },
        });

        if (q?.admins) {
          cache.writeQuery({
            query: ADMINS({
              selectionSet: `
              profile{
                id
                name
                handle
                profilePhoto{
                  id
                  filename
                }
              }
            `,
            }),
            variables: { where: {} },
            data: {
              admins: [updateUser, ...q.admins],
            },
          });
        }

        setAppState({ modal: false });

        //   history.push("/app/event/" + createEvent.id);
      },
      onError: (error) => {
        setError(error.message);
        console.log(error);
      },
    }
  );

  console.log(profile);

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Add Admin</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!profile?.user?.id) {
            setError("Invalid user.");
            return;
          }

          mutate({
            variables: {
              where: { id: profile.user.id },
              data: {
                isAdmin: true,
              },
            },
          });
        }}
      >
        <div
          className="App-modal-form__body__group"
          style={{ height: "240px" }}
        >
          <div className="App-modal-form__body__group__label">
            Profile<span className="required">*</span>
          </div>

          {profile ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setProfile("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {profile.name}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.profiles || []}
              onItemSelect={(item) => {
                setProfile(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        {/* <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">From date</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">To date</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div> */}

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

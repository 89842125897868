import "./CreatePage.css";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import {
  Loader,
  LocationSearch,
  SearchWithDropdown,
  Spinner,
} from "../../common";
import { useAppState } from "../../../utils/appState";
import { ORGANIZATIONS } from "../../../api/organizations";
import { CREATE_ORGANIZATION } from "../../../api/mutations";
import { useMutation } from "@apollo/client";
import moment from "moment";

export default function CreatePage() {
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [handle, setHandle] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");

  const [mutate, mutation] = useMutation(CREATE_ORGANIZATION, {
    update: (cache, { data: { createOrganization } }) => {
      const q = cache.readQuery({
        query: ORGANIZATIONS(),
        variables: { where: {} },
      });

      if (q?.organizations) {
        cache.writeQuery({
          query: ORGANIZATIONS(),
          variables: { where: {} },
          data: {
            organizations: [createOrganization, ...q.organizations],
          },
        });
      }

      setAppState({ modal: false });

      //   history.push("/app/event/" + createEvent.id);
    },
    onError: (error) => {
      setError(error.message);
      console.log(error);
    },
  });

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Create Event</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!name || !handle || !city || !state || !country || !lat || !lng) {
            setError("Please fill out all required fields.");
            return;
          }

          const vars = {
            data: {
              name,
              handle,
              city,
              state,
              country,
              lat,
              lng,
            },
          };

          mutate({
            variables: vars,
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Name<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Handle(@)<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="text"
              value={handle}
              onChange={(e) => {
                setHandle(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Location<span className="required">*</span>
          </div>

          {city ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setCity("");
                  setState("");
                  setCountry("");
                  setLat("");
                  setLng("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {city}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <LocationSearch
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              onItemSelect={(city, state, country, lat, lng) => {
                console.log(city, state, country, lat, lng);
                setCity(city);
                setState(state);
                setCountry(country);
                setLat(lat);
                setLng(lng);
              }}
            />
          )}
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

import "./OrganizationDetails.css";
import { Card, CardTitle, EditableField, Spinner, Error } from "../../common";
import { useQuery } from "@apollo/client";
import { ORGANIZATION, UPDATE_ORGANIZATION } from "../../../api/organizations";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function OrganizationDetails({ org }) {
  const { loading, error, data } = useQuery(
    ORGANIZATION({
      selectionSet: `
    name
    handle    
    city{
      id
      value
    }   
  `,
    }),
    {
      variables: { id: org.id },
    }
  );

  if (error) {
    console.log(error);
    return <Error />;
  }

  const { name, handle, city } = data?.organization || {};

  return (
    <Card className="event-info-page__details">
      <CardTitle title={"DETAILS"} />

      {loading || !data ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="event-info-page__details__items">
          <EditableField
            label={"Name"}
            value={name}
            config={{
              valueType: "text",
              mutation: UPDATE_ORGANIZATION({ selectionSet: `name` }),
              vars: (v) => {
                return {
                  where: { id: org.id },
                  data: {
                    name: v,
                  },
                };
              },
            }}
          />

          <EditableField
            label={"Handle"}
            value={handle}
            config={{
              valueType: "text",
              mutation: UPDATE_ORGANIZATION({ selectionSet: `handle` }),
              vars: (v) => {
                return {
                  where: { id: org.id },
                  data: {
                    handle: v,
                  },
                };
              },
              onError: () => {
                toast(
                  "This handle is unavailable. Please try again with a unique handle."
                );
              },
            }}
          />

          <EditableField
            label={"Location"}
            value={city}
            getValueString={(x) => x?.value || ""}
            config={{
              valueType: "location",
              mutation: UPDATE_ORGANIZATION({ selectionSet: `city{id value}` }),
              vars: (v) => {
                return {
                  where: { id: org.id },
                  data: {
                    city: v.city,
                    state: v.state,
                    country: v.country,
                    lat: v.lat,
                    lng: v.lng,
                  },
                };
              },
            }}
          />
        </div>
      )}

      <ToastContainer position={"bottom-right"} theme="dark" />
    </Card>
  );
}

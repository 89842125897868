import { Fragment, useState } from "react";
import "./ReportedMediaBrowser.css";
import { Card, Spinner, Button, Loader } from "../../common";
import { useMutation, useQuery } from "@apollo/client";
import {
  DASHBOARD_STATS,
  HANDLE_REPORTED_MEDIA,
  REPORTED_MEDIA,
  SUPPORT_TICKETS,
} from "../../../api/dashboard";
import makeFileUrl from "../../../utils/makeFileUrl";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../icons/chevron-right.svg";
import ClickOutside from "../../common/ClickOutside";
import { useAppState } from "../../../utils/appState";

function TicketsList({ mediaId }) {
  const { loading, error, data } = useQuery(
    SUPPORT_TICKETS({
      selectionSet: `
      createdAt
      createdBy{
        id
        name
      }
      description
    `,
    }),
    {
      variables: {
        where: {
          media: { id: mediaId },
          handled: false,
        },
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const tickets = data.supportTickets;

  console.log(tickets);

  return (
    <div className="report-browser__meta__tickets">
      {tickets.map((ticket) => (
        <div className="report-browser__meta__tickets__ticket">
          <div className="report-browser__meta__tickets__ticket__user">
            <div className="report-browser__meta__tickets__ticket__user__img"></div>

            <div className="report-browser__meta__tickets__ticket__user__name">
              {ticket.createdBy?.name}
            </div>
          </div>

          <div className="report-browser__meta__tickets__ticket__description">
            {ticket.description || (
              <span className="placeholder">No comment</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function ReportedMediaBrowser() {
  const [, setAppState] = useAppState();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [mutate, { loading: updating }] = useMutation(
    HANDLE_REPORTED_MEDIA({
      selectionSet: `
    id
    flagged
  `,
    }),
    {
      update: (cache, { data: { handleReportedMedia } }) => {
        // 1. Update the dashboard stats query
        const statsQuery = cache.readQuery({
          query: DASHBOARD_STATS,
        });

        if (statsQuery?.dashboardStats) {
          cache.writeQuery({
            query: DASHBOARD_STATS,
            data: {
              dashboardStats: {
                ...statsQuery.dashboardStats,
                media: statsQuery.dashboardStats.media - 1,
              },
            },
          });
        }

        // 2. Update the reported media query
        const reportsQuery = cache.readQuery({
          query: REPORTED_MEDIA({
            selectionSet: `
            type
            filename
          `,
          }),
        });

        if (reportsQuery?.reportedMedia) {
          const newReports = [...reportsQuery.reportedMedia].filter(
            (m) => m.id !== handleReportedMedia.id
          );
          cache.writeQuery({
            query: REPORTED_MEDIA({
              selectionSet: `
              type
              filename
            `,
            }),
            data: {
              reportedMedia: newReports,
            },
          });

          if (!newReports.length) {
            setAppState({ modal: false });
          }
        }
      },
    }
  );

  const { loading, error, data } = useQuery(
    REPORTED_MEDIA({
      selectionSet: `
      type
      filename
    `,
    })
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const media = data.reportedMedia;
  const selectedMedia = media[selectedIndex];

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="report-browser__wrapper" ref={wrapperRef}>
          <Card className="report-browser">
            <div className="report-browser__content">
              {selectedMedia.type === "IMAGE" && (
                <img src={makeFileUrl(selectedMedia)} />
              )}
            </div>

            <div className="report-browser__meta">
              <div className="report-browser__meta__header">
                <div className="report-browser__meta__header__title">
                  Media #{selectedMedia?.id}
                </div>

                <div className="report-browser__meta__header__options">
                  <div
                    className={
                      "report-browser__meta__header__options__option " +
                      (selectedIndex > 0 ? "" : "disabled")
                    }
                    onClick={() => {
                      if (selectedIndex > 0) {
                        setSelectedIndex((prev) => prev - 1);
                      } else {
                        return;
                      }
                    }}
                  >
                    <ChevronLeft
                      className={
                        "report-browser__meta__header__options__option__icon "
                      }
                    />
                  </div>

                  <div
                    className="report-browser__meta__header__options__option"
                    onClick={() => {
                      if (selectedIndex < media.length - 1) {
                        setSelectedIndex((prev) => prev + 1);
                      } else {
                        // Fetch more
                      }
                    }}
                  >
                    <ChevronRight className="report-browser__meta__header__options__option__icon" />
                  </div>
                </div>
              </div>

              <TicketsList mediaId={selectedMedia?.id} />

              <div className="report-browser__meta__actions">
                {updating ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <Button
                      className="report-browser__meta__actions__action danger"
                      onClick={() => {
                        mutate({
                          variables: {
                            mediaId: selectedMedia?.id,
                            flagged: true,
                          },
                        });
                      }}
                    >
                      FLAG THIS POST
                    </Button>

                    <Button
                      className="report-browser__meta__actions__action"
                      onClick={() => {
                        mutate({
                          variables: {
                            mediaId: selectedMedia?.id,
                            flagged: false,
                          },
                        });
                      }}
                    >
                      POST IS OKAY
                    </Button>
                  </Fragment>
                )}
              </div>
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

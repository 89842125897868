import ModalComponent from "./ModalComponent";
import Editable from "./Editable";
import Form from "./Form";
import InfoField from "./InfoField";
import { useAppState } from "../utils/appState";
import moment from "moment";
import { useState } from "react";
import {
  EVENT_REGISTRATIONS,
  UPDATE_REGISTRATION,
  DELETE_REGISTRATION,
} from "../api/events";
import ORGANIZATIONS from "../api/queries/organizations";
import { FaTimes } from "react-icons/fa";

function ViewRegistration({ eventRegistration, eventId }) {
  console.log("Reg:", eventRegistration);
  const [, setAppState] = useAppState();
  const [registration, setRegistration] = useState({ ...eventRegistration });
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-card">
          <div className="modal-card-header">
            <div className="primary">{registration.profile.name}</div>
            <div className="secondary">{registration.profile.email}</div>
            <div className="secondary">{registration.profile.phone}</div>
          </div>

          <div className="modal-card-body">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ organizationId: registration.organizationId }}
                  fields={[
                    {
                      label: "Edit Team",
                      type: "select",
                      key: "organizationId",
                      query: ORGANIZATIONS,
                      variables: { where: { types: ["Sports Team"] } },
                      items: (d) => d.organizations,
                      optionLabel: (i) => i.name,
                    },
                  ]}
                  mutation={UPDATE_REGISTRATION({
                    selectionSet: "organization{id name}",
                  })}
                  update={(cache, { data: { updateRegistration } }) => {
                    const q = cache.readQuery({
                      query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                      variables: { eventId },
                    });

                    const index = q.eventRegistrations.findIndex(
                      (r) => r.id === updateRegistration.id
                    );

                    if (index > -1) {
                      cache.writeQuery({
                        query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                        variables: { eventId },
                        data: {
                          eventRegistrations: [
                            ...q.eventRegistrations.slice(0, index),
                            { ...updateRegistration },
                            ...q.eventRegistrations.slice(
                              index + 1,
                              q.eventRegistrations.length
                            ),
                          ],
                        },
                      });

                      setRegistration({
                        ...registration,
                        organization: updateRegistration.organization,
                      });
                    }

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id: registration.id },
                    data: {
                      organizationId: s.organizationId || null,
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <InfoField
                  setEditing={setEditing}
                  label={"Team"}
                  value={registration.organization?.name}
                  required={true}
                />
              )}
            />
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ paid: registration.paid }}
                  fields={[
                    {
                      label: "Edit Paid",
                      type: "select",
                      key: "paid",
                      items: [
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                      ],
                    },
                  ]}
                  mutation={UPDATE_REGISTRATION({
                    selectionSet: "paid",
                  })}
                  update={(cache, { data: { updateRegistration } }) => {
                    const q = cache.readQuery({
                      query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                      variables: { eventId },
                    });

                    const index = q.eventRegistrations.findIndex(
                      (r) => r.id === updateRegistration.id
                    );

                    if (index > -1) {
                      cache.writeQuery({
                        query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                        variables: { eventId },
                        data: {
                          eventRegistrations: [
                            ...q.eventRegistrations.slice(0, index),
                            { ...updateRegistration },
                            ...q.eventRegistrations.slice(
                              index + 1,
                              q.eventRegistrations.length
                            ),
                          ],
                        },
                      });

                      setRegistration({
                        ...registration,
                        paid: updateRegistration.paid,
                      });
                    }

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id: registration.id },
                    data: {
                      paid: s.paid === "true" ? true : false,
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <InfoField
                  setEditing={setEditing}
                  label={"Paid"}
                  value={registration.paid ? "YES" : "NO"}
                  required={true}
                />
              )}
            />
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{
                    amountPaid: registration.amountPaid || 0,
                  }}
                  fields={[
                    {
                      label: "Edit Amount Paid",
                      type: "number",
                      key: "amountPaid",
                    },
                  ]}
                  mutation={UPDATE_REGISTRATION({
                    selectionSet: "amountPaid",
                  })}
                  update={(cache, { data: { updateRegistration } }) => {
                    // console.log(updateRegistration);
                    const q = cache.readQuery({
                      query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                      variables: { eventId },
                    });

                    const index = q.eventRegistrations.findIndex(
                      (r) => r.id === updateRegistration.id
                    );

                    if (index > -1) {
                      cache.writeQuery({
                        query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                        variables: { eventId },
                        data: {
                          eventRegistrations: [
                            ...q.eventRegistrations.slice(0, index),
                            { ...updateRegistration },
                            ...q.eventRegistrations.slice(
                              index + 1,
                              q.eventRegistrations.length
                            ),
                          ],
                        },
                      });

                      setRegistration({
                        ...registration,
                        amountPaid: updateRegistration.amountPaid,
                      });
                    }

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id: registration.id },
                    data: {
                      amountPaid: Number(s.amountPaid) || 0,
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <InfoField
                  setEditing={setEditing}
                  label={"Amount Paid"}
                  value={registration.amountPaid || 0}
                  required={true}
                />
              )}
            />
          </div>

          <div className="modal-card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  action={"delete"}
                  primaryActionText={"Delete"}
                  className={"editable-field-form"}
                  initialState={{}}
                  fields={[]}
                  mutation={DELETE_REGISTRATION}
                  update={(cache, { data: { deleteRegistration } }) => {
                    const q = cache.readQuery({
                      query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                      variables: { eventId },
                    });

                    const index = q.eventRegistrations.findIndex(
                      (r) => r.id === deleteRegistration.id
                    );

                    if (index > -1) {
                      const newRegs = [...q.eventRegistrations];
                      cache.writeQuery({
                        query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                        variables: { eventId },
                        data: {
                          eventRegistrations: [
                            ...newRegs.slice(0, index),
                            ...newRegs.slice(index + 1, newRegs.length),
                          ],
                        },
                      });

                      setAppState({ modal: false });
                    }

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    id: registration.id,
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                  title={"Are you sure you want to delete this registration?"}
                />
              )}
              content={(setEditing) => (
                <div
                  className="App-btn danger"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Delete Registration
                </div>
              )}
            />
          </div>
        </div>
      )}
    </ModalComponent>
  );
}

export default ViewRegistration;

import "./CardTitle.css";

export default function CardTitle({ title, options }) {
  return (
    <div className="App-card__title">
      <div className="App-card__title__text">{title}</div>
      <div className="App-card__title__options">{options}</div>
    </div>
  );
}

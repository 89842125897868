import "./UnpublishRequestsSummary.css";
import { Card, CardTitle } from "../../common";

export default function UnpublishRequestsSummary({ count }) {
  return (
    <Card className="dashboard-report">
      <CardTitle title={"UNPUBLISH REQUESTS"} />
      <div className="dashboard-report__body">
        <div className="dashboard-report__body__blurb">
          There are <span className="bold">{count}</span> requests to unpublish
          stats.
        </div>

        {!!count && (
          <div className="dashboard-report__body__link">
            View unpublish requests
          </div>
        )}
      </div>
    </Card>
  );
}

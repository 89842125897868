import { gql } from "@apollo/client";

export function ADMINS({ selectionSet }) {
  return gql`
      query admins {
        admins {
          id
          name
          email
          ${selectionSet}
        }
      }
    `;
}

export function UPDATE_USER({ selectionSet }) {
  return gql`
      mutation updateUser($where: UniqueInput!, $data:UpdateUserInput!) {
        updateUser(where:$where, data:$data) {
          id
          ${selectionSet}
        }
      }
    `;
}

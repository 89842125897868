import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixtures";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";
import Loading from "../../Loading";

function FixtureDetails({ id }) {
  const selectionSet = `
    label
    scheduledStart
    scheduledEnd
    actualStart
    actualEnd
  `;

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const fixture = data?.fixture;

  return (
    <div className="card">
      <div className="card-title">Fixture Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="card-body">
          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ label: fixture.label }}
                fields={[{ label: "Edit Label", type: "text", key: "label" }]}
                mutation={UPDATE_FIXTURE({ selectionSet: `label` })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                    data: {
                      fixture: {
                        ...q.fixture,
                        label: updateFixture.label,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    label: s.label,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Label"}
                value={fixture.label}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  scheduledStart: fixture.scheduledStart
                    ? moment(fixture.scheduledStart).format("YYYY-MM-DDTHH:mm")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Scheduled Start",
                    type: "datetime-local",
                    key: "scheduledStart",
                  },
                ]}
                mutation={UPDATE_FIXTURE({ selectionSet: `scheduledStart` })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                    data: {
                      fixture: {
                        ...q.fixture,
                        scheduledStart: updateFixture.scheduledStart,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    scheduledStart: s.scheduledStart
                      ? moment(s.scheduledStart).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Scheduled Start"}
                value={
                  fixture.scheduledStart
                    ? moment(fixture.scheduledStart).format("Do MMM YYYY HH:mm")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  scheduledEnd: fixture.scheduledEnd
                    ? moment(fixture.scheduledEnd).format("YYYY-MM-DDTHH:mm")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Scheduled End",
                    type: "datetime-local",
                    key: "scheduledEnd",
                  },
                ]}
                mutation={UPDATE_FIXTURE({ selectionSet: `scheduledEnd` })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                    data: {
                      fixture: {
                        ...q.fixture,
                        scheduledEnd: updateFixture.scheduledEnd,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    scheduledEnd: s.scheduledEnd
                      ? moment(s.scheduledEnd).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Scheduled End"}
                value={
                  fixture.scheduledEnd
                    ? moment(fixture.scheduledEnd).format("Do MMM YYYY HH:mm")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  actualStart: fixture.actualStart
                    ? moment(fixture.actualStart).format("YYYY-MM-DDTHH:mm")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Actual Start",
                    type: "datetime-local",
                    key: "actualStart",
                  },
                ]}
                mutation={UPDATE_FIXTURE({ selectionSet: `actualStart` })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                    data: {
                      fixture: {
                        ...q.fixture,
                        actualStart: updateFixture.actualStart,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    actualStart: s.actualStart
                      ? moment(s.actualStart).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Actual Start"}
                value={
                  fixture.actualStart
                    ? moment(fixture.actualStart).format("Do MMM YYYY HH:mm")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  actualEnd: fixture.actualEnd
                    ? moment(fixture.actualEnd).format("YYYY-MM-DDTHH:mm")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Actual End",
                    type: "datetime-local",
                    key: "actualEnd",
                  },
                ]}
                mutation={UPDATE_FIXTURE({ selectionSet: `actualEnd` })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                    data: {
                      fixture: {
                        ...q.fixture,
                        actualEnd: updateFixture.actualEnd,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    actualEnd: s.actualEnd
                      ? moment(s.actualEnd).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Actual Start"}
                value={
                  fixture.actualEnd
                    ? moment(fixture.actualEnd).format("Do MMM YYYY HH:mm")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  totalLegs: fixture.totalLegs || 1,
                }}
                fields={[
                  {
                    label: "Edit Total Legs",
                    type: "number",
                    key: "totalLegs",
                  },
                ]}
                mutation={UPDATE_FIXTURE({ selectionSet: `totalLegs` })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        totalLegs: updateFixture.totalLegs,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    totalLegs: s.totalLegs,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Total Legs"}
                value={fixture.totalLegs || 1}
                required={true}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default FixtureDetails;

import { SPORT, UPDATE_SPORT } from "../../../api/sports";
import SPORTS from "../../../api/queries/sports";
import { useQuery } from "@apollo/client";
import Form from "../../Form";
import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function SportFormats({ id }) {
  const selectionSet = `
    formats{
      id
      name
    }
  `;
  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const sport = data?.sport;
  // console.log("Formats:", sport)
  // console.log(sport);

  return (
    <div className="card">
      <div className="card-title">Sport Formats</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {sport.formats?.map((format) => (
              <Editable
                form={(setEditing) => (
                  <Form
                    className={"editable-field-form"}
                    initialState={{ formatId: "" }}
                    fields={[
                      {
                        label: `Are you sure? Type ${format.id} to confirm.`,
                        type: "text",
                        key: "formatId",
                      },
                    ]}
                    mutation={UPDATE_SPORT({
                      selectionSet: `formats{id name}`,
                    })}
                    update={(cache, { data: { updateSport } }) => {
                      const q = cache.readQuery({
                        query: SPORT({ selectionSet }),
                        variables: { id },
                      });

                      cache.writeQuery({
                        query: SPORT({ selectionSet }),
                        variables: { id },
                        data: {
                          sport: {
                            ...q.sport,
                            formats: updateSport.formats,
                          },
                        },
                      });

                      setEditing(false);
                    }}
                    vars={(s) => ({
                      where: { id },
                      data: {
                        formats: {
                          disconnect: [{ id: s.formatId }],
                        },
                      },
                    })}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setEditing(false);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                )}
                content={(setEditing) => (
                  <InfoField
                    setEditing={setEditing}
                    label={""}
                    value={format.name}
                    required={false}
                    disableEditing={true}
                  />
                )}
              />
            ))}
          </div>

          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ formatId: "" }}
                  fields={[
                    {
                      label: "Select Sport",
                      type: "select",
                      key: "formatId",
                      query: SPORTS,
                      items: (d) => d.sports,
                      optionLabel: (i) => i.name,
                    },
                  ]}
                  mutation={UPDATE_SPORT({ selectionSet })}
                  update={(cache, { data: { updateSport } }) => {
                    const q = cache.readQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id },
                      data: {
                        sport: {
                          ...q.sport,
                          formats: updateSport.formats,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => {
                    if (!s.formatId) {
                      return false;
                    }

                    return {
                      where: { id },
                      data: {
                        formats: {
                          connect: [{ id: s.formatId }],
                        },
                      },
                    };
                  }}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add a format
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default SportFormats;

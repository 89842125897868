import Editable from "../../Editable";
import InfoField from "../../InfoField";
import StatefulView from "../../StatefulView";
import { useQuery } from "@apollo/client";
import { SPORT, UPDATE_SPORT } from "../../../api/sports";
import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes, FaTrash, FaPencilAlt } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function PositionTypeList({ id }) {
  const selectionSet = `
    positionTypes{
      id
      name
      group{
        id
        name
      }
    }
    positionTypeGroups{
      id
      name
    }
  `;

  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const sport = data?.sport;
  console.log("Types:", sport);

  return (
    <div className="card">
      <div className="card-title">Positions List</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {sport.positionTypeGroups?.map((stg) => (
              <div className="card-body-group">
                <div className="card-body-group-title">{stg.name}</div>
                {sport.positionTypes
                  ?.filter((st) => st.group?.id === stg.id)
                  .map((st) => (
                    <StatefulView
                      states={[
                        (setSelectedState) => (
                          <InfoField
                            options={[
                              <div
                                className="option delete"
                                onClick={() => {
                                  setSelectedState(2);
                                }}
                              >
                                <FaTrash />
                              </div>,
                              <div
                                className="option edit"
                                onClick={() => {
                                  setSelectedState(1);
                                }}
                              >
                                <FaPencilAlt />
                              </div>,
                            ]}
                            setSelectedState={setSelectedState}
                            label={""}
                            value={st.name}
                            required={false}
                          />
                        ),
                        (setSelectedState) => (
                          <Form
                            className={"editable-field-form"}
                            initialState={{
                              groupId: st.group.id,
                              name: st.name,
                            }}
                            fields={[
                              {
                                label: "Group",
                                type: "select",
                                key: "groupId",
                                items: sport.positionTypeGroups.map((x) => ({
                                  label: x.name,
                                  value: x.id,
                                })),
                              },
                              {
                                label: "Position Name",
                                type: "text",
                                key: "name",
                              },
                            ]}
                            mutation={UPDATE_SPORT({ selectionSet })}
                            update={(cache, { data: { updateSport } }) => {
                              const q = cache.readQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                              });
                              cache.writeQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                                data: {
                                  sport: {
                                    ...q.sport,
                                    positionTypes: updateSport.positionTypes,
                                  },
                                },
                              });

                              setSelectedState(0);
                            }}
                            vars={(s) => ({
                              where: { id },
                              data: {
                                positionTypes: {
                                  update: [
                                    {
                                      where: { id: st.id },
                                      data: {
                                        groupId: s.groupId,
                                        name: s.name,
                                      },
                                    },
                                  ],
                                },
                              },
                            })}
                            options={[
                              <div
                                className="option cancel"
                                onClick={() => {
                                  setSelectedState(0);
                                }}
                              >
                                <FaTimes />
                              </div>,
                            ]}
                          />
                        ),
                        (setSelectedState) => (
                          <Form
                            className={"editable-field-form"}
                            initialState={{ positionTypeId: "" }}
                            fields={[
                              {
                                label: `Are you sure? Type ${st.id} to confirm.`,
                                type: "text",
                                key: "positionTypeId",
                              },
                            ]}
                            mutation={UPDATE_SPORT({ selectionSet })}
                            update={(cache, { data: { updateSport } }) => {
                              const q = cache.readQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                              });
                              cache.writeQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                                data: {
                                  sport: {
                                    ...q.sport,
                                    positionTypes: updateSport.positionTypes,
                                  },
                                },
                              });

                              setSelectedState(0);
                            }}
                            vars={(s) => ({
                              where: { id },
                              data: {
                                positionTypes: {
                                  deleteMany: [
                                    {
                                      id: st.id,
                                    },
                                  ],
                                },
                              },
                            })}
                            options={[
                              <div
                                className="option cancel"
                                onClick={() => {
                                  setSelectedState(0);
                                }}
                              >
                                <FaTimes />
                              </div>,
                            ]}
                          />
                        ),
                      ]}
                    />
                  ))}
              </div>
            ))}

            <div className="card-body-group">
              <div className="card-body-group-title">Ungrouped</div>
              {sport.positionTypes
                ?.filter((st) => !st.group)
                .map((st) => (
                  <Editable
                    form={(setEditing) => (
                      <Form
                        className={"editable-field-form"}
                        initialState={{ groupId: "", name: st.name }}
                        fields={[
                          {
                            label: "Group",
                            type: "select",
                            key: "groupId",
                            items: sport.positionTypeGroups.map((x) => ({
                              label: x.name,
                              value: x.id,
                            })),
                          },
                          {
                            label: "Position Name",
                            type: "text",
                            key: "name",
                          },
                        ]}
                        mutation={UPDATE_SPORT({ selectionSet })}
                        update={(cache, { data: { updateSport } }) => {
                          const q = cache.readQuery({
                            query: SPORT({ selectionSet }),
                            variables: { id },
                          });
                          cache.writeQuery({
                            query: SPORT({ selectionSet }),
                            variables: { id },
                            data: {
                              sport: {
                                ...q.sport,
                                positionTypes: updateSport.positionTypes,
                              },
                            },
                          });

                          setEditing(false);
                        }}
                        vars={(s) => ({
                          where: { id },
                          data: {
                            positionTypes: {
                              update: [
                                {
                                  where: { id: st.id },
                                  data: { groupId: s.groupId, name: s.name },
                                },
                              ],
                            },
                          },
                        })}
                        options={[
                          <div
                            className="option cancel"
                            onClick={() => {
                              setEditing(false);
                            }}
                          >
                            <FaTimes />
                          </div>,
                        ]}
                      />
                    )}
                    content={(setEditing) => (
                      <InfoField
                        setEditing={setEditing}
                        label={""}
                        value={st.name}
                        required={false}
                      />
                    )}
                  />
                ))}
            </div>
          </div>
          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ groupId: "", name: "", shortName: "" }}
                  fields={[
                    {
                      label: "Group",
                      type: "select",
                      key: "groupId",
                      items: sport.positionTypeGroups.map((st) => ({
                        label: st.name,
                        value: st.id,
                      })),
                    },
                    {
                      label: "Position Name",
                      type: "text",
                      key: "name",
                    },
                    {
                      label: "Short Name",
                      type: "text",
                      key: "shortName",
                    },
                  ]}
                  mutation={UPDATE_SPORT({ selectionSet })}
                  update={(cache, { data: { updateSport } }) => {
                    const q = cache.readQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id },
                      data: {
                        sport: {
                          ...q.sport,
                          positionTypes: updateSport.positionTypes,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id },
                    data: {
                      positionTypes: {
                        create: [
                          {
                            groupId: s.groupId,
                            name: s.name,
                            shortName: s.shortName,
                          },
                        ],
                      },
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add Position Type
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default PositionTypeList;

import "./FixtureDetails.css";
import { Card, CardTitle, EditableField, Spinner, Error } from "../../common";
import { useQuery } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixtures";
// import { SPORTS } from "../../../api/sports";
// import { EVENT_TYPES } from "../../../api/queries";
import moment from "moment";

export default function FixtureDetails({ fixture }) {
  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet: `
    label
    scheduledStart
    scheduledEnd    
  `,
    }),
    {
      variables: { id: fixture.id },
    }
  );

  if (error) {
    console.log(error);
    return <Error />;
  }

  const { label, scheduledStart, scheduledEnd } = data?.fixture || {};

  //   const participantTypes = [
  //     { label: "Teams", value: "TEAM" },
  //     { label: "Individuals", value: "INDIVIDUAL" },
  //   ];

  return (
    <Card className="event-info-page__details">
      <CardTitle title={"DETAILS"} />

      {loading || !data ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="event-info-page__details__items">
          <EditableField
            label={"Label"}
            value={label}
            config={{
              valueType: "text",
              mutation: UPDATE_FIXTURE({ selectionSet: `label` }),
              vars: (v) => {
                return {
                  where: { id: fixture.id },
                  data: {
                    label: v,
                  },
                };
              },
            }}
          />

          <EditableField
            label={"Scheduled Start Date"}
            value={scheduledStart || ""}
            getValueString={(x) => {
              if (x) {
                return moment(x).format("Do MMM YYYY");
              } else {
                return "";
              }
            }}
            config={{
              valueType: "date",
              mutation: UPDATE_FIXTURE({ selectionSet: `scheduledStart` }),
              vars: (v) => {
                return {
                  where: { id: fixture.id },
                  data: {
                    scheduledStart: v && moment(v).toISOString(),
                  },
                };
              },
              getDefaultValue: (x) => {
                if (x) {
                  return moment(x).format("YYYY-MM-DD");
                } else {
                  return "";
                }
              },
            }}
          />

          <EditableField
            label={"Scheduled End Date"}
            value={scheduledEnd || ""}
            getValueString={(x) => {
              if (x) {
                return moment(x).format("Do MMM YYYY");
              } else {
                return "";
              }
            }}
            config={{
              valueType: "date",
              mutation: UPDATE_FIXTURE({ selectionSet: `scheduledEnd` }),
              vars: (v) => {
                return {
                  where: { id: fixture.id },
                  data: {
                    scheduledEnd: v && moment(v).toISOString(),
                  },
                };
              },
              getDefaultValue: (x) => {
                if (x) {
                  return moment(x).format("YYYY-MM-DD");
                } else {
                  return "";
                }
              },
            }}
          />
        </div>
      )}
    </Card>
  );
}

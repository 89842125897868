import { gql } from "@apollo/client";

const ORGANIZATION_TYPES = gql`
  query organizationTypes {
    organizationTypes {
      id
      name
    }
  }
`;

export default ORGANIZATION_TYPES;

import ModalComponent from "../../ModalComponent";
import Form from "../../Form";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
import ORGANIZATIONS from "../../../api/queries/organizations";
import EVENTS from "../../../api/queries/events";
import PROFILE from "../../../api/queries/profile";
import { useAppState } from "../../../utils/appState";
import moment from "moment";

function CreateEventRegistration({ profileId }) {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={UPDATE_PROFILE}
            initialState={{
              eventId: "",
              organizationId: "",
              paid: false,
              amountPaid: 0,
            }}
            fields={[
              {
                label: "Event",
                type: "select",
                key: "eventId",
                query: EVENTS,
                items: (d) => d.events,
                optionLabel: (i) => i.name,
              },
              {
                label: "Team",
                type: "select",
                key: "organizationId",
                query: ORGANIZATIONS,
                variables: { where: { types: ["Team"] } },
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
              {
                label: "Paid",
                type: "select",
                key: "paid",
                items: [
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ],
              },
              { label: "Amount Paid", type: "number", key: "amountPaid" },
            ]}
            update={(cache, { data: { updateProfile } }) => {
              const q = cache.readQuery({
                query: PROFILE,
                variables: { id: profileId },
              });
              cache.writeQuery({
                query: PROFILE,
                variables: { id: profileId },
                data: {
                  profile: {
                    ...q.profile,
                    registrations: updateProfile.registrations,
                  },
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => {
              const v = {
                where: { id: profileId },
                data: {
                  event: { connect: { id: s.eventId } },
                  organization: { connect: { id: s.organizationId } },
                  paid: s.paid === "true" ? true : false,
                  amountPaid: Number(s.amountPaid),
                },
              };

              console.log(v);
              return v;
            }}
            title={"New Registration"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateEventRegistration;

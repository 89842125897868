import "./Toggle.css";
import { useState } from "react";

function Toggle({ label, defaultValue, onToggle = () => {}, style = {} }) {
  const [selected, setSelected] = useState(defaultValue);
  return (
    <div
      className="App-toggle"
      onClick={() => {
        const newValue = !selected;
        setSelected(newValue);
        onToggle(newValue);
      }}
      style={{ ...style }}
    >
      {label && <div className="App-toggle__label">{label}</div>}
      <div className={"App-toggle__toggle " + (selected ? "selected" : "")}>
        <div className="App-toggle__toggle__handle" />
      </div>
    </div>
  );
}

export default Toggle;

import { gql } from "@apollo/client";

const EVENTS = gql`
  query events($where: EventWhereInput) {
    events(where: $where) {
      id
      name
      scheduledStart
      scheduledEnd
    }
  }
`;

export default EVENTS;

import "./EditRegistration.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import {
  UPDATE_REGISTRATION,
  EVENT_REGISTRATION,
  EVENT_REGISTRATIONS,
  DELETE_REGISTRATION,
} from "../../../api/events";
import { Button, Loader, SearchWithDropdown, Spinner } from "../../common";
import { useMutation, useQuery } from "@apollo/client";
import { EditableField } from "../../common";

function DeleteRegistration({ ev, reg }) {
  const [, setAppState] = useAppState();
  const [confirm, setConfirm] = useState(false);
  const [mutate, mutation] = useMutation(DELETE_REGISTRATION, {
    update: (cache, { data: { deleteRegistration } }) => {
      const q = cache.readQuery({
        query: EVENT_REGISTRATIONS({ selectionSet: "" }),
        variables: {
          eventId: ev.id,
        },
      });

      if (q.eventRegistrations) {
        cache.writeQuery({
          query: EVENT_REGISTRATIONS({ selectionSet: "" }),
          variables: {
            eventId: ev.id,
          },
          data: {
            eventRegistrations: [...q.eventRegistrations].filter(
              (r) => r.id !== deleteRegistration.id
            ),
          },
        });
      }

      setAppState({ modal: false });
    },
  });
  return (
    <div className="Delete-reg">
      {confirm ? (
        <form
          className="Delete-reg__form"
          onSubmit={(e) => {
            e.preventDefault();

            mutate({
              variables: {
                id: reg.id,
              },
            });
          }}
        >
          <div className="Delete-reg__form__statement">
            Are you sure you want to delete this registration?
          </div>
          <div className="Delete-reg__form__options">
            <button
              type="submit"
              className={
                "App-button Delete-reg__form__options__option danger " +
                (mutation.loading ? "loading" : "")
              }
            >
              {mutation.loading ? <Loader theme="small" /> : "DELETE"}
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                setConfirm(false);
              }}
              className={
                "App-button Delete-reg__form__options__option " +
                (mutation.loading ? "loading" : "")
              }
            >
              CANCEL
            </button>
          </div>
        </form>
      ) : (
        <Button
          onClick={() => {
            setConfirm(true);
          }}
          className="Delete-reg__btn"
        >
          DELETE REGISTRATION
        </Button>
      )}
    </div>
  );
}

export default function EditRegistration({ ev, reg }) {
  const [, setAppState] = useAppState();

  const { loading, error, data } = useQuery(
    EVENT_REGISTRATION({ selectionSet: "" }),
    {
      variables: { id: reg.id },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const registration = data?.eventRegistration;

  //   const [mutate, mutation] = useMutation(CREATE_REGISTRATION, {
  //     update: (cache, { data: { createRegistration } }) => {
  //       const q = cache.readQuery({
  //         query: EVENT_REGISTRATIONS({ selectionSet: `` }),
  //         variables: {
  //           eventId: ev.id,
  //         },
  //       });

  //       if (q?.eventRegistrations) {
  //         cache.writeQuery({
  //           query: EVENT_REGISTRATIONS({ selectionSet: `` }),
  //           variables: {
  //             eventId: ev.id,
  //           },
  //           data: {
  //             eventRegistrations: [createRegistration, ...q.eventRegistrations],
  //           },
  //         });
  //       }

  //       setAppState({ modal: false });

  //       //   history.push("/app/event/" + createEvent.id);
  //     },
  //     onError: (error) => {
  //       setError(error.message);
  //       console.log(error);
  //     },
  //   });

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header" style={{ height: "44px" }}>
        <div className="App-modal-form__header__title">
          <div className="App-modal-form__header__title__primary">
            {registration.profile?.name}
          </div>
          <div className="App-modal-form__header__title__secondary">
            {registration.profile?.email}
          </div>
        </div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <div className="event-info-page__details__items">
        <EditableField
          label={"Team"}
          value={registration.organization}
          getValueString={(x) => x?.name || ""}
          config={{
            valueType: "search",
            mutation: UPDATE_REGISTRATION({ selectionSet: `` }),
            vars: (v) => {
              return {
                where: { id: registration.id },
                data: {
                  organizationId: v.id,
                },
              };
            },
            update: (cache, { data: { updateRegistration } }) => {
              cache.modify({
                id: cache.identify(updateRegistration),
                fields: {
                  organization() {
                    return updateRegistration.organization;
                  },
                },
              });
            },
          }}
          fieldConfig={{
            getItems: (d) => d?.search?.organizations || [],
            getValue: (x) => x?.name || "",
          }}
        />

        <EditableField
          label={"Paid?"}
          value={registration.paid ? "YES" : "NO"}
          getValueString={(x) => (registration.paid ? "YES" : "NO")}
          config={{
            valueType: "select",
            mutation: UPDATE_REGISTRATION({ selectionSet: `` }),
            vars: (v) => {
              return {
                where: { id: registration.id },
                data: {
                  paid: v === "YES" ? true : false,
                },
              };
            },
            update: (cache, { data: { updateRegistration } }) => {
              cache.modify({
                id: cache.identify(updateRegistration),
                fields: {
                  paid() {
                    return updateRegistration.paid;
                  },
                },
              });
            },
          }}
          fieldConfig={{
            items: [
              { label: "YES", value: "YES" },
              { label: "NO", value: "NO" },
            ],
          }}
        />

        <EditableField
          label={"Amount Paid"}
          value={registration.amountPaid}
          getValueString={(x) => registration.amountPaid}
          config={{
            valueType: "text",
            mutation: UPDATE_REGISTRATION({ selectionSet: `` }),
            vars: (v) => {
              return {
                where: { id: registration.id },
                data: {
                  amountPaid: Number(v),
                },
              };
            },
            update: (cache, { data: { updateRegistration } }) => {
              cache.modify({
                id: cache.identify(updateRegistration),
                fields: {
                  amountPaid() {
                    return updateRegistration.amountPaid;
                  },
                },
              });
            },
          }}
          fieldConfig={
            {
              // items: [
              //   { label: "YES", value: "YES" },
              //   { label: "NO", value: "NO" },
              // ],
            }
          }
        />

        <DeleteRegistration ev={ev} reg={reg} />

        {/* {error && <div className="App-modal-form__body__error">{error}</div>} */}

        {/* <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button> */}
      </div>
    </div>
  );
}

import { FaPencilAlt, FaTrash } from "react-icons/fa";

function InfoField({
  label,
  value,
  required,
  setEditing,
  setSelectedState,
  disableEditing,
  options,
}) {
  return (
    <div className="editable-field">
      <div className="meta">
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
      <div className="options">
        {options && options.map((o) => o)}

        {!options && !required && (
          <div
            className="option delete"
            onClick={() => {
              setEditing(true);
            }}
          >
            <FaTrash />
          </div>
        )}

        {!options && !disableEditing && (
          <div
            className="option edit"
            onClick={() => {
              setEditing(true);
            }}
          >
            <FaPencilAlt />
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoField;

import OrganizationDetails from "../OrganizationDetails";
import OrganizationMembers from "../OrganizationMembers";
import "./OrganizationInfo.css";

export default function OrganizationInfo({ org }) {
  return (
    <div className="App-grid event-info-page">
      <OrganizationDetails org={org} />
      <OrganizationMembers org={org} />
    </div>
  );
}

import "./Events.css";
import { Switch, Route } from "react-router-dom";
import { EventsIndex, ViewEvent } from "../../componentsV2";

export default function Events() {
  return (
    <Switch>
      <Route path="/v2/events/index" component={EventsIndex} />
      <Route path="/v2/events/view/:id" component={ViewEvent} />
      <Route path="/v2/events" component={EventsIndex} />
    </Switch>
  );
}

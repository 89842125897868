import { gql } from "@apollo/client";

const ENQUIRIES = gql`
  query enquiries {
    enquiries {
      id
      createdAt
      event {
        id
        name
      }
      email
      phone
      name
      city
    }
  }
`;

export default ENQUIRIES;

import Login from "../components/Login";
import "../styles/Auth.css";

function Auth() {
  return (
    <div className="Auth">
      <Login />
    </div>
  );
}

export default Auth;

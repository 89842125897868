import { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { useAppState } from "../../../utils/appState";
import "./SportFormations.css";
import { SPORT } from "../../../api/sports";
import { Spinner, Table, Button } from "../../common";
import EditFormation from "../EditFormation";
import CreateFormation from "../CreateFormation";

export default function SportFormations({ sport }) {
  const [, setAppState] = useAppState();
  const { loading, data, error } = useQuery(
    SPORT({
      selectionSet: `
    formations{
      id
      name
      positions{
        id
        positionType{
          id
          name
          shortName
        }
        x
        y
      }
    }
    positionTypes{
      id
      name
      shortName
    }
  `,
    }),
    {
      variables: {
        id: sport.id,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  // if (loading || !data) {
  //   return (
  //     <div className="App-loading-wrapper">
  //       <Spinner />
  //     </div>
  //   );
  // }

  const tableFields = [
    {
      label: "Name",
      getValue: (item) => {
        return (
          <Fragment>
            <div className="Table__main__body__rows__row__td__text">
              {item?.name}
            </div>
          </Fragment>
        );
      },
      style: {
        width: "100%",
        // paddingLeft: "24px",
      },
    },
  ];

  const formations = data?.sport?.formations || [];

  return (
    <div className="App-grid event-regs-page">
      <Table
        title={"Formations"}
        fields={tableFields}
        rows={formations}
        onRowClick={(row) => {
          setAppState({
            modal: <EditFormation sport={sport} formation={row} />,
          });
        }}
        loading={loading}
        options={() => (
          <Fragment>
            <Button
              label="ADD"
              className="Table__main__header__options__option add"
              onClick={() => {
                setAppState({
                  modal: <CreateFormation sport={data?.sport} />,
                });
              }}
            />
          </Fragment>
        )}
      />
    </div>
  );
}

import { gql } from "@apollo/client";

const CREATE_REGISTRATION = gql`
  mutation createRegistration($data: CreateEventRegistrationInput!) {
    createRegistration(data: $data) {
      id
      paid
      amountPaid
      profile {
        id
        name
        email
        phone
      }
      organization {
        id
        name
      }
    }
  }
`;

export default CREATE_REGISTRATION;

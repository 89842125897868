import "./ReportedMediaSummary.css";
import { Card, CardTitle } from "../../common";
import ReportedMediaBrowser from "../ReportedMediaBrowser";
import { useAppState } from "../../../utils/appState";

export default function ReportedMediaSummary({ count }) {
  const [, setAppState] = useAppState();
  return (
    <Card className="dashboard-report">
      <CardTitle title={"REPORTED MEDIA"} />
      <div className="dashboard-report__body">
        <div className="dashboard-report__body__blurb">
          There are <span className="bold">{count}</span> reported photos or
          videos pending review.
        </div>
        {!!count && (
          <div
            className="dashboard-report__body__link"
            onClick={() => {
              setAppState({ modal: <ReportedMediaBrowser /> });
            }}
          >
            View reported media
          </div>
        )}
      </div>
    </Card>
  );
}

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixtures";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";
import makeFixturePlaceholder from "../../../utils/makeFixturePlaceholder";

import Loading from "../../Loading";
import { Fragment } from "react";

function FixtureResults({ id }) {
  const selectionSet = `
    roles{
      id
      order
      type{
        id
        name
      }
      organization{
        id
        name
      }
      placement{
        id
        order
        fixture{
          id
          label
        }
        eventPart{
          id
          label
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const fixture = data.fixture;

  return (
    <div className="card">
      <div className="card-title">Fixture Results</div>
      <div className="card-body"></div>
    </div>
  );
}

export default FixtureResults;

import { gql } from "@apollo/client";

const FORMATIONS = gql`
  query formations {
    formations {
      id
      name
    }
  }
`;

export default FORMATIONS;

import EventParticipants from "./EventParticipants";
import FixturesList from "./FixturesList";

function EventFixtures({ event }) {
  return (
    <div className="page">
      <div className="section" style={{ width: "40%" }}>
        <EventParticipants id={event.id} />
      </div>
      <div className="section" style={{ width: "60%" }}>
        <FixturesList id={event.id} />
      </div>
    </div>
  );
}

export default EventFixtures;

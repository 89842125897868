import Editable from "../../Editable";
import InfoField from "../../InfoField";
import StatefulView from "../../StatefulView";
import { useQuery } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixtures";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes, FaTrash, FaPencilAlt } from "react-icons/fa";
import makeFixturePlaceholder from "../../../utils/makeFixturePlaceholder";

import Loading from "../../Loading";
import { Fragment } from "react";

function FixturePeriods({ id }) {
  const selectionSet = `
    periods{
      id
      label
      order
      duration
    }
  `;

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const fixture = data?.fixture;

  return (
    <div className="card">
      <div className="card-title">Fixture Periods</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {fixture.periods.map((p) => (
              <StatefulView
                states={[
                  (setSelectedState) => (
                    <InfoField
                      options={[
                        <div
                          className="option delete"
                          onClick={() => {
                            setSelectedState(2);
                          }}
                        >
                          <FaTrash />
                        </div>,
                        <div
                          className="option edit"
                          onClick={() => {
                            setSelectedState(1);
                          }}
                        >
                          <FaPencilAlt />
                        </div>,
                      ]}
                      setSelectedState={setSelectedState}
                      label={""}
                      value={p.label + ` (${p.duration}')`}
                      required={false}
                    />
                  ),
                  (setSelectedState) => (
                    <Form
                      className={"editable-field-form"}
                      initialState={{ label: p.label, duration: p.duration }}
                      fields={[
                        {
                          label: "Label",
                          type: "text",
                          key: "label",
                        },
                        {
                          label: "Duration",
                          type: "number",
                          key: "duration",
                        },
                      ]}
                      mutation={UPDATE_FIXTURE({ selectionSet })}
                      update={(cache, { data: { updateFixture } }) => {
                        const q = cache.readQuery({
                          query: FIXTURE({ selectionSet }),
                          variables: { id },
                        });
                        cache.writeQuery({
                          query: FIXTURE({ selectionSet }),
                          variables: { id },
                          data: {
                            fixture: {
                              ...q.fixture,
                              periods: updateFixture.periods,
                            },
                          },
                        });

                        setSelectedState(0);
                      }}
                      vars={(s) => ({
                        where: { id },
                        data: {
                          periods: {
                            update: {
                              where: { id: p.id },
                              data: {
                                label: s.label,
                                duration: Number(s.duration),
                              },
                            },
                          },
                        },
                      })}
                      options={[
                        <div
                          className="option cancel"
                          onClick={() => {
                            setSelectedState(0);
                          }}
                        >
                          <FaTimes />
                        </div>,
                      ]}
                    />
                  ),
                  (setSelectedState) => (
                    <Form
                      className={"editable-field-form"}
                      initialState={{ period: "" }}
                      fields={[
                        {
                          label: `Are you sure? Type ${p.id} to confirm`,
                          type: "text",
                          key: "period",
                        },
                      ]}
                      mutation={UPDATE_FIXTURE({ selectionSet })}
                      update={(cache, { data: { updateFixture } }) => {
                        const q = cache.readQuery({
                          query: FIXTURE({ selectionSet }),
                          variables: { id },
                        });
                        cache.writeQuery({
                          query: FIXTURE({ selectionSet }),
                          variables: { id },
                          data: {
                            fixture: {
                              ...q.fixture,
                              periods: updateFixture.periods,
                            },
                          },
                        });

                        setSelectedState(0);
                      }}
                      vars={(s) => ({
                        where: { id },
                        data: {
                          periods: {
                            deleteMany: [{ id: s.period }],
                          },
                        },
                      })}
                      options={[
                        <div
                          className="option cancel"
                          onClick={() => {
                            setSelectedState(0);
                          }}
                        >
                          <FaTimes />
                        </div>,
                      ]}
                    />
                  ),
                ]}
              />
            ))}
          </div>
          <div className="card-footer">
            <StatefulView
              states={[
                (setSelectedState) => (
                  <div
                    className={"App-btn success hover-pointer "}
                    onClick={() => {
                      setSelectedState(1);
                    }}
                  >
                    Add a fixture period
                  </div>
                ),
                (setSelectedState) => (
                  <Form
                    className={"editable-field-form"}
                    initialState={{ label: "", duration: "" }}
                    fields={[
                      {
                        label: "Label",
                        type: "text",
                        key: "label",
                      },
                      {
                        label: "Duration",
                        type: "number",
                        key: "duration",
                      },
                    ]}
                    mutation={UPDATE_FIXTURE({ selectionSet })}
                    update={(cache, { data: { updateFixture } }) => {
                      const q = cache.readQuery({
                        query: FIXTURE({ selectionSet }),
                        variables: { id },
                      });
                      cache.writeQuery({
                        query: FIXTURE({ selectionSet }),
                        variables: { id },
                        data: {
                          fixture: {
                            ...q.fixture,
                            periods: updateFixture.periods,
                          },
                        },
                      });

                      setSelectedState(0);
                    }}
                    vars={(s) => ({
                      where: { id },
                      data: {
                        periods: {
                          create: {
                            label: s.label,
                            order: fixture.periods.length,
                            duration: Number(s.duration),
                          },
                        },
                      },
                    })}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setSelectedState(0);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                ),
              ]}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default FixturePeriods;

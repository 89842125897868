import { gql } from "@apollo/client";

const ACCEPT_INVITE = gql`
  mutation acceptInvite($token: String!, $password: String!) {
    acceptInvite(token: $token, password: $password) {
      token
      expiresAt
      name
    }
  }
`;

export default ACCEPT_INVITE;

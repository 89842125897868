const uploadFile = async ({ url, file, headers = {} }) => {
  // console.log("Uploading the file:", file)
  const response = await fetch(url, {
    method: "PUT",
    mode: "cors",
    headers: {
      ...headers,
      "Content-Type": file.type,
    },
    body: file.uri,
  });

  // console.log(response)

  // const json = await response.json()

  // console.log(json)

  return response;
};

export default uploadFile;

function makeFixturePlaceholder(role) {
  if (role.placement) {
    if (role.placement.fixture) {
      return `${!role.placement.order ? "Winner" : "Loser"} ${
        role.placement.fixture.label
      }`;
    } else if (role.placement.group) {
      return `# ${role.placement.order + 1} ${role.placement.group.label}`;
    }
  } else {
    return "No placeholder added.";
  }
}

export default makeFixturePlaceholder;

import { gql } from "@apollo/client";

const ORGANIZATIONS = gql`
  query organizations($where: OrganizationWhereInput) {
    organizations(where: $where) {
      id
      name
      handle
      types {
        id
        name
      }
      followersCount
      description
    }
  }
`;

export default ORGANIZATIONS;

import { useQuery } from "@apollo/client";

function Select({
  query,
  variables,
  items = [],
  value,
  onChange,
  optionLabel,
  disabled,
}) {
  if (query) {
    return (
      <SelectWithQuery
        query={query}
        variables={variables}
        items={items}
        value={value}
        onChange={onChange}
        optionLabel={optionLabel}
        disabled={disabled}
      />
    );
  } else {
    return (
      <select value={value} onChange={onChange} disabled={disabled}>
        <option value="" />
        {items.map((i) => (
          <option value={i.value}>{i.label}</option>
        ))}
      </select>
    );
  }
}

function SelectWithQuery({
  query,
  variables = {},
  items,
  value,
  onChange,
  optionLabel,
}) {
  const { loading, error, data } = useQuery(query, { variables });

  if (error) {
    console.log(error);
  }

  if (loading || !data) {
    return <div>Loading</div>;
  }

  const options = items(data);

  console.log(options);

  return (
    <select value={value} onChange={onChange}>
      <option value="" />
      {options.map((i) => (
        <option value={i.id}>{optionLabel(i, data)}</option>
      ))}
    </select>
  );
}

export default Select;

import Papa from "papaparse";

const exportToCSV = ({ fields, data }) => {
  const csv = Papa.unparse({
    fields: fields.map((f) => f.label),
    data: data.map((d) => {
      return fields.map((f) => f.value(d));
    }),
  });

  const filename = "export.csv";
  let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  let csvURL = window.URL.createObjectURL(csvData);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", filename);
  tempLink.click();
};

export default exportToCSV;

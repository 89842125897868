import { useState } from "react";
import { Card, Toggle } from "../../common";
import "./EnquiryBrowser.css";
import { useMutation, useQuery } from "@apollo/client";
import { SUPPORT_TICKETS, UPDATE_SUPPORT_TICKET } from "../../../api/dashboard";
import ClickOutside from "../../common/ClickOutside";
import { useAppState } from "../../../utils/appState";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../icons/chevron-right.svg";

export default function EnquiryBrowser({ defaultSelected }) {
  const [, setAppState] = useAppState();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [mutate, { loading: updating }] = useMutation(
    UPDATE_SUPPORT_TICKET({
      selectionSet: `
    handled
  `,
    })
  );

  const { loading, error, data } = useQuery(
    SUPPORT_TICKETS({
      selectionSet: `
      createdAt
      handled
      type
      name
      email
      phone
      subject
      description
      createdBy{
        id
        name
      }
    `,
    }),
    {
      variables: {
        where: {
          handled: false,
          type: "ENQUIRY",
        },
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const enquiries = data?.supportTickets || [];
  const selectedEnquiry = enquiries[selectedIndex];

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="enquiry-browser__wrapper" ref={wrapperRef}>
          <Card className="enquiry-browser">
            <div className="enquiry-browser__nav">
              <div className="enquiry-browser__nav__title">
                Enquiry #{selectedEnquiry?.id}
              </div>

              <div className="enquiry-browser__nav__options">
                <div
                  className={
                    "enquiry-browser__nav__options__option " +
                    (selectedIndex > 0 ? "" : "disabled")
                  }
                  onClick={() => {
                    if (selectedIndex > 0) {
                      setSelectedIndex((prev) => prev - 1);
                    } else {
                      return;
                    }
                  }}
                >
                  <ChevronLeft
                    className={"enquiry-browser__nav__options__option__icon "}
                  />
                </div>

                <div
                  className="enquiry-browser__nav__options__option"
                  onClick={() => {
                    if (selectedIndex < enquiries.length - 1) {
                      setSelectedIndex((prev) => prev + 1);
                    } else {
                      // Fetch more
                    }
                  }}
                >
                  <ChevronRight className="enquiry-browser__nav__options__option__icon" />
                </div>
              </div>
            </div>
            <div className="enquiry-browser__body">
              <div className="enquiry-browser__body__from">
                <div className="enquiry-browser__body__label">FROM:</div>
                <div className="enquiry-browser__body__from__value">
                  {selectedEnquiry?.createdBy?.name}{" "}
                  {`<${selectedEnquiry?.email}> <${selectedEnquiry?.phone}>`}
                </div>
              </div>

              <div className="enquiry-browser__body__subject">
                <div className="enquiry-browser__body__label">SUBJECT:</div>
                <div className="enquiry-browser__body__subject__text">
                  {selectedEnquiry?.subject}
                </div>
              </div>

              <div className="enquiry-browser__body__desc">
                <div className="enquiry-browser__body__label">MESSAGE:</div>

                <div className="enquiry-browser__body__desc__text">
                  {selectedEnquiry?.description}
                </div>
              </div>
            </div>
            <div className="enquiry-browser__footer">
              <div className="enquiry-browser__footer__read-label">
                Mark as read
              </div>
              <Toggle
                defaultValue={selectedEnquiry?.handled}
                onToggle={() => {
                  mutate({
                    variables: {
                      where: {
                        id: selectedEnquiry?.id,
                      },
                      data: {
                        handled: !selectedEnquiry.handled,
                      },
                    },
                  });
                }}
              />
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

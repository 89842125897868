import { gql } from "@apollo/client";

export function FIXTURE({ selectionSet }) {
  return gql`
    query fixture($id: ID!) {
      fixture(id: $id) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function CREATE_FIXTURE({ selectionSet }) {
  return gql`
    mutation createFixture($data: CreateFixtureInput!) {
      createFixture(data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function CREATE_FIXTURE_STATS({ selectionSet }) {
  return gql`
    mutation createFixtureStats($fixtureId: ID!, $stats:[CreateFixtureStatsStatsInput!], $relationships:[CreateFixtureStatsRelationshipsInput!]) {
      createFixtureStats(fixtureId:$fixtureId, stats:$stats, relationships:$relationships) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function UPDATE_FIXTURE({ selectionSet }) {
  return gql`
    mutation updateFixture($where:UniqueInput!,$data: UpdateFixtureInput!) {
      updateFixture(where:$where, data: $data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export const DELETE_FIXTURE = gql`
  mutation deleteFixture($id: ID!) {
    deleteFixture(id: $id) {
      id
    }
  }
`;

export const DELETE_FIXTURE_STAT = gql`
  mutation deleteFixtureStat($id: ID!) {
    deleteFixtureStat(id: $id) {
      id
    }
  }
`;

export const UNPUBLISH_FIXTURE = gql`
  mutation unpublishFixture($fixtureId: ID!) {
    unpublishFixture(fixtureId: $fixtureId) {
      id
      completed
    }
  }
`;

import { gql } from "@apollo/client";

const PROFILE_CLAIMS_LIST = gql`
  query profileClaims {
    profileClaims {
      id
      name
      claimsCount
    }
  }
`;

export default PROFILE_CLAIMS_LIST;

import { useMutation } from "@apollo/client";
import { useState, Fragment } from "react";
import Select from "./Select";

function Form({
  initialState,
  fields = [],
  mutation,
  update,
  vars,
  title,
  className,
  options = [],
  action = "create",
  primaryActionText = "Submit",
}) {
  const [formState, setFormState] = useState(initialState);
  const [mutate, { loading }] = useMutation(mutation, {
    update,
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <form
      className={"App-form " + className}
      onSubmit={(e) => {
        e.preventDefault();
        const variables = vars(formState);
        console.log("Variables:", variables);
        // return;
        if (!variables) {
          return;
        }
        mutate({ variables });
      }}
    >
      {title && <div className="title">{title}</div>}

      {fields.map((f) => (
        <Fragment>
          {f.showIf ? (
            <Fragment>
              {f.showIf(formState) && (
                <Fragment>
                  <div className="form-group">
                    <div className="label">{f.label}</div>

                    {f.type === "select" ? (
                      <Select
                        query={f.query}
                        variables={f.variables}
                        items={f.items}
                        value={formState[f.key]}
                        onChange={(e) => {
                          const newState = {};
                          newState[f.key] = e.target.value;
                          setFormState({
                            ...formState,
                            ...newState,
                          });
                        }}
                        optionLabel={f.optionLabel}
                      />
                    ) : (
                      <input
                        value={formState[f.key]}
                        className="input"
                        type={f.type}
                        onChange={(e) => {
                          const newState = {};
                          newState[f.key] = e.target.value;
                          setFormState({
                            ...formState,
                            ...newState,
                          });
                        }}
                      />
                    )}
                  </div>
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div className="form-group">
                <div className="label">{f.label}</div>

                {f.type === "select" ? (
                  <Select
                    query={f.query}
                    variables={f.variables}
                    items={f.items}
                    value={formState[f.key]}
                    onChange={(e) => {
                      const newState = {};
                      newState[f.key] = e.target.value;
                      setFormState({
                        ...formState,
                        ...newState,
                      });
                    }}
                    optionLabel={f.optionLabel}
                  />
                ) : (
                  <input
                    value={formState[f.key]}
                    className="input"
                    type={f.type}
                    onChange={(e) => {
                      const newState = {};
                      newState[f.key] = e.target.value;
                      setFormState({
                        ...formState,
                        ...newState,
                      });
                    }}
                  />
                )}
              </div>
            </Fragment>
          )}
        </Fragment>
      ))}

      <div className="options">
        <button
          type="submit"
          className={"option " + (action === "delete" ? "delete" : "submit")}
        >
          {primaryActionText}
        </button>
        {options.map((o) => o)}
      </div>
    </form>
  );
}

export default Form;

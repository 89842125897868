import Editable from "../../Editable";
import StatefulView from "../../StatefulView";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../../../api/events";
import ORGANIZATIONS from "../../../api/queries/organizations";
import ROLE_TYPES from "../../../api/queries/roleTypes";
import Form from "../../Form";
import { FaTimes, FaTrash, FaPencilAlt } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function EventRoles({ id }) {
  const selectionSet = `
  roles{
    id
    type{
      id
      name
    }
    organization{
      id
      name
    }
    profile{
      id
      name
    }
  }
  `;
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const event = data?.event;

  return (
    <div className="card">
      <div className="card-title">Event Roles</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {event.roles
              .filter((r) => r.type.name !== "PARTICIPANT")
              .map((r) => (
                <StatefulView
                  states={[
                    (setSelectedState) => (
                      <InfoField
                        options={[
                          <div
                            className="option delete"
                            onClick={() => {
                              setSelectedState(2);
                            }}
                          >
                            <FaTrash />
                          </div>,

                          <div
                            className="option edit"
                            onClick={() => {
                              setSelectedState(1);
                            }}
                          >
                            <FaPencilAlt />
                          </div>,
                        ]}
                        setSelectedState={setSelectedState}
                        label={r.type.name}
                        value={r.organization?.name || r.profile?.name}
                        required={true}
                      />
                    ),
                    (setSelectedState) => {
                      return (
                        <Form
                          className={"editable-field-form"}
                          initialState={{
                            typeId: r.type.id,
                            organizationId: r.organization?.id,
                          }}
                          fields={[
                            {
                              label: "Role Type",
                              type: "select",
                              key: "typeId",
                              query: ROLE_TYPES,
                              items: (d) => d.roleTypes,
                              optionLabel: (i) => i.name,
                            },
                            {
                              label: "Organization",
                              type: "select",
                              key: "organizationId",
                              query: ORGANIZATIONS,
                              items: (d) => d.organizations,
                              optionLabel: (i) => i.name,
                            },
                          ]}
                          mutation={UPDATE_EVENT({ selectionSet })}
                          update={(cache, { data: { updateEvent } }) => {
                            const q = cache.readQuery({
                              query: EVENT({ selectionSet }),
                              variables: { id },
                            });
                            cache.writeQuery({
                              query: EVENT({ selectionSet }),
                              variables: { id },
                              data: {
                                event: {
                                  ...q.event,
                                  roles: updateEvent.roles,
                                },
                              },
                            });

                            setSelectedState(0);
                          }}
                          vars={(s) => ({
                            where: { id },
                            data: {
                              roles: {
                                update: {
                                  where: {
                                    id: r.id,
                                  },
                                  data: {
                                    typeId: s.typeId,
                                    organizationId: s.organizationId,
                                  },
                                },
                              },
                            },
                          })}
                          options={[
                            <div
                              className="option cancel"
                              onClick={() => {
                                setSelectedState(0);
                              }}
                            >
                              <FaTimes />
                            </div>,
                          ]}
                        />
                      );
                    },
                    (setSelectedState) => {
                      return (
                        <Form
                          className={"editable-field-form"}
                          initialState={{
                            eventRoleId: "",
                          }}
                          fields={[
                            {
                              label: `Are you sure you want to delete this role? Type ${r.id} to confirm`,
                              type: "text",
                              key: "eventRoleId",
                            },
                          ]}
                          mutation={UPDATE_EVENT({ selectionSet })}
                          update={(cache, { data: { updateEvent } }) => {
                            const q = cache.readQuery({
                              query: EVENT({ selectionSet }),
                              variables: { id },
                            });
                            cache.writeQuery({
                              query: EVENT({ selectionSet }),
                              variables: { id },
                              data: {
                                event: {
                                  ...q.event,
                                  roles: updateEvent.roles,
                                },
                              },
                            });

                            setSelectedState(0);
                          }}
                          vars={(s) => ({
                            where: { id },
                            data: {
                              roles: {
                                deleteMany: [{ id: r.id }],
                              },
                            },
                          })}
                          options={[
                            <div
                              className="option cancel"
                              onClick={() => {
                                setSelectedState(0);
                              }}
                            >
                              <FaTimes />
                            </div>,
                          ]}
                        />
                      );
                    },
                  ]}
                />
              ))}
          </div>
          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ typeId: "", organizationId: "" }}
                  fields={[
                    {
                      label: "Role Type",
                      type: "select",
                      key: "typeId",
                      query: ROLE_TYPES,
                      items: (d) => d.roleTypes,
                      optionLabel: (i) => i.name,
                    },
                    {
                      label: "Organization",
                      type: "select",
                      key: "organizationId",
                      query: ORGANIZATIONS,
                      items: (d) => d.organizations,
                      optionLabel: (i) => i.name,
                    },
                  ]}
                  mutation={UPDATE_EVENT({ selectionSet })}
                  update={(cache, { data: { updateEvent } }) => {
                    const q = cache.readQuery({
                      query: EVENT({ selectionSet }),
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: EVENT({ selectionSet }),
                      variables: { id },
                      data: {
                        event: {
                          ...q.event,
                          roles: updateEvent.roles,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id },
                    data: {
                      roles: {
                        create: [
                          {
                            typeId: s.typeId,
                            organizationId: s.organizationId,
                          },
                        ],
                      },
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add role
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default EventRoles;

import { useState, useRef, Fragment } from "react";

function DeleteZone({ deletePosition }) {
  return (
    <div
      onDragEnd={(e) => {
        e.preventDefault();
        // console.log(e.target);
        // let id = e.dataTransfer.getData("id");
        // console.log("Updateing id:", id);
        // updatePosition(
        //   {
        //     x: e.clientX - containerRef.current.getBoundingClientRect().x,
        //     y: e.clientY - containerRef.current.getBoundingClientRect().y,
        //   },
        //   id
        // );
      }}
      onDragOver={(e) => {
        e.preventDefault();
        // console.log(e.target);
      }}
      onDrop={(e) => {
        e.preventDefault();
        let id = e.dataTransfer.getData("id");
        // console.log("ID:", Number(id));
        // console.log(typeof id);

        deletePosition(Number(id));
      }}
      className="delete-zone"
      style={{
        background: "#ff6666",
        height: 60,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
      }}
    >
      Drag here to delete
    </div>
  );
}

function FootballPitch({
  width = 324,
  markers = [],
  markerOnClick,
  coordinates,
  updatePosition,
  lockEditing,
}) {
  console.log("Coordinates:", coordinates);
  // 3:2 pitch dimensions
  const containerRef = useRef(null);
  const height = (width * 3) / 2;
  const totalBands = 11;
  const bandWidth = width / totalBands;
  const horizontalBands = new Array(totalBands).fill(0);
  const backgroundColor = "var(--green)";
  const verticalBandsLength = Math.ceil(height / bandWidth);
  const verticalBands = new Array(verticalBandsLength).fill(0);
  const lineWidth = 2;
  const pitchPadding = 0.02 * width;
  const markerDiameter = Math.floor(width * 0.1);
  const lines = [
    {
      // TOP LINE
      height: lineWidth,
      // width: "100%",
      position: "absolute",
      top: pitchPadding,
      left: pitchPadding,
      right: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // BOTTOM LINE
    {
      height: lineWidth,
      // width: "100%",
      position: "absolute",
      bottom: pitchPadding,
      left: pitchPadding,
      right: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // LEFT LINE
    {
      // height: lineWidth,
      width: lineWidth,
      position: "absolute",
      top: pitchPadding,
      left: pitchPadding,
      bottom: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // RIGHT LINE
    {
      // height: lineWidth,
      width: lineWidth,
      position: "absolute",
      top: pitchPadding,
      right: pitchPadding,
      bottom: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // CENTER LINE
    {
      height: lineWidth,
      // width: lineWidth,
      position: "absolute",
      top: height / 2 - lineWidth / 2,
      left: pitchPadding,
      right: pitchPadding,
      background: "rgba(255,255,255,0.8)",
    },
    // CENTER CIRCLE
    {
      height: 0.2 * width,
      width: 0.2 * width,
      position: "absolute",
      top: height / 2 - (0.2 * width) / 2,
      left: width / 2 - (0.2 * width) / 2,
      // background: "rgba(255,255,255,0.8)",
      border: `solid ${lineWidth}px rgba(255,255,255,0.8)`,
      borderRadius: "100%",
    },
    // BOX TOP LEFT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX TOP RIGHT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      right: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX TOP BOTTOM
    {
      height: lineWidth,
      width: 0.6 * width + lineWidth,
      position: "absolute",
      top: 0.2 * height + lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX TOP LEFT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX TOP RIGHT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      top: lineWidth + pitchPadding,
      right: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX TOP BOTTOM
    {
      height: lineWidth,
      width: 0.4 * width + lineWidth,
      position: "absolute",
      top: 0.1 * height + lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },

    // BOX BOTTOM LEFT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX BOTTOM RIGHT
    {
      height: 0.2 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      right: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // BOX BOTTOM BOTTOM
    {
      height: lineWidth,
      width: 0.6 * width + lineWidth,
      position: "absolute",
      bottom: 0.2 * height + lineWidth + pitchPadding,
      left: width * 0.2 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX BOTTOM LEFT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX BOTTOM RIGHT
    {
      height: 0.1 * height,
      width: lineWidth,
      position: "absolute",
      bottom: lineWidth + pitchPadding,
      right: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
    // INNER BOX BOTTOM BOTTOM
    {
      height: lineWidth,
      width: 0.4 * width + lineWidth,
      position: "absolute",
      bottom: 0.1 * height + lineWidth + pitchPadding,
      left: width * 0.3 - lineWidth / 2,
      background: "rgba(255,255,255,0.8)",
    },
  ];

  return (
    <div
      className="fp-container"
      style={{ width, height, position: "relative" }}
      onDragEnd={(e) => {
        e.preventDefault();
        // console.log(e.target);
        // let id = e.dataTransfer.getData("id");
        // console.log("Updateing id:", id);
        // updatePosition(
        //   {
        //     x: e.clientX - containerRef.current.getBoundingClientRect().x,
        //     y: e.clientY - containerRef.current.getBoundingClientRect().y,
        //   },
        //   id
        // );
      }}
      onDragOver={(e) => {
        e.preventDefault();
        // console.log(e.target);
      }}
      onDrop={(e) => {
        e.preventDefault();

        if (lockEditing) {
          return;
        }

        let id = e.dataTransfer.getData("id");

        // console.log("ID:", Number(id));
        // console.log(typeof id);
        const value = {
          x: e.clientX - containerRef.current.getBoundingClientRect().x,
          y: e.clientY - containerRef.current.getBoundingClientRect().y,
        };

        console.log(value);
        updatePosition(value, Number(id));
      }}
      ref={containerRef}
    >
      <div
        className="fp-background"
        style={{
          width,
          height,
          background: backgroundColor,
          position: "absolute",
          top: 0,
          left: 0,
          overflow: "hidden",
          // zIndex: -1,
        }}
      >
        {horizontalBands.map((b, i) => (
          <div
            style={{
              height: "100%",
              width: bandWidth + "px",
              position: "absolute",
              top: 0,
              left: i * bandWidth,
              background: `rgba(255, 255, 255, ${i % 2 ? 0 : 0.1})`,
            }}
          ></div>
        ))}

        {verticalBands.map((b, i) => (
          <div
            style={{
              height: bandWidth + "px",
              width: "100%",
              position: "absolute",
              top: i * bandWidth,
              left: 0,
              background: `rgba(255, 255, 255, ${i % 2 ? 0 : 0.1})`,
            }}
          ></div>
        ))}

        {lines.map((line) => (
          <div style={line}></div>
        ))}
      </div>

      {markers.map((m, i) => (
        <div
          className={"marker " + (markerOnClick ? "hover-pointer" : "")}
          style={{
            position: "absolute",
            top: lockEditing
              ? m.y * width - markerDiameter
              : m.y - markerDiameter / 2,
            left: lockEditing
              ? m.x * width - markerDiameter
              : m.x - markerDiameter / 2,
            height: markerDiameter,
            width: markerDiameter,
            borderRadius: "100%",
            background: "var(--blue)",
            zIndex: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            fontWeight: 600,
          }}
          draggable
          onDragStart={(e) => {
            console.log("Dragstart:", i);
            e.dataTransfer.setData("id", i);
          }}
          onClick={() => {
            markerOnClick && markerOnClick(m);
          }}
        >
          {m.label}
          {m.secondaryLabel && (
            <div
              className="secondary-label"
              style={{ position: "absolute", top: markerDiameter + 4 }}
            >
              {m.secondaryLabel}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function CricketPitch() {
  // Circular dimension
}

function BasketballPitch() {
  // 2:1 pitch dimensions
}

function Pitch({
  width,
  markers,
  markerOnClick,
  coordinates,
  updatePosition = () => {},
  deletePosition = () => {},
  deleteZone,
  lockEditing,
}) {
  console.log("MRKRS:", markers);
  return (
    <Fragment>
      <FootballPitch
        width={width}
        markers={markers}
        markerOnClick={markerOnClick}
        coordinates={coordinates}
        updatePosition={updatePosition}
        lockEditing={lockEditing}
      />
      {deleteZone && <DeleteZone deletePosition={deletePosition} />}
    </Fragment>
  );
}

export default Pitch;

import { useMutation, useQuery } from "@apollo/client";
import { Avatar, Card, CardTitle, Button, Spinner, Loader } from "../../common";
import "./Admins.css";
import { ADMINS, UPDATE_USER } from "../../../api/users";
import AddAdmin from "../AddAdmin";
import { useAppState } from "../../../utils/appState";

function AdminItem({ admin, bg }) {
  const [updateUser, { loading }] = useMutation(
    UPDATE_USER({ selectionSet: `` }),
    {
      update: (cache, { data: { updateUser } }) => {
        const q = cache.readQuery({
          query: ADMINS({
            selectionSet: `
              profile{
                id
                name
                handle
                profilePhoto{
                  id
                  filename
                }
              }
            `,
          }),
          variables: {},
        });
        if (q?.admins) {
          cache.writeQuery({
            query: ADMINS({
              selectionSet: `
                profile{
                  id
                  name
                  handle
                  profilePhoto{
                    id
                    filename
                  }
                }
              `,
            }),
            variables: {},
            data: {
              admins: [...q.admins].filter((x) => x.id !== updateUser.id),
            },
          });
        }
        // setAppState({ modal: false });
        //   history.push("/app/event/" + createEvent.id);
      },
      onError: (error) => {
        // setError(error.message);
        console.log(error);
      },
    }
  );
  const profile = admin.profile;
  return (
    <div
      className="access-control-page__admins__items__item"
      // style={bg ? { background: "var(--light-2)" } : {}}
    >
      <div className="access-control-page__admins__items__item__img">
        {profile?.profilePhoto && <Avatar media={profile.profilePhoto} />}
      </div>
      <div className="access-control-page__admins__items__item__text">
        <div className="access-control-page__admins__items__item__text__primary">
          {profile?.name || admin.name}
        </div>

        <div className="access-control-page__admins__items__item__text__secondary">
          {profile?.handle ? "@" + profile.handle : admin.email}
        </div>
      </div>

      <Button
        className={
          "access-control-page__admins__items__item__revoke " +
          (loading ? "disabled" : "")
        }
        onClick={() => {
          if (loading) return;

          updateUser({
            variables: {
              where: { id: admin.id },
              data: { isAdmin: false },
            },
          });
        }}
      >
        REVOKE
      </Button>
    </div>
  );
}

export default function Admins() {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(
    ADMINS({
      selectionSet: `
    profile{
      id
      name
      handle
      profilePhoto{
        id
        filename
      }
    }
  `,
    })
  );

  if (error) {
    console.log(error);
  }

  const admins = data?.admins || [];
  console.log(admins);
  return (
    <Card className="access-control-page__admins">
      <CardTitle
        title={"ADMINS"}
        options={
          <div
            className="access-control-page__admins__add-btn"
            onClick={() => {
              setAppState({
                modal: <AddAdmin />,
              });
            }}
          >
            +ADD
          </div>
        }
      />
      {loading ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="access-control-page__admins__items">
          {admins.map((admin, i) => (
            <AdminItem key={admin.id} admin={admin} bg={i % 2} />
          ))}
        </div>
      )}
    </Card>
  );
}

import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixtures";
import PROFILES from "../../../api/queries/profiles";
import FORMATIONS from "../../../api/queries/formations";
import "../../../styles/FixtureLineups.css";
import { FaPencilAlt, FaTrash, FaTimes } from "react-icons/fa";
import Editable from "../../Editable";
import StatefulView from "../../StatefulView";
import Form from "../../Form";
import Pitch from "../../Pitch";
import { useState } from "react";
import lastname from "../../../utils/lastname";
import { useAppState } from "../../../utils/appState";
import Loading from "../../Loading";
import UploadData from "../../common/UploadData";
import { Fragment } from "react";

function Lineup({
  fixtureRole,
  lineupRoles,
  selectionSet,
  fixtureId,
  sportId,
  refetch,
}) {
  const { formation, organization } = fixtureRole;
  const [updateFixture, { loading }] = useMutation(
    UPDATE_FIXTURE({ selectionSet }),
    {
      onError: (e) => {
        console.log(e);
      },
    }
  );
  const [choosingPosition, setChoosingPosition] = useState("");

  const markerOnClick = (m) => {
    if (m.id === choosingPosition?.id) {
      setChoosingPosition(false);
    } else {
      setChoosingPosition(m);
    }
  };

  const [, setAppState] = useAppState();

  console.log("GAME ROLE:", fixtureRole);

  return (
    <Fragment>
      <div className="card">
        <div
          className="card-title"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <StatefulView
            states={[
              (setSelectedState) => (
                <Fragment>
                  {fixtureRole.organization?.name || "TBD"}
                  <div
                    className="card-option hover-pointer"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setSelectedState(1);
                    }}
                  >
                    {fixtureRole.formation?.name || "Edit Formation"}
                  </div>
                </Fragment>
              ),
              (setSelectedState) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{
                    formationId: "",
                  }}
                  fields={[
                    {
                      label: "Choose fixture",
                      type: "select",
                      key: "formationId",
                      query: FORMATIONS,
                      variables: { where: { id: sportId } },
                      items: (d) => d.formations,
                      optionLabel: (i) => i.name,
                    },
                  ]}
                  mutation={UPDATE_FIXTURE({ selectionSet })}
                  update={(cache, { data: { updateFixture } }) => {
                    const q = cache.readQuery({
                      query: FIXTURE({ selectionSet }),
                      variables: { id: fixtureId },
                    });
                    cache.writeQuery({
                      query: FIXTURE({ selectionSet }),
                      variables: { id: fixtureId },
                      data: {
                        fixture: {
                          ...q.fixture,
                          roles: updateFixture.roles,
                        },
                      },
                    });

                    setSelectedState(0);
                  }}
                  vars={(s) => {
                    const v = {
                      where: { id: fixtureId },
                      data: {
                        roles: {
                          update: {
                            where: {
                              id: fixtureRole.id,
                            },
                            data: {},
                          },
                        },
                      },
                    };

                    if (!s.formationId) {
                      if (fixtureRole.formation) {
                        v.data.roles.update.data.formation = {
                          disconnect: true,
                        };
                      } else {
                        return false;
                      }
                    } else {
                      v.data.roles.update.data.formation = {
                        connect: { id: s.formationId },
                      };
                    }

                    return v;
                  }}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setSelectedState(0);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              ),
            ]}
          />
        </div>

        <div className="card-body">
          <div className="fixture-lineup-container">
            <div className="formation">
              <Pitch
                width={324}
                markers={
                  fixtureRole.formation?.positions.map((p) => ({
                    ...p,
                    label: p.positionType.shortName,
                    secondaryLabel: lastname(
                      lineupRoles.find((lr) => lr.position?.id === p.id)
                        ?.profile?.name
                    ),
                  })) || []
                }
                markerOnClick={markerOnClick}
                lockEditing={true}
              />
            </div>
            <div className="list">
              {lineupRoles.map((r) => (
                <div className="list-item">
                  <div
                    className={
                      "formation-position " +
                      (choosingPosition ? "selectable hover-pointer" : "")
                    }
                    onClick={() => {
                      if (!choosingPosition) {
                        return;
                      } else {
                        const v = {
                          where: { id: fixtureId },
                          data: {
                            roles: {
                              update: [
                                {
                                  where: { id: r.id },
                                  data: {
                                    position: {
                                      connect: { id: choosingPosition.id },
                                    },
                                  },
                                },
                              ],
                            },
                          },
                        };

                        const currentlyOccupied = lineupRoles.find(
                          (lr) => lr.position?.id === choosingPosition.id
                        );

                        if (currentlyOccupied) {
                          if (currentlyOccupied.id === r.id) {
                            //occupied by same team
                            v.data.roles.update.push({
                              where: { id: r.id },
                              data: { position: { disconnect: true } },
                            });
                          } else {
                            //occupied by other team
                            v.data.roles.update.push({
                              where: { id: currentlyOccupied.id },
                              data: { position: { disconnect: true } },
                            });
                            v.data.roles.update.push({
                              where: { id: r.id },
                              data: {
                                position: {
                                  connect: { id: choosingPosition.id },
                                },
                              },
                            });
                          }
                        } else {
                          v.data.roles.update.push({
                            where: { id: r.id },
                            data: {
                              position: {
                                connect: { id: choosingPosition.id },
                              },
                            },
                          });
                        }

                        // console.log(v);

                        updateFixture({
                          variables: v,
                          update: (cache, { data: { updateFixture } }) => {
                            const q = cache.readQuery({
                              query: FIXTURE({ selectionSet }),
                              variables: { id: fixtureId },
                            });
                            cache.writeQuery({
                              query: FIXTURE({ selectionSet }),
                              variables: { id: fixtureId },
                              data: {
                                fixture: {
                                  ...q.fixture,
                                  roles: updateFixture.roles,
                                },
                              },
                            });
                            setChoosingPosition("");
                          },
                        });
                      }
                    }}
                  >
                    {r.position?.positionType.shortName || "POS"}
                  </div>
                  <div className="meta">
                    <div className="jersey-num">6</div>
                    <div className="player-name">{r.profile?.name}</div>
                  </div>
                  <div className="options">
                    <div
                      className="option delete"
                      onClick={() => {
                        updateFixture({
                          variables: {
                            where: { id: fixtureId },
                            data: {
                              roles: {
                                deleteMany: [{ id: r.id }],
                              },
                            },
                          },
                          update: (cache, { data: { updateFixture } }) => {
                            const q = cache.readQuery({
                              query: FIXTURE({ selectionSet }),
                              variables: { id: fixtureId },
                            });
                            cache.writeQuery({
                              query: FIXTURE({ selectionSet }),
                              variables: { id: fixtureId },
                              data: {
                                fixture: {
                                  ...q.fixture,
                                  roles: updateFixture.roles,
                                },
                              },
                            });
                          },
                        });
                      }}
                    >
                      <FaTrash />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="card-footer fixture-lineup-footer">
          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ profileId: "" }}
                fields={[
                  {
                    label: "Add lineup player",
                    type: "select",
                    key: "profileId",
                    query: PROFILES,
                    items: (d) => d.profiles,
                    optionLabel: (i) => i.name,
                  },
                ]}
                mutation={UPDATE_FIXTURE({ selectionSet })}
                update={(cache, { data: { updateFixture } }) => {
                  const q = cache.readQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id: fixtureId },
                  });
                  cache.writeQuery({
                    query: FIXTURE({ selectionSet }),
                    variables: { id: fixtureId },
                    data: {
                      fixture: {
                        ...q.fixture,
                        roles: updateFixture.roles,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id: fixtureId },
                  data: {
                    roles: {
                      create: [
                        {
                          order: lineupRoles.length,
                          type: { connect: { name: "LINEUP PLAYER" } },
                          organization: { connect: { id: organization.id } },
                          profile: { connect: { id: s.profileId } },
                        },
                      ],
                    },
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <div
                className={"App-btn success hover-pointer add-lineup-player"}
                onClick={() => {
                  setEditing(true);
                }}
              >
                Add Lineup player
              </div>
            )}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className={"App-btn outline hover-pointer upload-lineup-players"}
          onClick={() => {
            // setEditing(true);
            setAppState({
              modal: (
                <UploadData
                  headers={[
                    { label: "Name", key: "name" },
                    { label: "Email", key: "email" },
                    { label: "Phone", key: "phone" },
                    { label: "Jersey Num", key: "jerseyNum" },
                  ]}
                  type={"lineupPlayer"}
                  transform={(rows) => {
                    return rows
                      .map((r, i) => ({
                        name: r[0]?.trim(),
                        email:
                          r[1]?.trim() ||
                          r[0]?.trim().toLowerCase().split(" ").join("_") +
                            "@sportspaddock.com",
                        phone: r[4]?.trim(),
                        jerseyNum: r[3]?.trim() || i + 1,
                        organizationId: organization.id,
                        fixtureId,
                      }))
                      .filter((r) => r.name && r.email);
                  }}
                  refetch={refetch}
                />
              ),
            });
          }}
        >
          Upload Lineup Players
        </div>
      </div>
    </Fragment>
  );
}

function FixtureLineups({ fixture }) {
  const selectionSet = `
  event{
    id
    sport{
      id
    }
  }
  roles{
    id
    order
    type{
      id
      name
    }
    organization{
      id
      name
    }
    profile{
      id
      name
    }
    formation{
      id
      name
      positions{
        id
        x
        y
        positionType{
          id
          name
          shortName
        }
      }
    }
    position{
      id
      positionType{
        id
        name
        shortName
      }
    }
    placement{
      id
      order
      fixture{
        id
        label
      }
      eventPart{
        id
        label
      }
    }
  }
  `;

  const { loading, error, data, refetch } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id: fixture.id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <Loading />
      </div>
    );
    // return <div>Loading</div>;
  }

  const teams = data?.fixture?.roles?.filter(
    (r) => r.type.name === "PARTICIPANT"
  );
  const homeTeam = teams?.find((r) => !r.order);
  const awayTeam = teams?.find((r) => r.order === 1);
  // const homeLineupRoles = data.fixture.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization?.id === homeTeam.organization.id
  // );
  // const awayLineupRoles = data.fixture.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization?.id === awayTeam.organization.id
  // );

  return (
    <div className="page">
      <div className="section">
        <Lineup
          fixtureRole={homeTeam}
          lineupRoles={data.fixture.roles.filter(
            (r) =>
              r.type.name === "LINEUP PLAYER" &&
              r.organization.id === homeTeam.organization.id
          )}
          selectionSet={selectionSet}
          fixtureId={fixture.id}
          sportId={data.fixture.event.sport.id}
          refetch={refetch}
        />
      </div>
      <div className="section">
        <Lineup
          fixtureRole={awayTeam}
          lineupRoles={data.fixture.roles.filter(
            (r) =>
              r.type.name === "LINEUP PLAYER" &&
              r.organization.id === awayTeam.organization.id
          )}
          selectionSet={selectionSet}
          fixtureId={fixture.id}
          sportId={data.fixture.event.sport.id}
          refetch={refetch}
        />
      </div>
    </div>
  );
}

export default FixtureLineups;

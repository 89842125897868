import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import ORGANIZATION from "../../../api/queries/organization";
import UPDATE_ORGANIZATION from "../../../api/mutations/updateOrganization";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Table from "../../Table";
import { FaTimes } from "react-icons/fa";
import { useAppState } from "../../../utils/appState";
import CreateEventRole from "./CreateEventRole";

function OrganizationEvents({ organization }) {
  const [, setAppState] = useAppState();

  return (
    <Table
      config={{
        query: ORGANIZATION,
        variables: {
          id: organization.id,
        },
        headerOptions: [
          () => (
            <div
              className="option"
              onClick={() => {
                setAppState({
                  modal: <CreateEventRole organizationId={organization.id} />,
                });
              }}
            >
              Add
            </div>
          ),
        ],
        orderBy: [],
        search: () => {},
        fields: [
          { label: "Event", value: (row) => row.event.name, weight: 0.5 },
          {
            label: "Role",
            value: (row) => row.type.name,
            weight: 0.5,
          },
        ],
        itemOnClick: (history, item) => {
          // setAppState({
          //   modal: (
          //     <ViewRegistration eventRegistration={item} eventId={event.id} />
          //   ),
          // });
        },
        rows: (d) => d.organization.eventRoles,
        title: "",
      }}
    />
  );
}

export default OrganizationEvents;

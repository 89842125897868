import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import ORGANIZATION from "../../../api/queries/organization";
import UPDATE_ORGANIZATION from "../../../api/mutations/updateOrganization";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function OrganizationDetails({ id }) {
  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const organization = data?.organization;

  return (
    <div className="card">
      <div className="card-title">Organization Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ name: organization.name }}
                  fields={[{ label: "Edit Name", type: "text", key: "name" }]}
                  mutation={UPDATE_ORGANIZATION}
                  update={(cache, { data: { updateOrganization } }) => {
                    const q = cache.readQuery({
                      query: ORGANIZATION,
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: ORGANIZATION,
                      variables: { id },
                      data: {
                        organization: {
                          ...q.organization,
                          name: updateOrganization.name,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id },
                    data: {
                      name: s.name,
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <InfoField
                  setEditing={setEditing}
                  label={"Name"}
                  value={organization.name}
                  required={true}
                />
              )}
            />

            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ mapsUrl: organization.mapsUrl }}
                  fields={[
                    { label: "Edit Maps Url", type: "text", key: "mapsUrl" },
                  ]}
                  mutation={UPDATE_ORGANIZATION}
                  update={(cache, { data: { updateOrganization } }) => {
                    const q = cache.readQuery({
                      query: ORGANIZATION,
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: ORGANIZATION,
                      variables: { id },
                      data: {
                        organization: {
                          ...q.organization,
                          mapsUrl: updateOrganization.mapsUrl,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id },
                    data: {
                      mapsUrl: s.mapsUrl,
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <InfoField
                  setEditing={setEditing}
                  label={"Maps URL"}
                  value={organization.mapsUrl}
                  required={true}
                />
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default OrganizationDetails;

import { gql } from "@apollo/client";

export function ORGANIZATION({ selectionSet }) {
  return gql`
      query organization($id: ID!) {
        organization(id: $id) {
          id
          ${selectionSet}
        }
      }
    `;
}

export function ORGANIZATIONS() {
  return gql`
    query organizations($where: OrganizationWhereInput, $cursor: ID) {
      organizations(where: $where, cursor: $cursor) {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  `;
}

export function UPDATE_ORGANIZATION({ selectionSet }) {
  return gql`
    mutation updateOrganization($where: UniqueInput!, $data:UpdateOrganizationInput!) {
      updateOrganization(where:$where, data:$data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export const DELETE_ORGANIZATION_MEMBERSHIP = gql`
  mutation deleteOrganizationMembership($id: ID!) {
    deleteOrganizationMembership(id: $id) {
      id
    }
  }
`;

import "./Profiles.css";
import { Switch, Route } from "react-router-dom";
import {
  ProfilesIndex,
  ViewProfile,
  // ViewOrganization,
} from "../../componentsV2/profiles";

export default function Profiles() {
  return (
    <Switch>
      <Route path="/v2/profiles/index" component={ProfilesIndex} />
      <Route path="/v2/profiles/view/:id" component={ViewProfile} />
      <Route path="/v2/profiles" component={ProfilesIndex} />
    </Switch>
  );
}

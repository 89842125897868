import "./Table.css";
import Card from "../Card";
import Spinner from "../Spinner";
import { Fragment } from "react";

export default function Table({
  title = "",
  fields = [],
  rows = [],
  onRowClick,
  options,
  loading,
}) {
  return (
    <Card className="Table">
      <div className="Table__main">
        <div className="Table__main__header">
          <div className="Table__main__header__title">{title}</div>

          <div className="Table__main__header__options">
            {options ? options() : <div />}
          </div>
        </div>

        <div className="Table__main__body">
          <div className="Table__main__body__headers">
            {fields.map((field, i) => (
              <div
                key={"th-" + i}
                className="Table__main__body__headers__th"
                style={field.style || {}}
              >
                {field.label}
              </div>
            ))}
          </div>

          <div className="Table__main__body__rows">
            {loading ? (
              <div className="App-loading-wrapper">
                <Spinner />
              </div>
            ) : (
              <Fragment>
                {rows.map((row, i) => (
                  <div
                    key={"row-" + i}
                    className={
                      "Table__main__body__rows__row " +
                      (i % 2 ? "odd " : "even ") +
                      (onRowClick ? "hover-pointer" : "")
                    }
                    onClick={() => {
                      if (onRowClick) {
                        onRowClick(row);
                      }
                    }}
                  >
                    {fields.map((field, j) => (
                      <div
                        key={"cell-" + i + "-" + j}
                        className="Table__main__body__rows__row__td"
                        style={field.style || {}}
                      >
                        {field.getValue(row)}
                      </div>
                    ))}
                  </div>
                ))}
              </Fragment>
            )}
          </div>
        </div>
      </div>

      <div className="Table__filters"></div>
    </Card>
  );
}

import { gql } from "@apollo/client";

const UPDATE_PROFILE = gql`
  mutation updateProfile($where: UniqueInput!, $data: UpdateProfileInput!) {
    updateProfile(where: $where, data: $data) {
      id
      name
      email
      phone

      user {
        id
        name
      }

      contactMechanisms {
        id
        type
        subtype
        value
        areaCode
        countryCode
      }

      eventRoles {
        id
        type {
          id
          name
        }
        event {
          id
          name
        }
      }

      organizations {
        id
        organization {
          id
          name
        }
        user {
          id
          name
        }
        fromDate
        toDate
        role
      }

      registrations {
        id
      }
    }
  }
`;

export default UPDATE_PROFILE;

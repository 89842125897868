import "./EditFormation.css";
import { useState } from "react";
import { useAppState } from "../../../utils/appState";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { Pitch, Loader } from "../../common";
import { useMutation } from "@apollo/client";
import { UPDATE_SPORT } from "../../../api/sports";

export default function EditFormation({ sport, formation }) {
  const [, setAppState] = useAppState();
  const [name, setName] = useState(formation.name);
  const [error, setError] = useState("");

  const [mutate, mutation] = useMutation(
    UPDATE_SPORT({
      selectionSet: `
    formations{
      id
      name
      positions{
        id
        positionType{
          id
          name
          shortName
        }
        x
        y
      }
    }
    `,
    })
  );

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Edit Formation</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <div className="App-modal-form__body">
        <div className="App-modal-form__body__group ">
          <div className="App-modal-form__body__group__label">
            Name<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
              // placeholder="Enter Formation Name"
            />
          </div>
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
          onClick={() => {
            if (mutation.loading) {
              return;
            }

            mutate({
              variables: {
                where: { id: sport.id },
                data: {
                  formations: {
                    update: {
                      where: { id: formation.id },
                      data: { name },
                    },
                  },
                },
              },
              update: () => {
                setAppState({ modal: false });
              },
            });
          }}
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </div>
    </div>
  );
}

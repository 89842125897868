import { useRef, Fragment } from "react";
import { FaFileUpload } from "react-icons/fa";
import "../styles/FileSelector.css";

function FileSelector({ onChange, image, type }) {
  const inputRef = useRef(null);
  return (
    <div className="input-file">
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => {
          onChange(e, inputRef);
        }}
      />
      <div
        className="selection-input"
        onClick={() => {
          inputRef.current.click();
        }}
      >
        {image ? (
          <Fragment>
            {type === "video" ? (
              <video className="image" controls={true}>
                <source src={image} type="video/mp4" />
              </video>
            ) : (
              <img alt={""} src={image} className="image" />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <div className="icon">
              <FaFileUpload />
            </div>
            <div className="text heading">Click to select a file</div>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default FileSelector;

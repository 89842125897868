import ModalComponent from "../../ModalComponent";
import Form from "../../Form";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
import EVENTS from "../../../api/queries/events";
import PROFILE from "../../../api/queries/profile";
import ROLE_TYPES from "../../../api/queries/roleTypes";
import { useAppState } from "../../../utils/appState";
import moment from "moment";

function CreateEventRole({ profileId }) {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={UPDATE_PROFILE}
            initialState={{
              type: "",
              event: "",
            }}
            fields={[
              {
                label: "Event",
                type: "select",
                key: "event",
                query: EVENTS,
                items: (d) => d.events,
                optionLabel: (i) => i.name,
              },
              {
                label: "Role",
                type: "select",
                key: "type",
                query: ROLE_TYPES,
                items: (d) => d.roleTypes,
                optionLabel: (i) => i.name,
              },
            ]}
            update={(cache, { data: { updateProfile } }) => {
              const q = cache.readQuery({
                query: PROFILE,
                variables: { id: profileId },
              });
              cache.writeQuery({
                query: PROFILE,
                variables: { id: profileId },
                data: {
                  profile: {
                    ...q.profile,
                    eventRoles: updateProfile.eventRoles,
                  },
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => {
              const v = {
                where: { id: profileId },
                data: {
                  eventRoles: {
                    create: {
                      event: { connect: { id: s.event } },
                      type: { connect: { id: s.type } },
                    },
                  },
                },
              };

              console.log(v);
              return v;
            }}
            title={"New Event Role"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateEventRole;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter } from "react-router-dom";
import { BatchHttpLink } from "apollo-link-batch-http";
import { setContext } from "apollo-link-context";
import { StateProvider } from "./utils/appState";

const httpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_API_ENDPOINT,
});

console.log(process.env.REACT_APP_API_ENDPOINT);

// DEV
// const httpLink = new BatchHttpLink({
//   uri: "http://localhost:4000",
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("sp_auth_token") || null;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const link = authLink.concat(httpLink);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          events: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = [], { args }) => {
              if (args.cursor) {
                return [...existing, ...incoming];
              } else {
                return [...incoming];
              }
            },
          },
          organizations: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = [], { args }) => {
              if (args.cursor) {
                return [...existing, ...incoming];
              } else {
                return [...incoming];
              }
            },
          },
          profiles: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = [], { args }) => {
              if (args.cursor) {
                return [...existing, ...incoming];
              } else {
                return [...incoming];
              }
            },
          },
          dataUploads: {
            keyArgs: ["id"],
            merge: (existing = [], incoming = [], { args }) => {
              if (args.cursor) {
                return [...existing, ...incoming];
              } else {
                return [...incoming];
              }
            },
          },
        },
      },
    },
  }),
});

const initialState = {
  modal: false,
};

const reducer = (state, action = {}) => {
  return {
    ...state,
    ...action,
  };
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <React.StrictMode>
        <StateProvider initialState={initialState} reducer={reducer}>
          <App />
        </StateProvider>
      </React.StrictMode>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

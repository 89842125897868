import gql from "graphql-tag";

const CREATE_MEDIA = gql`
  mutation createMedia(
    $type: String!
    $subtype: String
    $filename: String!
    $private: Boolean
    $isExternal: Boolean
    $eventId: ID
  ) {
    createMedia(
      type: $type
      subtype: $subtype
      filename: $filename
      private: $private
      isExternal: $isExternal
      eventId: $eventId
    ) {
      url
      media {
        id
        createdAt
        type
        filename
      }
    }
  }
`;

export default CREATE_MEDIA;

const lastname = (name) => {
  if (!name) {
    return "";
  }

  const tokens = name.split(" ");
  return tokens[tokens.length - 1];
};

export default lastname;

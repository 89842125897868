import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import PROFILE from "../../../api/queries/profile";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function ProfileDetails({ id }) {
  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const profile = data?.profile;

  return (
    <div className="card">
      <div className="card-title">Profile Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="card-body">
          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ name: profile.name }}
                fields={[{ label: "Edit Name", type: "text", key: "name" }]}
                mutation={UPDATE_PROFILE}
                update={(cache, { data: { updateProfile } }) => {
                  const q = cache.readQuery({
                    query: PROFILE,
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: PROFILE,
                    variables: { id },
                    data: {
                      profile: {
                        ...q.profile,
                        name: updateProfile.name,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    name: s.name,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Name"}
                value={profile.name}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ email: profile.email }}
                fields={[{ label: "Edit Email", type: "text", key: "email" }]}
                mutation={UPDATE_PROFILE}
                update={(cache, { data: { updateProfile } }) => {
                  const q = cache.readQuery({
                    query: PROFILE,
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: PROFILE,
                    variables: { id },
                    data: {
                      profile: {
                        ...q.profile,
                        email: updateProfile.email,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    email: s.email,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Email"}
                value={profile.email}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ phone: profile.phone }}
                fields={[{ label: "Edit Phone", type: "text", key: "phone" }]}
                mutation={UPDATE_PROFILE}
                update={(cache, { data: { updateProfile } }) => {
                  const q = cache.readQuery({
                    query: PROFILE,
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: PROFILE,
                    variables: { id },
                    data: {
                      profile: {
                        ...q.profile,
                        phone: updateProfile.phone,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    phone: s.phone,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Phone"}
                value={profile.phone}
                required={true}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default ProfileDetails;

import "./Enquiries.css";
import { Card, CardTitle, Spinner } from "../../common";
import { useState } from "react";
import { useQuery } from "@apollo/client";
import { SUPPORT_TICKETS } from "../../../api/dashboard";
import EnquiryBrowser from "../EnquiryBrowser";
import { useAppState } from "../../../utils/appState";

function EnquiryListItem({ item }) {
  const [, setAppState] = useAppState();
  return (
    <div
      className="enquiries-summary__list__item"
      onClick={() => {
        setAppState({
          modal: <EnquiryBrowser />,
        });
      }}
    >
      <div className="enquiries-summary__list__item__content">
        <div className="enquiries-summary__list__item__content__subject">
          {item.subject}
        </div>

        <div className="enquiries-summary__list__item__content__description">
          {item.description}
        </div>

        {/* <div className="enquiries-summary__list__item__content__author">
          

          <div className="enquiries-summary__list__item__content__author__name">
            Kushal Shah @kushalshah
          </div>
        </div> */}

        <div className="enquiries-summary__list__item__content__timestamp">
          3 Minutes Ago
        </div>
      </div>

      <div className="enquiries-summary__list__item__options"></div>
    </div>
  );
}

export default function Enquiries({ count }) {
  const [tabIndex, setTabIndex] = useState(0);

  const { loading, error, data } = useQuery(
    SUPPORT_TICKETS({
      selectionSet: `
      createdAt
      handled
      type
      name
      email
      phone
      subject
      description
    `,
    }),
    {
      variables: {
        where: {
          handled: false,
          type: "ENQUIRY",
        },
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const enquiries = data?.supportTickets || [];

  return (
    <Card className="enquiries-summary">
      <CardTitle
        title={"ENQUIRIES"}
        options={[
          <div className="enquiries-summary__tabs">
            <div
              className={
                "enquiries-summary__tabs__tab " + (!tabIndex ? "selected" : "")
              }
            >
              Unread ({count})
            </div>

            <div
              className={
                "enquiries-summary__tabs__tab " + (tabIndex ? "selected" : "")
              }
            >
              Read
            </div>
          </div>,
        ]}
      />
      <div className="enquiries-summary__list">
        {loading && (
          <div className="App-loading-wrapper">
            <Spinner />
          </div>
        )}
        {enquiries.map((e) => (
          <EnquiryListItem item={e} />
        ))}

        {!loading && !enquiries?.length && (
          <div className="enquiries-summary__list__empty">Nothing here yet</div>
        )}
      </div>
    </Card>
  );
}

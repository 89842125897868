import Editable from "../../Editable";
import InfoField from "../../InfoField";
import StatefulView from "../../StatefulView";
import { useQuery } from "@apollo/client";
import { SPORT, UPDATE_SPORT } from "../../../api/sports";
import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes, FaTrash, FaPencilAlt } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function PositionTypeGroupList({ id }) {
  const selectionSet = `
    positionTypeGroups{
      id
      name
    }
  `;

  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const sport = data?.sport;
  console.log("groups:", sport);

  return (
    <div className="card">
      <div className="card-title">Position Groups</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {sport.positionTypeGroups?.map((stg) => (
              <StatefulView
                states={[
                  (setSelectedState) => (
                    <InfoField
                      options={[
                        <div
                          className="option delete"
                          onClick={() => {
                            setSelectedState(2);
                          }}
                        >
                          <FaTrash />
                        </div>,

                        <div
                          className="option edit"
                          onClick={() => {
                            setSelectedState(1);
                          }}
                        >
                          <FaPencilAlt />
                        </div>,
                      ]}
                      setSelectedState={setSelectedState}
                      label={""}
                      value={stg.name}
                      required={false}
                    />
                  ),
                  (setSelectedState) => (
                    <Form
                      className={"editable-field-form"}
                      initialState={{ name: stg.name }}
                      fields={[
                        {
                          label: "Group Name",
                          type: "text",
                          key: "name",
                        },
                      ]}
                      mutation={UPDATE_SPORT({ selectionSet })}
                      update={(cache, { data: { updateSport } }) => {
                        const q = cache.readQuery({
                          query: SPORT({ selectionSet }),
                          variables: { id },
                        });
                        cache.writeQuery({
                          query: SPORT({ selectionSet }),
                          variables: { id },
                          data: {
                            sport: {
                              ...q.sport,
                              statTypeGroups: updateSport.statTypeGroups,
                            },
                          },
                        });

                        setSelectedState(0);
                      }}
                      vars={(s) => ({
                        where: { id },
                        data: {
                          positionTypeGroups: {
                            update: [
                              { where: { id: stg.id }, data: { name: s.name } },
                            ],
                          },
                        },
                      })}
                      options={[
                        <div
                          className="option cancel"
                          onClick={() => {
                            setSelectedState(0);
                          }}
                        >
                          <FaTimes />
                        </div>,
                      ]}
                    />
                  ),
                  (setSelectedState) => (
                    <Form
                      className={"editable-field-form"}
                      initialState={{ groupId: "" }}
                      fields={[
                        {
                          label: `Are you sure? Type ${stg.id} to confirm.`,
                          type: "text",
                          key: "groupId",
                        },
                      ]}
                      mutation={UPDATE_SPORT({ selectionSet })}
                      update={(cache, { data: { updateSport } }) => {
                        const q = cache.readQuery({
                          query: SPORT({ selectionSet }),
                          variables: { id },
                        });
                        cache.writeQuery({
                          query: SPORT({ selectionSet }),
                          variables: { id },
                          data: {
                            sport: {
                              ...q.sport,
                              positionTypeGroups:
                                updateSport.positionTypeGroups,
                            },
                          },
                        });

                        setSelectedState(0);
                      }}
                      vars={(s) => ({
                        where: { id },
                        data: {
                          positionTypeGroups: {
                            deleteMany: [{ id: stg.id }],
                          },
                        },
                      })}
                      options={[
                        <div
                          className="option cancel"
                          onClick={() => {
                            setSelectedState(0);
                          }}
                        >
                          <FaTimes />
                        </div>,
                      ]}
                    />
                  ),
                ]}
              />
            ))}
          </div>
          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ name: "" }}
                  fields={[
                    {
                      label: "Group Name",
                      type: "text",
                      key: "name",
                    },
                  ]}
                  mutation={UPDATE_SPORT({ selectionSet })}
                  update={(cache, { data: { updateSport } }) => {
                    const q = cache.readQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id },
                      data: {
                        sport: {
                          ...q.sport,
                          positionTypeGroups: updateSport.positionTypeGroups,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id },
                    data: {
                      positionTypeGroups: {
                        create: [{ name: s.name }],
                      },
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add Position Group
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default PositionTypeGroupList;

import "./EditPositionTypeGroup.css";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { Loader } from "../../common";
import { useMutation } from "@apollo/client";
import { UPDATE_SPORT } from "../../../api/sports";

export default function EditPositionTypeGroup({ sport, group }) {
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");
  const [name, setName] = useState(group.name);

  const [mutate, mutation] = useMutation(
    UPDATE_SPORT({
      selectionSet: `
        positionTypeGroups{
            id
            name
        }
  `,
    }),
    {
      update: () => {
        setAppState({ modal: false });
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Edit Group</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!name) {
            setError("A name is required.");
            return;
          }

          const vars = {
            where: { id: sport.id },
            data: {
              positionTypeGroups: {
                update: [
                  {
                    where: { id: group.id },
                    data: { name },
                  },
                ],
              },
            },
          };

          mutate({
            variables: vars,
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Name<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

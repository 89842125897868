import { gql } from "@apollo/client";

const EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      name
      scheduledStart
      scheduledEnd
      sport {
        id
        name
      }
    }
  }
`;

export default EVENT;

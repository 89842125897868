import { useState, Fragment } from "react";
import { FaCopy } from "react-icons/fa";
import FileSelector from "../../FileSelector";
import CREATE_MEDIA from "../../../api/mutations/createMedia";
import DELETE_MEDIA from "../../../api/mutations/deleteMedia";
import EVENT from "../../../api/queries/event";
import { useMutation } from "@apollo/client";
import uploadFile from "../../../utils/uploadFile";
import makeFileUrl from "../../../utils/makeFileUrl";
import Loading from "../../Loading";
import EventDetails from "./EventDetails";
import EventRoles from "./EventRoles";

function EventInfo({ event }) {
  const [copied, setCopied] = useState(false);
  const [image, setImage] = useState(false);
  const [updateLandingImage, setUpdateLandingImage] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [imageName, setImageName] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [createMedia, { loading: creating }] = useMutation(CREATE_MEDIA, {
    onError: (error) => {
      console.log(error);
    },
  });
  const [deleteMedia, { loading: deleting }] = useMutation(DELETE_MEDIA, {
    onError: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="page">
      <div className="section">
        <EventDetails id={event.id} />
      </div>

      <div className="section">
        <EventRoles id={event.id} />
        <div className="card">
          <div className="card-title">Landing Page Link</div>
          <div className="card-body">
            <div className="link-wrapper">
              <a className="link">
                https://events.sportspaddock.com/{event.id}
              </a>

              {copied && <span style={{ color: "var(--blue)" }}>Copied</span>}
              <span
                className="copy-link hover-pointer"
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://events.sportspaddock.com/" + event.id
                  );
                  setCopied(true);
                  window.setTimeout(() => {
                    setCopied(false);
                  }, 1000);
                }}
              >
                <FaCopy />
              </span>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-title">Landing Page Image</div>
          <div className="card-body">
            {!event.landingImage || updateLandingImage ? (
              <FileSelector
                onChange={(e, inputRef) => {
                  if (inputRef.current && inputRef.current.files[0]) {
                    setImage(inputRef.current.files[0]);
                    setImagePreview(
                      URL.createObjectURL(inputRef.current.files[0])
                    );
                    setImageName(inputRef.current.files[0].name);
                  }
                }}
                image={imagePreview}
                type={"image"}
              />
            ) : (
              <img
                src={makeFileUrl(event.landingImage)}
                alt={""}
                className="image"
              />
            )}
          </div>
          <div className="card-footer">
            {!event.landingImage || updateLandingImage ? (
              <Fragment>
                {creating ? (
                  <Loading />
                ) : (
                  <div
                    className={
                      "App-btn success hover-pointer " +
                      (image ? "" : "disabled")
                    }
                    onClick={() => {
                      if (!image) {
                        setUploadError("No image selected");
                        window.setTimeout(() => {
                          setUploadError("");
                        }, 1000);
                        return;
                      }

                      if (creating) {
                        return;
                      }
                      // console.log(imageName);
                      // return;
                      createMedia({
                        variables: {
                          type: "image",
                          subtype: "LANDING_IMAGE",
                          filename: imageName,
                          private: false,
                          isExternal: false,
                          eventId: event.id,
                        },
                        update: async (cache, { data: { createMedia } }) => {
                          console.log("Created media:", createMedia);

                          const url = createMedia.url;
                          console.log(url);
                          const response = await uploadFile({
                            url,
                            file: {
                              uri: image,
                              type: "image/png",
                            },
                            headers: {
                              "x-amz-acl": "public-read",
                            },
                          });

                          if (response) {
                            cache.writeQuery({
                              query: EVENT,
                              variables: { id: event.id },
                              data: {
                                event: {
                                  ...event,
                                  landingImage: { ...createMedia.media },
                                },
                              },
                            });
                          }

                          setImage(false);
                          setImagePreview(false);
                          setImageName(false);
                        },
                      });
                    }}
                  >
                    Upload
                  </div>
                )}
              </Fragment>
            ) : (
              <Fragment>
                {deleting ? (
                  <Loading />
                ) : (
                  <div
                    className="App-btn danger hover-pointer"
                    onClick={() => {
                      console.log("Deleting");
                      if (deleting) {
                        console.log("Aborting");
                        return;
                      }

                      deleteMedia({
                        variables: {
                          id: event.landingImage.id,
                        },
                        update: (cache, { data: { deleteMedia } }) => {
                          if (deleteMedia && deleteMedia.id) {
                            cache.writeQuery({
                              query: EVENT,
                              variables: { id: event.id },
                              data: {
                                event: {
                                  ...event,
                                  landingImage: null,
                                },
                              },
                            });
                          }
                        },
                      });
                    }}
                  >
                    Delete
                  </div>
                )}
              </Fragment>
            )}

            {uploadError && <div className="error">{uploadError}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EventInfo;

import indexRouteConfig from "../utils/indexRouteConfig";
import { useParams } from "react-router-dom";
import Table from "../components/Table";
import "../styles/Index.css";

function Index() {
  const { type } = useParams();
  const config = indexRouteConfig[type];
  return (
    <div className="Index">
      <Table config={config} />
    </div>
  );
}

export default Index;

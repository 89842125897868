import "./Merges.css";
import { Switch, Route } from "react-router-dom";
import { MergesIndex } from "../../componentsV2/merges";

export default function Merges() {
  return (
    <Switch>
      <Route path="/v2/merges" component={MergesIndex} />
    </Switch>
  );
}

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery, useMutation } from "@apollo/client";
import PROFILE from "../../../api/queries/profile";
import USERS from "../../../api/queries/users";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes, FaTrash } from "react-icons/fa";
import "../../../styles/EnquiryNotes.css";

import Loading from "../../Loading";
import { Fragment } from "react";

function ProfileUser({ id }) {
  const [updateProfile, { loading: deleting }] = useMutation(UPDATE_PROFILE);
  const { loading, error, data } = useQuery(PROFILE, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const profile = data?.profile;

  return (
    <div className="card">
      <div className="card-title">Claimed By User</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {profile.user ? (
              <div className="enquiry-registration">
                <div className="title">
                  The user {profile.user.name} has claimed this profile.
                </div>
              </div>
            ) : (
              <div>This profile has not been claimed.</div>
            )}
          </div>
          <div className="card-footer">
            {profile.user ? (
              <div
                className={"App-btn danger hover-pointer "}
                onClick={() => {
                  updateProfile({
                    variables: {
                      where: { id },
                      data: {
                        user: { disconnect: true },
                      },
                    },
                    update: (cache, { data: updateProfile }) => {
                      const q = cache.readQuery({
                        query: PROFILE,
                        variables: { id: id },
                      });
                      cache.writeQuery({
                        query: PROFILE,
                        variables: { id: id },
                        data: {
                          profile: {
                            ...q.profile,
                            user: updateProfile.user,
                          },
                        },
                      });
                    },
                  });
                }}
              >
                Invalidate Claim
              </div>
            ) : (
              <Editable
                form={(setEditing) => (
                  <Form
                    className={"editable-field-form column"}
                    initialState={{ user: "" }}
                    fields={[
                      {
                        label: "User",
                        type: "select",
                        key: "user",
                        query: USERS,
                        items: (d) => d.users,
                        optionLabel: (i) => i.name,
                      },
                    ]}
                    mutation={UPDATE_PROFILE}
                    update={(cache, { data: { updateProfile } }) => {
                      const q = cache.readQuery({
                        query: PROFILE,
                        variables: { id: id },
                      });
                      cache.writeQuery({
                        query: PROFILE,
                        variables: { id: id },
                        data: {
                          profile: {
                            ...q.profile,
                            user: updateProfile.user,
                          },
                        },
                      });

                      setEditing(false);
                    }}
                    vars={(s) => {
                      const v = {
                        where: { id: id },
                        data: {
                          user: {
                            connect: { id: s.user },
                          },
                        },
                      };
                      return v;
                    }}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setEditing(false);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                )}
                content={(setEditing) => (
                  <div
                    className={"App-btn success hover-pointer "}
                    onClick={() => {
                      setEditing(true);
                    }}
                  >
                    Connect to user
                  </div>
                )}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ProfileUser;

import { gql } from "@apollo/client";

const PROFILES = gql`
  query profiles($where: ProfileWhereInput) {
    profiles(where: $where) {
      id
      createdAt
      name
      email
      phone
    }
  }
`;

export default PROFILES;

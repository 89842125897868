import { Fragment } from "react";
import { Button, Card, CardTitle, Spinner, Loader } from "../../common";
import "./UnpublishStats.css";
import { FIXTURE, UNPUBLISH_FIXTURE } from "../../../api/fixtures";
import { useMutation, useQuery } from "@apollo/client";

export default function UnpublishStats({ fixture }) {
  const selectionSet = `
    completed
  `;

  const [mutate, { loading: unpublishing }] = useMutation(UNPUBLISH_FIXTURE, {
    update: (cache, { data: { unpublishFixture } }) => {
      console.log("Completed:", unpublishFixture);
      cache.modify({
        id: cache.identify(fixture),
        fields: {
          completed() {
            return unpublishFixture.completed;
          },
        },
      });
      // cache.modify({})
      // cache.writeQuery({
      //   query: FIXTURE({ selectionSet }),
      //   variables: { id: fixture.id },
      //   data: {
      //     fixture: {
      //       completed,
      //     },
      //   },
      // });
    },
  });

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id: fixture.id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (!data?.fixture?.completed) {
    return <div />;
  }

  return (
    <Card className="unpublish-stats">
      <CardTitle title={"UNPUBLISH STATS"} />
      {loading || !data ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <Fragment>
          <div className="unpublish-stats__blurb">
            Click the button below to unpublish this fixture. This action will
            lock your database in order to ensure data consistency, and slow
            down the website for your users. Please do this only if necessary
            and at a time where you expect less people to be using the website.
          </div>
          <Button
            className={
              "unpublish-stats__btn " + (unpublishing ? "loading" : "")
            }
            onClick={() => {
              if (unpublishing) return;

              mutate({
                variables: { fixtureId: fixture.id },
              });
            }}
          >
            {unpublishing ? <Loader theme="small" /> : "UNPUBLISH"}
          </Button>
        </Fragment>
      )}
    </Card>
  );
}

import "./Directory.css";
import { Switch, Route } from "react-router-dom";
import {
  OrganizationsIndex,
  ViewOrganization,
} from "../../componentsV2/directory";

export default function Directory() {
  return (
    <Switch>
      <Route path="/v2/directory/index" component={OrganizationsIndex} />
      <Route path="/v2/directory/view/:id" component={ViewOrganization} />
      <Route path="/v2/directory" component={OrganizationsIndex} />
    </Switch>
  );
}

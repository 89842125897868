import { gql } from "@apollo/client";

const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization(
    $where: UniqueInput!
    $data: UpdateOrganizationInput!
  ) {
    updateOrganization(where: $where, data: $data) {
      id
      name
      mapsUrl
      contactMechanisms {
        id
        type
        subtype
        value
        areaCode
        countryCode
      }
      types {
        id
        name
      }
      members {
        id
        profile {
          id
          name
        }
        user {
          id
          name
        }
        fromDate
        toDate
        role
        isAdmin
      }
      eventRoles {
        id
        type {
          id
          name
        }
        event {
          id
          name
        }
      }
    }
  }
`;

export default UPDATE_ORGANIZATION;

import { useQuery } from "@apollo/client";
import { Avatar, Button, Search, Spinner, Loader, Card } from "../../common";
import "./UploadsIndex.css";
// import { SPORTS } from "../../../api/sports";
import { DATA_UPLOADS } from "../../../api/dataUploads";
import { Fragment } from "react";
import { useAppState } from "../../../utils/appState";
// import CreatePage from "../CreatePage";
import { useHistory } from "react-router-dom";
import moment from "moment";
import makeFileUrl from "../../../utils/makeFileUrl";

function getDateDiff(startDate, endDate) {
  const start = moment(startDate);
  const end = moment(endDate);

  const duration = moment.duration(end.diff(start));
  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const seconds = duration.asSeconds();
  if (minutes < 1) {
    return seconds.toFixed(2) + "s";
  }

  if (hours < 1) {
    return minutes.toFixed(2) + "m";
  }

  return hours.toFixed(2) + "h";
}

function getUploadType(upload) {
  if (upload?.uploadType === "eventRegistration") {
    return "Event Registration Upload";
  } else if (upload?.uploadType === "stat") {
    return (upload.event?.sport?.name || "") + " Stats Upload";
  } else {
    return upload.uploadType;
  }
}

export default function UploadsIndex() {
  const history = useHistory();
  const [, setAppState] = useAppState();
  const { loading, error, data, refetch, networkStatus, variables, fetchMore } =
    useQuery(
      DATA_UPLOADS({
        selectionSet: `
            id
            createdAt
            updatedAt
            rawFilename
            filename
            uploadType
            status
            event{
                id
                name
                profilePhoto{
                    id
                    filename
                }
                sport{
                    id
                    name
                }
            }
            uploadedBy{
                id
                profile{
                    id
                    name
                    profilePhoto{
                        id
                        filename
                    }
                }
            }
        `,
      }),
      {
        notifyOnNetworkStatusChange: true,
      }
    );

  if (error) {
    return <div>Error</div>;
  }

  const items = data?.dataUploads || [];

  return (
    <div className="App-index-page">
      <div className="App-index-page__header">
        <div className="App-index-page__header__title">Uploads</div>
      </div>

      <div className="App-index-page__list" style={{ padding: "24px" }}>
        {networkStatus === 1 || networkStatus === 2 ? (
          <div className="App-index-page__list__loading">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {items.map((item) => (
              <Card className="upload-index-item" key={item.id}>
                <div className="upload-index-item__header">
                  <div className="upload-index-item__header__text">
                    {getUploadType(item).toUpperCase()}
                  </div>

                  <div className="upload-index-item__header__timestamp">
                    {moment(item.createdAt).format("DD/MM/YYYY h:mm A")}
                  </div>
                </div>

                <div className="upload-index-item__body">
                  <div className="upload-index-item__body__text-group">
                    {/* <div className="upload-index-item__body__text-group__label">
                      Event
                    </div> */}

                    <div className="upload-index-item__body__text-group__value">
                      <div className="upload-index-item__body__text-group__value__img">
                        {item.event?.profilePhoto && (
                          <Avatar media={item.event?.profilePhoto} />
                        )}
                      </div>
                      <div className="upload-index-item__body__text-group__value__text">
                        {item.event.name}
                      </div>
                    </div>
                  </div>

                  <div className="upload-index-item__body__text-group">
                    <div className="upload-index-item__body__text-group__label">
                      Filename
                    </div>

                    <div className="upload-index-item__body__text-group__value">
                      {item.rawFilename}{" "}
                      <a
                        href={makeFileUrl(item)}
                        target="_blank"
                        rel={"noreferrer"}
                        className="upload-index-item__body__text-group__value__link"
                      >
                        Download
                      </a>
                    </div>
                  </div>

                  <div className="upload-index-item__body__text-group">
                    <div className="upload-index-item__body__text-group__label">
                      Uploaded By
                    </div>

                    <div className="upload-index-item__body__text-group__value">
                      {item.uploadedBy?.profile?.name || "Unknown"}
                    </div>
                  </div>

                  <div className="upload-index-item__body__text-group">
                    <div className="upload-index-item__body__text-group__label">
                      Status
                    </div>

                    <div className="upload-index-item__body__text-group__value">
                      {item.status}
                    </div>
                  </div>

                  <div className="upload-index-item__body__text-group">
                    <div className="upload-index-item__body__text-group__label">
                      Duration
                    </div>

                    <div className="upload-index-item__body__text-group__value">
                      {getDateDiff(item.createdAt, item.updatedAt)}
                    </div>
                  </div>
                </div>
              </Card>
            ))}

            {!!items.length && (
              <div
                className="App-index-page__list__item"
                onClick={() => {
                  fetchMore({
                    variables: {
                      ...variables,
                      cursor: items[items.length - 1].id,
                    },
                  });
                }}
                style={{ alignItems: "center", justifyContent: "center" }}
              >
                <div className="App-index-page__list__item__text">
                  <div className="App-index-page__list__item__text__secondary">
                    {networkStatus === 3 ? (
                      <Loader theme={"small grey"} />
                    ) : (
                      "Load More"
                    )}
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
}

import { UPDATE_ORGANIZATION } from "../../../api/organizations";
import "./CreateOrganizationMember.css";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import {
  Loader,
  LocationSearch,
  SearchWithDropdown,
  Spinner,
} from "../../common";
import { useAppState } from "../../../utils/appState";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

export default function CreateOrganizationMember({ org }) {
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");
  const [profile, setProfile] = useState("");
  const [role, setRole] = useState("");
  //   const [fromDate, setFromDate] = useState("");
  //   const [toDate, setToDate] = useState("");

  const [mutate, mutation] = useMutation(
    UPDATE_ORGANIZATION({
      selectionSet: `
  members {
    id
    profile {
      id
      name
      handle
      profilePhoto{
        id
        filename
      }
    }            
    fromDate
    toDate
    role
    isAdmin
  }   
  `,
    }),
    {
      update: (cache, { data: { updateOrganization } }) => {
        // const q = cache.readQuery({
        //   query: ORGANIZATIONS(),
        //   variables: { where: {} },
        // });

        // if (q?.organizations) {
        //   cache.writeQuery({
        //     query: ORGANIZATIONS(),
        //     variables: { where: {} },
        //     data: {
        //       organizations: [createOrganization, ...q.organizations],
        //     },
        //   });
        // }

        setAppState({ modal: false });

        //   history.push("/app/event/" + createEvent.id);
      },
      onError: (error) => {
        setError(error.message);
        console.log(error);
      },
    }
  );

  const roles = [
    { label: "Member", value: "Member" },
    { label: "Squad Member", value: "Squad Member" },
  ];

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Add Page Member</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!profile?.id || !role) {
            setError("Please fill out all required fields.");
            return;
          }

          mutate({
            variables: {
              where: { id: org.id },
              data: {
                members: {
                  create: [
                    {
                      profile: { connect: { id: profile.id } },
                      role: role,
                    },
                  ],
                },
              },
            },
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Profile<span className="required">*</span>
          </div>

          {profile ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setProfile("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {profile.name}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.profiles || []}
              onItemSelect={(item) => {
                setProfile(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Role<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <select
              className="App-modal-form__body__group__input__select"
              value={role}
              onChange={(e) => {
                setRole(e.target.value);
              }}
            >
              <option value=""></option>
              {roles.map((t) => (
                <option key={t.value} value={t.value}>
                  {t.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">From date</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={fromDate}
              onChange={(e) => {
                setFromDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">To date</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={toDate}
              onChange={(e) => {
                setToDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div> */}

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

import { gql } from "@apollo/client";

export function DATA_UPLOADS({ selectionSet = `` }) {
  return gql`
    query dataUploads($cursor:ID) {
      dataUploads(cursor:$cursor) {
        id
        ${selectionSet}
      }
    }
  `;
}

import { gql } from "@apollo/client";

export function SUPPORT_TICKETS({ selectionSet }) {
  return gql`
    query supportTickets($where: SupportTicketWhereInput!, $cursor:ID) {
      supportTickets(where: $where, cursor:$cursor) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function UPDATE_SUPPORT_TICKET({ selectionSet }) {
  return gql`
    mutation updateSupportTicket($where: UniqueInput!, $data:UpdateSupportTicketInput!) {
      updateSupportTicket(where: $where, data:$data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function HANDLE_REPORTED_MEDIA({ selectionSet }) {
  return gql`
    mutation handleReportedMedia($mediaId: ID!, $flagged:Boolean!) {
      handleReportedMedia(mediaId: $mediaId, flagged:$flagged) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function HANDLE_REPORTED_POST({ selectionSet }) {
  return gql`
    mutation handleReportedPost($postId: ID!, $flagged:Boolean!) {
      handleReportedPost(postId: $postId, flagged:$flagged) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function REPORTED_POSTS({ selectionSet }) {
  return gql`
    query reportedPosts($cursor:ID) {
      reportedPosts(cursor:$cursor) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function REPORTED_MEDIA({ selectionSet }) {
  return gql`
    query reportedMedia($cursor:ID) {
      reportedMedia(cursor:$cursor) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function PROFILE_CLAIMS({ selectionSet }) {
  return gql`
    query profileClaims($cursor:ID) {
      profileClaims(cursor:$cursor) {
        id
        ${selectionSet}
      }
    }
  `;
}

export const DASHBOARD_STATS = gql`
  query dashboardStats {
    dashboardStats {
      enquiries
      media
      posts
      claims
      unpublish
    }
  }
`;

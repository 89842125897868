import { Fragment, useState } from "react";
import "./ProfileClaimsBrowser.css";
import { Card, Spinner, Button, Loader, Post } from "../../common";
import { useMutation, useQuery } from "@apollo/client";
import {
  DASHBOARD_STATS,
  HANDLE_REPORTED_POST,
  PROFILE_CLAIMS,
  REPORTED_POSTS,
  SUPPORT_TICKETS,
} from "../../../api/dashboard";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../icons/chevron-right.svg";
import ClickOutside from "../../common/ClickOutside";
import { useAppState } from "../../../utils/appState";
import moment from "moment";
import HANDLE_CLAIM from "../../../api/mutations/handleClaim";

const selectionSet = `
    name
    handle
    claims{
        id
        createdAt        
        user{
            id
            name
            email
            profile{
                id
            }
        }        
    }
    registrations{
        id
        event{
            id
            name
        }
        organization{
            id
            name
        }
    }
`;

function Profile({ profile }) {
  console.log(profile);
  return (
    <Card className="mini-profile">
      <div className="mini-profile__header">
        <div className="mini-profile__header__img"></div>

        <div className="mini-profile__header__text">
          <div className="mini-profile__header__text__primary">
            {profile?.name}
          </div>

          <div className="mini-profile__header__text__secondary">
            @{profile?.handle}
          </div>
        </div>
      </div>
      <div className="mini-profile__body">
        <div className="mini-profile__body__group">
          <div className="mini-profile__body__group__label">EVENTS</div>

          <div className="mini-profile__body__group__items">
            {profile.registrations.map((reg) => (
              <div className="mini-profile__body__group__items__item">
                <div className="mini-profile__body__group__items__item__primary">
                  {reg.event.name}
                </div>

                <div className="mini-profile__body__group__items__item__secondary">
                  {reg.organization?.name}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <a
        href={"https://dev.sportspaddock.com/app/profile/" + profile.id}
        target="_blank"
        rel={"noreferrer"}
        className="mini-profile__footer"
      >
        View Profile
      </a>
    </Card>
  );
}

function ClaimItem({ profile, claim }) {
  const [handleClaim, { loading }] = useMutation(HANDLE_CLAIM, {
    update: (cache, { data: { handleProfileClaim } }) => {
      const statsQuery = cache.readQuery({
        query: DASHBOARD_STATS,
      });

      if (statsQuery?.dashboardStats) {
        cache.writeQuery({
          query: DASHBOARD_STATS,
          data: {
            dashboardStats: {
              ...statsQuery.dashboardStats,
              claims: statsQuery.dashboardStats.claims - 1,
            },
          },
        });
      }

      cache.modify({
        id: cache.identify(profile),
        fields: {
          claims(existingClaimRefs, { readField }) {
            const newClaims = existingClaimRefs.filter(
              (claimRef) => handleProfileClaim.id !== readField("id", claimRef)
            );

            return newClaims;
          },
        },
      });
    },
  });
  return (
    <div className="report-browser__meta__claims__claim">
      <div className="report-browser__meta__claims__claim__img"></div>
      <div className="report-browser__meta__claims__claim__text">
        <div className="report-browser__meta__claims__claim__text__primary">
          {claim.user?.name}
        </div>

        <div className="report-browser__meta__claims__claim__text__secondary">
          {`<${claim.user?.email}>`}
        </div>

        <a
          href={
            "https://dev.sportspaddock.com/app/profile/" +
            claim.user?.profile?.id
          }
          target="_blank"
          rel={"noreferrer"}
          className="report-browser__meta__claims__claim__text__link"
        >
          View User
        </a>
      </div>
      <div className="report-browser__meta__claims__claim__options">
        {loading ? (
          <Spinner />
        ) : (
          <Fragment>
            <Button
              className="report-browser__meta__claims__claim__options__option success"
              onClick={() => {
                if (loading) {
                  return;
                }
                handleClaim({
                  variables: {
                    id: claim.id,
                    approved: true,
                  },
                });
              }}
            >
              APPROVE
            </Button>
            <Button
              className="report-browser__meta__claims__claim__options__option"
              onClick={() => {
                if (loading) {
                  return;
                }

                handleClaim({
                  variables: {
                    id: claim.id,
                    approved: false,
                  },
                });
              }}
            >
              DECLINE
            </Button>
          </Fragment>
        )}
      </div>
    </div>
  );
}

function ClaimsList({ profile }) {
  const claims = profile?.claims;

  //   const onHandleClaim = (cache, {data:{handleProfileClaim}})=>{

  //   }

  return (
    <div className="report-browser__meta__claims">
      {claims.map((claim) => (
        <ClaimItem profile={profile} claim={claim} />
      ))}
    </div>
  );
}

export default function ProfileClaimsBrowser() {
  const [, setAppState] = useAppState();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [mutate, { loading: updating }] = useMutation(
    HANDLE_REPORTED_POST({
      selectionSet: `
    id
    flagged
  `,
    }),
    {
      update: (cache, { data: { handleReportedPost } }) => {
        console.log(handleReportedPost);
        // 1. Update the dashboard stats query
        const statsQuery = cache.readQuery({
          query: DASHBOARD_STATS,
        });

        if (statsQuery?.dashboardStats) {
          cache.writeQuery({
            query: DASHBOARD_STATS,
            data: {
              dashboardStats: {
                ...statsQuery.dashboardStats,
                posts: statsQuery.dashboardStats.posts - 1,
              },
            },
          });
        }

        // 2. Update the reported media query
        const reportsQuery = cache.readQuery({
          query: REPORTED_POSTS({
            selectionSet,
          }),
        });

        if (reportsQuery?.reportedPosts) {
          const newReports = [...reportsQuery.reportedPosts].filter(
            (m) => m.id !== handleReportedPost.id
          );

          if (!newReports.length) {
            setAppState({ modal: false });
          } else {
            cache.writeQuery({
              query: REPORTED_POSTS({
                selectionSet,
              }),
              data: {
                reportedPosts: newReports,
              },
            });
          }
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { loading, error, data } = useQuery(
    PROFILE_CLAIMS({
      selectionSet,
    })
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const profiles = data.profileClaims;
  const selectedProfile = profiles[selectedIndex];

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="report-browser__wrapper" ref={wrapperRef}>
          <Card className="report-browser">
            <div className="report-browser__content">
              <Profile profile={selectedProfile} />
            </div>

            <div className="report-browser__meta">
              <div className="report-browser__meta__header">
                <div className="report-browser__meta__header__title">
                  Claimed by {selectedProfile?.claims?.length || 0} user(s)
                </div>

                <div className="report-browser__meta__header__options">
                  <div
                    className={
                      "report-browser__meta__header__options__option " +
                      (selectedIndex > 0 ? "" : "disabled")
                    }
                    onClick={() => {
                      if (selectedIndex > 0) {
                        setSelectedIndex((prev) => prev - 1);
                      } else {
                        return;
                      }
                    }}
                  >
                    <ChevronLeft
                      className={
                        "report-browser__meta__header__options__option__icon "
                      }
                    />
                  </div>

                  <div
                    className="report-browser__meta__header__options__option"
                    onClick={() => {
                      if (selectedIndex < profiles.length - 1) {
                        setSelectedIndex((prev) => prev + 1);
                      } else {
                        // Fetch more
                      }
                    }}
                  >
                    <ChevronRight className="report-browser__meta__header__options__option__icon" />
                  </div>
                </div>
              </div>

              <ClaimsList profile={selectedProfile} />
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

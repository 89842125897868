import Editable from "../../Editable";
import InfoField from "../../InfoField";
import Loading from "../../Loading";
import { useQuery } from "@apollo/client";
import ENQUIRY from "../../../api/queries/enquiry";
import UPDATE_EVENT_ENQUIRY from "../../../api/mutations/updateEventEnquiry";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

function EnquiryDetails({ id }) {
  const { loading, error, data } = useQuery(ENQUIRY, { variables: { id } });
  // const loading = true;

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  // if (loading || !data) {
  //   // return <div>Loading</div>;
  // }

  const enquiry = data && data.enquiry;

  return (
    <div className="card">
      <div className="card-title">Enquiry Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="card-body">
          <InfoField
            setEditing={() => {}}
            disableEditing={true}
            label={"Name"}
            value={enquiry.name}
            required={true}
          />

          <InfoField
            setEditing={() => {}}
            disableEditing={true}
            label={"Email"}
            value={enquiry.email}
            required={true}
          />

          <InfoField
            setEditing={() => {}}
            disableEditing={true}
            label={"Phone"}
            value={enquiry.phone}
            required={true}
          />

          <InfoField
            setEditing={() => {}}
            disableEditing={true}
            label={"City"}
            value={enquiry.city}
            required={true}
          />

          <InfoField
            setEditing={() => {}}
            disableEditing={true}
            label={"Event"}
            value={enquiry.event.name}
            required={true}
          />
        </div>
      )}
    </div>
  );
}

export default EnquiryDetails;

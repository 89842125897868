import ModalComponent from "../../ModalComponent";
import Form from "../../Form";
import Pitch from "../../Pitch";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
import EVENTS from "../../../api/queries/events";
import PROFILE from "../../../api/queries/profile";
import ROLE_TYPES from "../../../api/queries/roleTypes";
import "../../../styles/CreateFormation.css";
import { useAppState } from "../../../utils/appState";
import moment from "moment";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { UPDATE_SPORT, SPORT } from "../../../api/sports";

function CreateFormation({ sport, formation, selectionSet }) {
  console.log("SPORT", sport);
  const PITCH_WIDTH = 324;
  const MARKER_RADIUS = 16;
  const [, setAppState] = useAppState();
  const [name, setName] = useState(formation?.name || "");
  const [markers, setMarkers] = useState([]);
  const [updateSport, { loading }] = useMutation(
    UPDATE_SPORT({ selectionSet })
  );

  const updatePosition = (value, id) => {
    const newMarkers = [...markers];
    newMarkers[id] = {
      ...newMarkers[id],
      ...value,
    };

    // console.log(value);
    setMarkers(newMarkers);
  };

  const deletePosition = (id) => {
    const newMarkers = [
      ...markers.slice(0, id),
      ...markers.slice(id + 1, markers.length),
    ];
    setMarkers(newMarkers);
  };

  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form" style={{ width: "auto" }}>
          <div className="title">
            {formation ? "Edit formation" : "Create Formation"}
          </div>

          {formation ? (
            <Pitch
              width={PITCH_WIDTH}
              markers={formation.positions.map((p) => ({
                ...p,
                label: p.positionType.shortName,
              }))}
              lockEditing={true}
            />
          ) : (
            <div className="position-editor">
              <div className="pitch-container">
                <Pitch
                  width={PITCH_WIDTH}
                  markers={markers}
                  updatePosition={updatePosition}
                  deletePosition={deletePosition}
                  deleteZone={true}
                />
              </div>

              <div className="positions">
                {sport.positionTypes.map((p) => (
                  <div
                    key={p.id}
                    className="position-item hover-pointer"
                    onClick={() => {
                      const newMarkers = [
                        ...markers,
                        { ...p, x: 0, y: 0, label: p.shortName },
                      ];
                      // newCoordinates[newMarkers.length - 1] = { x: 0, y: 0 };
                      setMarkers(newMarkers);
                      // setCoordinates(newCoordinates);
                    }}
                    style={{
                      width: 32,
                      height: 32,
                      borderRadius: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "var(--blue)",
                      color: "white",
                      marginLeft: 16,
                      fontWeight: 600,
                    }}
                  >
                    {p.shortName}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div className="form-group" style={{ flexGrow: 1 }}>
              <input
                value={name}
                placeholder={"Formation Name"}
                className="input"
                type={"text"}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                style={{ marginTop: 16 }}
              />
            </div>

            <div className="options">
              <button
                onClick={(e) => {
                  updateSport({
                    variables: {
                      where: { id: sport.id },
                      data: {
                        formations: formation
                          ? {
                              update: {
                                where: { id: formation.id },
                                data: { name },
                              },
                            }
                          : {
                              create: {
                                name: name,
                                positions: {
                                  create: markers.map((m) => ({
                                    x: (m.x + MARKER_RADIUS) / PITCH_WIDTH,
                                    y: (m.y + MARKER_RADIUS) / PITCH_WIDTH,
                                    positionType: { connect: { id: m.id } },
                                  })),
                                },
                              },
                            },
                      },
                    },
                    update: (cache, { data: { updateSport } }) => {
                      const q = cache.readQuery({
                        query: SPORT({ selectionSet }),
                        variables: { id: sport.id },
                      });
                      cache.writeQuery({
                        query: SPORT({ selectionSet }),
                        variables: { id: sport.id },
                        data: {
                          sport: {
                            ...q.sport,
                            formations: updateSport.formations,
                          },
                        },
                      });

                      setAppState({ modal: false });
                    },
                  });
                }}
                className={"option submit"}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateFormation;

import Table from "../../Table";
import CreateRegistration from "../../CreateRegistration";
import ViewRegistration from "../../ViewRegistration";
import UploadData from "../../common/UploadData";
import exportToCSV from "../../../utils/exportToCSV";
import { EVENT } from "../../../api/events";
import { useAppState } from "../../../utils/appState";
import { FaUpload } from "react-icons/fa";
import moment from "moment";
import { useLocation } from "react-router-dom";

function EventEnquiries({ event }) {
  const [, setAppState] = useAppState();
  let location = useLocation();

  return (
    <Table
      config={{
        query: EVENT({
          selectionSet: `enquiries{
          id
          createdAt
          email
          phone
          name
          city
        }`,
        }),
        variables: {
          id: event.id,
        },
        headerOptions: [
          (_, rows) => (
            <div
              className="option outline"
              onClick={() => {
                exportToCSV({
                  fields: [
                    { label: "Name", value: (x) => x.name },
                    { label: "Email", value: (x) => x.email },
                    { label: "Phone", value: (x) => x.phone },
                    { label: "City", value: (x) => x.city },
                    {
                      label: "Created At",
                      value: (x) =>
                        moment(x.createdAt).format("DD-MM-YYYY HH:mm"),
                    },
                  ],
                  data: rows,
                });
              }}
            >
              Export Data
            </div>
          ),
        ],
        orderBy: [],
        search: () => {},
        fields: [
          { label: "Name", value: (row) => row.name, weight: 0.2 },

          { label: "Email", value: (row) => row.email, weight: 0.2 },
          { label: "Phone", value: (row) => row.phone, weight: 0.2 },
          {
            label: "City",
            value: (row) => row.email,
            weight: 0.2,
          },
          {
            label: "Created At",
            value: (row) => moment(row.createdAt).format("DD-MM-YYYY HH:mm"),
            weight: 0.2,
          },
        ],
        itemOnClick: (history, item) => {
          history.push("/view/enquiries/" + item.id, {
            referrer: location.pathname + location.search,
          });
        },
        rows: (d) => d.event.enquiries,
        title: "",
      }}
    />
  );
}

export default EventEnquiries;

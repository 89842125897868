import { gql } from "@apollo/client";

const SPORT = gql`
  query sport($id: ID!) {
    sport(id: $id) {
      id
      name
    }
  }
`;

export default SPORT;

import Editable from "../../Editable";
import StatefulView from "../../StatefulView";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { FIXTURE, UPDATE_FIXTURE } from "../../../api/fixtures";
import { EVENT } from "../../../api/events";
import ORGANIZATIONS from "../../../api/queries/organizations";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";
import makeFixturePlaceholder from "../../../utils/makeFixturePlaceholder";
import makeEventPartLabel from "../../../utils/makeEventPartLabel";

import Loading from "../../Loading";
import { Fragment } from "react";
import "../../../styles/FixtureParticipants.css";

function FixtureParticipants({ id }) {
  const selectionSet = `
    roles(where:{type:{name:"PARTICIPANT"}}){
      id
      order
      type{
        id
        name
      }
      organization{
        id
        name
      }
      placement{
        id
        order
        fixture{
          id
          label
        }
        eventPart{
          id
          label
        }
      }
    }
    event{
      id
    }
  `;

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const fixture = data?.fixture;

  console.log("FP Roles:", fixture?.roles);

  return (
    <div className="card">
      <div className="card-title">Fixture Participants</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="card-body">
          {[...fixture.roles]
            .sort((a, b) => a.order - b.order)
            .map((role, i) => (
              <StatefulView
                states={[
                  (setSelectedState) => (
                    <div className="fixture-participant">
                      <div className="title">{`Participant ${i + 1} ${
                        !i ? "(Home)" : i === 1 ? "(Away)" : ""
                      }`}</div>
                      <div className="team">
                        {role.organization?.name || role.profile?.name || "TBD"}
                      </div>

                      <div
                        className="edit-button"
                        onClick={() => {
                          setSelectedState(1);
                        }}
                      >
                        Edit team
                      </div>

                      {!role.organization && !role.profile && (
                        <div className="placeholder">
                          {role.placement ? (
                            makeFixturePlaceholder(role)
                          ) : (
                            <div
                              className="edit-button"
                              onClick={() => {
                                setSelectedState(2);
                              }}
                            >
                              Edit placeholder
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ),
                  (setSelectedState) => (
                    <div className="fixture-participant">
                      <div className="title">{`Edit Participant ${i + 1} ${
                        !i ? "(Home)" : i === 1 ? "(Away)" : ""
                      }`}</div>
                      <Form
                        className={"editable-field-form"}
                        initialState={{
                          organizationId: role.organization?.id,
                        }}
                        fields={[
                          {
                            label: "",
                            type: "select",
                            key: "organizationId",
                            query: ORGANIZATIONS,
                            where: { types: ["TEAM"] },
                            items: (d) => d.organizations,
                            optionLabel: (i) => i.name,
                          },
                        ]}
                        mutation={UPDATE_FIXTURE({ selectionSet })}
                        update={(cache, { data: { updateFixture } }) => {
                          const q = cache.readQuery({
                            query: FIXTURE({ selectionSet }),
                            variables: { id },
                          });
                          cache.writeQuery({
                            query: FIXTURE({ selectionSet }),
                            variables: { id },
                            data: {
                              fixture: {
                                ...q.fixture,
                                roles: updateFixture.roles,
                              },
                            },
                          });

                          setSelectedState(0);
                        }}
                        vars={(s) => {
                          const v = {
                            where: { id },
                            data: {
                              roles: {
                                update: {
                                  where: {
                                    id: role.id,
                                  },
                                  data: {},
                                },
                              },
                            },
                          };

                          if (!s.organizationId) {
                            if (role.organization) {
                              v.data.roles.update.data.organization = {
                                disconnect: true,
                              };
                            } else {
                              return false;
                            }
                          } else {
                            v.data.roles.update.data.organization = {
                              connect: { id: s.organizationId },
                            };
                          }

                          return v;
                        }}
                        options={[
                          <div
                            className="option cancel"
                            onClick={() => {
                              setSelectedState(0);
                            }}
                          >
                            <FaTimes />
                          </div>,
                        ]}
                      />
                    </div>
                  ),
                  (setSelectedState) => (
                    <div className="fixture-participant">
                      <div className="title">{`Edit Placeholder ${i + 1} ${
                        !i
                          ? "(Home) Placeholder"
                          : i === 1
                          ? "(Away) Placeholder"
                          : ""
                      }`}</div>
                      <Form
                        className={"editable-field-form column"}
                        initialState={{
                          order: "",
                          connectsTo: "fixture",
                          fixtureId: "",
                          groupId: "",
                        }}
                        fields={[
                          {
                            label: "Placement",
                            type: "select",
                            key: "order",
                            items: new Array(20).fill(0).map((x, i) => ({
                              label: !i
                                ? "#1 (Winner)"
                                : i === 1
                                ? "#2 Runner-up"
                                : "#" + (i + 1),
                              value: i,
                            })),
                            optionLabel: (i) => i.label,
                          },
                          {
                            label: "Connects To",
                            type: "select",
                            key: "connectsTo",
                            items: [
                              { label: "Group", value: "group" },
                              { label: "Fixture", value: "fixture" },
                            ],
                            optionLabel: (i) => i.label,
                          },
                          {
                            label: "Group",
                            showIf: (s) => s.connectsTo === "group",
                            type: "select",
                            key: "groupId",
                            query: EVENT({
                              selectionSet: `
                              parts{
                                id
                                order
                                type
                                label
                                partOf{
                                  id
                                }
                                roles{
                                  id
                                  organization{
                                    id
                                    name
                                  }
                                }
                              }
                            `,
                            }),
                            variables: { id: fixture.event.id },
                            items: (d) =>
                              d.event.parts.filter((x) => x.type === "Group"),
                            optionLabel: (i, d) =>
                              makeEventPartLabel({
                                part: i,
                                parts: d.event.parts,
                              }),
                          },

                          {
                            label: "Fixture",
                            showIf: (s) => s.connectsTo === "fixture",
                            type: "select",
                            key: "fixtureId",
                            query: EVENT({
                              selectionSet: `
                              fixtures{
                                id
                                label
                              }
                            `,
                            }),
                            variables: { id: fixture.event.id },
                            items: (d) => d.event.fixtures,
                            optionLabel: (i, d) => i.label,
                          },
                        ]}
                        mutation={UPDATE_FIXTURE({ selectionSet })}
                        update={(cache, { data: { updateFixture } }) => {
                          const q = cache.readQuery({
                            query: FIXTURE({ selectionSet }),
                            variables: { id },
                          });
                          cache.writeQuery({
                            query: FIXTURE({ selectionSet }),
                            variables: { id },
                            data: {
                              fixture: {
                                ...q.fixture,
                                roles: updateFixture.roles,
                              },
                            },
                          });

                          setSelectedState(0);
                        }}
                        vars={(s) => {
                          return false;

                          // TO DO
                          const v = {
                            where: { id },
                            data: {
                              roles: {
                                update: {
                                  where: {
                                    id: role.id,
                                  },
                                  data: {},
                                },
                              },
                            },
                          };

                          return v;
                        }}
                        options={[
                          <div
                            className="option cancel"
                            onClick={() => {
                              setSelectedState(0);
                            }}
                          >
                            <FaTimes />
                          </div>,
                        ]}
                      />
                    </div>
                  ),
                ]}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default FixtureParticipants;

import SportStatTypeGroups from "../SportStatTypeGroups";
import SportStatTypesList from "../SportStatTypesList";
import "./SportStatTypes.css";

export default function SportStatTypes({ sport }) {
  return (
    <div className="App-grid event-info-page">
      <SportStatTypeGroups sport={sport} />
      <SportStatTypesList sport={sport} />
    </div>
  );
}

import HANDLE_CLAIM from "../../api/mutations/handleClaim";
import { useMutation } from "@apollo/client";
import PROFILE_CLAIMS_LIST from "../../api/queries/profileClaimsList";
import Loading from "../Loading";
import { useState, Fragment } from "react";

export default function ProfileClaimItem({ claim, profile }) {
  const [handleClaim, { loading }] = useMutation(HANDLE_CLAIM);
  const [approved, setApproved] = useState(false);
  const [confirmApproval, setConfirmApproval] = useState(false);

  return (
    <div className="profile-claim-item">
      <div className="profile-claim-item__id">Claim ID: # {claim.id}</div>
      <div className="profile-claim-item__user">
        Claim by {claim.user.name} ({claim.user.email})
      </div>

      {loading ? (
        <Loading />
      ) : (
        <Fragment>
          {confirmApproval ? (
            <div className="profile-claim-item__confirmation">
              Are you sure you want to approve this claim? This cannot be
              undone.
              <div className="profile-claim-item__confirmation__options">
                <div
                  className="profile-claim-item__confirmation__options__option confirm"
                  onClick={() => {
                    handleClaim({
                      variables: {
                        id: claim.id,
                        approved: true,
                      },
                      update: (cache, { data: { handleProfileClaim } }) => {
                        cache.modify({
                          id: cache.identify(profile),
                          fields: {
                            claims(existingClaimRefs, { readField }) {
                              return existingClaimRefs.filter(
                                (claimRef) =>
                                  handleProfileClaim.id ===
                                  readField("id", claimRef)
                              );
                            },
                          },
                        });
                        setConfirmApproval(false);
                        setApproved(true);
                      },
                    });
                  }}
                >
                  Yes, approve
                </div>
                <div
                  className="profile-claim-item__confirmation__options__option cancel"
                  onClick={() => {
                    setConfirmApproval(false);
                  }}
                >
                  No, cancel
                </div>
              </div>
            </div>
          ) : approved ? (
            <div className="profile-claim-item__options">
              This claim has been approved succesfully!
            </div>
          ) : (
            <div className="profile-claim-item__options">
              <div
                className="profile-claim-item__options__option approve"
                onClick={() => {
                  setConfirmApproval(true);
                }}
              >
                Approve
              </div>
              <div
                className="profile-claim-item__options__option dismiss"
                onClick={() => {
                  handleClaim({
                    variables: {
                      id: claim.id,
                      approved: false,
                    },
                    update: (cache, { data: { handleProfileClaim } }) => {
                      const q = cache.readQuery({
                        query: PROFILE_CLAIMS_LIST,
                      });

                      const index = q?.profileClaims?.findIndex(
                        (pc) => pc.id === profile.id
                      );

                      if (index && index > -1) {
                        const count = q.profileClaims[index].claimsCount;
                        if (count < 2) {
                          cache.writeQuery({
                            query: PROFILE_CLAIMS_LIST,
                            data: [...q.profileClaims].filter(
                              (x) => x.id !== profile.id
                            ),
                          });
                        } else {
                          const newData = [...q.profileClaims];
                          newData[index].claimsCount =
                            newData[index].claimsCount - 1;
                          cache.writeQuery({
                            query: PROFILE_CLAIMS_LIST,
                            data: newData,
                          });
                        }
                      }

                      cache.modify({
                        id: cache.identify(profile),
                        fields: {
                          claims(existingClaimRefs, { readField }) {
                            return existingClaimRefs.filter(
                              (claimRef) =>
                                handleProfileClaim.id !==
                                readField("id", claimRef)
                            );
                          },
                        },
                      });
                    },
                  });
                }}
              >
                Dismiss
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

import "./MergeProfiles.css";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { useAppState } from "../../../utils/appState";
import { useMutation } from "@apollo/client";
import { CREATE_PROFILE_MERGE, MERGE } from "../../../api/profiles";
import { Loader, SearchWithDropdown } from "../../common";

export default function MergeProfiles() {
  const [, setAppState] = useAppState();
  const [profileToKeep, setProfileToKeep] = useState();
  const [profileToDelete, setProfileToDelete] = useState();
  const [error, setError] = useState("");
  const [merge, { loading: merging }] = useMutation(MERGE(), {
    onError: (e) => {
      console.log(e);
      setError(e.message);
    },
  });
  const [requestMerge, { loading: requesting }] = useMutation(
    CREATE_PROFILE_MERGE(),
    {
      update: (cache, { data: { createProfileMerge } }) => {
        if (createProfileMerge?.id) {
          merge({
            variables: {
              profileToKeepId: profileToKeep.id,
              profileToDeleteId: profileToDelete.id,
              mergeId: createProfileMerge.id,
            },
          });
        } else {
          setError("Oops, something went wrong.");
        }
      },
      onError: (e) => {
        console.log(e);
        setError(e.message);
      },
    }
  );

  return (
    <div className="App-modal-form" style={{ height: "80%" }}>
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Merge Profiles</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <div className="App-modal-form__instructions">
        <div className="App-modal-form__instructions__title">*IMPORTANT*</div>

        <ul className="App-modal-form__instructions__list">
          <li>
            <span className="bold">
              This action is permanent and cannot be undone
            </span>
            . Ensure the profiles you have selected are correct before
            initiating a merge.
          </li>
          <li>
            <span className="bold">Perform only one merge at a time</span>. Wait
            till a merge is completed successfully before initiating a new merge
            to ensure data consistency. You will be notified by email when a
            merge is complete.
          </li>
          <li>
            Merging profiles may lock the database, slowing down the website for
            other users.{" "}
            <span className="bold">
              Use this feature sparingly and when there are less users expected
              to be using the website
            </span>{" "}
            .
          </li>
          <li>
            Any tags in photos, videos or posts cannot be transferred and will
            have to be updated manually.
          </li>
        </ul>
      </div>

      <div className="App-modal-form__body">
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Profile To Keep<span className="required">*</span>
          </div>

          {profileToKeep ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setProfileToKeep("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {profileToKeep.name} | @{profileToKeep.handle}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.profiles || []}
              onItemSelect={(item) => {
                setProfileToKeep(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Profile To Delete<span className="required">*</span>
          </div>

          {profileToDelete ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setProfileToDelete("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {profileToDelete.name} | @{profileToDelete.handle}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.profiles || []}
              onItemSelect={(item) => {
                setProfileToDelete(item);
              }}
            />
          )}

          {error && (
            <div
              className="App-modal-form__body__error"
              style={{ marginTop: "16px" }}
            >
              {error}
            </div>
          )}

          <button
            onClick={() => {
              if (requesting || merging) return;
              if (!profileToKeep?.id || !profileToDelete?.id) {
                setError("Both fields are required");
                return;
              }

              if (profileToKeep?.id === profileToDelete?.id) {
                setError("Profiles must be different");
              }

              setError("");

              requestMerge({
                variables: {
                  profileToKeepId: profileToKeep.id,
                  profileToDeleteId: profileToDelete.id,
                },
              });
            }}
            className={
              "App-button App-modal-form__body__submit-btn " +
              (requesting || merging ? "loading" : "")
            }
          >
            {requesting || merging ? <Loader theme="small" /> : "SUBMIT"}
          </button>

          {(requesting || merging) && (
            <div className="" style={{ marginTop: "16px" }}>
              This may take a while. You will be notified by email once the
              merge is complete.
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

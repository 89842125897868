import FixtureDetails from "../FixtureDetails";
import UnpublishStats from "../UnpublishStats";
import "./FixtureInfo.css";

export default function FixtureInfo({ fixture }) {
  return (
    <div className="fixture-info-page">
      <FixtureDetails fixture={fixture} />
      <UnpublishStats fixture={fixture} />
    </div>
  );
}

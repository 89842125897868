import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../../../api/events";
import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function EventDetails({ id }) {
  const selectionSet = `
  name
  sport{
    id
    name
  }
  type{
    id
    name
  }
  subtype
  scheduledStart
  scheduledEnd
  registrationStart
  registrationEnd
  published
  `;
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const event = data?.event;

  return (
    <div className="card">
      <div className="card-title">Event Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="card-body">
          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ name: event.name }}
                fields={[{ label: "Edit Name", type: "text", key: "name" }]}
                mutation={UPDATE_EVENT({ selectionSet: `name` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        name: updateEvent.name,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    name: s.name,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Name"}
                value={event.name}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ sportId: event.sport.id }}
                fields={[
                  {
                    label: "Edit Sport",
                    type: "select",
                    key: "sportId",
                    query: SPORTS,
                    items: (d) => d.sports,
                    optionLabel: (i) => i.name,
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `sport{id name}` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        sport: updateEvent.sport,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    sportId: s.sportId,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Sport"}
                value={event.sport.name}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ type: event.type || "" }}
                fields={[
                  {
                    label: "Edit Type",
                    type: "select",
                    key: "type",
                    items: [{ value: "tournament", label: "Tournament" }],
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `type` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        type: updateEvent.type,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    type: s.type,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Type"}
                value={event.type?.name || "-"}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ subtype: event.subtype }}
                fields={[
                  { label: "Edit Subtype", type: "text", key: "subtype" },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `subtype` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        subtype: updateEvent.subtype,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    subtype: s.subtype,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Subtype"}
                value={event.subtype || "-"}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  scheduledStart: event.scheduledStart
                    ? moment(event.scheduledStart).format("YYYY-MM-DD")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Scheduled Start",
                    type: "date",
                    key: "scheduledStart",
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `scheduledStart` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        scheduledStart: updateEvent.scheduledStart,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    scheduledStart: s.scheduledStart
                      ? moment(s.scheduledStart).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Scheduled Start"}
                value={
                  event.scheduledStart
                    ? moment(event.scheduledStart).format("Do MMM YYYY")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  scheduledEnd: event.scheduledEnd
                    ? moment(event.scheduledEnd).format("YYYY-MM-DD")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Scheduled End",
                    type: "date",
                    key: "scheduledEnd",
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `scheduledEnd` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        scheduledEnd: updateEvent.scheduledEnd,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    scheduledEnd: s.scheduledEnd
                      ? moment(s.scheduledEnd).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Scheduled End"}
                value={
                  event.scheduledEnd
                    ? moment(event.scheduledEnd).format("Do MMM YYYY")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  registrationStart: event.registrationStart
                    ? moment(event.registrationStart).format("YYYY-MM-DD")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Registration Start",
                    type: "date",
                    key: "registrationStart",
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `registrationStart` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        registrationStart: updateEvent.registrationStart,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    registrationStart: s.registrationStart
                      ? moment(s.registrationStart).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Registration Start"}
                value={
                  event.registrationStart
                    ? moment(event.registrationStart).format("Do MMM YYYY")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  registrationEnd: event.registrationEnd
                    ? moment(event.registrationEnd).format("YYYY-MM-DD")
                    : "",
                }}
                fields={[
                  {
                    label: "Edit Registration End",
                    type: "date",
                    key: "registrationEnd",
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `registrationEnd` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        registrationEnd: updateEvent.registrationEnd,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    registrationEnd: s.registrationEnd
                      ? moment(s.registrationEnd).toISOString()
                      : null,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Registration End"}
                value={
                  event.registrationEnd
                    ? moment(event.registrationEnd).format("Do MMM YYYY")
                    : "-"
                }
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{
                  published: event.published,
                }}
                fields={[
                  {
                    label: "Edit Published",
                    type: "select",
                    key: "published",
                    items: [
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ],
                  },
                ]}
                mutation={UPDATE_EVENT({ selectionSet: `published` })}
                update={(cache, { data: { updateEvent } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id },
                    data: {
                      event: {
                        ...q.event,
                        published: updateEvent.published,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    published: s.published === "true" ? true : false,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Published"}
                value={event.published ? "Yes" : "No"}
                required={true}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default EventDetails;

import { gql } from "@apollo/client";

const ENQUIRY = gql`
  query enquiry($id: ID!) {
    enquiry(id: $id) {
      id
      event {
        id
        name
      }
      email
      phone
      name
      city
      notes {
        id
        createdAt
        text
      }
      registration {
        id
        paid
        amountPaid
        profile {
          id
          name
          email
          phone
        }
        organization {
          id
          name
        }
        event {
          id
          name
        }
      }
    }
  }
`;

export default ENQUIRY;

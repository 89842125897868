import { gql } from "@apollo/client";

const CREATE_ORGANIZATION = gql`
  mutation createOrganization($data: CreateOrganizationInput!) {
    createOrganization(data: $data) {
      id
      name
      handle
      profilePhoto {
        id
        filename
      }
    }
  }
`;

export default CREATE_ORGANIZATION;

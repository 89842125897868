import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { SPORT, UPDATE_SPORT } from "../../../api/sports";
import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function SportDetails({ id }) {
  const selectionSet = `
    name
    description
    formatOf{
      id
      name
    }
  `;
  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const sport = data?.sport;
  // console.log("Deets:", sport);

  return (
    <div className="card">
      <div className="card-title">Sport Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="card-body">
          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ name: sport.name }}
                fields={[{ label: "Edit Name", type: "text", key: "name" }]}
                mutation={UPDATE_SPORT({ selectionSet: `name` })}
                update={(cache, { data: { updateSport } }) => {
                  const q = cache.readQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id },
                    data: {
                      sport: {
                        ...q.sport,
                        name: updateSport.name,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    name: s.name,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Name"}
                value={sport.name}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ description: sport.description }}
                fields={[
                  {
                    label: "Edit Description",
                    type: "text",
                    key: "description",
                  },
                ]}
                mutation={UPDATE_SPORT({ selectionSet: `description` })}
                update={(cache, { data: { updateSport } }) => {
                  const q = cache.readQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id },
                    data: {
                      sport: {
                        ...q.sport,
                        description: updateSport.description,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    description: s.description,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Description"}
                value={sport.description}
                required={true}
              />
            )}
          />

          <Editable
            form={(setEditing) => (
              <Form
                className={"editable-field-form"}
                initialState={{ formatOfId: sport.formatOf?.id }}
                fields={[
                  {
                    label: "Edit Parent Sport Format",
                    type: "select",
                    key: "formatOfId",
                    query: SPORTS,
                    items: (d) => d.sports,
                    optionLabel: (i) => i.name,
                  },
                ]}
                mutation={UPDATE_SPORT({ selectionSet: `formatOf{id name}` })}
                update={(cache, { data: { updateSport } }) => {
                  const q = cache.readQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id },
                  });
                  cache.writeQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id },
                    data: {
                      sport: {
                        ...q.sport,
                        formatOf: updateSport.formatOf,
                      },
                    },
                  });

                  setEditing(false);
                }}
                vars={(s) => ({
                  where: { id },
                  data: {
                    formatOfId: s.formatOfId,
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setEditing(false);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            )}
            content={(setEditing) => (
              <InfoField
                setEditing={setEditing}
                label={"Format Of"}
                value={sport.formatOf?.name}
                required={true}
              />
            )}
          />
        </div>
      )}
    </div>
  );
}

export default SportDetails;

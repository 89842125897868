import gql from "graphql-tag";

const GENERATE_FIXTURES = gql`
  mutation generateFixtures(
    $eventId: ID!
    $rounds: [GenerateFixtureEventPartInput!]
    $fixtures: [GenerateFixtureFixtureInput!]
    $roles: [GenerateFixtureRoleInput!]
    $placements: [GenerateFixturePlacementInput!]
  ) {
    generateFixtures(
      eventId: $eventId
      rounds: $rounds
      roles: $roles
      fixtures: $fixtures
      placements: $placements
    ) {
      success
      error
    }
  }
`;

export default GENERATE_FIXTURES;

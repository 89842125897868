import { gql } from "@apollo/client";

const PROFILE = gql`
  query profile($id: ID!) {
    profile(id: $id) {
      id
      name
      email
      phone

      user {
        id
        name
      }

      profilePhoto {
        id
        filename
      }

      coverPhoto {
        id
        filename
      }

      contactMechanisms {
        id
        type
        subtype
        value
        areaCode
        countryCode
      }

      eventRoles {
        id
        type {
          id
          name
        }
        event {
          id
          name
        }
      }

      organizations {
        id
        organization {
          id
          name
        }
        user {
          id
          name
        }
        fromDate
        toDate
        role
      }

      registrations {
        id
        paid
        amountPaid
        event {
          id
          name
        }
        organization {
          id
          name
        }
      }
    }
  }
`;

export default PROFILE;

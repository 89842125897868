import Table from "../../Table";
import CreateRegistration from "../../CreateRegistration";
import ViewRegistration from "../../ViewRegistration";
import UploadData from "../../common/UploadData";
import exportToCSV from "../../../utils/exportToCSV";
import { EVENT_REGISTRATIONS } from "../../../api/events";
import { useAppState } from "../../../utils/appState";
import { FaUpload } from "react-icons/fa";

function EventRegistrations({ event }) {
  const [, setAppState] = useAppState();

  return (
    <Table
      config={{
        query: EVENT_REGISTRATIONS({ selectionSet: "" }),
        variables: {
          eventId: event.id,
        },
        headerOptions: [
          () => (
            <div
              className="option"
              onClick={() => {
                setAppState({
                  modal: <CreateRegistration eventId={event.id} />,
                });
              }}
            >
              Add
            </div>
          ),
          (x, y, refetch) => (
            <div
              className="option outline"
              onClick={() => {
                setAppState({
                  modal: (
                    <UploadData
                      headers={[
                        { label: "Name", key: "name" },
                        { label: "Email", key: "email" },
                        { label: "Phone", key: "phone" },
                        { label: "Team", key: "organization" },
                        { label: "Paid(YES/NO)", key: "paid" },
                        { label: "Amount Paid", key: "amountPaid" },
                      ]}
                      type={"eventRegistration"}
                      transform={(rows) => {
                        return rows
                          .map((r) => ({
                            name: r[0]?.trim(),
                            email:
                              r[1]?.trim() ||
                              r[0]?.trim().toLowerCase().split(" ").join("_") +
                                "@sportspaddock.com",
                            phone: r[2]?.trim(),
                            organization: r[3]?.trim(),
                            paid: r[4] === "YES" ? true : false,
                            amountPaid: r[5],
                            eventId: event.id,
                          }))
                          .filter((r) => r.name && r.email);
                      }}
                      validate={() => {
                        return { valid: true };
                      }}
                      refetch={refetch}
                      // instructions={
                      //   "Please note, email is required to create a registration. If no email is provided, the system will automatically create an email from the player's name in the format: firstname_lastname@sportspaddock.com"
                      // }
                    />
                  ),
                });
              }}
            >
              <FaUpload />
            </div>
          ),
          (_, rows) => (
            <div
              className="option outline"
              onClick={() => {
                exportToCSV({
                  fields: [
                    { label: "Name", value: (x) => x.profile.name },
                    { label: "Email", value: (x) => x.profile.email },
                    { label: "Phone", value: (x) => x.profile.phone },
                    { label: "Team", value: (x) => x.organization?.name || "" },
                    {
                      label: "Paid(YES/NO)",
                      value: (x) => (x.paid ? "YES" : "NO"),
                    },
                    { label: "Amount Paid", value: (x) => x.amountPaid || 0 },
                  ],
                  data: rows,
                });
              }}
            >
              Export Data
            </div>
          ),
        ],
        orderBy: [],
        search: () => {},
        fields: [
          { label: "Name", value: (row) => row.profile.name, weight: 0.25 },
          {
            label: "Team",
            value: (row) => row.organization?.name,
            weight: 0.25,
          },
          { label: "Email", value: (row) => row.profile.email, weight: 0.25 },
          { label: "Phone", value: (row) => row.profile.phone, weight: 0.25 },
        ],
        itemOnClick: (history, item) => {
          setAppState({
            modal: (
              <ViewRegistration eventRegistration={item} eventId={event.id} />
            ),
          });
        },
        rows: (d) => d.eventRegistrations,
        title: "",
      }}
    />
  );
}

export default EventRegistrations;

import { gql } from "@apollo/client";

const SPORTS = gql`
  query sports {
    sports {
      id
      name
    }
  }
`;

export default SPORTS;

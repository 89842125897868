import "./Spinner.css";
import { ReactComponent as SpinnerIcon } from "../../../icons/spinner.svg";

function Spinner({ size = 24, color = "#13A0FA" }) {
  return (
    <div className="spinner">
      <SpinnerIcon style={{ height: size, fill: color }} />
    </div>
  );
}

export default Spinner;

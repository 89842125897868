import ModalComponent from "./ModalComponent";
import Form from "./Form";
import CREATE_ORGANIZATION from "../api/mutations/createOrganization";
import ORGANIZATIONS from "../api/queries/organizations";
import { useAppState } from "../utils/appState";

function CreateOrganization() {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={CREATE_ORGANIZATION}
            initialState={{ name: "", mapsUrl: "" }}
            fields={[
              { label: "Name", type: "text", key: "name" },
              { label: "Maps URL", type: "text", key: "mapsUrl" },
            ]}
            update={(cache, { data: { createOrganization } }) => {
              console.log(createOrganization);
              const q = cache.readQuery({ query: ORGANIZATIONS });
              cache.writeQuery({
                query: ORGANIZATIONS,
                data: {
                  organizations: [createOrganization, ...q.organizations],
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => ({
              name: s.name,
              mapsUrl: s.mapsUrl,
            })}
            title={"New Organization"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateOrganization;

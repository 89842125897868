import Editable from "../../Editable";
import StatefulView from "../../StatefulView";
import InfoField from "../../InfoField";
import { useQuery, useMutation } from "@apollo/client";
import { FIXTURE, UNPUBLISH_FIXTURE } from "../../../api/fixtures";

import Loading from "../../Loading";
import { Fragment } from "react";

function UnpublishFixture({ id }) {
  const selectionSet = `
    completed
  `;

  const [unpublishFixture, { loading: unpublishing }] = useMutation(
    UNPUBLISH_FIXTURE,
    {
      update: (cache, { data: { unpublishFixture } }) => {
        cache.writeQuery({
          query: FIXTURE({ selectionSet }),
          data: {
            fixture: {
              ...unpublishFixture,
            },
          },
        });
      },
    }
  );

  const { loading, error, data } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const fixture = data?.fixture;

  if (!fixture?.completed) {
    return <div />;
  }

  return (
    <div className="card">
      <div className="card-title">Unpublish Fixture</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            Click the button below to unpublish this fixture. This action will
            lock your database in order to ensure data consistency, and slow
            down the website for your users. Please do this only if necessary
            and at a time where you expect less people to be using the website.
          </div>
          <div className="card-footer">
            {unpublishing ? (
              <div>Unpublish in progress. This may take a few minutes.</div>
            ) : (
              <div
                className={"App-btn danger hover-pointer "}
                onClick={() => {
                  unpublishFixture({
                    variables: { fixtureId: id },
                  });
                }}
              >
                Unpublish this fixture
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default UnpublishFixture;

import { useQuery } from "@apollo/client";
import "./FormField.css";
import { useState } from "react";
import Error from "../Error";
import Spinner from "../Spinner";
import SearchWithDropdown from "../SearchWithDropdown";
import LocationSearch from "../LocationSearch";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";

function LocationField({
  defaultValue,
  config: { className = "", onChange = () => {} },
}) {
  const [value, setValue] = useState("");

  if (value) {
    return (
      <div className="Form-field-search-selected">
        <div className="Form-field-search-selected__value">{value?.city}</div>

        <div
          className="Form-field-search-selected__dismiss"
          onClick={() => {
            setValue("");
          }}
        >
          <TimesIcon className="Form-field-search-selected__dismiss__icon" />
        </div>
      </div>
    );
  } else {
    return (
      <LocationSearch
        className={""}
        inputClassName={className}
        onItemSelect={(city, state, country, lat, lng) => {
          //   console.log(city, state, country, lat, lng);
          setValue({
            city,
            state,
            country,
            lat,
            lng,
          });

          onChange({
            city,
            state,
            country,
            lat,
            lng,
          });
        }}
      />
    );
  }
}

function SearchField({
  defaultValue,
  config: { className = "", onChange = () => {}, getItems, getValue },
}) {
  const [value, setValue] = useState("");

  if (value) {
    return (
      <div className="Form-field-search-selected">
        <div className="Form-field-search-selected__value">
          {getValue(value)}
        </div>

        <div
          className="Form-field-search-selected__dismiss"
          onClick={() => {
            setValue("");
          }}
        >
          <TimesIcon className="Form-field-search-selected__dismiss__icon" />
        </div>
      </div>
    );
  } else {
    return (
      <SearchWithDropdown
        className={""}
        inputClassName={className}
        getItems={getItems}
        onItemSelect={(item) => {
          setValue(item);
          onChange(item);
        }}
      />
    );
  }
}

function DateField({
  defaultValue,
  config: { className = "", onChange = () => {} },
}) {
  const [value, setValue] = useState(defaultValue);

  return (
    <input
      type="date"
      className={className}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      value={value}
    />
  );
}

function SelectWithQueryField({
  defaultValue,
  config: { className = "", onChange = () => {}, query, getItems },
}) {
  const [value, setValue] = useState(defaultValue);
  const { loading, error, data } = useQuery(query);

  if (error) {
    return <Error />;
  }

  if (loading || !data) {
    return <Spinner />;
  }

  const items = getItems(data) || [];

  console.log(defaultValue);

  return (
    <select
      value={value}
      className={className}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    >
      <option value=""></option>
      {items.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

function SelectField({
  defaultValue,
  config: { className = "", onChange = () => {}, items },
}) {
  const [value, setValue] = useState(defaultValue);

  return (
    <select
      value={value}
      className={className}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    >
      {items.map((item, i) => (
        <option key={"option-" + i} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

function TextField({
  defaultValue = "",
  config: { className = "", onChange = () => {} },
}) {
  const [value, setValue] = useState(defaultValue);

  return (
    <input
      type="text"
      className={className}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      value={value}
    />
  );
}

export default function FormField({ type, defaultValue, config }) {
  if (type === "location") {
    return <LocationField defaultValue={defaultValue} config={config} />;
  }
  if (type === "search") {
    return <SearchField defaultValue={defaultValue} config={config} />;
  }

  if (type === "select") {
    return <SelectField defaultValue={defaultValue} config={config} />;
  }

  if (type === "date") {
    return <DateField defaultValue={defaultValue} config={config} />;
  }

  if (type === "select-with-query") {
    return <SelectWithQueryField defaultValue={defaultValue} config={config} />;
  }

  return <TextField defaultValue={defaultValue} config={config} />;
}

import { useState, useEffect } from "react";
import { useAppState } from "../utils/appState";
import viewRouteConfig from "../utils/viewRouteConfig";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import "../styles/View.css";
import { FaArrowLeft } from "react-icons/fa";
import Loading from "../components/Loading";

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

function View() {
  let location = useLocation();
  // console.log(location);
  let queryParams = useQueryParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState(
    Number(queryParams.get("t")) || 0
  );
  const { type, id } = useParams();
  const history = useHistory();
  const [, setAppState] = useAppState();
  const {
    query,
    title,
    headerOptions = [],
    tabs,
    goBackRoute,
  } = viewRouteConfig[type];
  const { loading, error, data } = useQuery(query, { variables: { id } });
  console.log(selectedTabIndex);
  useEffect(() => {
    // console.log("Location changed");
    setSelectedTabIndex(Number(queryParams.get("t")) || 0);
  }, [queryParams]);

  console.log("View ID:", id);

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <Loading />
      </div>
    );
  }

  console.log("View Data:", data);

  return (
    <div className="View">
      <div className="header">
        <div className="title">
          <span
            className="back-icon"
            onClick={() => {
              location.state?.referrer
                ? history.push(location.state.referrer)
                : history.push(
                    goBackRoute ? goBackRoute(data) : "/index/" + type
                  );
            }}
          >
            <FaArrowLeft />
          </span>

          {title(data)}
        </div>

        <div className="options">
          {headerOptions.map((o) => o(setAppState, data, history))}
        </div>
      </div>
      <div className="tabs">
        {tabs &&
          tabs(data).map((t, i) => (
            <div
              className={
                "tab hover-pointer " + (selectedTabIndex === i ? "active" : "")
              }
              onClick={() => {
                history.push(location.pathname + "?t=" + i, {
                  ...location.state,
                });
                // setSelectedTabIndex(i);
              }}
            >
              {t.label}
            </div>
          ))}
      </div>
      <div className="body">
        {tabs && tabs(data)[selectedTabIndex].component}
      </div>
    </div>
  );
}

export default View;

import "./ProfileDetails.css";
import { Card, CardTitle, EditableField, Spinner, Error } from "../../common";
import { useQuery } from "@apollo/client";
// import { EVENT, UPDATE_EVENT } from "../../../api/events";
import { PROFILE, UPDATE_PROFILE } from "../../../api/profiles";
// import { SPORTS } from "../../../api/sports";
// import { EVENT_TYPES } from "../../../api/queries";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

export default function ProfileDetails({ profile }) {
  const { loading, error, data } = useQuery(
    PROFILE({
      selectionSet: `
    name
    email
    handle
  `,
    }),
    {
      variables: { id: profile.id },
    }
  );

  if (error) {
    console.log(error);
    return <Error />;
  }

  const { name, firstname, lastname, handle, email } = data?.profile || {};

  // const participantTypes = [
  //   { label: "Teams", value: "TEAM" },
  //   { label: "Individuals", value: "INDIVIDUAL" },
  // ];

  return (
    <Card className="event-info-page__details">
      <CardTitle title={"DETAILS"} />

      {loading || !data ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="event-info-page__details__items">
          <EditableField
            label={"Name"}
            value={name}
            config={{
              valueType: "text",
              mutation: UPDATE_PROFILE({ selectionSet: `name` }),
              vars: (v) => {
                return {
                  where: { id: profile.id },
                  data: {
                    name: v,
                  },
                };
              },
              onError: () => {
                toast("Oops, something went wrong please try again.");
              },
            }}
          />

          <EditableField
            label={"Email"}
            value={email}
            // getValueString={(x) => x?.name || ""}
            config={{
              valueType: "text",
              mutation: UPDATE_PROFILE({ selectionSet: `email` }),
              vars: (v) => {
                return {
                  where: { id: profile.id },
                  data: {
                    email: v,
                  },
                };
              },
              onError: () => {
                toast(
                  "This email is unavailable. Please try again with a unique email."
                );
              },
            }}
          />

          <EditableField
            label={"Handle"}
            value={handle}
            // getValueString={(x) => x?.name || ""}
            config={{
              valueType: "text",
              mutation: UPDATE_PROFILE({ selectionSet: `handle` }),
              vars: (v) => {
                return {
                  where: { id: profile.id },
                  data: {
                    handle: v,
                  },
                };
              },
              onError: () => {
                toast(
                  "This handle is unavailable. Please try again with a unique handle."
                );
              },
            }}
          />
        </div>
      )}

      <ToastContainer position={"bottom-right"} theme="dark" />
    </Card>
  );
}

import { gql } from "@apollo/client";

const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($id: ID!) {
    deleteOrganization(id: $id) {
      id
    }
  }
`;

export default DELETE_ORGANIZATION;

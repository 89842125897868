import { Fragment, useState } from "react";
import "./ReportedPostsBrowser.css";
import { Card, Spinner, Button, Loader, Post } from "../../common";
import { useMutation, useQuery } from "@apollo/client";
import {
  DASHBOARD_STATS,
  HANDLE_REPORTED_POST,
  REPORTED_POSTS,
  SUPPORT_TICKETS,
} from "../../../api/dashboard";
import { ReactComponent as ChevronLeft } from "../../../icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../icons/chevron-right.svg";
import ClickOutside from "../../common/ClickOutside";
import { useAppState } from "../../../utils/appState";

const selectionSet = `
createdAt
sourceType
isSaved
clapCount
isClapped
postType
source {
  id
  profile {
    id
    name
    profilePhoto {
      id
      filename
    }
  }
  organization {
    id
    name
    profilePhoto {
      id
      filename
    }
  }
  event {
    id
    name
    organizedBy {
      id
      name
      profilePhoto {
        id
        filename
      }
    }
  }
  fixture {
    id
    completed
    event {
      id
      name
      participantType
      organizedBy {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
    roles {
      id
      order
      type {
        id
        name
      }
      subtypes {
        id
        name
      }
      profile {
        id
        name
        firstname
        lastname
        profilePhoto {
          id
          filename
        }
      }
      organization {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
    placements {
      id
      value
      valueString
      profile {
        id
        name
      }
      organization {
        id
        name
      }
    }
  }
}
author {
  id
  name
}
title
content
truncatedContent
contentLength
tags {
  id
  media {
    id
    type
    filename
    isExternal
  }
  mediaSet {
    id
    preview {
      id
      filename
    }
  }
}`;

function TicketsList({ postId }) {
  const { loading, error, data } = useQuery(
    SUPPORT_TICKETS({
      selectionSet: `
      createdAt
      createdBy{
        id
        name
      }
      description
    `,
    }),
    {
      variables: {
        where: {
          post: { id: postId },
          handled: false,
        },
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const tickets = data.supportTickets;

  console.log(tickets);

  return (
    <div className="report-browser__meta__tickets">
      {tickets.map((ticket) => (
        <div className="report-browser__meta__tickets__ticket">
          <div className="report-browser__meta__tickets__ticket__user">
            <div className="report-browser__meta__tickets__ticket__user__img"></div>

            <div className="report-browser__meta__tickets__ticket__user__name">
              {ticket.createdBy?.name}
            </div>
          </div>

          <div className="report-browser__meta__tickets__ticket__description">
            {ticket.description || (
              <span className="placeholder">No comment</span>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default function ReportedPostsBrowser() {
  const [, setAppState] = useAppState();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const [mutate, { loading: updating }] = useMutation(
    HANDLE_REPORTED_POST({
      selectionSet: `
    id
    flagged
  `,
    }),
    {
      update: (cache, { data: { handleReportedPost } }) => {
        console.log(handleReportedPost);
        // 1. Update the dashboard stats query
        const statsQuery = cache.readQuery({
          query: DASHBOARD_STATS,
        });

        if (statsQuery?.dashboardStats) {
          cache.writeQuery({
            query: DASHBOARD_STATS,
            data: {
              dashboardStats: {
                ...statsQuery.dashboardStats,
                posts: statsQuery.dashboardStats.posts - 1,
              },
            },
          });
        }

        // 2. Update the reported media query
        const reportsQuery = cache.readQuery({
          query: REPORTED_POSTS({
            selectionSet,
          }),
        });

        if (reportsQuery?.reportedPosts) {
          const newReports = [...reportsQuery.reportedPosts].filter(
            (m) => m.id !== handleReportedPost.id
          );

          if (!newReports.length) {
            setAppState({ modal: false });
          } else {
            cache.writeQuery({
              query: REPORTED_POSTS({
                selectionSet,
              }),
              data: {
                reportedPosts: newReports,
              },
            });
          }
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const { loading, error, data } = useQuery(
    REPORTED_POSTS({
      selectionSet,
    })
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const posts = data.reportedPosts;
  const selectedPost = posts[selectedIndex];

  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => (
        <div className="report-browser__wrapper" ref={wrapperRef}>
          <Card className="report-browser">
            <div className="report-browser__content">
              {selectedPost && <Post post={selectedPost} />}
            </div>

            <div className="report-browser__meta">
              <div className="report-browser__meta__header">
                <div className="report-browser__meta__header__title">
                  Post #{selectedPost?.id}
                </div>

                <div className="report-browser__meta__header__options">
                  <div
                    className={
                      "report-browser__meta__header__options__option " +
                      (selectedIndex > 0 ? "" : "disabled")
                    }
                    onClick={() => {
                      if (selectedIndex > 0) {
                        setSelectedIndex((prev) => prev - 1);
                      } else {
                        return;
                      }
                    }}
                  >
                    <ChevronLeft
                      className={
                        "report-browser__meta__header__options__option__icon "
                      }
                    />
                  </div>

                  <div
                    className="report-browser__meta__header__options__option"
                    onClick={() => {
                      if (selectedIndex < posts.length - 1) {
                        setSelectedIndex((prev) => prev + 1);
                      } else {
                        // Fetch more
                      }
                    }}
                  >
                    <ChevronRight className="report-browser__meta__header__options__option__icon" />
                  </div>
                </div>
              </div>

              <TicketsList postId={selectedPost?.id} />

              <div className="report-browser__meta__actions">
                {updating ? (
                  <Loader />
                ) : (
                  <Fragment>
                    <Button
                      className="report-browser__meta__actions__action danger"
                      onClick={() => {
                        mutate({
                          variables: {
                            postId: selectedPost?.id,
                            flagged: true,
                          },
                        });
                      }}
                    >
                      FLAG THIS POST
                    </Button>

                    <Button
                      className="report-browser__meta__actions__action"
                      onClick={() => {
                        mutate({
                          variables: {
                            postId: selectedPost?.id,
                            flagged: false,
                          },
                        });
                      }}
                    >
                      POST IS OKAY
                    </Button>
                  </Fragment>
                )}
              </div>
            </div>
          </Card>
        </div>
      )}
    </ClickOutside>
  );
}

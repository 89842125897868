function makeEventPartLabel({ part, parts }) {
  let ancestors = [part];

  while (ancestors[0].partOf?.id) {
    ancestors.unshift(parts.find((p) => p.id === ancestors[0].partOf.id));
  }

  return ancestors.map((a) => a.label).join(">");
}

export default makeEventPartLabel;

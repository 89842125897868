import { gql } from "@apollo/client";

const ROLE_TYPES = gql`
  query roleTypes($where: RoleTypeWhereInput) {
    roleTypes(where: $where) {
      id
      name
    }
  }
`;

export default ROLE_TYPES;

import "./AppLayout.css";
import { Switch, Route, Link, useHistory } from "react-router-dom";
import ProtectedRoute from "../../components/ProtectedRoute";
import ModalComponent from "../../components/ModalComponent";
import Index from "../../screens/Index";
import View from "../../screens/View";
import Home from "../../screens/Home";
import Auth from "../../screens/Auth";
import Logout from "../../screens/Logout";
import AcceptInvite from "../../screens/AcceptInvite";
import { FaBars } from "react-icons/fa";
import { useAppState } from "../../utils/appState";

export default function AppLayout() {
  const [{ modal, menu }, setAppState] = useAppState();
  let history = useHistory();

  return (
    <div className="App">
      <header className="App-header">
        <Link to="/" className="logo">
          <img src="/logo.png" />
        </Link>

        <div className="account-button"></div>
        <div className="menu-button-container">
          <div
            className="menu-button"
            onClick={() => {
              setAppState({
                modal: (
                  <ModalComponent>
                    {(wrapperRef) => (
                      <div className="App-menu" ref={wrapperRef}>
                        <img src="/logo.png" />
                        <div
                          onClick={() => {
                            history.push("/v1/index/enquiries");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Enquiries
                        </div>
                        <div
                          onClick={() => {
                            history.push("/v1/index/events");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Events
                        </div>
                        <div
                          onClick={() => {
                            history.push("/v1/index/organizations");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Organizations
                        </div>
                        <div
                          onClick={() => {
                            history.push("/v1/index/profiles");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Profiles
                        </div>
                        <div
                          onClick={() => {
                            history.push("/v1/index/sports");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Sports
                        </div>
                        <div
                          onClick={() => {
                            history.push("/v1/index/users");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Users
                        </div>
                        <div
                          onClick={() => {
                            history.push("/v1/index/claims");
                            setAppState({ modal: false });
                          }}
                          className="link"
                        >
                          Claims
                        </div>
                      </div>
                    )}
                  </ModalComponent>
                ),
              });
            }}
          >
            <FaBars />
          </div>
        </div>
      </header>
      <main className="App-body">
        <Switch>
          <Route path="/v1/auth" component={Auth} />
          <Route path="/v1/logout" component={Logout} />
          <Route path="/v1/accept_invite/:token" component={AcceptInvite} />
          <ProtectedRoute path="/v1/view/:type/:id" component={View} />
          <ProtectedRoute path="/v1/index/:type" component={Index} />
          <ProtectedRoute path="/v1" component={Home} />
        </Switch>
      </main>

      {modal && <div className="App-modal">{modal}</div>}
    </div>
  );
}

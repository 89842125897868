import ModalComponent from "./ModalComponent";
import Form from "./Form";
import CREATE_SPORT from "../api/mutations/createSport";
import SPORTS from "../api/queries/sports";
import { useAppState } from "../utils/appState";

function CreateSport() {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={CREATE_SPORT}
            initialState={{ name: "" }}
            fields={[{ label: "Name", type: "text", key: "name" }]}
            update={(cache, { data: { createSport } }) => {
              console.log(createSport);
              const q = cache.readQuery({ query: SPORTS });
              cache.writeQuery({
                query: SPORTS,
                data: {
                  sports: {
                    createSport,
                    ...q.sports,
                  },
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => ({
              name: s.name,
            })}
            title={"New Sport"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateSport;

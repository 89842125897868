import { useQuery, useMutation } from "@apollo/client";
import {
  FIXTURE,
  UPDATE_FIXTURE,
  CREATE_FIXTURE_STATS,
  DELETE_FIXTURE_STAT,
} from "../../../api/fixtures";
import PROFILES from "../../../api/queries/profiles";
// import STAT_TYPES from "../../../api/queries/statTypes";
import "../../../styles/FixtureStats.css";
import { FaPencilAlt, FaTrash, FaTimes } from "react-icons/fa";
import Editable from "../../Editable";
import Form from "../../Form";
import Select from "../../Select";
import { useState } from "react";
import UploadData from "../../common/UploadData";
import Loading from "../../Loading";
import { Fragment } from "react";
import { useAppState } from "../../../utils/appState";

function NewStatRow({
  stat,
  statTypes,
  lineupRoles,
  setPrimaryStat,
  setAssociatedStats,
  associateIndex,
  type,
}) {
  return (
    <Fragment>
      <div className="editable-field-form">
        <div className="form-group">
          <div className="label">Timestamp</div>
          <input
            value={stat.timestamp}
            className="input"
            type={"number"}
            onChange={(e) => {
              if (type === "primary") {
                const newStats = { ...stat };
                newStats.timestamp = e.target.value;
                setPrimaryStat(newStats);
              } else if (type === "associate") {
              }
            }}
            disabled={type === "parent" || type === "associate" ? true : false}
          />
        </div>
        <div className="form-group">
          <div className="label">Stat Type</div>
          <Select
            items={statTypes.map((s) => ({
              ...s,
              label: s.name,
              value: s.id,
            }))}
            value={stat.statTypeId}
            onChange={(e) => {
              if (type === "primary") {
                const newStats = { ...stat };
                newStats.statTypeId = e.target.value;
                newStats.statType = statTypes.find(
                  (x) => x.id === e.target.value
                );
                newStats.parents = statTypes
                  .find((x) => x.id === e.target.value)
                  .relationshipsTo.filter((x) => x.type.name === "Ancestor")
                  .map((x) => ({
                    relationship: x,
                    statType: x.from,
                    timestamp: stat.timestamp,
                    statTypeId: x.from.id,
                    profileId: "",
                  }));

                newStats.associates = statTypes
                  .find((x) => x.id === e.target.value)
                  .relationshipsFrom.filter((x) => x.type.name === "Sibling")
                  .map((x) => ({
                    relationship: x,
                    statType: x.to,
                    timestamp: stat.timestamp,
                    statTypeId: x.to.id,
                    profileId: "",
                    parents: statTypes
                      .find((y) => x.to.id === y.id)
                      .relationshipsTo.filter((y) => y.type.name === "Ancestor")
                      .map((y) => ({
                        relationship: y,
                        statType: y.from,
                        timestamp: stat.timestamp,
                        statTypeId: y.from.id,
                        profileId: "",
                      })),
                  }));

                setPrimaryStat(newStats);
                // setAssociatedStats(associates);
              } else if (type === "associate") {
              }
            }}
            optionLabel={(i) => i.label}
            disabled={type === "parent" || type === "associate" ? true : false}
          />
        </div>
        <div className="form-group">
          <div className="label">Player</div>
          <Select
            items={lineupRoles.map((s) => ({
              label: s.profile.name,
              value: s.profile.id,
            }))}
            value={stat.profileId}
            onChange={(e) => {
              if (type === "primary") {
                const newStats = { ...stat };
                newStats.profileId = e.target.value;

                newStats.parents = newStats.parents.map((ps) => ({
                  ...ps,
                  profileId: e.target.value,
                }));

                setPrimaryStat(newStats);
              } else if (type === "associate") {
                const newStats = { ...stat };
                newStats.profileId = e.target.value;
                //
                newStats.parents = newStats.parents.map((ps) => ({
                  ...ps,
                  profileId: e.target.value,
                }));
                //
                // setPrimaryStat(newStats);
                // console.log("NEW:", newStats);
                setAssociatedStats(newStats);
              }
            }}
            optionLabel={(i) => i.label}
            disabled={type === "parent" ? true : false}
          />
        </div>
      </div>
      {stat.parents?.map((parentStat) => (
        <NewStatRow
          stat={parentStat}
          statTypes={statTypes}
          lineupRoles={lineupRoles}
          setPrimaryStat={setPrimaryStat}
          setAssociatedStats={setAssociatedStats}
          type={"parent"}
        />
      ))}

      {stat.associates?.map((associateStat) => (
        <NewStatRow
          stat={associateStat}
          statTypes={statTypes}
          lineupRoles={lineupRoles}
          setPrimaryStat={setPrimaryStat}
          setAssociatedStats={setAssociatedStats}
          type={"associate"}
        />
      ))}
    </Fragment>
  );
}

function AddStat({
  statTypes,
  lineupRoles,
  selectionSet,
  fixtureId,
  fixtureRole,
  setEditing,
}) {
  const [primaryStat, setPrimaryStat] = useState({
    timestamp: 1,
    statTypeId: "",
    profileId: "",
    parents: [],
    associates: [],
  });
  // const [associatedStats, setAssociatedStats] = useState([]);

  const [createFixtureStats, { loading }] = useMutation(
    CREATE_FIXTURE_STATS({
      selectionSet: `
      timeStamp
      organization{
        id
        name
      }
      profile{
        id
        name
      }
      statType{
        id
        name
      }
      `,
    }),
    {
      update: (cache, { data: { createFixtureStats } }) => {
        const q = cache.readQuery({
          query: FIXTURE({ selectionSet }),
          variables: { id: fixtureId },
        });
        cache.writeQuery({
          query: FIXTURE({ selectionSet }),
          variables: { id: fixtureId },
          data: {
            fixture: {
              ...q.fixture,
              stats: [...createFixtureStats],
            },
          },
        });

        setEditing(false);
      },
    }
  );

  const setAssociate = (newStat) => {
    const o = { ...primaryStat };
    o.associates = o.associates.map((a) => {
      if (a.relationship.id === newStat.relationship.id) {
        return newStat;
      } else {
        return a;
      }
    });
    // console.log(o);
    setPrimaryStat(o);
    // console.log(primaryStat);
  };

  return (
    <form
      className="App-form"
      onSubmit={(e) => {
        e.preventDefault();

        const stats = [
          {
            timeStamp: primaryStat.timestamp
              ? Number(primaryStat.timestamp)
              : 0,
            organization: {
              connect: { id: fixtureRole.organization.id },
            },
            profile: { connect: { id: primaryStat.profileId } },
            statType: { connect: { id: primaryStat.statTypeId } },
          },
        ];
        const relationships = [];

        primaryStat.parents.forEach((parent) => {
          stats.push({
            timeStamp: primaryStat.timestamp
              ? Number(primaryStat.timestamp)
              : 0,
            organization: {
              connect: { id: fixtureRole.organization.id },
            },
            profile: { connect: { id: primaryStat.profileId } },
            statType: { connect: { id: parent.statTypeId } },
          });

          relationships.push({
            from: stats.length - 1,
            to: 0,
            relationship: { connect: { id: parent.relationship.id } },
          });
        });

        primaryStat.associates.forEach((associate) => {
          stats.push({
            timeStamp: primaryStat.timestamp
              ? Number(primaryStat.timestamp)
              : 0,
            organization: {
              connect: { id: fixtureRole.organization.id },
            },
            profile: { connect: { id: associate.profileId } },
            statType: { connect: { id: associate.statTypeId } },
          });

          const associateIndex = stats.length - 1;

          relationships.push({
            from: 0,
            to: associateIndex,
            relationship: { connect: { id: associate.relationship.id } },
          });

          associate.parents.forEach((p) => {
            stats.push({
              timeStamp: primaryStat.timestamp,
              organization: {
                connect: {
                  id: fixtureRole.organization.id,
                },
              },
              profile: { connect: { id: associate.profileId } },
              statType: { connect: { id: p.statTypeId } },
            });

            relationships.push({
              from: stats.length - 1,
              to: associateIndex,
              relationship: { connect: { id: p.relationship.id } },
            });
          });
        });

        createFixtureStats({
          variables: {
            fixtureId: fixtureId,
            stats,
            relationships,
          },
        });
      }}
    >
      <NewStatRow
        stat={primaryStat}
        statTypes={statTypes}
        lineupRoles={lineupRoles}
        setPrimaryStat={setPrimaryStat}
        setAssociatedStats={setAssociate}
        type={"primary"}
      />

      <div className="editable-field-form">
        <div className="options" style={{ marginLeft: "auto" }}>
          <button type="submit" className={"option submit"}>
            Save
          </button>
          <div
            className="option cancel"
            onClick={() => {
              setEditing(false);
            }}
          >
            <FaTimes />
          </div>
          ,
        </div>
      </div>
    </form>
  );
}

function LineupStatsList({
  fixtureRole,
  stats,
  selectionSet,
  fixtureId,
  statTypes,
  lineupRoles,
  refetch,
}) {
  const { formation, organization } = fixtureRole;
  const [deleteFixtureStat, { loading }] = useMutation(DELETE_FIXTURE_STAT);
  const [, setAppState] = useAppState();

  return (
    <div className="card">
      <div
        className="card-title"
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {fixtureRole.organization?.name || "TBD"}
        <div className="card-option" style={{ marginLeft: "auto" }}>
          <div
            className={"App-btn outline hover-pointer upload-lineup-players"}
            onClick={() => {
              // setEditing(true);
              setAppState({
                modal: (
                  <UploadData
                    headers={[
                      { label: "Timestamp", key: "timestamp" },
                      { label: "Stat Type", key: "type" },
                      { label: "Player Name", key: "name" },
                      { label: "Email", key: "email" },
                      { label: "Phone", key: "phone" },
                    ]}
                    type={"stat"}
                    transform={(rows) => {
                      return rows
                        .map((r, i) => ({
                          timestamp: r[0]?.trim() || 1,
                          statType: statTypes.find(
                            (st) => st.name === r[1]?.trim()
                          )?.id,
                          name: r[2]?.trim(),
                          email:
                            r[3]?.trim() ||
                            r[2]?.trim().toLowerCase().split(" ").join("_") +
                              "@sportspaddock.com",
                          phone: r[4]?.trim(),
                          organizationId: organization.id,
                          fixtureId,
                        }))
                        .filter((r) => r.name && r.email && r.statType);
                    }}
                    refetch={refetch}
                  />
                ),
              });
            }}
          >
            Upload Stats
          </div>
        </div>
      </div>

      <div className="add-stat-container">
        <Editable
          form={(setEditing) => (
            <AddStat
              setEditing={setEditing}
              statTypes={statTypes}
              lineupRoles={lineupRoles}
              selectionSet={selectionSet}
              fixtureId={fixtureId}
              fixtureRole={fixtureRole}
            />
          )}
          content={(setEditing) => (
            <div
              className={"App-btn success hover-pointer "}
              onClick={() => {
                setEditing(true);
              }}
              style={{ marginLeft: "auto" }}
            >
              Add Stat
            </div>
          )}
        />
      </div>

      <div className="card-body">
        {stats.map((s) => (
          <div className="stat-item">
            <div className="meta">
              <div className="timestamp">{s.timeStamp}'</div>
              <div className="description">
                {s.statType.name + " - " + s.profile.name}
              </div>
              <div className="options">
                <div
                  className="option delete hover-pointer"
                  onClick={() => {
                    deleteFixtureStat({
                      variables: {
                        id: s.id,
                      },
                      update: (cache, { data: { deleteFixtureStat } }) => {
                        const q = cache.readQuery({
                          query: FIXTURE({ selectionSet }),
                          variables: { id: fixtureId },
                        });
                        cache.writeQuery({
                          query: FIXTURE({ selectionSet }),
                          variables: { id: fixtureId },
                          data: {
                            fixture: {
                              ...q.fixture,
                              stats: q.fixture.stats.filter(
                                (x) => x.id !== deleteFixtureStat.id
                              ),
                            },
                          },
                        });
                      },
                    });
                  }}
                >
                  <FaTrash />
                </div>
              </div>
            </div>
            {s.comment && <div className="comment">{s.comment}</div>}
          </div>
        ))}
      </div>
    </div>
  );
}

function FixtureStats({ fixture }) {
  const selectionSet = `
  event{
    id
    sport{
      id
      statTypes{
        id
        name
        group {
          id
          name
        }
        relationshipsFrom{
          id
          from{
            id
            name
          }
          to{
            id
            name
          }
          type{
            id
            name
          }
        }
        relationshipsTo{
          id
          from{
            id
          }
          to{
            id
          }
          type{
            id
            name
          }
        }
      }
    }
  }
  roles{
    id
    order
    type{
      id
      name
    }
    organization{
      id
      name
    }
    profile{
      id
      name
    }
    placement{
      id
      order
      fixture{
        id
        label
      }
      eventPart{
        id
        label
      }
    }
  }
  stats{
    id
    timeStamp
    organization{
      id
      name
    }
    profile{
      id
      name
    }
    statType{
      id
      name
    }
  }
  `;

  const { loading, error, data, refetch } = useQuery(
    FIXTURE({
      selectionSet,
    }),
    { variables: { id: fixture.id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <Loading />
      </div>
    );
  }

  const teams = data.fixture.roles.filter((r) => r.type.name === "PARTICIPANT");
  const homeTeam = teams.find((r) => !r.order);
  const awayTeam = teams.find((r) => r.order === 1);
  // const homeLineupRoles = data.fixture.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization?.id === homeTeam.organization.id
  // );
  // const awayLineupRoles = data.fixture.roles.filter(
  //   (r) =>
  //     r.type.name === "LINEUP PLAYER" &&
  //     r.organization?.id === awayTeam.organization.id
  // );

  return (
    <div className="page">
      <div className="section">
        <LineupStatsList
          fixtureRole={homeTeam}
          stats={data.fixture.stats.filter(
            (r) => r.organization?.id === homeTeam.organization?.id
          )}
          selectionSet={selectionSet}
          fixtureId={fixture.id}
          statTypes={data.fixture.event.sport?.statTypes}
          lineupRoles={data.fixture.roles.filter(
            (r) =>
              r.organization?.id === homeTeam.organization?.id &&
              r.type.name === "LINEUP PLAYER"
          )}
          refetch={refetch}
        />
      </div>
      <div className="section">
        <LineupStatsList
          fixtureRole={awayTeam}
          stats={data.fixture.stats.filter(
            (r) => r.organization?.id === awayTeam.organization?.id
          )}
          selectionSet={selectionSet}
          fixtureId={fixture.id}
          statTypes={data.fixture.event.sport?.statTypes}
          lineupRoles={data.fixture.roles.filter(
            (r) =>
              r.organization?.id === awayTeam.organization?.id &&
              r.type?.name === "LINEUP PLAYER"
          )}
          refetch={refetch}
        />
      </div>
    </div>
  );
}

export default FixtureStats;

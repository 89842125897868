import "./DeleteFixture.css";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { DELETE_FIXTURE } from "../../../api/fixtures";
import { useHistory } from "react-router-dom";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { Loader } from "../../common";

export default function DeleteFixture({ id, ev }) {
  const history = useHistory();
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");
  const [fixtureId, setFixtureId] = useState("");

  const [mutate, mutation] = useMutation(DELETE_FIXTURE, {
    update: (cache, { data: { deleteFixture } }) => {
      history.replace("/v2/events/view/" + ev?.id + "?t=fixtures");
      cache.modify({
        id: cache.identify(ev),
        fields: {
          fixtures(existingRefs, { readField }) {
            return [
              ...existingRefs.filter((ref) => readField("id", ref) !== id),
            ];
          },
        },
      });
      setAppState({ modal: false });

      //   history.push("/app/event/" + createEvent.id);
    },
    onError: (error) => {
      setError(error.message);
      console.log(error);
    },
  });

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">
          Confirm Delete Fixture
        </div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!id || !fixtureId || fixtureId !== id) {
            setError("Invalid fixture Id.");
            return;
          }

          mutate({
            variables: {
              id,
            },
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Are you sure you want to delete this fixture? This will delete all
            stats, photos, videos, albums and posts related to this fixture.
            Please confirm by typing the following ID in the form below:{" "}
            <span className="bold" style={{ fontWeight: "700" }}>
              {id}
            </span>
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="text"
              value={fixtureId}
              onChange={(e) => {
                setFixtureId(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__delete-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "CONFIRM"}
        </button>
      </form>
    </div>
  );
}

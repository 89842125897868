import Admins from "../../componentsV2/accessControl";
import "./AccessControl.css";

export default function AccessControl() {
  return (
    <div className="App-view-page">
      <div className="App-view-page__header">
        {/* <div
      className="App-view-page__header__back-btn"
      onClick={() => {
        history.push("/v2/events");
      }}
    >
      <ArrowLeftIcon className="App-view-page__header__back-btn__icon" />
    </div> */}
        <div className="App-view-page__header__title">Access Control</div>
        {/* <div className="App-view-page__header__options">
      <Button
        label={"DELETE EVENT"}
        className="App-view-page__header__options__delete"
        onClick={() => {
          // setAppState({ modal: <CreateEvent /> });
        }}
      />
    </div> */}
      </div>

      {/* <div className="App-view-page__tabs">
    {tabs.map((t, i) => (
      <div
        key={"event-tab-" + i}
        className={
          "App-view-page__tabs__item " +
          (selectedTab.key === t.key ? "selected" : "")
        }
        onClick={() => {
          history.replace(location.pathname + "?t=" + t.key);
        }}
      >
        {t.label}
      </div>
    ))}
  </div> */}

      <div className="App-view-page__body">
        <div className="access-control-page">
          <Admins />
        </div>
        {/* <SelectedComponent ev={ev} /> */}
      </div>
    </div>
  );
}

import Papa from "papaparse";

const exportTemplate = ({ headers, type }) => {
  const csv = Papa.unparse({
    fields: headers.map((f) => f.label),
    data: [],
  });

  const filename = type + ".csv";
  let csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  let csvURL = window.URL.createObjectURL(csvData);
  let tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", filename);
  tempLink.click();
};

export default exportTemplate;

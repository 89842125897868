import EventFixturesList from "../EventFixturesList";
import EventParticipantsList from "../EventParticipantsList";
import "./EventFixtures.css";

export default function EventFixtures({ ev }) {
  return (
    <div className="App-grid event-fixtures-page">
      <EventParticipantsList ev={ev} />
      <EventFixturesList ev={ev} />
    </div>
  );
}

import { gql } from "@apollo/client";

export function PROFILE_MERGES({ selectionSet = `` }) {
  return gql`
    query profileMerges($cursor:ID) {
        profileMerges(cursor:$cursor) {
        id
        ${selectionSet}
      }
    }
  `;
}

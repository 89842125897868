import "./SportPositionTypes.css";
import SportPositionTypesList from "../SportPositionTypesList";
import SportPositionTypeGroups from "../SportPositionTypeGroups";

export default function SportPositionTypes({ sport }) {
  return (
    <div className="App-grid sport-positions-page">
      <SportPositionTypeGroups sport={sport} />
      <SportPositionTypesList sport={sport} />
    </div>
  );
}

import { gql } from "@apollo/client";

const CREATE_SPORT = gql`
  mutation createSport($name: String!) {
    createSport(name: $name) {
      id
      name
    }
  }
`;

export default CREATE_SPORT;

import { gql } from "@apollo/client";

const ORGANIZATION = gql`
  query organization($id: ID!) {
    organization(id: $id) {
      id
      name
      mapsUrl
      contactMechanisms {
        id
        type
        subtype
        value
        areaCode
        countryCode
      }
      types {
        id
        name
      }
      members {
        id
        profile {
          id
          name
        }
        user {
          id
          name
        }
        fromDate
        toDate
        role
        isAdmin
      }
      eventRoles {
        id
        type {
          id
          name
        }
        event {
          id
          name
        }
      }
    }
  }
`;

export default ORGANIZATION;

import { gql } from "@apollo/client";

const CREATE_EVENT = gql`
  mutation createEvent($data: CreateEventInput!) {
    createEvent(data: $data) {
      id
      name
      organizedBy {
        id
        name
      }
      profilePhoto {
        id
        filename
      }
    }
  }
`;

export default CREATE_EVENT;

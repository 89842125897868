import "./LocationSearch.css";
import { useState } from "react";
import { usePopper } from "react-popper";
import ClickOutside from "../ClickOutside";
import Spinner from "../Spinner";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function LocationSearch({
  className,
  inputClassName,
  onItemSelect,
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  return (
    <PlacesAutocomplete
      value={searchTerm}
      onChange={(x) => {
        setSearchTerm(x);
      }}
      onSelect={(loc) => {
        const city = loc?.split(",")[0]?.trim();
        const state = loc?.split(",")[1]?.trim();
        const country = loc?.split(",")[2]?.trim();
        geocodeByAddress(loc)
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            onItemSelect(city, state, country, latLng.lat, latLng.lng);
          })
          .catch((error) => console.error("Error", error));
      }}
      debounce={200}
      searchOptions={{
        types: ["(cities)"],
        componentRestrictions: {
          country: "IN",
        },
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: "relative" }}>
          <div className={className} ref={setReferenceElement}>
            <input
              {...getInputProps({
                className: inputClassName,
                autocomplete: "off",
              })}
              type="text"
              onFocus={() => {
                setShowMenu(true);
              }}
              value={searchTerm}
              id="location-search"
            />
          </div>

          {showMenu && (
            <ClickOutside
              onClickOutside={() => {
                setShowMenu(false);
                setSearchTerm("");
              }}
            >
              {(wrapperRef) => (
                <div
                  ref={setPopperElement}
                  style={{ ...styles.popper, zIndex: 5, width: "100%" }}
                  {...attributes.popper}
                >
                  <div className={"location-search__menu"} ref={wrapperRef}>
                    {loading && (
                      <div className="location-search__menu__loading">
                        <Spinner size={12} />
                      </div>
                    )}

                    {suggestions
                      .filter(
                        (suggestion) =>
                          suggestion?.description?.split(",")?.length &&
                          suggestion?.description?.split(",").length > 2
                      )
                      .map((suggestion, i) => (
                        <div
                          key={"city-suggestion-" + i}
                          {...getSuggestionItemProps(suggestion, {
                            className: "location-search__menu__item",
                          })}
                        >
                          {suggestion.description}
                        </div>
                      ))}

                    <div ref={setArrowElement} style={styles.arrow} />
                  </div>
                </div>
              )}
            </ClickOutside>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
}

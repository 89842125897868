import { gql } from "@apollo/client";

export function SPORT({ selectionSet }) {
  return gql`
    query sport($id: ID!) {
      sport(id: $id) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function SPORTS() {
  return gql`
    query sports {
      sports {
        id
        name
      }
    }
  `;
}

export function UPDATE_SPORT({ selectionSet }) {
  return gql`
    mutation updateSport($where: UniqueInput!, $data:UpdateSportInput!) {
      updateSport(where:$where, data:$data) {
        id
        ${selectionSet}
      }
    }
  `;
}

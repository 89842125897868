import { gql } from "@apollo/client";

const SEARCH = gql`
  query search(
    $term: String!
    $disableProfiles: Boolean
    $disableOrganizations: Boolean
    $disableEvents: Boolean
    $take: Int
    $cursor: ID
  ) {
    search(
      term: $term
      disableProfiles: $disableProfiles
      disableOrganizations: $disableOrganizations
      disableEvents: $disableEvents
      take: $take
      cursor: $cursor
    ) {
      profiles {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
        user {
          id
        }
      }
      organizations {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
      events {
        id
        name
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default SEARCH;

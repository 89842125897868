import SportDetails from "./SportDetails";
import SportFormats from "./SportFormats";

function SportInfo({ sport }) {
  return (
    <div className="page">
      <div className="section">
        <SportDetails id={sport.id} />
      </div>
      <div className="section">
        <SportFormats id={sport.id} />
      </div>
    </div>
  );
}

export default SportInfo;

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import Loading from "../../Loading";
import { useQuery, useMutation } from "@apollo/client";
import ENQUIRY from "../../../api/queries/enquiry";
import UPDATE_EVENT_ENQUIRY from "../../../api/mutations/updateEventEnquiry";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes, FaTrash } from "react-icons/fa";
import "../../../styles/EnquiryNotes.css";
import { Fragment } from "react";

function EnquiryNotes({ id }) {
  const [updateEventEnquiry, { loading: deleting }] = useMutation(
    UPDATE_EVENT_ENQUIRY
  );
  const { loading, error, data } = useQuery(ENQUIRY, { variables: { id } });
  // const loading = true;

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const enquiry = data.enquiry;

  return (
    <div className="card">
      <div className="card-title">Enquiry Notes</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {enquiry.notes.map((n) => (
              <div className="note">
                <div className="meta">
                  <div className="text">{n.text}</div>
                  <div className="createdAt">
                    Created at {moment(n.createdAt).format("Do MMM YYYY HH:mm")}
                  </div>
                </div>
                <div
                  className="option delete hover-pointer"
                  onClick={() => {
                    updateEventEnquiry({
                      variables: {
                        where: { id },
                        data: {
                          notes: {
                            deleteMany: [{ id: n.id }],
                          },
                        },
                      },
                      update: (cache, { data: { updateEventEnquiry } }) => {
                        const q = cache.readQuery({
                          query: ENQUIRY,
                          variables: { id: id },
                        });
                        cache.writeQuery({
                          query: ENQUIRY,
                          variables: { id: id },
                          data: {
                            enquiry: {
                              ...q.enquiry,
                              notes: updateEventEnquiry.notes,
                            },
                          },
                        });
                      },
                    });
                  }}
                >
                  <FaTrash />
                </div>
              </div>
            ))}
          </div>
          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{ text: "" }}
                  fields={[
                    {
                      label: "Add note",
                      type: "text",
                      key: "text",
                    },
                  ]}
                  mutation={UPDATE_EVENT_ENQUIRY}
                  update={(cache, { data: { updateEventEnquiry } }) => {
                    const q = cache.readQuery({
                      query: ENQUIRY,
                      variables: { id: id },
                    });
                    cache.writeQuery({
                      query: ENQUIRY,
                      variables: { id: id },
                      data: {
                        enquiry: {
                          ...q.enquiry,
                          notes: updateEventEnquiry.notes,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => ({
                    where: { id: id },
                    data: {
                      notes: {
                        create: {
                          text: s.text,
                        },
                      },
                    },
                  })}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add Note
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default EnquiryNotes;

import { gql } from "@apollo/client";

const PROFILE_CLAIMS = gql`
  query profile($id: ID!) {
    profile(id: $id) {
      id
      name
      email
      phone

      claims {
        id
        user {
          id
          name
          email
        }
      }
    }
  }
`;

export default PROFILE_CLAIMS;

import "./ViewOrganization.css";
import { Button, Spinner } from "../../common";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useRouterQuery from "../../../utils/useRouterQuery";
import { ORGANIZATION } from "../../../api/organizations";
import { useQuery } from "@apollo/client";
import OrganizationInfo from "../OrganizationInfo";

const tabs = [
  { key: "info", label: "Info", component: OrganizationInfo },
  // {
  //   key: "registrations",
  //   label: "Registrations",
  //   component: EventRegistrations,
  // },
  // { key: "fixtures", label: "Fixtures", component: EventFixtures },
  // { key: "upload", label: "Upload Stats", component: UploadEventStats },
];

export default function ViewEvent() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = useRouterQuery();
  const tab = query.get("t") || "info";
  const selectedTab = tabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const { loading, error, data } = useQuery(
    ORGANIZATION({
      selectionSet: `
    name
    handle
    profilePhoto{
      id
      filename
    }
    
  `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const org = data?.organization;

  return (
    <div className="App-view-page">
      <div className="App-view-page__header">
        <div
          className="App-view-page__header__back-btn"
          onClick={() => {
            history.push("/v2/directory");
          }}
        >
          <ArrowLeftIcon className="App-view-page__header__back-btn__icon" />
        </div>
        <div className="App-view-page__header__title">{org.name}</div>
        <div className="App-view-page__header__options">
          <Button
            label={"DELETE ORGANIZATION"}
            className="App-view-page__header__options__delete"
            onClick={() => {
              // setAppState({ modal: <CreateEvent /> });
            }}
          />
        </div>
      </div>

      <div className="App-view-page__tabs">
        {tabs.map((t, i) => (
          <div
            key={"event-tab-" + i}
            className={
              "App-view-page__tabs__item " +
              (selectedTab.key === t.key ? "selected" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>

      <div className="App-view-page__body">
        <SelectedComponent org={org} />
      </div>
    </div>
  );
}

import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import { Switch, Route, Link, useHistory, Redirect } from "react-router-dom";
import { AppLayout, AppLayoutV2 } from "./layouts";
import { ProtectedRoute } from "./componentsV2/common";
import Auth from "./screens/Auth";
import Logout from "./screens/Logout";
import { Quill } from "react-quill";
import QuillMention from "quill-mention";
import quillEmoji from "quill-emoji";

Quill.register("modules/mentions", QuillMention);
Quill.register(
  {
    "formats/emoji": quillEmoji.EmojiBlot,
    "modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
    "modules/emoji-textarea": quillEmoji.TextAreaEmoji,
    "modules/emoji-shortname": quillEmoji.ShortNameEmoji,
  },
  true
);

function App() {
  return (
    <Switch>
      <Route path="/auth" component={Auth} />
      <Route path="/logout" component={Logout} />
      <ProtectedRoute path="/v2" component={AppLayoutV2} />
      <Route path="/v1" component={AppLayout} />
      <Route exact path="/">
        <Redirect
          to={{
            pathname: "/v2/events",
          }}
        />
      </Route>
    </Switch>
  );
}

export default App;

import "./CreateEvent.css";
import { useState } from "react";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import {
  Loader,
  LocationSearch,
  SearchWithDropdown,
  Spinner,
} from "../../common";
import { useAppState } from "../../../utils/appState";
import { SPORTS } from "../../../api/sports";
import { EVENTS } from "../../../api/events";
import { EVENT_TYPES } from "../../../api/queries";
import { CREATE_EVENT } from "../../../api/mutations";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";

export default function CreateEvent() {
  const [, setAppState] = useAppState();
  const [error, setError] = useState("");
  const [name, setName] = useState("");
  const [organizedBy, setOrganizedBy] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [lat, setLat] = useState("");
  const [lng, setLng] = useState("");
  const [sport, setSport] = useState("");
  const [eventType, setEventType] = useState("");
  const [participantType, setParticipantType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");

  const sportsQuery = useQuery(SPORTS());
  const eventTypesQuery = useQuery(EVENT_TYPES);

  const [mutate, mutation] = useMutation(CREATE_EVENT, {
    update: (cache, { data: { createEvent } }) => {
      const q = cache.readQuery({
        query: EVENTS(),
        variables: { where: {} },
      });

      if (q?.events) {
        cache.writeQuery({
          query: EVENTS(),
          variables: { where: {} },
          data: {
            events: [createEvent, ...q.events],
          },
        });
      }

      setAppState({ modal: false });

      //   history.push("/app/event/" + createEvent.id);
    },
    onError: (error) => {
      setError(error.message);
      console.log(error);
    },
  });

  const loading =
    sportsQuery.loading ||
    !sportsQuery.data ||
    eventTypesQuery.loading ||
    !eventTypesQuery.data;

  if (loading) {
    return (
      <div className="App-modal-form">
        <div className="App-modal-form__header">
          <div className="App-modal-form__header__title">Create Event</div>

          <div
            className="App-modal-form__header__dismiss"
            onClick={() => {
              setAppState({ modal: false });
            }}
          >
            <TimesIcon className="App-modal-form__header__dismiss__icon" />
          </div>
        </div>

        <div className="App-modal-form__body">
          <div
            style={{
              height: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  const sports = sportsQuery?.data?.sports;
  const eventTypes = eventTypesQuery?.data?.eventTypes;
  const participantTypes = [
    { label: "Teams", value: "TEAM" },
    { label: "Individuals", value: "INDIVIDUAL" },
  ];

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">Create Event</div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (
            !name ||
            !organizedBy?.id ||
            !city ||
            !state ||
            !country ||
            !lat ||
            !lng ||
            !sport ||
            !eventType ||
            !participantType
          ) {
            setError("Please fill out all required fields.");
            return;
          }

          const vars = {
            data: {
              name,
              organizedBy: { connect: { id: organizedBy.id } },
              city,
              state,
              country,
              lat,
              lng,
              sport: { connect: { id: sport } },
              type: { connect: { id: eventType } },
              participantType: participantType,
            },
          };

          if (startDate) {
            vars.data.scheduledStart = moment(startDate).toISOString();
          }

          if (endDate) {
            vars.data.scheduledEnd = moment(endDate).toISOString();
          }

          if (registrationStartDate) {
            vars.data.registrationStart = moment(
              registrationStartDate
            ).toISOString();
          }

          if (registrationEndDate) {
            vars.data.registrationEnd =
              moment(registrationEndDate).toISOString();
          }

          mutate({
            variables: vars,
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Name<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Organized By<span className="required">*</span>
          </div>

          {organizedBy ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setOrganizedBy("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {organizedBy.name}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.organizations || []}
              onItemSelect={(item) => {
                setOrganizedBy(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Location<span className="required">*</span>
          </div>

          {city ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setCity("");
                  setState("");
                  setCountry("");
                  setLat("");
                  setLng("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {city}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <LocationSearch
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              onItemSelect={(city, state, country, lat, lng) => {
                console.log(city, state, country, lat, lng);
                setCity(city);
                setState(state);
                setCountry(country);
                setLat(lat);
                setLng(lng);
              }}
            />
          )}
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Sport<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <select
              className="App-modal-form__body__group__input__select"
              value={sport}
              onChange={(e) => {
                setSport(e.target.value);
              }}
            >
              <option value=""></option>
              {sports.map((s) => (
                <option key={s.id} value={s.id}>
                  {s.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Event Type<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <select
              className="App-modal-form__body__group__input__select"
              value={eventType}
              onChange={(e) => {
                setEventType(e.target.value);
              }}
            >
              <option value=""></option>
              {eventTypes.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Participant Type<span className="required">*</span>
          </div>

          <div className="App-modal-form__body__group__input">
            <select
              className="App-modal-form__body__group__input__select"
              value={participantType}
              onChange={(e) => {
                setParticipantType(e.target.value);
              }}
            >
              <option value=""></option>
              {participantTypes.map((p) => (
                <option key={p.value} value={p.value}>
                  {p.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">Start Date</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={startDate}
              onChange={(e) => {
                setStartDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">End Date</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={endDate}
              onChange={(e) => {
                setEndDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Registration Start Date
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={registrationStartDate}
              onChange={(e) => {
                setRegistrationStartDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Registration End Date
          </div>

          <div className="App-modal-form__body__group__input">
            <input
              type="date"
              value={registrationEndDate}
              onChange={(e) => {
                setRegistrationEndDate(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

import "./EventInfo.css";
import EventDetails from "../EventDetails";

export default function EventInfo({ ev }) {
  return (
    <div className="App-grid event-info-page">
      <EventDetails ev={ev} />
    </div>
  );
}

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import Loading from "../../Loading";
import { useQuery, useMutation } from "@apollo/client";
import ENQUIRY from "../../../api/queries/enquiry";
import ORGANIZATIONS from "../../../api/queries/organizations";
import UPDATE_EVENT_ENQUIRY from "../../../api/mutations/updateEventEnquiry";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes, FaTrash } from "react-icons/fa";
import "../../../styles/EnquiryNotes.css";
import { Fragment } from "react";

function EnquiryRegistration({ id }) {
  const [updateEventEnquiry, { loading: deleting }] = useMutation(
    UPDATE_EVENT_ENQUIRY
  );
  const { loading, error, data } = useQuery(ENQUIRY, { variables: { id } });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const enquiry = data.enquiry;

  return (
    <div className="card">
      <div className="card-title">Enquiry Registration</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {enquiry.registration ? (
              <div className="enquiry-registration">
                <div className="title">
                  A registeration associated with this enquiry has been created
                  for the event {enquiry.registration.event.name}
                </div>
              </div>
            ) : (
              <div>No associated registration. </div>
            )}
          </div>
          <div className="card-footer">
            {enquiry.registration ? (
              <div
                className={"App-btn danger hover-pointer "}
                onClick={() => {
                  updateEventEnquiry({
                    variables: {
                      where: { id },
                      data: {
                        registration: { delete: true },
                      },
                    },
                    update: (cache, { data: updateEventEnquiry }) => {
                      const q = cache.readQuery({
                        query: ENQUIRY,
                        variables: { id: id },
                      });
                      cache.writeQuery({
                        query: ENQUIRY,
                        variables: { id: id },
                        data: {
                          enquiry: {
                            ...q.enquiry,
                            registration: updateEventEnquiry.registration,
                          },
                        },
                      });
                    },
                  });
                }}
              >
                Delete Registration
              </div>
            ) : (
              <Editable
                form={(setEditing) => (
                  <Form
                    className={"editable-field-form column"}
                    initialState={{ organization: "" }}
                    fields={[
                      {
                        label: "Team",
                        type: "select",
                        key: "organization",
                        query: ORGANIZATIONS,
                        variables: { where: { types: ["Team"] } },
                        items: (d) =>
                          d.organizations.map((o) => ({ ...o, id: o.name })),
                        optionLabel: (i) => i.name,
                      },
                      {
                        label: "Paid",
                        type: "select",
                        key: "paid",
                        items: [
                          { label: "Yes", value: true },
                          { label: "No", value: false },
                        ],
                      },
                      {
                        label: "Amount Paid",
                        type: "number",
                        key: "amountPaid",
                      },
                    ]}
                    mutation={UPDATE_EVENT_ENQUIRY}
                    update={(cache, { data: { updateEventEnquiry } }) => {
                      const q = cache.readQuery({
                        query: ENQUIRY,
                        variables: { id: id },
                      });
                      cache.writeQuery({
                        query: ENQUIRY,
                        variables: { id: id },
                        data: {
                          enquiry: {
                            ...q.enquiry,
                            registration: updateEventEnquiry.registration,
                          },
                        },
                      });

                      setEditing(false);
                    }}
                    vars={(s) => {
                      const v = {
                        where: { id: id },
                        data: {
                          registration: {
                            create: {
                              event: { connect: { id: enquiry.event.id } },
                              profile: {
                                connectOrCreate: {
                                  where: { email: enquiry.email },
                                  create: {
                                    name: enquiry.name,
                                    email: enquiry.email,
                                    phone: enquiry.phone,
                                  },
                                },
                              },
                              paid: s.paid === "true" ? true : false,
                              amountPaid: Number(s.amountPaid),
                            },
                          },
                        },
                      };

                      if (s.organization) {
                        v.data.registration.create.organization = {
                          connectOrCreate: {
                            where: { name: s.organization },
                            create: {
                              name: s.organization,
                            },
                          },
                        };
                      }

                      return v;
                    }}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setEditing(false);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                )}
                content={(setEditing) => (
                  <div
                    className={"App-btn success hover-pointer "}
                    onClick={() => {
                      setEditing(true);
                    }}
                  >
                    Add Registration
                  </div>
                )}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default EnquiryRegistration;

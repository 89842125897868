import { useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import ACCEPT_INVITE from "../api/mutations/acceptInvite";
import "../styles/AcceptInvite.css";

function AcceptInvite() {
  const history = useHistory();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [acceptInvite, { loading }] = useMutation(ACCEPT_INVITE, {
    update: (cache, { data: { acceptInvite } }) => {
      if (!acceptInvite.token || !acceptInvite.expiresAt) {
        setError(true);
        return;
      }

      localStorage.setItem("sp_auth_token", acceptInvite.token);
      localStorage.setItem("sp_expires_at", acceptInvite.expiresAt);
      localStorage.setItem("sp_name", acceptInvite.name);

      history.push("/");
    },
    onError: (e) => {
      console.log(e);
    },
  });

  return (
    <div className="AcceptInvite">
      <form
        className="form"
        onSubmit={(e) => {
          e.preventDefault();
          if (!token || !password) {
            setError(true);
            return;
          }
          acceptInvite({
            variables: {
              token,
              password,
            },
          });
        }}
      >
        <div className="title">
          Please enter a new password to create your account.
        </div>
        <input
          type="password"
          value={password}
          placeholder="Enter Password"
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />
        {error && <div className="error">Invalid token or password.</div>}
        <button type="submit">Create Account</button>
      </form>
    </div>
  );
}

export default AcceptInvite;

import { gql } from "@apollo/client";

export function PROFILE({ selectionSet }) {
  return gql`
      query profile($id: ID!) {
        profile(id: $id) {
          id
          ${selectionSet}
        }
      }
    `;
}

export function PROFILES() {
  return gql`
    query profiles($where: ProfileWhereInput, $cursor: ID) {
      profiles(where: $where, cursor: $cursor) {
        id
        name
        email
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  `;
}

export function UPDATE_PROFILE({ selectionSet }) {
  return gql`
    mutation updateProfile($where: UniqueInput!, $data:UpdateProfileInput!) {
      updateProfile(where:$where, data:$data) {
        id
        ${selectionSet}
      }
    }
  `;
}

export function CREATE_PROFILE_MERGE() {
  return gql`
    mutation createProfileMerge(
      $profileToKeepId: ID!
      $profileToDeleteId: ID!
    ) {
      createProfileMerge(
        profileToKeepId: $profileToKeepId
        profileToDeleteId: $profileToDeleteId
      ) {
        id
      }
    }
  `;
}

export function MERGE() {
  return gql`
    mutation merge(
      $profileToKeepId: ID!
      $profileToDeleteId: ID!
      $mergeId: ID!
    ) {
      merge(
        profileToKeepId: $profileToKeepId
        profileToDeleteId: $profileToDeleteId
        mergeId: $mergeId
      ) {
        success
        error
      }
    }
  `;
}

import { useQuery } from "@apollo/client";
import {
  Enquiries,
  ProfileClaimsSummary,
  ReportedMediaSummary,
  ReportedPostsSummary,
  UnpublishRequestsSummary,
} from "../../componentsV2/dashboard";
import { Spinner } from "../../componentsV2/common";
import "./Dashboard.css";
import { DASHBOARD_STATS } from "../../api/dashboard";

export default function Dashboard() {
  const { loading, error, data } = useQuery(DASHBOARD_STATS);

  if (error) {
    console.log(error);
  }

  if (loading || !data) {
    return (
      <div className="App-loading-wrapper">
        <Spinner />
      </div>
    );
  }

  const stats = data?.dashboardStats;

  return (
    <div className="App-view-page">
      <div className="dashboard-page">
        <div className="dashboard-page__left-column">
          <Enquiries count={stats?.enquiries} />
        </div>

        <div className="dashboard-page__right-column">
          <ReportedMediaSummary count={stats?.media} />
          <ReportedPostsSummary count={stats?.posts} />
          <ProfileClaimsSummary count={stats?.claims} />
          <UnpublishRequestsSummary count={stats?.unpublish} />
        </div>
      </div>
    </div>
  );
}

import { gql } from "@apollo/client";

const LOADING = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      expiresAt
      name
    }
  }
`;

export default LOADING;

import "./ProfileInfo.css";
// import EventDetails from "../EventDetails";
import ProfileDetails from "../ProfileDetails";

export default function ProfileInfo({ profile }) {
  return (
    <div className="App-grid event-info-page">
      <ProfileDetails profile={profile} />
    </div>
  );
}

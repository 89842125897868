import { useState } from "react";
import "../styles/Editable.css";

function Editable({ form, content }) {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return form(setEditing);
  } else {
    return content(setEditing);
  }
}

export default Editable;

import FixtureDetails from "./FixtureDetails";
import FixtureLegs from "./FixtureLegs";
import FixturePeriods from "./FixturePeriods";
import FixtureParticipants from "./FixtureParticipants";
import FixtureResults from "./FixtureResults";
import UnpublishFixture from "./UnpublishFixture";

function FixtureInfo({ fixture }) {
  return (
    <div className="page">
      <div className="section">
        <FixtureDetails id={fixture.id} />
        {fixture.totalLegs && fixture.totalLegs > 1 ? (
          <FixtureLegs id={fixture.id} />
        ) : (
          <FixturePeriods id={fixture.id} />
        )}
      </div>
      <div className="section">
        <FixtureParticipants id={fixture.id} />
        <FixtureResults id={fixture.id} />
        <UnpublishFixture id={fixture.id} />
      </div>
    </div>
  );
}

export default FixtureInfo;

import { gql } from "@apollo/client";

const DELETE_PROFILE = gql`
  mutation deleteProfile($id: ID!) {
    deleteProfile(id: $id) {
      id
    }
  }
`;

export default DELETE_PROFILE;

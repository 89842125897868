import "./SportPositionTypesList.css";
import { useQuery } from "@apollo/client";
import { SPORT } from "../../../api/sports";
import { Card, CardTitle, Spinner } from "../../common";
import { Fragment } from "react";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import CreatePositionType from "../CreatePositionType";
import { useAppState } from "../../../utils/appState";
import EditPositionType from "../EditPositionType";
import DeletePositionType from "../DeletePositionType";

function PositionType({ sport, type, groups }) {
  const [, setAppState] = useAppState();
  return (
    <div className="sport-position-types__groups__group__items__item">
      <div className="sport-position-types__groups__group__items__item__text">
        <div className="sport-position-types__groups__group__items__item__text__primary">
          {type?.name}
        </div>

        <div className="sport-position-types__groups__group__items__item__text__secondary">
          {type?.shortName}
        </div>
      </div>

      <div className="sport-position-types__groups__group__items__item__options">
        <div
          className="sport-position-groups__items__item__options__option edit"
          onClick={() => {
            setAppState({
              modal: (
                <EditPositionType pos={type} sport={sport} groups={groups} />
              ),
            });
            // mutate({
            //   variables: {
            //     where: { id: ev.id },
            //     data: {
            //       roles: {
            //         deleteMany: [{ id: role.id }],
            //       },
            //     },
            //   },
            // });
          }}
        >
          <PencilIcon className="sport-position-groups__items__item__options__option__icon" />
        </div>
        <div
          className={
            "sport-position-types__groups__group__items__item__options__option delete " +
            (type.canDelete ? "" : "disabled")
          }
          onClick={() => {
            setAppState({
              modal: <DeletePositionType sport={sport} pos={type} />,
            });
            // mutate({
            //   variables: {
            //     where: { id: ev.id },
            //     data: {
            //       roles: {
            //         deleteMany: [{ id: role.id }],
            //       },
            //     },
            //   },
            // });
          }}
        >
          <TrashIcon className="sport-position-types__groups__group__items__item__options__option__icon" />
        </div>
      </div>
    </div>
  );
}

function PositionTypeGroup({ sport, group, types, groups }) {
  return (
    <div className="sport-position-types__groups__group">
      <div className="sport-position-types__groups__group__title">
        {group.name}
      </div>

      <div className="sport-position-types__groups__group__items">
        {!types?.length && (
          <div
            className="App-loading-wrapper"
            style={{ color: "var(--light-4)" }}
          >
            No positions added.
          </div>
        )}
        {types.map((type) => (
          <PositionType sport={sport} type={type} groups={groups} />
        ))}
      </div>
    </div>
  );
}

export default function SportPositionTypesList({ sport }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet: `
      positionTypes{
        id
        name
        shortName
        group{
          id
          name
        }
        canDelete
      }
      positionTypeGroups{
        id
        name
      }
  `,
    }),
    {
      variables: {
        id: sport.id,
      },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  const groups = data?.sport?.positionTypeGroups || [];
  const types = data?.sport?.positionTypes || [];

  return (
    <Card className="sport-position-types">
      <CardTitle
        title="POSITIONS"
        options={
          <div
            className="sport-position-types__add-btn"
            onClick={() => {
              setAppState({
                modal: (
                  <CreatePositionType sport={data?.sport} groups={groups} />
                ),
              });
            }}
          >
            + ADD
          </div>
        }
      />

      <div className="sport-position-types__groups">
        {loading || !data ? (
          <div className="App-loading-wrapper">
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {groups.map((group) => (
              <PositionTypeGroup
                sport={sport}
                group={group}
                types={types.filter((t) => t.group.id === group.id)}
                groups={groups}
              />
            ))}
          </Fragment>
        )}
      </div>
    </Card>
  );
}

import ModalComponent from "./ModalComponent";
import Form from "./Form";
import INVITE_ADMIN from "../api/mutations/inviteAdmin";
import { useAppState } from "../utils/appState";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function InviteAdmin() {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={INVITE_ADMIN}
            initialState={{ name: "", email: "", phone: "" }}
            fields={[
              { label: "Name", type: "text", key: "name" },
              { label: "Email", type: "text", key: "email" },
              { label: "Phone", type: "text", key: "phone" },
            ]}
            update={(cache, { data: { inviteAdmin } }) => {
              // console.log(createSport);
              // const q = cache.readQuery({ query: SPORTS });
              // cache.writeQuery({
              //   query: SPORTS,
              //   data: {
              //     sports: {
              //       createSport,
              //       ...q.sports,
              //     },
              //   },
              // });
              if (inviteAdmin.success) {
                toast("Invitation Sent!");
                setAppState({ modal: false });
              }
            }}
            vars={(s) => ({
              name: s.name,
              email: s.email,
              phone: s.phone,
            })}
            title={"New Sport"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default InviteAdmin;

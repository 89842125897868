import EnquiryDetails from "./EnquiryDetails";
import EnquiryRegistration from "./EnquiryRegistration";
import EnquiryNotes from "./EnquiryNotes";

function EnquiryInfo({ enquiry }) {
  return (
    <div className="page">
      <div className="section">
        <EnquiryDetails id={enquiry.id} />
        {/**/}
      </div>
      <div className="section">
        {<EnquiryNotes id={enquiry.id} />}
        <EnquiryRegistration id={enquiry.id} />
      </div>
    </div>
  );
}

export default EnquiryInfo;

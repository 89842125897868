import Editable from "../../Editable";
import StatefulView from "../../StatefulView";
import InfoField from "../../InfoField";
import { useQuery, useMutation } from "@apollo/client";
import { SPORT, UPDATE_SPORT } from "../../../api/sports";
import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import {
  FaTimes,
  FaPencilAlt,
  FaLink,
  FaTrash,
  FaInfoCircle,
} from "react-icons/fa";
import "../../../styles/StatTypeList.css";
import { useState } from "react";

import Loading from "../../Loading";
import { Fragment } from "react";

function RelationshipSection({
  sport,
  selectionSet,
  statType,
  title,
  description,
  type,
  relationships,
  button,
}) {
  const [showInfo, setShowInfo] = useState(false);
  const [updateSport, { loading }] = useMutation(
    UPDATE_SPORT({ selectionSet })
  );

  return (
    <div className="relationship-section">
      <div className="primary-text">
        {title}
        <div
          className="info-icon hover-pointer"
          onClick={() => {
            setShowInfo(!showInfo);
          }}
        >
          {showInfo ? "Hide Info" : <FaInfoCircle />}
        </div>
      </div>
      <div className={"secondary-text " + (!showInfo ? "hide" : "")}>
        {description}
      </div>
      <div className="list">
        {relationships.map((r) => (
          <div className="list-item">
            {r.label}
            <div
              className="delete-relationship"
              onClick={() => {
                const v = {};

                if (r.isFrom) {
                  v.relationshipsFrom = {
                    delete: { id: r.id },
                  };
                } else {
                  v.relationshipsTo = {
                    delete: { id: r.id },
                  };
                }

                updateSport({
                  variables: {
                    where: { id: sport.id },
                    data: {
                      statTypes: {
                        update: [{ where: { id: statType.id }, data: v }],
                      },
                    },
                  },
                  update: (cache, { data: { updateSport } }) => {
                    const q = cache.readQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id: sport.id },
                    });
                    cache.writeQuery({
                      query: SPORT({ selectionSet }),
                      variables: { id: sport.id },
                      data: {
                        sport: {
                          ...q.sport,
                          statTypes: updateSport.statTypes,
                        },
                      },
                    });
                  },
                });
              }}
            >
              <FaTrash />
            </div>
          </div>
        ))}
      </div>

      <div className="footer-options">
        <StatefulView
          states={[
            (setSelectedState) => {
              return (
                <div
                  className="App-btn success"
                  onClick={() => {
                    setSelectedState(1);
                  }}
                >
                  {button}
                </div>
              );
            },
            (setSelectedState) => (
              <Form
                className={"editable-field-form"}
                initialState={{ fromId: "" }}
                fields={[
                  {
                    label: "Select stat",
                    type: "select",
                    key: "fromId",
                    items: sport.statTypes.map((st) => ({
                      label: st.name,
                      value: st.id,
                    })),
                  },
                ]}
                mutation={UPDATE_SPORT({ selectionSet })}
                update={(cache, { data: { updateSport } }) => {
                  const q = cache.readQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id: sport.id },
                  });
                  cache.writeQuery({
                    query: SPORT({ selectionSet }),
                    variables: { id: sport.id },
                    data: {
                      sport: {
                        ...q.sport,
                        statTypes: updateSport.statTypes,
                      },
                    },
                  });

                  setSelectedState(0);
                }}
                vars={(s) => ({
                  where: { id: sport.id },
                  data: {
                    statTypes: {
                      update: [
                        {
                          where: { id: statType.id },
                          data:
                            type === "Sibling"
                              ? {
                                  relationshipsFrom: {
                                    create: {
                                      sport: {
                                        connect: { id: sport.id },
                                      },
                                      to: {
                                        connect: {
                                          id: s.fromId,
                                        },
                                      },
                                      type: {
                                        connect: {
                                          name: type,
                                        },
                                      },
                                    },
                                  },
                                }
                              : {
                                  relationshipsTo: {
                                    create: {
                                      sport: {
                                        connect: { id: sport.id },
                                      },
                                      from: {
                                        connect: {
                                          id: s.fromId,
                                        },
                                      },
                                      type: {
                                        connect: {
                                          name: type,
                                        },
                                      },
                                    },
                                  },
                                },
                        },
                      ],
                    },
                  },
                })}
                options={[
                  <div
                    className="option cancel"
                    onClick={() => {
                      setSelectedState(0);
                    }}
                  >
                    <FaTimes />
                  </div>,
                ]}
              />
            ),
          ]}
        />
      </div>
    </div>
  );
}

function StatTypeList({ id }) {
  const selectionSet = `
    statTypes{
      id
      name
      group{
        id
        name
      }
      relationshipsFrom{
        id
        from{
          id
          name
        }
        to{
          id
          name
        }
        type{
          id
          name
        }
      }
      relationshipsTo{
        id
        from{
          id
          name
        }
        to{
          id
          name
        }
        type{
          id
          name
        }
      }
    }
    statTypeGroups{
      id
      name
    }
  `;

  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet,
    }),
    { variables: { id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const sport = data?.sport;
  console.log("Types:", sport);

  return (
    <div className="card">
      <div className="card-title">Stats List</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {sport.statTypeGroups.map((stg) => (
              <div className="card-body-group">
                <div className="card-body-group-title">{stg.name}</div>
                {sport.statTypes
                  ?.filter((st) => st.group?.id === stg.id)
                  .map((st) => (
                    <StatefulView
                      states={[
                        (setSelectedState) => {
                          return (
                            <InfoField
                              options={[
                                <div
                                  className="option delete"
                                  onClick={() => {
                                    setSelectedState(2);
                                  }}
                                >
                                  <FaTrash />
                                </div>,
                                <div
                                  className="option outline"
                                  onClick={() => {
                                    setSelectedState(3);
                                  }}
                                >
                                  <FaLink />
                                </div>,
                                <div
                                  className="option edit"
                                  onClick={() => {
                                    setSelectedState(1);
                                  }}
                                >
                                  <FaPencilAlt />
                                </div>,
                              ]}
                              setSelectedState={setSelectedState}
                              label={""}
                              value={st.name}
                              required={false}
                            />
                          );
                        },
                        (setSelectedState) => {
                          return (
                            <Form
                              className={"editable-field-form"}
                              initialState={{
                                groupId: st.group.id,
                                name: st.name,
                              }}
                              fields={[
                                {
                                  label: "Group",
                                  type: "select",
                                  key: "groupId",
                                  items: sport.statTypeGroups.map((x) => ({
                                    label: x.name,
                                    value: x.id,
                                  })),
                                },
                                {
                                  label: "Stat Name",
                                  type: "text",
                                  key: "name",
                                },
                              ]}
                              mutation={UPDATE_SPORT({ selectionSet })}
                              update={(cache, { data: { updateSport } }) => {
                                const q = cache.readQuery({
                                  query: SPORT({ selectionSet }),
                                  variables: { id },
                                });
                                cache.writeQuery({
                                  query: SPORT({ selectionSet }),
                                  variables: { id },
                                  data: {
                                    sport: {
                                      ...q.sport,
                                      statTypes: updateSport.statTypes,
                                    },
                                  },
                                });

                                setSelectedState(0);
                              }}
                              vars={(s) => ({
                                where: { id },
                                data: {
                                  statTypes: {
                                    update: [
                                      {
                                        where: { id: st.id },
                                        data: {
                                          groupId: s.groupId,
                                          name: s.name,
                                        },
                                      },
                                    ],
                                  },
                                },
                              })}
                              options={[
                                <div
                                  className="option cancel"
                                  onClick={() => {
                                    setSelectedState(0);
                                  }}
                                >
                                  <FaTimes />
                                </div>,
                              ]}
                            />
                          );
                        },
                        (setSelectedState) => {
                          return (
                            <Form
                              className={"editable-field-form"}
                              initialState={{ statTypeId: "" }}
                              fields={[
                                {
                                  label: `Are you sure you want to delete this stat type? Type ${st.id} to confirm.`,
                                  type: "text",
                                  key: "statTypeId",
                                },
                              ]}
                              mutation={UPDATE_SPORT({ selectionSet })}
                              update={(cache, { data: { updateSport } }) => {
                                const q = cache.readQuery({
                                  query: SPORT({ selectionSet }),
                                  variables: { id },
                                });
                                cache.writeQuery({
                                  query: SPORT({ selectionSet }),
                                  variables: { id },
                                  data: {
                                    sport: {
                                      ...q.sport,
                                      statTypes: updateSport.statTypes,
                                    },
                                  },
                                });

                                setSelectedState(0);
                              }}
                              vars={(s) => ({
                                where: { id },
                                data: {
                                  statTypes: {
                                    deleteMany: [
                                      {
                                        id: s.statTypeId,
                                      },
                                    ],
                                  },
                                },
                              })}
                              options={[
                                <div
                                  className="option cancel"
                                  onClick={() => {
                                    setSelectedState(0);
                                  }}
                                >
                                  <FaTimes />
                                </div>,
                              ]}
                            />
                          );
                        },
                        (setSelectedState) => {
                          return (
                            <div className="stat-type-relationships-editor">
                              <div className="title">
                                Edit relationships for {st.name}{" "}
                                <div
                                  className="close-editor hover-pointer"
                                  onClick={() => {
                                    setSelectedState(0);
                                  }}
                                >
                                  <FaTimes />
                                </div>
                              </div>
                              <RelationshipSection
                                title={"Associated Types"}
                                sport={sport}
                                selectionSet={selectionSet}
                                statType={st}
                                type={"Sibling"}
                                relationships={[
                                  ...st.relationshipsFrom
                                    .filter((r) => r.type.name === "Sibling")
                                    .map((r) => ({
                                      ...r,
                                      label: r.to.name,
                                      isFrom: true,
                                    })),
                                ]}
                                button={"Add associated stat type"}
                                description={
                                  "Associated stat types are stat types that are usually collected together. When a stat is recorded, the user will also be prompted to input records for all associated stat types. Eg.,Goal+Assist, Sub On+Sub Off"
                                }
                              />

                              <RelationshipSection
                                title={"Parent Types"}
                                sport={sport}
                                selectionSet={selectionSet}
                                statType={st}
                                type={"Ancestor"}
                                relationships={st.relationshipsTo
                                  .filter((r) => r.type.name === "Ancestor")
                                  .map((r) => ({
                                    ...r,
                                    label: r.from.name,
                                    isFrom: false,
                                  }))}
                                button={"Add parent stat type"}
                                description={
                                  "If a stat has a parent stat type, then everytime a record is added for the stat, another record is automatically created for the parent stat type. Eg., The stat type 'Assist' has a parent stat type, 'Successful Pass'. Everytime an assist is marked for a player, the system will automatically add a succesful pass for the player."
                                }
                              />
                            </div>
                          );
                        },
                      ]}
                    />
                  ))}
              </div>
            ))}

            <div className="card-body-group">
              <div className="card-body-group-title">Ungrouped</div>
              {sport.statTypes
                ?.filter((st) => !st.group)
                .map((st) => (
                  <StatefulView
                    states={[
                      (setSelectedState) => {
                        return (
                          <InfoField
                            options={[
                              <div
                                className="option delete"
                                onClick={() => {
                                  setSelectedState(2);
                                }}
                              >
                                <FaTrash />
                              </div>,
                              <div
                                className="option outline"
                                onClick={() => {
                                  setSelectedState(3);
                                }}
                              >
                                <FaLink />
                              </div>,
                              <div
                                className="option edit"
                                onClick={() => {
                                  setSelectedState(1);
                                }}
                              >
                                <FaPencilAlt />
                              </div>,
                            ]}
                            setSelectedState={setSelectedState}
                            label={""}
                            value={st.name}
                            required={false}
                          />
                        );
                      },
                      (setSelectedState) => {
                        return (
                          <Form
                            className={"editable-field-form"}
                            initialState={{
                              groupId: st.group?.id,
                              name: st.name,
                            }}
                            fields={[
                              {
                                label: "Group",
                                type: "select",
                                key: "groupId",
                                items: sport.statTypeGroups.map((x) => ({
                                  label: x.name,
                                  value: x.id,
                                })),
                              },
                              {
                                label: "Stat Name",
                                type: "text",
                                key: "name",
                              },
                            ]}
                            mutation={UPDATE_SPORT({ selectionSet })}
                            update={(cache, { data: { updateSport } }) => {
                              const q = cache.readQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                              });
                              cache.writeQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                                data: {
                                  sport: {
                                    ...q.sport,
                                    statTypes: updateSport.statTypes,
                                  },
                                },
                              });

                              setSelectedState(0);
                            }}
                            vars={(s) => ({
                              where: { id },
                              data: {
                                statTypes: {
                                  update: [
                                    {
                                      where: { id: st.id },
                                      data: {
                                        groupId: s.groupId,
                                        name: s.name,
                                      },
                                    },
                                  ],
                                },
                              },
                            })}
                            options={[
                              <div
                                className="option cancel"
                                onClick={() => {
                                  setSelectedState(0);
                                }}
                              >
                                <FaTimes />
                              </div>,
                            ]}
                          />
                        );
                      },
                      (setSelectedState) => {
                        return (
                          <Form
                            className={"editable-field-form"}
                            initialState={{ statTypeId: "" }}
                            fields={[
                              {
                                label: `Are you sure you want to delete this stat type? Type ${st.id} to confirm.`,
                                type: "text",
                                key: "statTypeId",
                              },
                            ]}
                            mutation={UPDATE_SPORT({ selectionSet })}
                            update={(cache, { data: { updateSport } }) => {
                              const q = cache.readQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                              });
                              cache.writeQuery({
                                query: SPORT({ selectionSet }),
                                variables: { id },
                                data: {
                                  sport: {
                                    ...q.sport,
                                    statTypes: updateSport.statTypes,
                                  },
                                },
                              });

                              setSelectedState(0);
                            }}
                            vars={(s) => ({
                              where: { id },
                              data: {
                                statTypes: {
                                  deleteMany: [
                                    {
                                      id: s.statTypeId,
                                    },
                                  ],
                                },
                              },
                            })}
                            options={[
                              <div
                                className="option cancel"
                                onClick={() => {
                                  setSelectedState(0);
                                }}
                              >
                                <FaTimes />
                              </div>,
                            ]}
                          />
                        );
                      },
                      (setSelectedState) => {
                        return (
                          <div className="stat-type-relationships-editor">
                            <div className="title">
                              Edit relationships for {st.name}{" "}
                              <div
                                className="close-editor hover-pointer"
                                onClick={() => {
                                  setSelectedState(0);
                                }}
                              >
                                <FaTimes />
                              </div>
                            </div>
                            <RelationshipSection
                              title={"Associated Types"}
                              sport={sport}
                              selectionSet={selectionSet}
                              statType={st}
                              type={"Sibling"}
                              relationships={[
                                ...st.relationshipsFrom
                                  .filter((r) => r.type.name === "Sibling")
                                  .map((r) => ({
                                    ...r,
                                    label: r.to.name,
                                    isFrom: true,
                                  })),
                                ...st.relationshipsTo
                                  .filter((r) => r.type.name === "Sibling")
                                  .map((r) => ({
                                    ...r,
                                    label: r.from.name,
                                    isFrom: false,
                                  })),
                              ]}
                              button={"Add associated stat type"}
                              description={
                                "Associated stat types are stat types that are usually collected together. When a stat is recorded, the user will also be prompted to input records for all associated stat types. Eg.,Goal+Assist, Sub On+Sub Off"
                              }
                            />

                            <RelationshipSection
                              title={"Parent Types"}
                              sport={sport}
                              selectionSet={selectionSet}
                              statType={st}
                              type={"Ancestor"}
                              relationships={st.relationshipsTo
                                .filter((r) => r.type.name === "Ancestor")
                                .map((r) => ({
                                  ...r,
                                  label: r.from.name,
                                  isFrom: false,
                                }))}
                              button={"Add parent stat type"}
                              description={
                                "If a stat has a parent stat type, then everytime a record is added for the stat, another record is automatically created for the parent stat type. Eg., The stat type 'Assist' has a parent stat type, 'Successful Pass'. Everytime an assist is marked for a player, the system will automatically add a succesful pass for the player."
                              }
                            />
                          </div>
                        );
                      },
                    ]}
                  />
                ))}
            </div>
          </div>
          <div className="card-footer">
            <StatefulView
              states={[
                (setSelectedState) => {
                  return (
                    <div
                      className={"App-btn success hover-pointer "}
                      onClick={() => {
                        setSelectedState(1);
                      }}
                    >
                      Add Stat Type
                    </div>
                  );
                },
                (setSelectedState) => (
                  <Form
                    className={"editable-field-form"}
                    initialState={{ groupId: "", name: "" }}
                    fields={[
                      {
                        label: "Group",
                        type: "select",
                        key: "groupId",
                        items: sport.statTypeGroups.map((st) => ({
                          label: st.name,
                          value: st.id,
                        })),
                      },
                      {
                        label: "Stat Name",
                        type: "text",
                        key: "name",
                      },
                    ]}
                    mutation={UPDATE_SPORT({ selectionSet })}
                    update={(cache, { data: { updateSport } }) => {
                      const q = cache.readQuery({
                        query: SPORT({ selectionSet }),
                        variables: { id },
                      });
                      cache.writeQuery({
                        query: SPORT({ selectionSet }),
                        variables: { id },
                        data: {
                          sport: {
                            ...q.sport,
                            statTypes: updateSport.statTypes,
                          },
                        },
                      });

                      setSelectedState(0);
                    }}
                    vars={(s) => ({
                      where: { id },
                      data: {
                        statTypes: {
                          create: [{ groupId: s.groupId, name: s.name }],
                        },
                      },
                    })}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setSelectedState(0);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                ),
              ]}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default StatTypeList;

import "./Button.css";

export default function Button({
  label,
  className = "",
  onClick = () => {},
  children,
  type,
}) {
  return (
    <button className={"App-button " + className} onClick={onClick}>
      {children || label}
    </button>
  );
}

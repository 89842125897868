import "./Uploads.css";
import { Switch, Route } from "react-router-dom";
import { UploadsIndex } from "../../componentsV2/uploads";

export default function Uploads() {
  return (
    <Switch>
      <Route path="/v2/uploads" component={UploadsIndex} />
    </Switch>
  );
}

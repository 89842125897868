import Editable from "../../Editable";
import InfoField from "../../InfoField";
import CreateFixture from "../../CreateFixture";
import GenerateFixtures from "../../GenerateFixtures";
import { useQuery } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../../../api/events";
import ORGANIZATIONS from "../../../api/queries/organizations";
import ROLE_TYPES from "../../../api/queries/roleTypes";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import Form from "../../Form";
import { FaTimes, FaPencilAlt } from "react-icons/fa";
import "../../../styles/FixtureList.css";
import moment from "moment";
import FixtureTree from "../../FixtureTree";
import UploadData from "../../common/UploadData";
import exportToCSV from "../../../utils/exportToCSV";

import Loading from "../../Loading";
import { Fragment } from "react";

function BasicView({ selectedPart, setSelectedPart, event }) {
  return (
    <div className="card-body">
      <div className="fixture-list">
        <div className="fixture-list-header">
          <div
            className="btn prev hover-pointer"
            onClick={() => {
              setSelectedPart(Math.max(selectedPart - 1, 0));
            }}
          >
            {"<"}
          </div>
          <div className="title h1">{event.parts[selectedPart].label}</div>
          <div
            className="btn next hover-pointer"
            onClick={() => {
              setSelectedPart(
                Math.min(selectedPart + 1, event.parts.length - 1)
              );
            }}
          >
            {">"}
          </div>
        </div>

        <div className="fixture-list-body">
          {event.fixtures
            .filter((f) => f.eventPart.id === event.parts[selectedPart].id)
            .map((f) => (
              <div className="fixture-item">
                <div className="teams">
                  {f.roles.map((r) => (
                    <div className="team">{r.organization.name}</div>
                  ))}
                </div>
                <div className="datetime">
                  <div className="date hover-pointer">
                    {f.scheduledStart
                      ? moment(f.scheduledStart).format("DD MMM YY")
                      : "No fixture date."}
                  </div>
                  <div className="time hover-pointer">
                    {f.scheduledStart
                      ? moment(f.scheduledStart).format("HH:MM")
                      : "No fixture time."}
                  </div>
                </div>
                <div className="options">
                  <div className="option edit hover-pointer">
                    <FaPencilAlt />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

function EmptyList({ eventId, refetch, setCustom }) {
  const [, setAppState] = useAppState();
  return (
    <div className="generator-helper">
      <div className="helper-text">
        Choose one of the formats to automatically generate fixtures for an
        entire season:
      </div>
      <div className="helper-options">
        <div className="helper-item-wrapper even">
          <div
            className="helper-item hover-pointer"
            onClick={() => {
              setAppState({
                modal: <GenerateFixtures eventId={eventId} refetch={refetch} />,
              });
            }}
          >
            <div className="primary-text">Round Robin</div>
            <div className="secondary-text">
              Traditional football league fixtures generator eg. EPL
            </div>
          </div>
        </div>

        <div className="helper-item-wrapper odd">
          <div
            className="helper-item  hover-pointer"
            onClick={() => {
              setAppState({
                modal: (
                  <GenerateFixtures
                    eventId={eventId}
                    refetch={refetch}
                    type="single-elimination"
                  />
                ),
              });
            }}
          >
            <div className="primary-text">Cup (Single elimination)</div>
            <div className="secondary-text">
              Traditional cup fixtures generator eg. FA Cup
            </div>
          </div>
        </div>

        <div className="helper-item-wrapper even">
          <div
            className="helper-item hover-pointer"
            onClick={() => {
              setAppState({
                modal: (
                  <GenerateFixtures
                    eventId={eventId}
                    refetch={refetch}
                    type="league-cup"
                  />
                ),
              });
            }}
          >
            <div className="primary-text">League Cup</div>
            <div className="secondary-text">
              Group stages followed by single elimination cup eg. UCL
            </div>
          </div>
        </div>
      </div>
      <div className="helper-text">Or generate a custom fixture list:</div>
      <div className="helper-options">
        <div className="helper-item-wrapper even">
          <div
            className="helper-item hover-pointer"
            onClick={() => {
              setCustom(true);
            }}
          >
            <div className="primary-text">Custom</div>
            <div className="secondary-text">
              Add tournaments stages/rounds and fixtures manually.
            </div>
          </div>
        </div>

        <div className="helper-item-wrapper odd">
          <div
            className="helper-item hover-pointer"
            onClick={() => {
              setAppState({
                modal: (
                  <UploadData
                    headers={[
                      { label: "S.No", key: "serialId" },
                      { label: "Stage", key: "stage" },
                      { label: "Home Team", key: "homeTeam" },
                      { label: "Away Team", key: "awayTeam" },
                      { label: "Home Goals", key: "homeGoals" },
                      { label: "Away Goals", key: "awayGoals" },
                      { label: "Start Date(dd/mm/yyyy)", key: "startDate" },
                      { label: "Start Time", key: "startTime" },
                    ]}
                    type={"fixture"}
                    transform={(rows) => {
                      return {
                        eventId: eventId,
                        rows: rows.map((r) => ({
                          label: "Match " + r[0],
                          stage: r[1],
                          homeTeam: r[2],
                          awayTeam: r[3],
                          homeGoals: r[4],
                          awayGoals: r[5],
                          scheduledStart: moment(
                            r[6] + " " + (r[7] || "12:00"),
                            "DD/MM/YYYY HH:mm"
                          ).toISOString(),
                        })),
                      };
                    }}
                    refetch={refetch}
                  />
                ),
              });
            }}
          >
            <div className="primary-text">Upload</div>
            <div className="secondary-text">
              Fill in this template to upload fixtures for a tournament.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function FixturesList({ id }) {
  const [, setAppState] = useAppState();
  const [selectedPart, setSelectedPart] = useState(0);
  const [custom, setCustom] = useState(false);
  const selectionSet = `
    fixtures{
      id
      label
      scheduledStart
      scheduledEnd
      actualStart
      actualEnd
      groupByDate
      totalLegs
      eventPartId
      eventPart{
        id
      }
      roles{
        id
        order
        type{
          id
          name
        }
        organization{
          id
          name
        }
        placement{
          id
          order
          fixture{
            id
            label
          }
          eventPart{
            id
            label
          }
        }
      }
    }
    parts{
      id
      order
      type
      label
      partOf{
        id
      }
      roles{
        id
        organization{
          id
          name
        }
      }
    }
  `;
  const { loading, error, data, refetch } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id } }
  );

  const { loading: loadingRoleTypes, data: roleTypesData } = useQuery(
    ROLE_TYPES,
    {
      variables: { where: { name: "PARTICIPANT" } },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data || loadingRoleTypes || !roleTypesData) {
    // return <div>Loading</div>;
  }

  const event = data?.event;
  const roleTypes = roleTypesData?.roleTypes || [];
  const participantRoleType = roleTypes[0];

  // console.log(roleTypesData);
  // console.log("Fxitures event:", event);
  return (
    <div className="card">
      <div className="card-title">Event Fixtures</div>
      {loading || !data || loadingRoleTypes || !roleTypesData ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          {!event.fixtures.length && !custom && (
            <EmptyList
              refetch={() => {
                refetch({ id });
              }}
              eventId={id}
              setCustom={setCustom}
            />
          )}

          <div className="card-body">
            {/* <FixtureTree
              event={event}
              rounds={event.parts.length ? event.parts : []}
              fixtures={event.fixtures.length ? event.fixtures : []}
              selectionSet={selectionSet}
              refetch={refetch}
            /> */}

            {(custom || event.fixtures.length) && (
              <FixtureTree
                event={event}
                rounds={event.parts.length ? event.parts : []}
                fixtures={event.fixtures.length ? event.fixtures : []}
                selectionSet={selectionSet}
                refetch={refetch}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default FixturesList;

import "./ViewProfile.css";
import { Button, Spinner } from "../../common";
import { ReactComponent as ArrowLeftIcon } from "../../../icons/arrow-left.svg";
import { useParams, useLocation, useHistory } from "react-router-dom";
import useRouterQuery from "../../../utils/useRouterQuery";
import { PROFILE } from "../../../api/profiles";
import { useQuery } from "@apollo/client";
import ProfileInfo from "../ProfileInfo";
// import EventInfo from "../EventInfo";
// import EventRegistrations from "../EventRegistrations";
// import EventFixtures from "../EventFixtures";
// import UploadEventStats from "../UploadEventStats";

const tabs = [
  { key: "info", label: "Info", component: ProfileInfo },
  //   {
  //     key: "registrations",
  //     label: "Registrations",
  //     component: EventRegistrations,
  //   },
  //   { key: "fixtures", label: "Fixtures", component: EventFixtures },
  //   { key: "upload", label: "Upload Stats", component: UploadEventStats },
];

export default function ViewProfile() {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = useRouterQuery();
  const tab = query.get("t") || "info";
  const selectedTab = tabs.find((t) => t.key === tab);
  const SelectedComponent = selectedTab.component;

  const { loading, error, data } = useQuery(
    PROFILE({
      selectionSet: `
        email
        name
        firstname
        lastname
        handle
        profilePhoto{
        id
        filename
        }
    `,
    }),
    {
      variables: { id },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const profile = data?.profile;

  return (
    <div className="App-view-page">
      <div className="App-view-page__header">
        <div
          className="App-view-page__header__back-btn"
          onClick={() => {
            history.push("/v2/profiles");
          }}
        >
          <ArrowLeftIcon className="App-view-page__header__back-btn__icon" />
        </div>
        <div className="App-view-page__header__title">{profile.name}</div>
        <div className="App-view-page__header__options">
          <Button
            label={"DELETE PROFILE"}
            className="App-view-page__header__options__delete"
            onClick={() => {
              // setAppState({ modal: <CreateEvent /> });
            }}
          />
        </div>
      </div>

      <div className="App-view-page__tabs">
        {tabs.map((t, i) => (
          <div
            key={"event-tab-" + i}
            className={
              "App-view-page__tabs__item " +
              (selectedTab.key === t.key ? "selected" : "")
            }
            onClick={() => {
              history.replace(location.pathname + "?t=" + t.key);
            }}
          >
            {t.label}
          </div>
        ))}
      </div>

      <div className="App-view-page__body">
        <SelectedComponent profile={profile} />
      </div>
    </div>
  );
}

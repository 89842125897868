import USERS from "../api/queries/users";
import PROFILES from "../api/queries/profiles";
import ORGANIZATIONS from "../api/queries/organizations";
import EVENTS from "../api/queries/events";
import SPORTS from "../api/queries/sports";
import ENQUIRIES from "../api/queries/enquiries";
import PROFILE_CLAIMS_LIST from "../api/queries/profileClaimsList";
import CreateOrganization from "../components/CreateOrganization";
import CreateProfile from "../components/CreateProfile";
import CreateEvent from "../components/CreateEvent";
import CreateSport from "../components/CreateSport";
import InviteAdmin from "../components/InviteAdmin";
import moment from "moment";
import exportToCSV from "./exportToCSV";

const indexRouteConfig = {
  users: {
    query: USERS,
    headerOptions: [
      (setAppState) => (
        <div
          className="option"
          onClick={() => {
            setAppState({ modal: <InviteAdmin /> });
          }}
        >
          Invite Admin
        </div>
      ),
      (setAppState, rows) => (
        <div
          className="option outline"
          onClick={() => {
            exportToCSV({
              fields: [
                { label: "Name", value: (x) => x.name },
                { label: "Email", value: (x) => x.email },
                { label: "Phone", value: (x) => x.phone },
              ],
              data: rows,
            });
          }}
        >
          Export Data
        </div>
      ),
    ],
    orderBy: [],
    search: () => {},
    fields: [
      { label: "Name", value: (row) => row.name, weight: 0.5 },
      { label: "Email", value: (row) => row.email, weight: 0.25 },
      { label: "Phone", value: (row) => row.phone, weight: 0.25 },
    ],
    itemOnClick: (history, item) => {
      history.push("/view/users/" + item.id);
    },
    rows: (d) => d.users,
    title: "Users",
  },
  organizations: {
    query: ORGANIZATIONS,
    hasSearch: {
      searchVars: (term) => {
        return {
          where: { name: term },
        };
      },
      clearSearchVars: {
        where: { name: "" },
      },
    },
    headerOptions: [
      (setAppState) => (
        <div
          className="option"
          onClick={() => {
            setAppState({ modal: <CreateOrganization /> });
          }}
        >
          New
        </div>
      ),
      (setAppState, rows) => (
        <div
          className="option outline"
          onClick={() => {
            exportToCSV({
              fields: [{ label: "Name", value: (x) => x.name }],
              data: rows,
            });
          }}
        >
          Export Data
        </div>
      ),
    ],
    orderBy: [],
    search: () => {},
    fields: [{ label: "Name", value: (row) => row.name, weight: 1 }],
    itemOnClick: (history, item) => {
      history.push("/view/organizations/" + item.id);
    },
    rows: (d) => d.organizations,
    title: "Organizations",
  },
  profiles: {
    query: PROFILES,
    hasSearch: {
      searchVars: (term) => {
        return {
          where: { name: term },
        };
      },
      clearSearchVars: {
        where: { name: "" },
      },
    },
    headerOptions: [
      (setAppState) => (
        <div
          className="option"
          onClick={() => {
            setAppState({ modal: <CreateProfile /> });
          }}
        >
          New
        </div>
      ),
      (setAppState, rows) => (
        <div
          className="option outline"
          onClick={() => {
            exportToCSV({
              fields: [{ label: "Name", value: (x) => x.name }],
              data: rows,
            });
          }}
        >
          Export Data
        </div>
      ),
    ],
    orderBy: [],
    search: () => {},
    fields: [{ label: "Name", value: (row) => row.name, weight: 1 }],
    itemOnClick: (history, item) => {
      history.push("/view/profiles/" + item.id);
    },
    rows: (d) => d.profiles,
    title: "Profiles",
  },
  enquiries: {
    query: ENQUIRIES,
    headerOptions: [
      (setAppState, rows) => (
        <div
          className="option outline"
          onClick={() => {
            exportToCSV({
              fields: [
                { label: "Name", value: (x) => x.name },
                { label: "Email", value: (x) => x.email },
                { label: "Phone", value: (x) => x.phone },
                { label: "City", value: (x) => x.city },
                { label: "Event", value: (x) => x.event.name },
                { label: "Enquiry Date", value: (x) => x.createdAt },
              ],
              data: rows,
            });
          }}
        >
          Export Data
        </div>
      ),
    ],
    orderBy: [],
    search: () => {},
    fields: [
      { label: "Name", value: (row) => row.name, weight: 0.2 },
      { label: "Email", value: (row) => row.email, weight: 0.2 },
      { label: "Phone", value: (row) => row.phone, weight: 0.1 },
      { label: "City", value: (row) => row.city, weight: 0.1 },
      { label: "Event", value: (row) => row.event.name, weight: 0.3 },
      {
        label: "Created At",
        value: (row) => moment(row.createdAt).format("Do MMM YYYY"),
        weight: 0.1,
      },
    ],
    itemOnClick: (history, item) => {
      history.push("/view/enquiries/" + item.id);
    },
    rows: (d) => d.enquiries,
    title: "Enquiries",
  },
  events: {
    query: EVENTS,
    hasSearch: {
      searchVars: (term) => {
        return {
          where: { name: term },
        };
      },
      clearSearchVars: {
        where: { name: "" },
      },
    },
    headerOptions: [
      (setAppState) => (
        <div
          className="option"
          onClick={() => {
            setAppState({ modal: <CreateEvent /> });
          }}
        >
          New
        </div>
      ),
      (setAppState, rows) => (
        <div
          className="option outline"
          onClick={() => {
            exportToCSV({
              fields: [
                { label: "Name", value: (x) => x.name },
                { label: "ID", value: (x) => x.id },
                { label: "Scheduled Start", value: (x) => x.scheduledStart },
                { label: "Scheduled End", value: (x) => x.scheduledEnd },
              ],
              data: rows,
            });
          }}
        >
          Export Data
        </div>
      ),
    ],
    orderBy: [],
    search: () => {},
    fields: [
      { label: "Name", value: (row) => row.name, weight: 0.4 },
      { label: "ID", value: (row) => row.id, weight: 0.2 },
      {
        label: "Start Date",
        value: (row) =>
          row.scheduledStart
            ? moment(row.scheduledStart).format("Do MMM YYYY")
            : "",
        weight: 0.2,
      },
      {
        label: "End Date",
        value: (row) =>
          row.scheduledEnd
            ? moment(row.scheduledEnd).format("Do MMM YYYY")
            : "",
        weight: 0.2,
      },
    ],
    itemOnClick: (history, item) => {
      history.push("/view/events/" + item.id);
    },
    rows: (d) => d.events,
    title: "Events",
  },
  sports: {
    query: SPORTS,
    headerOptions: [
      (setAppState) => (
        <div
          className="option"
          onClick={() => {
            setAppState({ modal: <CreateSport /> });
          }}
        >
          New
        </div>
      ),
    ],
    orderBy: [],
    search: () => {},
    fields: [{ label: "Name", value: (row) => row.name, weight: 1 }],
    itemOnClick: (history, item) => {
      history.push("/view/sports/" + item.id);
    },
    rows: (d) => d.sports,
    title: "Sports",
  },

  claims: {
    query: PROFILE_CLAIMS_LIST,
    headerOptions: [
      // (setAppState) => (
      //   <div
      //     className="option"
      //     onClick={() => {
      //       setAppState({ modal: <CreateSport /> });
      //     }}
      //   >
      //     New
      //   </div>
      // ),
    ],
    orderBy: [],
    search: () => {},
    fields: [
      { label: "Profile", value: (row) => row.name, weight: 0.5 },
      {
        label: "Total Claims",
        value: (row) => row.claimsCount,
        weight: 0.5,
      },
    ],
    itemOnClick: (history, item) => {
      history.push("/view/claims/" + item.id);
    },
    rows: (d) => d.profileClaims,
    title: "Profile Claims",
  },
};

export default indexRouteConfig;

import Editable from "../../Editable";
import StatefulView from "../../StatefulView";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../../../api/events";
import ORGANIZATIONS from "../../../api/queries/organizations";
import ROLE_TYPES from "../../../api/queries/roleTypes";
import Form from "../../Form";
import { FaTimes, FaTrash, FaPencilAlt } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function EventParticipants({ id }) {
  const selectionSet = `
  participantType
  participants{
    id
    type{
      id
      name
    }
    organization{
      id
      name
    }
  }  
  `;
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet,
    }),
    { variables: { id } }
  );

  const { loading: loadingRoleTypes, data: roleTypesData } = useQuery(
    ROLE_TYPES,
    {
      variables: { where: { name: "PARTICIPANT" } },
    }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  // if (loading || !data || loadingRoleTypes || !roleTypesData) {
  //   // return <div>Loading</div>;
  // }

  const event = data?.event;
  const roleTypes = roleTypesData?.roleTypes || [];
  const participantRoleType = roleTypes[0];
  console.log(roleTypesData);
  console.log("Event Participants:", event);

  return (
    <div className="card">
      <div className="card-title">Event Participants</div>
      {loading || !data || loadingRoleTypes || !roleTypesData ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {event.participants.map((r) => (
              <StatefulView
                states={[
                  (setSelectedState) => (
                    <InfoField
                      options={[
                        <div
                          className="option delete"
                          onClick={() => {
                            setSelectedState(2);
                          }}
                        >
                          <FaTrash />
                        </div>,

                        <div
                          className="option edit"
                          onClick={() => {
                            setSelectedState(1);
                          }}
                        >
                          <FaPencilAlt />
                        </div>,
                      ]}
                      setSelectedState={setSelectedState}
                      label={r.type.name}
                      value={r.organization.name}
                      required={false}
                    />
                  ),
                  (setSelectedState) => (
                    <Form
                      className={"editable-field-form"}
                      initialState={{
                        typeId: participantRoleType.id,
                        organizationId: r.organization.id,
                      }}
                      fields={[
                        {
                          label: "Organization",
                          type: "select",
                          key: "organizationId",
                          query: ORGANIZATIONS,
                          items: (d) => d.organizations,
                          optionLabel: (i) => i.name,
                        },
                      ]}
                      mutation={UPDATE_EVENT({ selectionSet })}
                      update={(cache, { data: { updateEvent } }) => {
                        const q = cache.readQuery({
                          query: EVENT({ selectionSet }),
                          variables: { id },
                        });
                        cache.writeQuery({
                          query: EVENT({ selectionSet }),
                          variables: { id },
                          data: {
                            event: {
                              ...q.event,
                              roles: updateEvent.roles,
                            },
                          },
                        });

                        setSelectedState(0);
                      }}
                      vars={(s) => ({
                        where: { id },
                        data: {
                          roles: {
                            update: {
                              where: {
                                id: r.id,
                              },
                              data: {
                                typeId: s.typeId,
                                organizationId: s.organizationId,
                              },
                            },
                          },
                        },
                      })}
                      options={[
                        <div
                          className="option cancel"
                          onClick={() => {
                            setSelectedState(0);
                          }}
                        >
                          <FaTimes />
                        </div>,
                      ]}
                    />
                  ),
                  (setSelectedState) => {
                    return (
                      <Form
                        className={"editable-field-form"}
                        initialState={{
                          eventRoleId: "",
                        }}
                        fields={[
                          {
                            label: `Are you sure you want to delete this role? Type ${r.id} to confirm`,
                            type: "text",
                            key: "eventRoleId",
                          },
                        ]}
                        mutation={UPDATE_EVENT({ selectionSet })}
                        update={(cache, { data: { updateEvent } }) => {
                          const q = cache.readQuery({
                            query: EVENT({ selectionSet }),
                            variables: { id },
                          });
                          cache.writeQuery({
                            query: EVENT({ selectionSet }),
                            variables: { id },
                            data: {
                              event: {
                                ...q.event,
                                roles: updateEvent.roles,
                              },
                            },
                          });

                          setSelectedState(0);
                        }}
                        vars={(s) => ({
                          where: { id },
                          data: {
                            roles: {
                              deleteMany: [{ id: r.id }],
                            },
                          },
                        })}
                        options={[
                          <div
                            className="option cancel"
                            onClick={() => {
                              setSelectedState(0);
                            }}
                          >
                            <FaTimes />
                          </div>,
                        ]}
                      />
                    );
                  },
                ]}
              />
            ))}
          </div>
          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{
                    typeId: participantRoleType.id,
                    organizationId: "",
                  }}
                  fields={[
                    {
                      label: "Organization",
                      type: "select",
                      key: "organizationId",
                      query: ORGANIZATIONS,
                      items: (d) => d.organizations,
                      optionLabel: (i) => i.name,
                    },
                  ]}
                  mutation={UPDATE_EVENT({ selectionSet })}
                  update={(cache, { data: { updateEvent } }) => {
                    const q = cache.readQuery({
                      query: EVENT({ selectionSet }),
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: EVENT({ selectionSet }),
                      variables: { id },
                      data: {
                        event: {
                          ...q.event,
                          roles: updateEvent.roles,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => {
                    if (!participantRoleType || !participantRoleType.id) {
                      console.log(
                        "No participant role type detected. Please contact tech support."
                      );
                      return false;
                    }
                    return {
                      where: { id },
                      data: {
                        roles: {
                          create: [
                            {
                              typeId: participantRoleType.id,
                              organizationId: s.organizationId,
                            },
                          ],
                        },
                      },
                    };
                  }}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add participant
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default EventParticipants;

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import { SPORT, UPDATE_SPORT } from "../../../api/sports";
import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";
import PositionTypeList from "./PositionTypeList";
import PositionTypeGroupList from "./PositionTypeGroupList";

function SportPositionTypes({ sport }) {
  return (
    <div className="page">
      <div className="section">
        <PositionTypeGroupList id={sport.id} />
      </div>
      <div className="section">
        <PositionTypeList id={sport.id} />
      </div>
    </div>
  );
}

export default SportPositionTypes;

import { Fragment } from "react";
import "../styles/FixtureTree.css";
import {
  FaPlay,
  FaPlus,
  FaTimes,
  FaPencilAlt,
  FaArrowUp,
  FaArrowDown,
  FaList,
  FaEye,
  FaTrash,
} from "react-icons/fa";
import { useState } from "react";
import Select from "./Select";
import Editable from "./Editable";
import Form from "./Form";
import { Link, useLocation } from "react-router-dom";
import { EVENT, UPDATE_EVENT, REORDER_EVENT_PARTS } from "../api/events";
import {
  CREATE_FIXTURE,
  DELETE_FIXTURE,
  UPDATE_FIXTURE,
} from "../api/fixtures";
import ORGANIZATIONS from "../api/queries/organizations";
import { useMutation } from "@apollo/client";
import moment from "moment";
import makeEventPartLabel from "../utils/makeEventPartLabel";

function AddEventFixture({ eventId, eventPartId }) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="form-group">
        <div className="type">Part Type</div>
        <Select
          items={[
            { label: "Stage", value: "Stage" },
            { label: "Group", value: "Group" },
            { label: "Gameweek", value: "Gameweek" },
            { label: "Round", value: "Round" },
          ]}
        />
      </div>

      <div className="form-group">
        <div className="label">Part Name</div>
        <input type="text" />
      </div>

      <div className="options">
        <button type="submit" className="submit">
          Submit
        </button>
        <button className="cancel">Submit</button>
      </div>
    </form>
  );
}

function AddEventPart({ parentId, order }) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="form-group">
        <div className="type">Part Type</div>
        <Select
          items={[
            { label: "Stage", value: "Stage" },
            { label: "Group", value: "Group" },
            { label: "Gameweek", value: "Gameweek" },
            { label: "Round", value: "Round" },
          ]}
        />
      </div>

      <div className="form-group">
        <div className="label">Part Name</div>
        <input type="text" />
      </div>

      <div className="options">
        <button type="submit" className="submit">
          Submit
        </button>
        <button className="cancel">Submit</button>
      </div>
    </form>
  );
}

function FixtureTreeNodes({
  nodes,
  rounds,
  fixtures,
  parentId,
  selectionSet,
  eventId,
}) {
  const [form, setForm] = useState(false);
  return (
    <div className="tree-nodes">
      {nodes.map((n, i) => (
        <FixtureTreeNode
          node={n}
          siblings={nodes}
          rounds={rounds}
          fixtures={fixtures}
          isFirstChild={!i}
          isLastChild={i === nodes.length - 1}
          selectionSet={selectionSet}
          eventId={eventId}
        />
      ))}
      <Editable
        form={(setEditing) => (
          <div style={{ marginTop: 12, paddingLeft: 8, paddingRight: 8 }}>
            <Form
              className={"editable-field-form"}
              initialState={{
                parentId: parentId,
                order: nodes.length,
                type: "",
                label: "",
              }}
              fields={[
                {
                  label: "Part Type",
                  type: "select",
                  key: "type",
                  items: [
                    { label: "Stage", value: "Stage" },
                    { label: "Group", value: "Group" },
                    { label: "Gameweek", value: "Gameweek" },
                    { label: "Round", value: "Round" },
                  ],
                  optionLabel: (i) => i.label,
                },
                {
                  label: "Part Name",
                  type: "text",
                  key: "label",
                },
              ]}
              mutation={UPDATE_EVENT({
                selectionSet: `parts{
                id
                order
                type
                label
                partOf{
                  id
                }
                roles{
                  id
                  organization{
                    id
                    name
                  }
                }
              }`,
              })}
              update={(cache, { data: { updateEvent } }) => {
                const q = cache.readQuery({
                  query: EVENT({ selectionSet }),
                  variables: { id: eventId },
                });
                cache.writeQuery({
                  query: EVENT({ selectionSet }),
                  variables: { id: eventId },
                  data: {
                    event: {
                      ...q.event,
                      parts: updateEvent.parts,
                    },
                  },
                });

                setEditing(false);
              }}
              vars={(s) => {
                return {
                  where: { id: eventId },
                  data: {
                    parts: {
                      create: {
                        type: s.type,
                        label: s.label,
                        order: nodes.length,
                        partOf: parentId
                          ? { connect: { id: parentId } }
                          : undefined,
                      },
                    },
                  },
                };
              }}
              options={[
                <div
                  className="option cancel"
                  onClick={() => {
                    setEditing(false);
                  }}
                >
                  <FaTimes />
                </div>,
              ]}
            />
          </div>
        )}
        content={(setEditing) => (
          <div className="tree-node add" style={{ paddingLeft: 8 }}>
            <div
              className="node-identifier"
              onClick={() => {
                setEditing(true);
              }}
            >
              <div className={"collapse-btn"}>
                <FaPlus size={8} className="icon" />
              </div>
              <div className="node-label">Add a part</div>
            </div>
          </div>
        )}
      />
    </div>
  );
}

function FixtureTreeNode({
  node,
  siblings,
  rounds,
  fixtures,
  isFirstChild,
  isLastChild,
  eventId,
  selectionSet,
}) {
  const children = rounds.filter((r) => r.partOf?.id === node.id);
  const [collapsed, setCollapsed] = useState(true);
  const [editingNode, setEditingNode] = useState(false);
  const [reorderEventParts, { loading: reordering }] = useMutation(
    REORDER_EVENT_PARTS({
      selectionSet: `
      id
      order
      type
      label
      partOf{
        id
      }
      roles{
        id
        organization{
          id
          name
        }
      }
      `,
    }),
    {
      update: (cache, { data: { reorderEventParts } }) => {
        const q = cache.readQuery({
          query: EVENT({ selectionSet }),
          variables: { id: eventId },
        });

        const reorderedIds = reorderEventParts.map((x) => x.id);

        cache.writeQuery({
          query: EVENT({ selectionSet }),
          variables: { id: eventId },
          data: {
            event: {
              ...q.event,
              parts: [...q.event.parts]
                .map((p) => {
                  if (reorderedIds.includes(p.id)) {
                    return reorderEventParts.find((x) => x.id === p.id);
                  } else {
                    return p;
                  }
                })
                .sort((a, b) => {
                  return a.order - b.order;
                }),
            },
          },
        });

        setEditingNode(false);
      },
    }
  );
  const [updateEvent, { loading }] = useMutation(
    UPDATE_EVENT({
      selectionSet: `parts{
  id
  order
  type
  label
  partOf{
    id
  }
  roles{
    id
    organization{
      id
      name
    }
  }
}`,
    })
  );

  return (
    <div className="tree-node">
      <div className="node-identifier">
        <div className="hover-wrapper">
          <div
            className={"collapse-btn " + (!collapsed ? "expanded" : "")}
            onClick={() => {
              setCollapsed(!collapsed);
              setEditingNode(false);
            }}
          >
            <FaPlay size={8} className="icon" />
          </div>

          <div
            className="node-label"
            onClick={() => {
              setCollapsed(!collapsed);
              setEditingNode(false);
            }}
          >
            {node.label}
          </div>
        </div>

        {collapsed && (
          <div className="node-options">
            {node.type === "Group" && (
              <div className={"option " + (children.length ? "disabled" : "")}>
                <FaList />
              </div>
            )}

            {editingNode && (
              <div
                className={"option " + (isFirstChild ? "disabled" : "")}
                onClick={() => {
                  const index = siblings.findIndex((x) => x.id === node.id);
                  reorderEventParts({
                    variables: {
                      data: [
                        {
                          where: { id: node.id },
                          order: Math.max(node.order - 1, 0),
                        },
                        {
                          where: { id: siblings[index - 1].id },
                          order: Math.min(
                            siblings[index - 1].order + 1,
                            siblings.length - 1
                          ),
                        },
                      ],
                    },
                  });
                }}
              >
                <FaArrowUp />
              </div>
            )}

            {editingNode && (
              <div
                className={"option " + (isLastChild ? "disabled" : "")}
                onClick={() => {
                  const index = siblings.findIndex((x) => x.id === node.id);
                  reorderEventParts({
                    variables: {
                      data: [
                        {
                          where: { id: node.id },
                          order: Math.min(node.order + 1, siblings.length - 1),
                        },
                        {
                          where: { id: siblings[index + 1].id },
                          order: Math.max(siblings[index + 1].order - 1, 0),
                        },
                      ],
                    },
                  });
                }}
              >
                <FaArrowDown />
              </div>
            )}

            <div
              className="option edit"
              onClick={() => {
                setEditingNode(true);
              }}
            >
              <FaPencilAlt />
            </div>

            <div
              className={
                "option delete " +
                ((children && children.length) ||
                fixtures.find((f) => f.eventPart?.id === node.id)
                  ? "disabled"
                  : "")
              }
              onClick={() => {
                updateEvent({
                  variables: {
                    where: { id: eventId },
                    data: { parts: { deleteMany: [{ id: node.id }] } },
                  },
                  update: (cache, { data: { updateEvent } }) => {
                    const q = cache.readQuery({
                      query: EVENT({ selectionSet }),
                      variables: { id: eventId },
                    });
                    cache.writeQuery({
                      query: EVENT({ selectionSet }),
                      variables: { id: eventId },
                      data: {
                        event: {
                          ...q.event,
                          parts: updateEvent.parts,
                        },
                      },
                    });
                  },
                });
              }}
            >
              <FaTrash />
            </div>
          </div>
        )}
      </div>

      {editingNode && (
        <div style={{ marginTop: 12, paddingLeft: 8, paddingRight: 8 }}>
          <Form
            className={"editable-field-form"}
            initialState={{
              parentId: node.partOf?.id,
              type: node.type,
              label: node.label,
            }}
            fields={[
              {
                label: "Parent",
                type: "select",
                key: "parentId",
                query: EVENT({
                  selectionSet: `
              parts{
                id
                order
                type
                label
                partOf{
                  id
                }
                roles{
                  id
                  organization{
                    id
                    name
                  }
                }
              }
              `,
                }),
                variables: { id: eventId },
                items: (d) => {
                  return d.event.parts;
                },
                optionLabel: (i) => i.label,
              },
              {
                label: "Part Type",
                type: "select",
                key: "type",
                items: [
                  { label: "Stage", value: "Stage" },
                  { label: "Group", value: "Group" },
                  { label: "Gameweek", value: "Gameweek" },
                  { label: "Round", value: "Round" },
                ],
                optionLabel: (i) => i.label,
              },
              {
                label: "Part Name",
                type: "text",
                key: "label",
              },
            ]}
            mutation={UPDATE_EVENT({
              selectionSet: `parts{
        id
        order
        type
        label
        partOf{
          id
        }
        roles{
          id
          organization{
            id
            name
          }
        }
      }`,
            })}
            update={(cache, { data: { updateEvent } }) => {
              const q = cache.readQuery({
                query: EVENT({ selectionSet }),
                variables: { id: eventId },
              });
              cache.writeQuery({
                query: EVENT({ selectionSet }),
                variables: { id: eventId },
                data: {
                  event: {
                    ...q.event,
                    parts: updateEvent.parts,
                  },
                },
              });

              setEditingNode(false);
            }}
            vars={(s) => {
              const v = {
                type: s.type,
                label: s.label,
              };

              if (!s.parentId) {
                if (node.partOf?.id) {
                  v.partOf = { disconnect: true };
                }
              } else {
                if (s.parentId !== node.partOf?.id) {
                  v.partOf = { connect: { id: s.parentId } };
                }
              }

              return {
                where: { id: eventId },
                data: {
                  parts: {
                    update: {
                      where: { id: node.id },
                      data: v,
                    },
                  },
                },
              };
            }}
            options={[
              <div
                className="option cancel"
                onClick={() => {
                  setEditingNode(false);
                }}
              >
                <FaTimes />
              </div>,
            ]}
          />
        </div>
      )}

      {!collapsed && (
        <div className="node-body">
          {children.length ? (
            <FixtureTreeNodes
              nodes={children}
              rounds={rounds}
              fixtures={fixtures}
              parentId={node.id}
              eventId={eventId}
              selectionSet={selectionSet}
            />
          ) : (
            <Fragment>
              {fixtures
                .filter((f) => f.eventPartId === node.id)
                .map((f) => (
                  <FixtureItem
                    fixture={f}
                    selectionSet={selectionSet}
                    eventId={eventId}
                  />
                ))}
            </Fragment>
          )}

          {!fixtures.filter((f) => f.eventPartId === node.id).length &&
            !children.length && (
              <Editable
                form={(setEditing) => (
                  <div
                    style={{ marginTop: 12, paddingLeft: 8, paddingRight: 8 }}
                  >
                    <Form
                      className={"editable-field-form"}
                      initialState={{
                        parentId: node.id,
                        order: children.length,
                        type: "",
                        label: "",
                      }}
                      fields={[
                        {
                          label: "Part Type",
                          type: "select",
                          key: "type",
                          items: [
                            { label: "Stage", value: "Stage" },
                            { label: "Group", value: "Group" },
                            { label: "Gameweek", value: "Gameweek" },
                            { label: "Round", value: "Round" },
                          ],
                          optionLabel: (i) => i.label,
                        },
                        {
                          label: "Part Name",
                          type: "text",
                          key: "label",
                        },
                      ]}
                      mutation={UPDATE_EVENT({
                        selectionSet: `parts{
                      id
                      order
                      type
                      label
                      partOf{
                        id
                      }
                      roles{
                        id
                        organization{
                          id
                          name
                        }
                      }
                    }`,
                      })}
                      update={(cache, { data: { updateEvent } }) => {
                        const q = cache.readQuery({
                          query: EVENT({ selectionSet }),
                          variables: { id: eventId },
                        });
                        cache.writeQuery({
                          query: EVENT({ selectionSet }),
                          variables: { id: eventId },
                          data: {
                            event: {
                              ...q.event,
                              parts: updateEvent.parts,
                            },
                          },
                        });

                        setEditing(false);
                      }}
                      vars={(s) => {
                        return {
                          where: { id: eventId },
                          data: {
                            parts: {
                              create: {
                                type: s.type,
                                label: s.label,
                                order: children.length,
                                partOf: { connect: { id: node.id } },
                              },
                            },
                          },
                        };
                      }}
                      options={[
                        <div
                          className="option cancel"
                          onClick={() => {
                            setEditing(false);
                          }}
                        >
                          <FaTimes />
                        </div>,
                      ]}
                    />
                  </div>
                )}
                content={(setEditing) => (
                  <div className="tree-node add" style={{ paddingLeft: 8 }}>
                    <div
                      className="node-identifier"
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      <div className={"collapse-btn"}>
                        <FaPlus size={8} className="icon" />
                      </div>
                      <div className="node-label">Add a part</div>
                    </div>
                  </div>
                )}
              />
            )}

          {!children.length && (
            <Editable
              form={(setEditing) => (
                <div style={{ marginTop: 12, paddingLeft: 8, paddingRight: 8 }}>
                  <Form
                    className={"editable-field-form"}
                    initialState={{
                      label: "",
                      participants: 2,
                    }}
                    fields={[
                      {
                        label: "Match Label",
                        type: "text",
                        key: "label",
                      },
                      {
                        label: "Total Participants",
                        type: "number",
                        key: "participants",
                      },
                    ]}
                    mutation={CREATE_FIXTURE({
                      selectionSet: `
                      label
                      scheduledStart
                      scheduledEnd
                      actualStart
                      actualEnd
                      groupByDate
                      totalLegs
                      eventPartId
                      eventPart{
                        id
                      }
                      roles{
                        id
                        order
                        type{
                          id
                          name
                        }
                        organization{
                          id
                          name
                        }
                        placement{
                          id
                          order
                          fixture{
                            id
                            label
                          }
                          eventPart{
                            id
                            label
                          }
                        }
                      }
                      `,
                    })}
                    update={(cache, { data: { createFixture } }) => {
                      const q = cache.readQuery({
                        query: EVENT({ selectionSet }),
                        variables: { id: eventId },
                      });
                      cache.writeQuery({
                        query: EVENT({ selectionSet }),
                        variables: { id: eventId },
                        data: {
                          event: {
                            ...q.event,
                            fixtures: [...q.event.fixtures, createFixture],
                          },
                        },
                      });

                      setEditing(false);
                    }}
                    vars={(s) => {
                      const v = {
                        event: { connect: { id: eventId } },
                        eventPart: { connect: { id: node.id } },
                        label: s.label,
                      };

                      if (s.participants && Number(s.participants)) {
                        v.roles = {
                          create: [],
                        };
                        for (let x = 0; x < Number(s.participants); x++) {
                          v.roles.create.push({
                            order: x,
                            type: { connect: { name: "PARTICIPANT" } },
                          });
                        }
                      }

                      console.log("Variables:", v);

                      return {
                        data: v,
                      };
                    }}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setEditing(false);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                </div>
              )}
              content={(setEditing) => (
                <div className="tree-node add" style={{ paddingLeft: 8 }}>
                  <div
                    className="node-identifier"
                    onClick={() => {
                      setEditing(true);
                    }}
                  >
                    <div className={"collapse-btn"}>
                      <FaPlus size={8} className="icon" />
                    </div>
                    <div className="node-label">Add a fixture</div>
                  </div>
                </div>
              )}
            />
          )}
        </div>
      )}
    </div>
  );
}

function Placeholder({ placement }) {
  if (placement?.fixture) {
    return (
      placement.fixture.label + " " + (placement.order ? "Loser" : "Winner")
    );
  }

  if (placement?.eventPart) {
    return "#" + (placement.order + 1) + " " + placement.eventPart.label;
  }

  return "No team or placeholder added";
}

function FixtureItem({ fixture, selectionSet, eventId }) {
  const location = useLocation();
  const [deleteFixture, { loading }] = useMutation(DELETE_FIXTURE, {
    onError: (e) => {
      console.log(e);
    },
  });

  const [editingFixture, setEditingFixture] = useState(false);

  return (
    <Fragment>
      <div className="fixture-item">
        <div className="meta">
          {fixture.label && (
            <div className="label">
              {fixture.label +
                (fixture.scheduledStart
                  ? moment(fixture.scheduledStart).format(
                      " (DD MMM YYYY HH:mm ) "
                    )
                  : "")}{" "}
            </div>
          )}

          <div className="teams">
            {fixture.roles
              .filter((r) => r.type.name === "PARTICIPANT")
              .map((r, homeIndex) => (
                <div className={homeIndex ? "away-team" : "home-team"}>
                  {r.organization?.name || (
                    <Placeholder placement={r.placement} />
                  )}
                </div>
              ))}
          </div>
        </div>

        <div className="options">
          <Link
            to={{
              pathname: "/view/fixtures/" + fixture.id,
              state: { referrer: location.pathname + location.search },
            }}
            className="option"
          >
            <FaEye />
          </Link>

          <div
            className="option edit"
            onClick={() => {
              setEditingFixture(true);
            }}
          >
            <FaPencilAlt />
          </div>

          <div
            className="option delete"
            onClick={() => {
              deleteFixture({
                variables: {
                  id: fixture.id,
                },
                update: (cache, { data: { deleteFixture } }) => {
                  const q = cache.readQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id: eventId },
                  });
                  cache.writeQuery({
                    query: EVENT({ selectionSet }),
                    variables: { id: eventId },
                    data: {
                      event: {
                        ...q.event,
                        fixtures: [
                          ...q.event.fixtures.filter(
                            (f) => f.id !== deleteFixture.id
                          ),
                        ],
                      },
                    },
                  });
                },
              });
            }}
          >
            <FaTrash />
          </div>
        </div>
      </div>
      {editingFixture && (
        <div style={{ marginTop: 12, paddingLeft: 8, paddingRight: 8 }}>
          <Form
            className={"editable-field-form column"}
            initialState={{
              label: fixture.label,
              homeTeam: fixture.roles.find((r) => r.order === 0)?.organization
                ?.id,
              awayTeam: fixture.roles.find((r) => r.order === 1)?.organization
                ?.id,
              scheduledStart: fixture.scheduledStart
                ? moment(fixture.scheduledStart).format("YYYY-MM-DDTHH:mm")
                : "",
              eventPart: fixture.eventPart?.id,
            }}
            fields={[
              {
                label: "Match Label",
                type: "text",
                key: "label",
              },
              {
                label: "Home Team",
                type: "select",
                key: "homeTeam",
                query: ORGANIZATIONS,
                variables: { where: { types: ["Sports Team"] } },
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
              // {
              //   label: "Home Team Placeholder",
              //   type: "number",
              //   key: "participants",
              // },
              {
                label: "Away Team",
                type: "select",
                key: "awayTeam",
                query: ORGANIZATIONS,
                variables: { where: { types: ["Sports Team"] } },
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
              // {
              //   label: "Away Team Placeholder",
              //   type: "number",
              //   key: "participants",
              // },
              {
                label: "Scheduled Start",
                type: "datetime-local",
                key: "scheduledStart",
              },
              {
                label: "Stage",
                type: "select",
                key: "eventPart",
                query: EVENT({
                  selectionSet: `parts{
                id
                order
                type
                label
                partOf{
                  id
                }
                roles{
                  id
                  organization{
                    id
                    name
                  }
                }
              }`,
                }),
                variables: { id: eventId },
                items: (d) => d.event.parts,
                optionLabel: (i, d) =>
                  makeEventPartLabel({ part: i, parts: d.event.parts }),
              },
            ]}
            mutation={UPDATE_FIXTURE({
              selectionSet: `
          label
          scheduledStart
          scheduledEnd
          actualStart
          actualEnd
          groupByDate
          totalLegs
          eventPartId
          eventPart{
            id
          }
          roles{
            id
            order
            type{
              id
              name
            }
            organization{
              id
              name
            }
            placement{
              id
              order
              fixture{
                id
                label
              }
              eventPart{
                id
                label
              }
            }
          }
          `,
            })}
            update={(cache, { data: { updateFixture } }) => {
              const q = cache.readQuery({
                query: EVENT({ selectionSet }),
                variables: { id: eventId },
              });

              cache.writeQuery({
                query: EVENT({ selectionSet }),
                variables: { id: eventId },
                data: {
                  event: {
                    ...q.event,
                    fixtures: [
                      ...q.event.fixtures.map((f) => {
                        if (f.id === updateFixture.id) {
                          return updateFixture;
                        } else {
                          return f;
                        }
                      }),
                    ],
                  },
                },
              });

              setEditingFixture(false);
            }}
            vars={(s) => {
              const v = {
                where: { id: fixture.id },
                data: {},
              };

              if (s.label !== fixture.label) {
                v.data.label = s.label;
              }

              console.log("SCHEDULED START", s.scheduledStart);

              if (
                !moment(s.scheduledStart)?.isSame(
                  moment(fixture.scheduledStart)
                )
              ) {
                v.data.scheduledStart = moment(s.scheduledStart).toISOString();
              }

              const homeTeamRole = fixture.roles.find(
                (r) => r.order === 0 && r.type.name === "PARTICIPANT"
              );
              const awayTeamRole = fixture.roles.find(
                (r) => r.order === 1 && r.type.name === "PARTICIPANT"
              );

              // console.log("HTR", homeTeamRole);
              // console.log("HTN", s.homeTeam);
              // console.log("ATR", awayTeamRole);
              // console.log("ATN", s.awayTeam);

              const homeTeamChanged =
                homeTeamRole?.organization?.id !== s.homeTeam;
              const awayTeamChanged =
                awayTeamRole?.organization?.id !== s.awayTeam;

              if (homeTeamChanged || awayTeamChanged) {
                v.data.roles = {
                  update: [],
                };
              }

              if (homeTeamChanged) {
                v.data.roles.update.push({
                  where: { id: homeTeamRole.id },
                  data: {
                    organization: s.homeTeam
                      ? { connect: { id: s.homeTeam } }
                      : { disconnect: true },
                  },
                });
              }

              if (awayTeamChanged) {
                v.data.roles.update.push({
                  where: { id: awayTeamRole.id },
                  data: {
                    organization: s.awayTeam
                      ? { connect: { id: s.awayTeam } }
                      : { disconnect: true },
                  },
                });
              }

              if (s.eventPart !== fixture.eventPart.id) {
                v.data.eventPart = s.eventPart
                  ? { connect: { id: s.eventPart } }
                  : { disconnect: true };
              }

              console.log(v);

              return v;
            }}
            options={[
              <div
                className="option cancel"
                onClick={() => {
                  setEditingFixture(false);
                }}
              >
                <FaTimes />
              </div>,
            ]}
          />
        </div>
      )}
    </Fragment>
  );
}

function FixtureTree({ event, rounds, fixtures, selectionSet }) {
  const rootRounds = rounds.filter((r) => {
    return !r.partOf?.id;
  });

  return (
    <Fragment>
      <FixtureTreeNodes
        nodes={rootRounds}
        rounds={rounds}
        fixtures={fixtures}
        parentId={false}
        selectionSet={selectionSet}
        eventId={event.id}
      />
      <div className="ungrouped-fixtures">
        {event.fixtures
          .filter((f) => !f.eventPart)
          .map((f) => (
            <FixtureItem
              fixture={f}
              selectionSet={selectionSet}
              eventId={event.id}
            />
          ))}
      </div>
    </Fragment>
  );
}

export default FixtureTree;

import ProfileDetails from "./ProfileDetails";
import ProfileContacts from "./ProfileContacts";
import ProfileUser from "./ProfileUser";

function ProfileInfo({ profile }) {
  // console.log("Info org:", organization);
  return (
    <div className="page">
      <div className="section">
        <ProfileDetails id={profile.id} />
        <ProfileContacts id={profile.id} />
      </div>
      <div className="section">
        <ProfileUser id={profile.id} />
      </div>
    </div>
  );
}

export default ProfileInfo;

import ModalComponent from "./ModalComponent";
import Form from "./Form";
import CREATE_EVENT from "../api/mutations/createEvent";
import EVENTS from "../api/queries/events";
import SPORTS from "../api/queries/sports";
import ORGANIZATIONS from "../api/queries/organizations";
import { useAppState } from "../utils/appState";
import moment from "moment";

function CreateEvent() {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={CREATE_EVENT}
            initialState={{
              name: "",
              scheduledStart: "",
              scheduledEnd: "",
              registrationEnd: "",
              sportId: "",
              organizedById: "",
            }}
            fields={[
              { label: "Name", type: "text", key: "name" },
              { label: "Start Date", type: "date", key: "scheduledStart" },
              { label: "End Date", type: "date", key: "scheduledEnd" },
              {
                label: "Registration Deadline",
                type: "date",
                key: "registrationEnd",
              },
              {
                label: "Sport",
                type: "select",
                key: "sportId",
                query: SPORTS,
                items: (d) => d.sports,
                optionLabel: (i) => i.name,
              },
              {
                label: "Organized By",
                type: "select",
                key: "organizedById",
                query: ORGANIZATIONS,
                variables: { where: { types: ["Event Organizer"] } },
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
            ]}
            update={(cache, { data: { createEvent } }) => {
              // console.log(createOrganization);
              const q = cache.readQuery({ query: EVENTS });
              cache.writeQuery({
                query: EVENTS,
                data: {
                  events: [createEvent, ...q.events],
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => ({
              data: {
                name: s.name,
                scheduledStart: s.scheduledStart
                  ? moment(s.scheduledStart).toISOString()
                  : null,
                scheduledEnd: s.scheduledEnd
                  ? moment(s.scheduledEnd).toISOString()
                  : null,
                registrationEnd: s.registrationEnd
                  ? moment(s.registrationEnd).toISOString()
                  : null,
                sport: { connect: { id: s.sportId } },
                organizedBy: { connect: { id: s.organizedById } },
              },
            })}
            title={"New Event"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateEvent;

import "./SportDetails.css";
import { Card, CardTitle, EditableField, Spinner, Error } from "../../common";
import { useQuery } from "@apollo/client";
import { SPORT, UPDATE_SPORT } from "../../../api/sports";

export default function SportDetails({ sport }) {
  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet: `
    name    
  `,
    }),
    {
      variables: { id: sport.id },
    }
  );

  if (error) {
    console.log(error);
    return <Error />;
  }

  const { name } = data?.sport || {};

  return (
    <Card className="event-info-page__details">
      <CardTitle title={"DETAILS"} />

      {loading || !data ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="event-info-page__details__items">
          <EditableField
            label={"Name"}
            value={name}
            config={{
              valueType: "text",
              mutation: UPDATE_SPORT({ selectionSet: `name` }),
              vars: (v) => {
                return {
                  where: { id: sport.id },
                  data: {
                    name: v,
                  },
                };
              },
            }}
          />
        </div>
      )}
    </Card>
  );
}

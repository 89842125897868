import { gql } from "@apollo/client";

const HANDLE_CLAIM = gql`
  mutation handleProfileClaim($id: ID!, $approved: Boolean!) {
    handleProfileClaim(id: $id, approved: $approved) {
      id
    }
  }
`;

export default HANDLE_CLAIM;

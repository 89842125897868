import "./CreateEventRegistration.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import { CREATE_REGISTRATION, EVENT_REGISTRATIONS } from "../../../api/events";
import { Loader, SearchWithDropdown } from "../../common";
import { useMutation } from "@apollo/client";

export default function CreateEventRegistration({ ev }) {
  const [, setAppState] = useAppState();
  const [profile, setProfile] = useState("");
  const [organization, setOrganization] = useState("");
  const [paid, setPaid] = useState("");
  const [amountPaid, setAmountPaid] = useState("");
  const [jerseyNum, setJerseyNum] = useState("");
  const [error, setError] = useState("");

  const [mutate, mutation] = useMutation(CREATE_REGISTRATION, {
    update: (cache, { data: { createRegistration } }) => {
      const q = cache.readQuery({
        query: EVENT_REGISTRATIONS({ selectionSet: `` }),
        variables: {
          eventId: ev.id,
        },
      });

      if (q?.eventRegistrations) {
        cache.writeQuery({
          query: EVENT_REGISTRATIONS({ selectionSet: `` }),
          variables: {
            eventId: ev.id,
          },
          data: {
            eventRegistrations: [createRegistration, ...q.eventRegistrations],
          },
        });
      }

      setAppState({ modal: false });

      //   history.push("/app/event/" + createEvent.id);
    },
    onError: (error) => {
      setError(error.message);
      console.log(error);
    },
  });

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">
          Create Event Registration
        </div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!profile?.id) {
            setError("Please fill out all required fields.");
            return;
          }

          const vars = {
            data: {
              eventId: ev.id,
              profileId: profile.id,
              organizationId: organization?.id || null,
              paid: paid === "YES" ? true : false,
              amountPaid: amountPaid ? Number(amountPaid) : 0,
              jerseyNum: jerseyNum ? Number(jerseyNum) : null,
            },
          };

          mutate({
            variables: vars,
          });
        }}
      >
        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">
            Profile<span className="required">*</span>
          </div>

          {profile ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setProfile("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {profile.name}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.profiles || []}
              onItemSelect={(item) => {
                setProfile(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">Team</div>

          {organization ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setOrganization("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {organization.name}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.organizations || []}
              onItemSelect={(item) => {
                setOrganization(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">Paid?</div>

          <div className="App-modal-form__body__group__input">
            <select
              className="App-modal-form__body__group__input__select"
              value={paid}
              onChange={(e) => {
                setPaid(e.target.value);
              }}
            >
              <option value=""></option>
              <option value="YES">YES</option>
              <option value="NO">NO</option>
            </select>
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">Amount Paid</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="number"
              value={amountPaid}
              onChange={(e) => {
                setAmountPaid(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        <div className="App-modal-form__body__group">
          <div className="App-modal-form__body__group__label">Jersey Num</div>

          <div className="App-modal-form__body__group__input">
            <input
              type="number"
              value={jerseyNum}
              onChange={(e) => {
                setJerseyNum(e.target.value);
              }}
              className="App-modal-form__body__group__input__text"
            />
          </div>
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

import { useQuery } from "@apollo/client";
import "../styles/Table.css";
import { useAppState } from "../utils/appState";
import { useHistory } from "react-router-dom";
import Loading from "./Loading";
import { useState } from "react";
import { FaTimes } from "react-icons/fa";

function SearchBar({ refetch, searchConfig }) {
  const [searchText, setSearchText] = useState("");
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  return (
    <div className="search">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!searchText) return;

          const vars = searchConfig.searchVars(searchText);
          console.log("Search Vars:", vars);
          refetch(vars);
          setSearchSubmitted(true);
        }}
      >
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
        />
      </form>

      {searchSubmitted && (
        <div
          className="dismiss-search-btn"
          onClick={() => {
            refetch(searchConfig.clearSearchVars || {});
            setSearchSubmitted(false);
            setSearchText("");
          }}
        >
          <FaTimes style={{ fill: "red" }} />
        </div>
      )}
    </div>
  );
}

function Table({ config }) {
  const {
    query,
    variables = {},
    title,
    headerOptions = [],
    fields,
    itemOnClick,
    hasSearch,
  } = config;
  const { loading, error, data, refetch } = useQuery(query, { variables });
  // const loading = true;
  const history = useHistory();
  const [, setAppState] = useAppState();

  if (error) {
    console.log(error);
  }

  const rows = (data && config.rows(data)) || [];
  console.log("Rows:", rows);
  return (
    <div className="Table">
      <div className="Table-header">
        <div className="title">{title}</div>

        {!(loading || !data) && (
          <div className="options">
            {hasSearch && (
              <SearchBar refetch={refetch} searchConfig={hasSearch} />
            )}

            {headerOptions.map((o) => o(setAppState, rows, refetch))}
          </div>
        )}
      </div>

      <div className="Table-fields">
        {fields.map((f) => (
          <div
            key={f.label}
            className="field"
            style={{ width: f.weight * 100 + "%" }}
          >
            {f.label}
          </div>
        ))}
      </div>

      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <div className="Table-rows">
          {rows.map((r, i) => (
            <div
              key={r.id}
              className={"tr " + (!(i % 2) ? "even" : "odd")}
              onClick={() => {
                itemOnClick(history, r);
              }}
            >
              {fields.map((f) => (
                <div className="td" style={{ width: f.weight * 100 + "%" }}>
                  {f.value(r)}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Table;

import { useAppState } from "../utils/appState";
import ClickOutside from "./ClickOutside";
import "../styles/ModalComponent.css";

function ModalComponent({ children }) {
  const [, setAppState] = useAppState();
  return (
    <ClickOutside
      onClickOutside={() => {
        setAppState({ modal: false });
      }}
    >
      {(wrapperRef) => children(wrapperRef)}
    </ClickOutside>
  );
}

export default ModalComponent;

import { gql } from "@apollo/client";

const CREATE_PROFILE = gql`
  mutation createProfile($data: CreateProfileInput!) {
    createProfile(data: $data) {
      id
      name
      email
      phone
    }
  }
`;

export default CREATE_PROFILE;

import "./AddEventParticipant.css";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import { useAppState } from "../../../utils/appState";
import { useState } from "react";
import {
  CREATE_REGISTRATION,
  EVENT_REGISTRATIONS,
  UPDATE_EVENT,
  EVENT,
} from "../../../api/events";
import { Loader, SearchWithDropdown } from "../../common";
import { useMutation } from "@apollo/client";

export default function AddEventParticipant({ ev, selectionSet }) {
  const [, setAppState] = useAppState();
  const [organization, setOrganization] = useState("");
  const [error, setError] = useState("");

  const [mutate, mutation] = useMutation(UPDATE_EVENT({ selectionSet }), {
    update: (cache, { data: { updateEvent } }) => {
      const q = cache.readQuery({
        query: EVENT({ selectionSet }),
        variables: { id: ev.id },
      });
      cache.writeQuery({
        query: EVENT({ selectionSet }),
        variables: { id: ev.id },
        data: {
          event: {
            ...q.event,
            roles: updateEvent.roles,
          },
        },
      });

      setAppState({ modal: false });

      //   history.push("/app/event/" + createEvent.id);
    },
    onError: (error) => {
      setError(error.message);
      console.log(error);
    },
  });

  return (
    <div className="App-modal-form">
      <div className="App-modal-form__header">
        <div className="App-modal-form__header__title">
          Add Event Participant
        </div>

        <div
          className="App-modal-form__header__dismiss"
          onClick={() => {
            setAppState({ modal: false });
          }}
        >
          <TimesIcon className="App-modal-form__header__dismiss__icon" />
        </div>
      </div>

      <form
        className="App-modal-form__body"
        onSubmit={(e) => {
          e.preventDefault();

          if (mutation.loading) return;

          setError("");

          if (!organization?.id) {
            setError("Please fill out all required fields.");
            return;
          }

          const vars = {
            where: { id: ev.id },
            data: {
              roles: {
                create: [
                  {
                    type: { connect: { name: "PARTICIPANT" } },
                    organization: { connect: { id: organization.id } },
                  },
                ],
              },
            },
          };

          mutate({
            variables: vars,
          });
        }}
      >
        <div
          className="App-modal-form__body__group"
          style={{ height: "240px" }}
        >
          <div className="App-modal-form__body__group__label">Team</div>

          {organization ? (
            <div className="App-modal-form__body__group__input">
              <div
                className="App-modal-form__body__group__input__selected-item"
                onClick={() => {
                  setOrganization("");
                }}
              >
                <div className="App-modal-form__body__group__input__selected-item__label">
                  {organization.name}
                </div>

                <div className="App-modal-form__body__group__input__selected-item__dismiss">
                  <TimesIcon className="App-modal-form__body__group__input__selected-item__dismiss__icon" />
                </div>
              </div>
            </div>
          ) : (
            <SearchWithDropdown
              className={"App-modal-form__body__group__input"}
              inputClassName={"App-modal-form__body__group__input__text"}
              getItems={(d) => d?.search?.organizations || []}
              onItemSelect={(item) => {
                setOrganization(item);
              }}
            />
          )}

          {/* <div className="App-modal-form__body__group__input"></div> */}
        </div>

        {error && <div className="App-modal-form__body__error">{error}</div>}

        <button
          type="submit"
          className={
            "App-button App-modal-form__body__submit-btn " +
            (mutation.loading ? "loading" : "")
          }
        >
          {mutation.loading ? <Loader theme="small" /> : "SUBMIT"}
        </button>
      </form>
    </div>
  );
}

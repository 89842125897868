import SportDetails from "../SportDetails";
import "./SportInfo.css";

export default function SportInfo({ sport }) {
  return (
    <div className="App-grid event-info-page">
      <SportDetails sport={sport} />
    </div>
  );
}

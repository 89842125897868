import { Link } from "react-router-dom";
import "../styles/Home.css";
import { FaChevronRight } from "react-icons/fa";

function Home() {
  return (
    <div className="Home">
      <div className="links">
        <div className="title">Quick Links</div>
        <Link className="link" to="/index/enquiries">
          <div className="label">Enquiries</div>
          <FaChevronRight />
        </Link>

        <Link className="link" to="/index/events">
          <div className="label">Events</div>
          <FaChevronRight />
        </Link>

        <Link className="link" to="/index/organizations">
          <div className="label">Organizations</div>
          <FaChevronRight />
        </Link>

        <Link className="link" to="/index/sports">
          <div className="label">Sports</div>
          <FaChevronRight />
        </Link>

        <Link className="link" to="/index/users">
          <div className="label">Users</div>
          <FaChevronRight />
        </Link>
      </div>
    </div>
  );
}

export default Home;

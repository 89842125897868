import ModalComponent from "./ModalComponent";
import Form from "./Form";
import { useAppState } from "../utils/appState";

function DeleteForm({ type, mutation, update, id, label }) {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <div className="title with-secondary">{`Delete ${type}`}</div>
          <div className="secondary-text">
            <div className="desc">{`Are you sure you want to delete the ${type}: ${label}? This cannot be undone.`}</div>
            <div className="desc">
              Please confirm by typing the following ID in the form below:{" "}
              <span style={{ fontWeight: 800 }}>{id}</span>
            </div>
          </div>
          <Form
            mutation={mutation}
            initialState={{ id: "" }}
            fields={[{ label: "", type: "text", key: "id" }]}
            update={update}
            vars={(s) => {
              if (s.id !== id) {
                return false;
              }

              return {
                id: s.id,
              };
            }}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default DeleteForm;

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import PROFILE from "../../../api/queries/profile";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Table from "../../Table";
import { FaTimes } from "react-icons/fa";
import { useAppState } from "../../../utils/appState";
import CreateEventRegistration from "./CreateEventRegistration";

function ProfileParticipations({ profile }) {
  const [, setAppState] = useAppState();

  return (
    <Table
      config={{
        query: PROFILE,
        variables: {
          id: profile.id,
        },
        headerOptions: [
          () => (
            <div
              className="option"
              onClick={() => {
                setAppState({
                  modal: <CreateEventRegistration profileId={profile.id} />,
                });
              }}
            >
              Add
            </div>
          ),
        ],
        orderBy: [],
        search: () => {},
        fields: [
          { label: "Event", value: (row) => row.event.name, weight: 0.3 },
          {
            label: "Organization",
            value: (row) => row.organization?.name,
            weight: 0.3,
          },
          {
            label: "Paid",
            value: (row) => (row.paid ? "Yes" : "No"),
            weight: 0.2,
          },
          {
            label: "Amount Paid",
            value: (row) => row.amountPaid,
            weight: 0.2,
          },
        ],
        itemOnClick: (history, item) => {
          // setAppState({
          //   modal: (
          //     <ViewRegistration eventRegistration={item} eventId={event.id} />
          //   ),
          // });
        },
        rows: (d) => d.profile.registrations,
        title: "",
      }}
    />
  );
}

export default ProfileParticipations;

import "./EditableField.css";
import { Fragment, useState } from "react";
import { ReactComponent as PencilIcon } from "../../../icons/pencil.svg";
import { ReactComponent as CheckIcon } from "../../../icons/check.svg";
import { ReactComponent as TimesIcon } from "../../../icons/times.svg";
import FormField from "../FormField";
import { useMutation } from "@apollo/client";
import Loader from "../Loader";

function EditableFieldForm({
  label,
  defaultValue = "",
  dismiss,
  config: {
    valueType = "text",
    mutation,
    vars,
    update,
    onError,
    getDefaultValue,
  },
  fieldConfig,
}) {
  const [value, setValue] = useState(
    getDefaultValue ? getDefaultValue(defaultValue) : defaultValue
  );
  const [error, setError] = useState(false);
  const [mutate, { loading }] = useMutation(mutation, {
    update: (cache, result) => {
      if (update) {
        update(cache, result);
      }
      dismiss();
    },
    onError: (e) => {
      onError && onError(e);
      console.log(e);
    },
  });

  return (
    <form
      className="Editable"
      onSubmit={(e) => {
        e.preventDefault();
        console.log(value);
        mutate({ variables: vars(value) });
      }}
    >
      <div className="Editable__content">
        <div className="Editable__content__input-wrapper">
          <div className="Editable__content__input-wrapper__label">
            Edit {label}
          </div>

          <FormField
            type={valueType}
            defaultValue={value}
            config={{
              className: "Editable__content__input-wrapper__input",
              onChange: (v) => {
                setValue(v);
              },
              ...fieldConfig,
            }}
          />
          {/* <input className="Editable__content__input-wrapper__input" /> */}
        </div>
      </div>
      <div className="Editable__options">
        {loading ? (
          <div className="Editable__options__loader">
            <Loader theme={"grey small"} />
          </div>
        ) : (
          <Fragment>
            <button className="Editable__options__option success" typ="submit">
              <CheckIcon className="Editable__options__option__icon" />
            </button>
            <div
              className="Editable__options__option neutral"
              onClick={() => {
                dismiss();
              }}
            >
              <TimesIcon className="Editable__options__option__icon" />
            </div>
          </Fragment>
        )}
      </div>
    </form>
  );
}

export default function EditableField({
  label,
  value,
  getValueString = (x) => x,
  config = {},
  fieldConfig = {},
}) {
  const [editing, setEditing] = useState(false);

  if (editing) {
    return (
      <EditableFieldForm
        label={label}
        config={config}
        fieldConfig={fieldConfig}
        defaultValue={value}
        dismiss={() => {
          setEditing(false);
        }}
      />
    );
  }

  return (
    <div className="Editable">
      <div className="Editable__content">
        <div className="Editable__content__label">{label}</div>

        <div className="Editable__content__value">{getValueString(value)}</div>
      </div>

      <div className="Editable__options">
        <div
          className="Editable__options__option edit"
          onClick={() => {
            setEditing(true);
          }}
        >
          <PencilIcon className="Editable__options__option__icon" />
        </div>
      </div>
    </div>
  );
}

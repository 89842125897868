import { gql } from "@apollo/client";

const INVITE_ADMIN = gql`
  mutation inviteAdmin($email: String!, $name: String!, $phone: String!) {
    inviteAdmin(email: $email, name: $name, phone: $phone) {
      success
      error
    }
  }
`;

export default INVITE_ADMIN;

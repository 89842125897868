import { useQuery } from "@apollo/client";
import { Avatar, Card, CardTitle, Toggle, Loader } from "../../common";
import "./OrganizationMembers.css";
import {
  ORGANIZATION,
  DELETE_ORGANIZATION_MEMBERSHIP,
  UPDATE_ORGANIZATION,
} from "../../../api/organizations";
import { ReactComponent as TrashIcon } from "../../../icons/trash.svg";
import CreateOrganizationMember from "../CreateOrganizationMember";
import { useAppState } from "../../../utils/appState";
import { useMutation } from "@apollo/client";

function OrganizationMembership({ org, membership: m }) {
  const [updateOrganization, updateOrganizationMutation] = useMutation(
    UPDATE_ORGANIZATION({
      selectionSet: `
          members {
              id
              profile {
                id
                name
                handle
                profilePhoto{
                  id
                  filename
                }
              }            
              fromDate
              toDate
              role
              isAdmin
            }      
      `,
    }),
    {
      onError: (error) => {
        // setError(error.message);
        console.log(error);
      },
    }
  );

  const [deleteMembership, deleteMembershipMutation] = useMutation(
    DELETE_ORGANIZATION_MEMBERSHIP,
    {
      update: (cache, { data: { deleteOrganizationMembership } }) => {
        cache.modify({
          id: cache.identify(org),
          fields: {
            members(existingRefs, { readField }) {
              return existingRefs.filter(
                (ref) =>
                  readField("id", ref) !== deleteOrganizationMembership.id
              );
            },
          },
        });
      },
      onError: (error) => {
        // setError(error.message);
        console.log(error);
      },
    }
  );
  return (
    <div className="org-info-page__members__items__item">
      <div className="org-info-page__members__items__item__img">
        {m.profile?.profilePhoto && <Avatar media={m.profile.profilePhoto} />}
      </div>

      <div className="org-info-page__members__items__item__text">
        <div className="org-info-page__members__items__item__text__primary">
          {m.profile?.name}
        </div>
        <div className="org-info-page__members__items__item__text__secondary">
          {m.role}
        </div>
      </div>

      <div className="org-info-page__members__items__item__options">
        <Toggle
          defaultValue={m.isAdmin}
          label={"Admin"}
          //   style={{ marginTop: "-18px", marginBottom: "24px" }}
          onToggle={(val) => {
            updateOrganization({
              variables: {
                where: { id: org.id },
                data: {
                  members: {
                    update: {
                      where: { id: m.id },
                      data: {
                        isAdmin: val,
                      },
                    },
                  },
                },
              },
            });
          }}
        />

        <div
          className="org-info-page__members__items__item__options__option delete"
          onClick={() => {
            if (deleteMembershipMutation.loading) return;

            deleteMembership({
              variables: {
                id: m.id,
              },
            });
            // setEditing(true);
          }}
        >
          {deleteMembershipMutation.loading ? (
            <Loader theme={"red small"} />
          ) : (
            <TrashIcon className="org-info-page__members__items__item__options__option__icon" />
          )}
        </div>
      </div>
    </div>
  );
}

export default function OrganizationMembers({ org }) {
  const [, setAppState] = useAppState();
  const { loading, error, data } = useQuery(
    ORGANIZATION({
      selectionSet: `
        members {
            id
            profile {
              id
              name
              handle
              profilePhoto{
                id
                filename
              }
            }            
            fromDate
            toDate
            role
            isAdmin
          }      
    `,
    }),
    {
      variables: {
        id: org.id,
      },
    }
  );

  const members = data?.organization?.members || [];

  //   console.log(data);

  return (
    <Card className="org-info-page__members">
      <CardTitle
        title={"MEMBERS"}
        options={
          <div
            className="org-info-page__members__add-btn"
            onClick={() => {
              setAppState({
                modal: <CreateOrganizationMember org={org} />,
              });
            }}
          >
            + ADD
          </div>
        }
      />
      <div className="org-info-page__members__items">
        {members.map((m) => (
          <OrganizationMembership org={org} membership={m} />
        ))}
      </div>
    </Card>
  );
}

import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery, useMutation } from "@apollo/client";
import ORGANIZATION from "../../../api/queries/organization";
import PROFILES from "../../../api/queries/profiles";
import UPDATE_ORGANIZATION from "../../../api/mutations/updateOrganization";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";
import Toggle from "../../Toggle";

function OrganizationMembers({ id }) {
  const [updateOrganization, { loading: updating }] =
    useMutation(UPDATE_ORGANIZATION);

  const { loading, error, data } = useQuery(ORGANIZATION, {
    variables: { id },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const organization = data?.organization;

  return (
    <div className="card">
      <div className="card-title">Members</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {organization.members.map((m) => (
              <Editable
                form={(setEditing) => (
                  <Form
                    className={"editable-field-form column"}
                    initialState={{
                      role: m.role,
                      fromDate: m.fromDate
                        ? moment(m.fromDate).format("YYYY-MM-DD")
                        : "",
                      toDate: m.toDate
                        ? moment(m.toDate).format("YYYY-MM-DD")
                        : "",
                      profile: m.profile?.id,
                    }}
                    fields={[
                      {
                        label: "Profile",
                        type: "select",
                        key: "profile",
                        query: PROFILES,
                        items: (d) => d.profiles,
                        optionLabel: (i) => i.name,
                      },
                      {
                        label: "Role",
                        type: "select",
                        key: "role",
                        items: [
                          { label: "Admin", value: "ADMIN" },
                          { label: "Squad Member", value: "SQUAD MEMBER" },
                        ],
                        optionLabel: (i) => i.label,
                      },
                      {
                        label: "From Date",
                        type: "date",
                        key: "fromDate",
                      },
                      {
                        label: "To Date",
                        type: "date",
                        key: "toDate",
                      },
                    ]}
                    mutation={UPDATE_ORGANIZATION}
                    update={(cache, { data: { updateOrganization } }) => {
                      const q = cache.readQuery({
                        query: ORGANIZATION,
                        variables: { id },
                      });
                      cache.writeQuery({
                        query: ORGANIZATION,
                        variables: { id },
                        data: {
                          organization: {
                            ...q.organization,
                            members: updateOrganization.members,
                          },
                        },
                      });

                      setEditing(false);
                    }}
                    vars={(s) => ({
                      where: { id },
                      data: {
                        members: {
                          update: {
                            where: { id: m.id },
                            data: {
                              role: s.role,
                              fromDate: s.fromDate
                                ? moment(s.fromDate).toISOString()
                                : undefined,
                              toDate: s.toDate
                                ? moment(s.toDate).toISOString()
                                : undefined,
                              profile: {
                                connect: { id: s.profile },
                              },
                            },
                          },
                        },
                      },
                    })}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setEditing(false);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                )}
                content={(setEditing) => (
                  <Fragment>
                    <InfoField
                      setEditing={setEditing}
                      label={
                        m.role +
                        ` | ${moment(m.fromDate).format("Do MMM YYYY")} - ${
                          m.toDate
                            ? moment(m.toDate).format("Do MMM YYYY")
                            : "Current"
                        }`
                      }
                      value={m.profile?.name}
                      required={false}
                    />
                    <Toggle
                      defaultValue={m.isAdmin}
                      label={"Is admin?"}
                      style={{ marginTop: "-18px", marginBottom: "24px" }}
                      onToggle={(val) => {
                        updateOrganization({
                          variables: {
                            where: { id: organization.id },
                            data: {
                              members: {
                                update: {
                                  where: { id: m.id },
                                  data: {
                                    isAdmin: val,
                                  },
                                },
                              },
                            },
                          },
                        });
                      }}
                    />
                  </Fragment>
                )}
              />
            ))}
          </div>

          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form column"}
                  initialState={{
                    role: "",
                    fromDate: "",
                    toDate: "",
                    profile: "",
                    isAdmin: false,
                  }}
                  fields={[
                    {
                      label: "Profile",
                      type: "select",
                      key: "profile",
                      query: PROFILES,
                      items: (d) =>
                        [...d.profiles].sort(function (a, b) {
                          return new Date(a.createdAt) - new Date(b.createdAt);
                        }),
                      optionLabel: (i) => i.name,
                    },
                    {
                      label: "Role",
                      type: "select",
                      key: "role",
                      items: [
                        { label: "Member", value: "Member" },
                        { label: "Squad Member", value: "Squad Member" },
                      ],
                      optionLabel: (i) => i.label,
                    },
                    {
                      label: "From Date",
                      type: "date",
                      key: "fromDate",
                    },
                    {
                      label: "To Date",
                      type: "date",
                      key: "toDate",
                    },
                  ]}
                  mutation={UPDATE_ORGANIZATION}
                  update={(cache, { data: { updateOrganization } }) => {
                    // const q = cache.readQuery({
                    //   query: ORGANIZATION,
                    //   variables: { id },
                    // });
                    cache.writeQuery({
                      query: ORGANIZATION,
                      variables: { id },
                      data: {
                        organization: {
                          ...updateOrganization,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => {
                    return {
                      where: { id },
                      data: {
                        members: {
                          create: {
                            role: s.role,
                            fromDate: s.fromDate
                              ? moment(s.fromDate).toISOString()
                              : undefined,
                            toDate: s.toDate
                              ? moment(s.toDate).toISOString()
                              : undefined,
                            profile: { connect: { id: s.profile } },
                          },
                        },
                      },
                    };
                  }}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add Member
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default OrganizationMembers;

import "./EventDetails.css";
import { Card, CardTitle, EditableField, Spinner, Error } from "../../common";
import { useQuery } from "@apollo/client";
import { EVENT, UPDATE_EVENT } from "../../../api/events";
import { SPORTS } from "../../../api/sports";
import { EVENT_TYPES } from "../../../api/queries";
import moment from "moment";

export default function EventDetails({ ev }) {
  const { loading, error, data } = useQuery(
    EVENT({
      selectionSet: `
    name
    participantType
    scheduledStart
    scheduledEnd
    registrationStart
    registrationEnd
    sport{
      id
      name
    }
    type{
      id
      name
    }
    organizedBy{
      id
      name
    }
    city{
      id
      value
    }   
  `,
    }),
    {
      variables: { id: ev.id },
    }
  );

  if (error) {
    console.log(error);
    return <Error />;
  }

  const {
    name,
    sport,
    type,
    participantType,
    scheduledStart,
    scheduledEnd,
    registrationStart,
    registrationEnd,
    organizedBy,
    city,
  } = data?.event || {};

  const participantTypes = [
    { label: "Teams", value: "TEAM" },
    { label: "Individuals", value: "INDIVIDUAL" },
  ];

  return (
    <Card className="event-info-page__details">
      <CardTitle title={"DETAILS"} />

      {loading || !data ? (
        <div className="App-loading-wrapper">
          <Spinner />
        </div>
      ) : (
        <div className="event-info-page__details__items">
          <EditableField
            label={"Name"}
            value={name}
            config={{
              valueType: "text",
              mutation: UPDATE_EVENT({ selectionSet: `name` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    name: v,
                  },
                };
              },
            }}
          />

          <EditableField
            label={"Organized By"}
            value={organizedBy}
            getValueString={(x) => x?.name || ""}
            config={{
              valueType: "search",
              mutation: UPDATE_EVENT({ selectionSet: `organizedBy{id name}` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    organizedBy: { connect: { id: v.id } },
                  },
                };
              },
            }}
            fieldConfig={{
              getItems: (d) => d?.search?.organizations || [],
              getValue: (x) => x?.name || "",
            }}
          />

          <EditableField
            label={"Location"}
            value={city}
            getValueString={(x) => x?.value || ""}
            config={{
              valueType: "location",
              mutation: UPDATE_EVENT({ selectionSet: `city{id value}` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    city: v.city,
                    state: v.state,
                    country: v.country,
                    lat: v.lat,
                    lng: v.lng,
                  },
                };
              },
            }}
          />

          <EditableField
            label={"Sport"}
            value={sport?.name || ""}
            config={{
              valueType: "select-with-query",
              mutation: UPDATE_EVENT({ selectionSet: `sport{id name}` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    sport: { connect: { name: v } },
                  },
                };
              },
            }}
            fieldConfig={{
              query: SPORTS(),
              getItems: (d) =>
                d?.sports?.map((x) => ({ label: x.name, value: x.name })),
            }}
          />

          <EditableField
            label={"Participant Type"}
            value={participantType || ""}
            getValueString={(x) =>
              participantTypes.find((t) => t.value === x)?.label
            }
            config={{
              valueType: "select",
              mutation: UPDATE_EVENT({ selectionSet: `participantType` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    participantType: v,
                  },
                };
              },
            }}
            fieldConfig={{
              items: [{ label: "", value: "" }, ...participantTypes],
            }}
          />

          <EditableField
            label={"Event Type"}
            value={type}
            getValueString={(x) => x?.name}
            config={{
              valueType: "select-with-query",
              mutation: UPDATE_EVENT({ selectionSet: `type{id name}` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    type: { connect: { id: v } },
                  },
                };
              },
              getDefaultValue: (x) => x?.id || "",
            }}
            fieldConfig={{
              query: EVENT_TYPES,
              getItems: (d) =>
                d?.eventTypes?.map((x) => ({ label: x.name, value: x.id })),
            }}
          />

          <EditableField
            label={"Start Date"}
            value={scheduledStart || ""}
            getValueString={(x) => {
              if (x) {
                return moment(x).format("Do MMM YYYY");
              } else {
                return "";
              }
            }}
            config={{
              valueType: "date",
              mutation: UPDATE_EVENT({ selectionSet: `scheduledStart` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    scheduledStart: v && moment(v).toISOString(),
                  },
                };
              },
              getDefaultValue: (x) => {
                if (x) {
                  return moment(x).format("YYYY-MM-DD");
                } else {
                  return "";
                }
              },
            }}
          />

          <EditableField
            label={"End Date"}
            value={scheduledEnd || ""}
            getValueString={(x) => {
              if (x) {
                return moment(x).format("Do MMM YYYY");
              } else {
                return "";
              }
            }}
            config={{
              valueType: "date",
              mutation: UPDATE_EVENT({ selectionSet: `scheduledEnd` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    scheduledEnd: v && moment(v).toISOString(),
                  },
                };
              },
              getDefaultValue: (x) => {
                if (x) {
                  return moment(x).format("YYYY-MM-DD");
                } else {
                  return "";
                }
              },
            }}
          />

          <EditableField
            label={"Registration Start Date"}
            value={registrationStart || ""}
            getValueString={(x) => {
              if (x) {
                return moment(x).format("Do MMM YYYY");
              } else {
                return "";
              }
            }}
            config={{
              valueType: "date",
              mutation: UPDATE_EVENT({ selectionSet: `registrationStart` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    registrationStart: v && moment(v).toISOString(),
                  },
                };
              },
              getDefaultValue: (x) => {
                if (x) {
                  return moment(x).format("YYYY-MM-DD");
                } else {
                  return "";
                }
              },
            }}
          />

          <EditableField
            label={"Registration End Date"}
            value={registrationEnd || ""}
            getValueString={(x) => {
              if (x) {
                return moment(x).format("Do MMM YYYY");
              } else {
                return "";
              }
            }}
            config={{
              valueType: "date",
              mutation: UPDATE_EVENT({ selectionSet: `registrationEnd` }),
              vars: (v) => {
                return {
                  where: { id: ev.id },
                  data: {
                    registrationEnd: v && moment(v).toISOString(),
                  },
                };
              },
              getDefaultValue: (x) => {
                if (x) {
                  return moment(x).format("YYYY-MM-DD");
                } else {
                  return "";
                }
              },
            }}
          />
        </div>
      )}
    </Card>
  );
}

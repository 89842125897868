import Editable from "../../Editable";
import InfoField from "../../InfoField";
import { useQuery } from "@apollo/client";
import PROFILE from "../../../api/queries/profile";
import UPDATE_PROFILE from "../../../api/mutations/updateProfile";
// import SPORTS from "../../../api/queries/sports";
import moment from "moment";
import Form from "../../Form";
import { FaTimes } from "react-icons/fa";

import Loading from "../../Loading";
import { Fragment } from "react";

function ProfileContacts({ id }) {
  const { loading, error, data } = useQuery(PROFILE, {
    variables: { id },
  });

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    // return <div>Loading</div>;
  }

  const profile = data?.profile;

  return (
    <div className="card">
      <div className="card-title">Contact Details</div>
      {loading || !data ? (
        <div className="loading-wrapper">
          <Loading />
        </div>
      ) : (
        <Fragment>
          <div className="card-body">
            {profile.contactMechanisms.map((c) => (
              <Editable
                form={(setEditing) => (
                  <Form
                    className={"editable-field-form"}
                    initialState={{ type: c.type, value: c.value }}
                    fields={[
                      {
                        label: "Type",
                        type: "select",
                        key: "type",
                        items: [
                          { label: "Phone", value: "Phone" },
                          { label: "Email", value: "Email" },
                        ],
                        optionLabel: (i) => i.label,
                      },
                      {
                        label: "Value",
                        type: "text",
                        key: "value",
                      },
                    ]}
                    mutation={UPDATE_PROFILE}
                    update={(cache, { data: { updateProfile } }) => {
                      const q = cache.readQuery({
                        query: PROFILE,
                        variables: { id },
                      });
                      cache.writeQuery({
                        query: PROFILE,
                        variables: { id },
                        data: {
                          profile: {
                            ...q.profile,
                            contactMechanisms: updateProfile.contactMechanisms,
                          },
                        },
                      });

                      setEditing(false);
                    }}
                    vars={(s) => ({
                      where: { id },
                      data: {
                        contactMechanisms: {
                          update: {
                            where: { id: c.id },
                            data: {
                              type: s.type,
                              value: s.value,
                            },
                          },
                        },
                      },
                    })}
                    options={[
                      <div
                        className="option cancel"
                        onClick={() => {
                          setEditing(false);
                        }}
                      >
                        <FaTimes />
                      </div>,
                    ]}
                  />
                )}
                content={(setEditing) => (
                  <InfoField
                    setEditing={setEditing}
                    label={c.type + (c.subtype ? " - " + c.subtype : "")}
                    value={c.value}
                    required={false}
                  />
                )}
              />
            ))}
          </div>

          <div className="card-footer">
            <Editable
              form={(setEditing) => (
                <Form
                  className={"editable-field-form"}
                  initialState={{
                    type: "",
                    value: "",
                  }}
                  fields={[
                    {
                      label: "Type",
                      type: "select",
                      key: "type",
                      items: [
                        { label: "Phone", value: "Phone" },
                        { label: "Email", value: "Email" },
                      ],
                      optionLabel: (i) => i.label,
                    },
                    {
                      label: "Value",
                      type: "text",
                      key: "value",
                    },
                  ]}
                  mutation={UPDATE_PROFILE}
                  update={(cache, { data: { updateProfile } }) => {
                    const q = cache.readQuery({
                      query: PROFILE,
                      variables: { id },
                    });
                    cache.writeQuery({
                      query: PROFILE,
                      variables: { id },
                      data: {
                        profile: {
                          ...q.profile,
                          contactMechanisms: updateProfile.contactMechanisms,
                        },
                      },
                    });

                    setEditing(false);
                  }}
                  vars={(s) => {
                    return {
                      where: { id },
                      data: {
                        contactMechanisms: {
                          create: {
                            type: s.type,
                            value: s.value,
                          },
                        },
                      },
                    };
                  }}
                  options={[
                    <div
                      className="option cancel"
                      onClick={() => {
                        setEditing(false);
                      }}
                    >
                      <FaTimes />
                    </div>,
                  ]}
                />
              )}
              content={(setEditing) => (
                <div
                  className={"App-btn success hover-pointer "}
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  Add Contact Info
                </div>
              )}
            />
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default ProfileContacts;

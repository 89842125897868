import ProfileClaimItem from "./ProfileClaimItem";
import "../../styles/ProfileClaimsList.css";

export default function ProfileClaimsList({ profile }) {
  const claims = profile.claims;
  return (
    <div className="profile-claims-list-page">
      {claims.map((claim) => (
        <ProfileClaimItem claim={claim} profile={profile} />
      ))}
    </div>
  );
}

import ModalComponent from "./ModalComponent";
import Form from "./Form";
import CREATE_PROFILE from "../api/mutations/createProfile";
import PROFILES from "../api/queries/profiles";
import { useAppState } from "../utils/appState";

function CreateProfile() {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={CREATE_PROFILE}
            initialState={{ name: "", phone: "", email: "" }}
            fields={[
              { label: "Name", type: "text", key: "name" },
              { label: "Email", type: "text", key: "email" },
              { label: "Phone", type: "text", key: "phone" },
            ]}
            update={(cache, { data: { createProfile } }) => {
              console.log(createProfile);
              const q = cache.readQuery({ query: PROFILES });
              cache.writeQuery({
                query: PROFILES,
                data: {
                  profiles: [createProfile, ...q.profiles],
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => ({
              data: {
                name: s.name,
                email: s.email,
                phone: s.phone,
              },
            })}
            title={"New Profile"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateProfile;

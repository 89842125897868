import { useState } from "react";
import "./NewDropdown.css";
import { usePopper } from "react-popper";
import ClickOutside from "../ClickOutside";

function NewDropdown({
  target,
  menu,
  onOpen = () => {},
  onClose = () => {},
  placement,
  wrapperClass = "",
  targetWrapperClass = "",
  menuStyle = {},
}) {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "bottom-end",
    modifiers: [{ name: "arrow", options: { element: arrowElement } }],
  });

  return (
    <div className={"app-dropdown " + wrapperClass}>
      <div
        ref={setReferenceElement}
        onClick={() => {
          setShowMenu(true);
          onOpen();
        }}
        onFocus={() => {
          setShowMenu(true);
          onOpen();
        }}
        className={"hover-pointer " + targetWrapperClass}
        style={showMenu ? { pointerEvents: "none" } : {}}
      >
        {typeof target === "function" ? target({ showMenu }) : target}
      </div>

      {showMenu && (
        <ClickOutside
          onClickOutside={() => {
            setShowMenu(false);
            onClose();
          }}
        >
          {(wrapperRef) => (
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 5, ...menuStyle }}
              {...attributes.popper}
            >
              <div className="dropdown-menu-wrapper" ref={wrapperRef}>
                {typeof menu === "function" ? menu({ setShowMenu }) : menu}
                <div ref={setArrowElement} style={styles.arrow} />
              </div>
            </div>
          )}
        </ClickOutside>
      )}
    </div>
  );
}

export default NewDropdown;

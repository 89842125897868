import { gql } from "@apollo/client";

const UPDATE_EVENT_ENQUIRY = gql`
  mutation updateEventEnquiry(
    $where: UniqueInput!
    $data: UpdateEventEnquiryInput!
  ) {
    updateEventEnquiry(where: $where, data: $data) {
      id
      event {
        id
        name
      }
      email
      phone
      name
      city
      notes {
        id
        createdAt
        text
      }
      registration {
        id
        paid
        amountPaid
        profile {
          id
          name
          email
          phone
        }
        organization {
          id
          name
        }
        event {
          id
          name
        }
      }
    }
  }
`;

export default UPDATE_EVENT_ENQUIRY;

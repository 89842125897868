import ModalComponent from "./ModalComponent";
import Form from "./Form";
import CREATE_REGISTRATION from "../api/mutations/createRegistration";
import ORGANIZATIONS from "../api/queries/organizations";
import PROFILES from "../api/queries/profiles";
import { EVENT_REGISTRATIONS } from "../api/events";
import { useAppState } from "../utils/appState";
import moment from "moment";

function CreateRegistration({ eventId }) {
  const [, setAppState] = useAppState();
  return (
    <ModalComponent>
      {(wrapperRef) => (
        <div ref={wrapperRef} className="modal-form">
          <Form
            mutation={CREATE_REGISTRATION}
            initialState={{
              profileId: "",
              organizationId: "",
              paid: false,
              amountPaid: 0,
            }}
            fields={[
              {
                label: "Player",
                type: "select",
                key: "profileId",
                query: PROFILES,
                items: (d) => d.profiles,
                optionLabel: (i) => i.name,
              },
              {
                label: "Team",
                type: "select",
                key: "organizationId",
                query: ORGANIZATIONS,
                variables: { where: { types: ["Sports Team"] } },
                items: (d) => d.organizations,
                optionLabel: (i) => i.name,
              },
              {
                label: "Paid",
                type: "select",
                key: "paid",
                items: [
                  { label: "Yes", value: true },
                  { label: "No", value: false },
                ],
              },
              { label: "Amount Paid", type: "number", key: "amountPaid" },
            ]}
            update={(cache, { data: { createRegistration } }) => {
              console.log("Create Registration");
              console.log(createRegistration);
              const q = cache.readQuery({
                query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                variables: { eventId },
              });
              cache.writeQuery({
                query: EVENT_REGISTRATIONS({ selectionSet: "" }),
                variables: { eventId },
                data: {
                  eventRegistrations: [
                    createRegistration,
                    ...q.eventRegistrations,
                  ],
                },
              });
              setAppState({ modal: false });
            }}
            vars={(s) => {
              const v = {
                data: {
                  eventId,
                  profileId: s.profileId,
                  organizationId: s.organizationId,
                  paid: s.paid === "true" ? true : false,
                  amountPaid: Number(s.amountPaid),
                },
              };

              console.log(v);
              return v;
            }}
            title={"New Registration"}
          />
        </div>
      )}
    </ModalComponent>
  );
}

export default CreateRegistration;

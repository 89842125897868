import Table from "../../Table";
import CreateFormation from "./CreateFormation";
import ViewRegistration from "../../ViewRegistration";
import UploadData from "../../common/UploadData";
import exportToCSV from "../../../utils/exportToCSV";
import { SPORT } from "../../../api/sports";
import { useAppState } from "../../../utils/appState";
import { FaUpload } from "react-icons/fa";
import { useQuery } from "@apollo/client";

import Loading from "../../Loading";
import { Fragment } from "react";

function SportFormations({ sport }) {
  const [, setAppState] = useAppState();
  const selectionSet = `
    formations{
      id
      name
      positions{
        id
        positionType{
          id
          name
          shortName
        }
        x
        y
      }
    }
    positionTypes{
      id
      name
      shortName
    }
  `;

  const { loading, error, data } = useQuery(
    SPORT({
      selectionSet,
    }),
    { variables: { id: sport.id } }
  );

  if (error) {
    console.log(error);
    return <div>Error</div>;
  }

  if (loading || !data) {
    return (
      <div className="loading-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <Table
      config={{
        query: SPORT({ selectionSet }),
        variables: {
          id: sport.id,
        },
        headerOptions: [
          () => (
            <div
              className="option"
              onClick={() => {
                setAppState({
                  modal: (
                    <CreateFormation
                      sport={data.sport}
                      selectionSet={selectionSet}
                    />
                  ),
                });
              }}
            >
              Add
            </div>
          ),
        ],
        orderBy: [],
        search: () => {},
        fields: [
          { label: "Name", value: (row) => row.name, weight: 0.9 },
          { label: "", value: () => "", weight: 0.1 },
        ],
        itemOnClick: (history, item) => {
          setAppState({
            modal: (
              <CreateFormation
                formation={item}
                sport={data.sport}
                selectionSet={selectionSet}
              />
            ),
          });
        },
        rows: (d) => d.sport.formations,
        title: "",
      }}
    />
  );
}

export default SportFormations;

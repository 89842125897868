// V1
import { useState } from "react";
import "./Post.css";
import Card from "../Card";
import YouTubeVideo from "../YouTubeVideo";
import makeFileUrl from "../../../utils/makeFileUrl";
import { useAppState } from "../../../utils/appState";
import moment from "moment";
import Avatar from "../Avatar/Avatar";
import ReactQuill from "react-quill";

function PostMedia({ media }) {
  if (media.type === "IMAGE") {
    return <img src={makeFileUrl(media)} alt="" />;
  } else if (media.type === "VIDEO") {
    if (media.isExternal) {
      return <YouTubeVideo filename={media.filename} />;
    } else {
      return (
        <video controls controlsList="nodownload">
          <source src={makeFileUrl(media)} type="video/mp4" />
        </video>
      );
    }
  } else {
    return <div></div>;
  }
}

function getPostMediaGridValues(length, index) {
  if (length === 1) {
    return {
      gridColumnStart: 1,
      gridColumnEnd: 3,
      gridRowStart: 1,
      gridRowEnd: 3,
    };
  } else if (length === 2) {
    return {
      gridColumnStart: index + 1,
      gridColumnEnd: index + 2,
      gridRowStart: 1,
      gridRowEnd: 3,
    };
  } else if (length === 3) {
    if (!index) {
      return {
        gridColumnStart: 1,
        gridColumnEnd: 2,
        gridRowStart: 1,
        gridRowEnd: 3,
      };
    } else {
      return {
        gridColumnStart: 2,
        gridColumnEnd: 3,
        gridRowStart: index,
        gridRowEnd: index + 1,
      };
    }
  } else {
    return {};
  }
}

function Post({ post }) {
  const [, setAppState] = useAppState();
  // const [deleting, setDeleting] = useState(false);
  // const [editing, setEditing] = useState(false);
  const media = post.tags.filter((x) => !!x.media);
  // const mediaSets = post.tags.filter((x) => !!x.mediaSet);
  // const [clapped, setClapped] = useState(post.isClapped);
  // const [clapCount, setClapCount] = useState(post.clapCount);
  // const [saved, setSaved] = useState(post.isSaved);
  const [truncated, setTruncated] = useState(
    (post.contentLength || 0) > 200 ? true : false
  );
  const source =
    post.source.profile ||
    post.source.organization ||
    post.source.event?.organizedBy ||
    post.source.fixture?.event.organizedBy;

  const sourceType = post.sourceType;

  return (
    <Card className="app-post-card">
      <div className="head">
        <div className="avatar">
          {source?.profilePhoto && <Avatar media={source.profilePhoto} />}
        </div>
        <div className="meta">
          <div className="primary hover-underline">{source?.name}</div>
          <div className="secondary">{moment(post.createdAt).fromNow()}</div>
        </div>
      </div>

      <div className="text">
        <ReactQuill
          id={"post-content-" + post.id}
          value={truncated ? post.truncatedContent : post.content}
          readOnly={true}
          modules={{ toolbar: false }}
        />
        {truncated ? (
          <div
            className="truncate-button"
            onClick={() => {
              setTruncated(false);
            }}
          >
            See more
          </div>
        ) : post.contentLength > 200 ? (
          <div
            className="truncate-button"
            onClick={() => {
              setTruncated(true);
            }}
          >
            See less
          </div>
        ) : (
          false
        )}
      </div>
      {!!media.length && (
        <div className="app-post-card__media">
          {[...media].slice(0, 3).map((m, i) => (
            <div
              className="app-post-card__media__item"
              style={getPostMediaGridValues(media.length, i)}
              onClick={() => {
                // setAppState({
                //   modal: (
                //     <MediaBrowserComponent
                //       items={media.map((x) => x.media)}
                //       media={m.media}
                //     />
                //   ),
                // });
              }}
            >
              <PostMedia media={m.media} />
              <div className="app-post-card__media__item__overlay" />
            </div>
          ))}

          {media.length === 4 && (
            <div
              className="app-post-card__media__item"
              onClick={() => {
                // setAppState({
                //   modal: (
                //     <MediaBrowserComponent
                //       items={media.map((x) => x.media)}
                //       media={media[3].media}
                //     />
                //   ),
                // });
              }}
            >
              <PostMedia media={media[3].media} />
              <div className="app-post-card__media__item__overlay" />
            </div>
          )}

          {media.length > 4 && (
            <div
              className="app-post-card__media__item"
              onClick={() => {
                // setAppState({
                //   modal: (
                //     <MediaBrowserComponent
                //       items={media.map((x) => x.media)}
                //       media={media[3].media}
                //     />
                //   ),
                // });
              }}
            >
              <PostMedia media={media[3].media} />
              <div className="app-post-card__media__item__overlay">
                + {media.length - 3}
              </div>
            </div>
          )}
        </div>
      )}
    </Card>
  );
}

export default Post;

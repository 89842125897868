import OrganizationDetails from "./OrganizationDetails";
import OrganizationContacts from "./OrganizationContacts";
import OrganizationClassification from "./OrganizationClassification";
import OrganizationMembers from "./OrganizationMembers";

function OrganizationInfo({ organization }) {
  console.log("Info org:", organization);
  return (
    <div className="page">
      <div className="section">
        <OrganizationDetails id={organization.id} />
        <OrganizationContacts id={organization.id} />
      </div>
      <div className="section">
        <OrganizationClassification id={organization.id} />
        <OrganizationMembers id={organization.id} />
      </div>
    </div>
  );
}

export default OrganizationInfo;

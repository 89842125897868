import { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom";
import LOGIN from "../api/mutations/login";
import queryString from "query-string";

function Logout() {
  const history = useHistory();
  // console.log(location);

  useEffect(() => {
    localStorage.setItem("sp_auth_token", "");
    localStorage.setItem("sp_expires_at", "");
    localStorage.setItem("sp_name", "");
    history.push("/auth");
  }, []);

  return <div />;
}

export default Logout;

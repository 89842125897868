import { gql } from "@apollo/client";

const FIXTURE = gql`
  query fixture($id: ID!) {
    fixture(id: $id) {
      id
      label
      totalLegs
      event {
        id
      }
    }
  }
`;

export default FIXTURE;

import { Route, Redirect } from "react-router-dom";
import moment from "moment";

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const token = localStorage.getItem("sp_auth_token");
      const expiresAt = localStorage.getItem("sp_expires_at");

      if (!token || !expiresAt || moment().isAfter(expiresAt)) {
        return (
          <Redirect
            to={{
              pathname: "/auth",
              state: { from: props.location },
            }}
          />
        );
      }

      return <Component {...props} />;
    }}
  />
);

export default ProtectedRoute;
